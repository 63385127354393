import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const attendance = data => getInstance().post(API.ATTENDANCE, data)
export const attendanceCsv = data => getInstance().post(API.ATTENDANCE_CSV, data)
export const attendanceGroup = data =>
  getInstance().get(API.ATTENDANCE_GROUP, {
    params: data,
  })

export const attendanceTable = data =>
  getInstance().get(API.ATTENDANCE_TABLE, {
    params: data,
  })

export const attendanceTableStudent = data =>
  getInstance().get(API.ATTENDANCE_TABLE_STUDENT, {
    params: data,
  })

export const attendanceTableModule = data =>
  getInstance().get(API.ATTENDANCE_TABLE_MODULE, {
    params: data,
  })

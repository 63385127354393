import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  PRODUCT_CATEGORY_LIST,
  PRODUCT_COLOR_LIST,
  PRODUCT_ITEM,
  PRODUCT_ITEM_ALL_SHORT,
  PRODUCT_ITEM_CLIENT,
  PRODUCT_ITEM_LIST,
  PRODUCT_MATERIAL_LIST,
  PRODUCT_SIZE_LIST,
  PRODUCT_TRADE_BUYER_ALL,
  PRODUCT_TRADE_LIST,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const SHOP = GLOBAL_STATE

export const { reducer: shopReducer } = createSlice({
  name: SHOP,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.productCategoryList.pending, state => {
        state[PRODUCT_CATEGORY_LIST].loading = true
      })
      .addCase(actionCreators.productCategoryList.rejected, (state, action) => {
        state[PRODUCT_CATEGORY_LIST].loading = false
        state[PRODUCT_CATEGORY_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productCategoryList.fulfilled, (state, action) => {
        state[PRODUCT_CATEGORY_LIST].loading = false
        state[PRODUCT_CATEGORY_LIST].error = null
        state[PRODUCT_CATEGORY_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.productColorList.pending, state => {
        state[PRODUCT_COLOR_LIST].loading = true
      })
      .addCase(actionCreators.productColorList.rejected, (state, action) => {
        state[PRODUCT_COLOR_LIST].loading = false
        state[PRODUCT_COLOR_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productColorList.fulfilled, (state, action) => {
        state[PRODUCT_COLOR_LIST].loading = false
        state[PRODUCT_COLOR_LIST].error = null
        state[PRODUCT_COLOR_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.productItemList.pending, state => {
        state[PRODUCT_ITEM_LIST].loading = true
      })
      .addCase(actionCreators.productItemList.rejected, (state, action) => {
        state[PRODUCT_ITEM_LIST].loading = false
        state[PRODUCT_ITEM_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productItemList.fulfilled, (state, action) => {
        state[PRODUCT_ITEM_LIST].loading = false
        state[PRODUCT_ITEM_LIST].error = null
        state[PRODUCT_ITEM_LIST].data = action.payload
      })

    builder.addCase(actionCreators.productItemListClear, state => {
      state[PRODUCT_ITEM_LIST] = defaultState()
    })

    builder
      .addCase(actionCreators.productItemAllShort.pending, state => {
        state[PRODUCT_ITEM_ALL_SHORT].loading = true
      })
      .addCase(actionCreators.productItemAllShort.rejected, (state, action) => {
        state[PRODUCT_ITEM_ALL_SHORT].loading = false
        state[PRODUCT_ITEM_ALL_SHORT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productItemAllShort.fulfilled, (state, action) => {
        state[PRODUCT_ITEM_ALL_SHORT].loading = false
        state[PRODUCT_ITEM_ALL_SHORT].error = null
        state[PRODUCT_ITEM_ALL_SHORT].data = action.payload
      })

    builder.addCase(actionCreators.productItemAllShortClear, state => {
      state[PRODUCT_ITEM_ALL_SHORT] = defaultState()
    })

    builder
      .addCase(actionCreators.productItem.pending, state => {
        state[PRODUCT_ITEM].loading = true
      })
      .addCase(actionCreators.productItem.rejected, (state, action) => {
        state[PRODUCT_ITEM].loading = false
        state[PRODUCT_ITEM].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productItem.fulfilled, (state, action) => {
        state[PRODUCT_ITEM].loading = false
        state[PRODUCT_ITEM].error = null
        state[PRODUCT_ITEM].data = action.payload
      })

    builder.addCase(actionCreators.productItemClear, state => {
      state[PRODUCT_ITEM] = defaultState()
    })

    builder
      .addCase(actionCreators.productItemClient.pending, state => {
        state[PRODUCT_ITEM_CLIENT].loading = true
      })
      .addCase(actionCreators.productItemClient.rejected, (state, action) => {
        state[PRODUCT_ITEM_CLIENT].loading = false
        state[PRODUCT_ITEM_CLIENT].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productItemClient.fulfilled, (state, action) => {
        state[PRODUCT_ITEM_CLIENT].loading = false
        state[PRODUCT_ITEM_CLIENT].error = null
        state[PRODUCT_ITEM_CLIENT].data = action.payload
      })

    builder.addCase(actionCreators.productItemClientClear, state => {
      state[PRODUCT_ITEM_CLIENT] = defaultState()
    })

    builder
      .addCase(actionCreators.productMaterialList.pending, state => {
        state[PRODUCT_MATERIAL_LIST].loading = true
      })
      .addCase(actionCreators.productMaterialList.rejected, (state, action) => {
        state[PRODUCT_MATERIAL_LIST].loading = false
        state[PRODUCT_MATERIAL_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productMaterialList.fulfilled, (state, action) => {
        state[PRODUCT_MATERIAL_LIST].loading = false
        state[PRODUCT_MATERIAL_LIST].error = null
        state[PRODUCT_MATERIAL_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.productSizeList.pending, state => {
        state[PRODUCT_SIZE_LIST].loading = true
      })
      .addCase(actionCreators.productSizeList.rejected, (state, action) => {
        state[PRODUCT_SIZE_LIST].loading = false
        state[PRODUCT_SIZE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productSizeList.fulfilled, (state, action) => {
        state[PRODUCT_SIZE_LIST].loading = false
        state[PRODUCT_SIZE_LIST].error = null
        state[PRODUCT_SIZE_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.productTradeList.pending, state => {
        state[PRODUCT_TRADE_LIST].loading = true
      })
      .addCase(actionCreators.productTradeList.rejected, (state, action) => {
        state[PRODUCT_TRADE_LIST].loading = false
        state[PRODUCT_TRADE_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productTradeList.fulfilled, (state, action) => {
        state[PRODUCT_TRADE_LIST].loading = false
        state[PRODUCT_TRADE_LIST].error = null
        state[PRODUCT_TRADE_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.productTradeBuyerAll.pending, state => {
        state[PRODUCT_TRADE_BUYER_ALL].loading = true
      })
      .addCase(actionCreators.productTradeBuyerAll.rejected, (state, action) => {
        state[PRODUCT_TRADE_BUYER_ALL].loading = false
        state[PRODUCT_TRADE_BUYER_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.productTradeBuyerAll.fulfilled, (state, action) => {
        state[PRODUCT_TRADE_BUYER_ALL].loading = false
        state[PRODUCT_TRADE_BUYER_ALL].error = null
        state[PRODUCT_TRADE_BUYER_ALL].data = action.payload
      })

    builder.addCase(actionCreators.productTradeBuyerAllClear, state => {
      state[PRODUCT_TRADE_BUYER_ALL] = defaultState()
    })
  },
})

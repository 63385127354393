import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const accountInstance = getInstance()

export const account = data =>
  accountInstance.get(`${API.ACCOUNT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const accountTypes = data =>
  accountInstance.get(API.ACCOUNT_TYPES, {
    params: data,
  })

export const accountCreate = data => accountInstance.post(API.ACCOUNT, data)

export const accountDelete = data => accountInstance.delete(`${API.ACCOUNT}${prop('id', data)}`)

export const accountList = data =>
  accountInstance.get(API.ACCOUNT, {
    params: data,
  })

export const accountAll = data =>
  accountInstance.get(API.ACCOUNT_ALL, {
    params: data,
  })

export const accountPasswordReset = data => accountInstance.put(API.ACCOUNT_PASSWORD_RESET, data)

export const accountRequiredAction = data => accountInstance.put(API.ACCOUNT_REQUIRED_ACTION, data)

export const accountProfilePic = data => accountInstance.put(API.ACCOUNT_PROFILE_PIC, data)

export const accountProfileUpdate = data => accountInstance.put(API.ACCOUNT_PROFILE_UPDATE, data)

import { toCamelCase } from '@helpers/toCamelCase'

export const FEEDBACK_LIST = 'FEEDBACK_LIST'
export const FEEDBACK_ALL = 'FEEDBACK_ALL'
export const FEEDBACK_CREATE = 'FEEDBACK_CREATE'
export const FEEDBACK_EDIT = 'FEEDBACK_EDIT'
export const FEEDBACK_STATUS = 'FEEDBACK_STATUS'
export const FEEDBACK_TYPES = 'FEEDBACK_TYPES'
export const FEEDBACK_UPDATE = 'FEEDBACK_UPDATE'

export const FEEDBACK_FORM = 'FEEDBACK_FORM'
export const FEEDBACK_FORM_LIST = 'FEEDBACK_FORM_LIST'
export const FEEDBACK_FORM_ALL = 'FEEDBACK_FORM_ALL'
export const FEEDBACK_FORM_CREATE = 'FEEDBACK_FORM_CREATE'
export const FEEDBACK_FORM_DELETE = 'FEEDBACK_FORM_DELETE'

export const GLOBAL_STATE = 'Feedback'

export const actionNames = {
  [toCamelCase(FEEDBACK_LIST)]: FEEDBACK_LIST,
  [toCamelCase(FEEDBACK_ALL)]: FEEDBACK_ALL,
  [toCamelCase(FEEDBACK_CREATE)]: FEEDBACK_CREATE,
  [toCamelCase(FEEDBACK_EDIT)]: FEEDBACK_EDIT,
  [toCamelCase(FEEDBACK_STATUS)]: FEEDBACK_STATUS,
  [toCamelCase(FEEDBACK_TYPES)]: FEEDBACK_TYPES,
  [toCamelCase(FEEDBACK_UPDATE)]: FEEDBACK_UPDATE,

  [toCamelCase(FEEDBACK_FORM)]: FEEDBACK_FORM,
  [toCamelCase(FEEDBACK_FORM_LIST)]: FEEDBACK_FORM_LIST,
  [toCamelCase(FEEDBACK_FORM_ALL)]: FEEDBACK_FORM_ALL,
  [toCamelCase(FEEDBACK_FORM_CREATE)]: FEEDBACK_FORM_CREATE,
  [toCamelCase(FEEDBACK_FORM_DELETE)]: FEEDBACK_FORM_DELETE,
}

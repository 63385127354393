import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/state'

export const moduleFileListSelector = createSelector(path([GLOBAL_STATE, MODULE_FILE_LIST]), data => data)

export const moduleFileAllSelector = createSelector(path([GLOBAL_STATE, MODULE_FILE_ALL]), data => data)

export const moduleFileSelector = createSelector(path([GLOBAL_STATE, MODULE_FILE]), data => data)

export const moduleFileCreateSelector = createSelector(path([GLOBAL_STATE, MODULE_FILE_CREATE]), data => data)

export const moduleFileDeleteSelector = createSelector(path([GLOBAL_STATE, MODULE_FILE_DELETE]), data => data)

export const moduleFileSelectors = {
  moduleFileListSelector,
  moduleFileAllSelector,
  moduleFileSelector,
  moduleFileCreateSelector,
  moduleFileDeleteSelector,
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../../constants/level/api'

export const level = data =>
  getInstance().get(`${API.LEVEL}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const levelCreate = data => getInstance().post(API.LEVEL, data)

export const levelDelete = data => getInstance().delete(`${API.LEVEL}${prop('id', data)}`)

export const levelList = data =>
  getInstance().get(API.LEVEL, {
    params: data,
  })

export const levelAll = data =>
  getInstance().get(API.LEVEL_ALL, {
    params: data,
  })

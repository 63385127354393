import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.COUNTRY_LIST]: createState(actionTypes.COUNTRY_LIST),
  [STATE.COUNTRY_CREATE]: createState(actionTypes.COUNTRY_CREATE),
  [STATE.COUNTRY_ALL]: createState(actionTypes.COUNTRY_ALL),
  [STATE.COUNTRY]: createState(actionTypes.COUNTRY),
})

export const COUNTRY = STATE.GLOBAL_STATE

export default reducer

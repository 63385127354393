export const GLOBAL_STATE = 'Assessment'

export const ASSESSMENT = 'assessment'
export const ASSESSMENT_BULK = 'assessmentBulk'
export const ASSESSMENT_LIST = 'assessmentList'
export const ASSESSMENT_COPY = 'assessmentCopy'
export const ASSESSMENT_CRITERIA = 'assessmentCriteria'
export const ASSESSMENT_CRITERIA_UPDATE = 'assessmentCriteriaUpdate'
export const ASSESSMENT_CRITERIA_LIST = 'assessmentCriteriaList'
export const ASSESSMENT_ATTEMPT = 'assessmentAttempt'
export const ASSESSMENT_CRITERIA_TYPES = 'assessmentCriteriaTypes'
export const ASSESSMENT_ATTEMPT_ITEM = 'assessmentAttemptItem'
export const ASSESSMENT_SUBMIT = 'assessmentSubmit'
export const ASSESSMENT_STUDENT_UPLOAD = 'assessmentStudentUpload'
export const ASSESSMENT_STUDENT = 'assessmentStudent'
export const ASSESSMENT_CREATE = 'assessmentCreate'
export const ATTEMPT_DELETE = 'attemptDelete'
export const ASSESSMENT_SINGLE = 'assessmentSingle'
export const ASSESSMENT_LOG = 'assessmentLog'

export const GLOBAL_STATE = 'Meals'

export const MEAL = 'MEAL' as const
export const MEAL_LIST = 'MEAL_LIST' as const
export const MEAL_ALL = 'MEAL_ALL' as const
export const MEAL_CREATE = 'MEAL_CREATE' as const
export const MEAL_DELETE = 'MEAL_DELETE' as const

export const MEAL_ACTIVATION = 'MEAL_ACTIVATION' as const
export const MEAL_ACTIVATION_ALL = 'MEAL_ACTIVATION_ALL' as const
// export const MEAL_ACTIVATION_CREATE = 'MEAL_ACTIVATION_CREATE' as const
export const MEAL_ACTIVATION_DELETE = 'MEAL_ACTIVATION_DELETE' as const
export const MEAL_ACTIVATION_UPDATE_STATUS = 'MEAL_ACTIVATION_UPDATE_STATUS' as const

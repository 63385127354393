import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.SEMESTER]: createState(actionTypes.SEMESTER),
  [STATE.SEMESTER_LIST]: createState(actionTypes.SEMESTER_LIST),
  [STATE.SEMESTER_ALL]: createState(actionTypes.SEMESTER_ALL),
})

export const SEMESTER = STATE.GLOBAL_STATE

export default reducer

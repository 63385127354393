import { createAction } from '@helpers/createAction'

export const COUNTRY = 'COUNTRY'
export const COUNTRY_CREATE = 'COUNTRY_CREATE'
export const COUNTRY_DELETE = 'COUNTRY_DELETE'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const COUNTRY_ALL = 'COUNTRY_ALL'

export default {
  [COUNTRY]: createAction(COUNTRY),
  [COUNTRY_CREATE]: createAction(COUNTRY_CREATE),
  [COUNTRY_DELETE]: createAction(COUNTRY_DELETE),
  [COUNTRY_LIST]: createAction(COUNTRY_LIST),
  [COUNTRY_ALL]: createAction(COUNTRY_ALL),
}

import { SuccessToast } from '@components/Toasts'
import * as ROUTE from '@constants/routes'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as stateSelectors from '@store/selector'
import { push } from 'connected-react-router'
import { pathOr, prop, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API_APPLICATION from '../api/application'
import * as API_APPLICATION_CATEGORY from '../api/applicationCategory'
import * as API_APPLICATION_REQUEST from '../api/applicationRequest'
import actionTypes, {
  ST_COURSE_APPLICATION,
  ST_COURSE_APPLICATION_ALL,
  ST_COURSE_APPLICATION_CATEGORY,
  ST_COURSE_APPLICATION_CATEGORY_ALL,
  ST_COURSE_APPLICATION_CATEGORY_CREATE,
  ST_COURSE_APPLICATION_CATEGORY_DELETE,
  ST_COURSE_APPLICATION_CATEGORY_LIST,
  ST_COURSE_APPLICATION_CREATE,
  ST_COURSE_APPLICATION_DELETE,
  ST_COURSE_APPLICATION_FIELD_BATCH,
  ST_COURSE_APPLICATION_FIELD_BATCH_CREATE,
  ST_COURSE_APPLICATION_LIST,
  ST_COURSE_APPLICATION_STATUS,
  ST_COURSE_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'
import * as actions from './actions'

function watchStCourseApplication() {
  return watchSaga({ action: actionTypes[ST_COURSE_APPLICATION], api: API_APPLICATION.stCourseApplication })
}

function watchStCourseApplicationCreate() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CREATE],
    api: API_APPLICATION.stCourseApplicationCreate,
  })
}

function watchStCourseApplicationDelete() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_DELETE],
    api: API_APPLICATION.stCourseApplicationDelete,
  })
}

function watchStCourseApplicationTypes() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_TYPES],
    api: API_APPLICATION.stCourseApplicationTypes,
  })
}

function watchStCourseApplicationTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[ST_COURSE_APPLICATION_TYPES],
    message: true,
  })
}

function watchStCourseApplicationStatus() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_STATUS],
    api: API_APPLICATION.stCourseApplicationStatus,
  })
}

function* watchStCourseApplicationStatusFulfilled() {
  yield takeEvery(actionTypes[ST_COURSE_APPLICATION_STATUS].fulfilled, function* (payload) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], payload)
    onFulfilled()
    yield toast.success(<SuccessToast text='Status has been changed!' />)
  })
}

function watchStCourseApplicationStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ST_COURSE_APPLICATION_STATUS],
    message: true,
  })
}

function* watchStCourseApplicationDeleteFulfilled() {
  yield takeEvery(actionTypes[ST_COURSE_APPLICATION_DELETE].fulfilled, function* () {
    yield put(actions.stCourseApplicationList())
  })
}

function watchStCourseApplicationList() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_LIST],
    api: API_APPLICATION.stCourseApplicationList,
  })
}

function watchStCourseApplicationListRejected() {
  return watchSagaRejected({
    action: actionTypes[ST_COURSE_APPLICATION_LIST],
    message: true,
  })
}

function watchStCourseApplicationAll() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_ALL],
    api: API_APPLICATION.stCourseApplicationAll,
  })
}

function watchStCourseApplicationFieldBatch() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH],
    api: API_APPLICATION.stCourseApplicationFieldBatch,
  })
}

function watchStCourseApplicationFieldBatchCreate() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE],
    api: API_APPLICATION.stCourseApplicationFieldBatchCreate,
  })
}

function* watchStCourseApplicationFieldBatchCreateFulfilled() {
  yield takeEvery(actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].fulfilled, function* ({ args }) {
    const application = prop('application', args)
    const id = prop('id', application)

    yield put(push(ROUTE.ST_COURSE_ADMIN_APPLICATION_FORM))
  })
}

function watchStCourseApplicationCategory() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CATEGORY],
    api: API_APPLICATION_CATEGORY.stCourseApplicationCategory,
  })
}

function watchStCourseApplicationCategoryCreate() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CATEGORY_CREATE],
    api: API_APPLICATION_CATEGORY.stCourseApplicationCategoryCreate,
  })
}

function* watchStCourseApplicationCategoryCreateFulfilled() {
  yield takeEvery(actionTypes[ST_COURSE_APPLICATION_CATEGORY_CREATE].fulfilled, function* () {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    yield put(actions.stCourseApplicationCategoryList({ ...get }))
  })
}

function watchStCourseApplicationCategoryDelete() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CATEGORY_DELETE],
    api: API_APPLICATION_CATEGORY.stCourseApplicationCategoryDelete,
  })
}

function* watchStCourseApplicationCategoryFulfilled() {
  yield takeEvery(actionTypes[ST_COURSE_APPLICATION_CATEGORY_DELETE].fulfilled, function* () {
    yield put(actions.stCourseApplicationCategoryList())
  })
}

function watchStCourseApplicationCategoryList() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CATEGORY_LIST],
    api: API_APPLICATION_CATEGORY.stCourseApplicationCategoryList,
  })
}

function watchStCourseApplicationCategoryAll() {
  return watchSaga({
    action: actionTypes[ST_COURSE_APPLICATION_CATEGORY_ALL],
    api: API_APPLICATION_CATEGORY.stCourseApplicationCategoryAll,
  })
}

function watchStCourseApplicationRequest() {
  return watchSaga({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST],
    api: API_APPLICATION_REQUEST.stCourseApplicationRequest,
  })
}

function* watchStCourseApplicationRequestFulfilled() {
  yield takeEvery(actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST].fulfilled, function* ({ payload, args }) {
    const onFulfilled = propOr(() => {}, 'onFulfilled', args)
    yield onFulfilled(payload)
  })
}

function watchStCourseApplicationRequestAll() {
  return watchSaga({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_ALL],
    api: API_APPLICATION_REQUEST.stCourseApplicationRequestAll,
  })
}

function watchStCourseApplicationRequestEdit() {
  return watchSaga({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_EDIT],
    api: API_APPLICATION_REQUEST.stCourseApplicationRequestEdit,
  })
}

function watchStCourseApplicationRequestUpdate() {
  return watchSaga({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE],
    api: API_APPLICATION_REQUEST.stCourseApplicationRequestUpdate,
  })
}

function watchStCourseApplicationRequestUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE],
    shouldDoFulfilledFunction: true,
    message: {
      description: 'Your changes have been saved!',
    },
  })
}

function watchStCourseApplicationRequestUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE],
    message: {
      description: 'Could not save your changes',
    },
  })
}

export function* stCourseAdmissionSaga() {
  yield all([
    fork(watchStCourseApplication),
    fork(watchStCourseApplicationCreate),
    fork(watchStCourseApplicationDelete),
    fork(watchStCourseApplicationDeleteFulfilled),
    fork(watchStCourseApplicationList),
    fork(watchStCourseApplicationListRejected),
    fork(watchStCourseApplicationAll),
    fork(watchStCourseApplicationFieldBatch),
    fork(watchStCourseApplicationFieldBatchCreate),
    fork(watchStCourseApplicationFieldBatchCreateFulfilled),
    fork(watchStCourseApplicationCategory),
    fork(watchStCourseApplicationCategoryCreate),
    fork(watchStCourseApplicationCategoryCreateFulfilled),
    fork(watchStCourseApplicationCategoryDelete),
    fork(watchStCourseApplicationCategoryFulfilled),
    fork(watchStCourseApplicationCategoryList),
    fork(watchStCourseApplicationCategoryAll),
    fork(watchStCourseApplicationRequest),
    fork(watchStCourseApplicationRequestFulfilled),
    fork(watchStCourseApplicationRequestAll),
    fork(watchStCourseApplicationRequestEdit),
    fork(watchStCourseApplicationRequestUpdate),
    fork(watchStCourseApplicationRequestUpdateFulfilled),
    fork(watchStCourseApplicationRequestUpdateRejected),
    fork(watchStCourseApplicationTypes),
    fork(watchStCourseApplicationTypesRejected),
    fork(watchStCourseApplicationStatus),
    fork(watchStCourseApplicationStatusFulfilled),
    fork(watchStCourseApplicationStatusRejected),
  ])
}

import { equals, find, length, type } from 'ramda'

export function areObjsEq(obj1, obj2) {
  if (!equals(type(obj1), type(obj2))) return false
  delete obj1.undefined
  delete obj2.undefined
  const firstKeys = Object.keys(obj1)
  const secondKeys = Object.keys(obj2)
  if (!equals(length(firstKeys), length(secondKeys))) return false
  return !find(key => !equals(obj1[key], obj2[key]))(firstKeys)
}

import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  GENERATE_TRANSCRIPT,
  STUDENT,
  STUDENT_ACHIEVEMENT_RECORDS,
  STUDENT_ALL,
  STUDENT_CONTACT_INFO,
  STUDENT_DELETE,
  STUDENT_GUARD_CREATE,
  STUDENT_GUARD_LIST,
  STUDENT_LIST,
  STUDENT_PERSONAL_INFO,
  STUDENT_STATUS_UPDATE,
  STUDENT_USERNAME,
} from '../constants/actionTypes'

function watchStudent() {
  return watchSaga({
    action: actionTypes[STUDENT],
    api: API.student,
  })
}

function watchStudentDelete() {
  return watchSaga({
    action: actionTypes[STUDENT_DELETE],
    api: API.studentDelete,
  })
}

function watchStudentList() {
  return watchSaga({
    action: actionTypes[STUDENT_LIST],
    api: API.studentList,
  })
}

function watchStudentAll() {
  return watchSaga({
    action: actionTypes[STUDENT_ALL],
    api: API.studentAll,
  })
}

function watchStudentAchievementRecords() {
  return watchSaga({
    action: actionTypes[STUDENT_ACHIEVEMENT_RECORDS],
    api: API.studentAchievementRecords,
  })
}

function watchStudentAchievementRecordsRejected() {
  return watchSagaRejected({
    action: actionTypes[STUDENT_ACHIEVEMENT_RECORDS],
    message: true,
  })
}

function watchStudentGuardCreate() {
  return watchSaga({
    action: actionTypes[STUDENT_GUARD_CREATE],
    api: API.studentGuardCreate,
  })
}

function watchStudentGuardList() {
  return watchSaga({
    action: actionTypes[STUDENT_GUARD_LIST],
    api: API.studentGuard,
  })
}

function watchStudentUsername() {
  return watchSaga({
    action: actionTypes[STUDENT_USERNAME],
    api: API.studentUsername,
  })
}

function watchStudentPersonalInfo() {
  return watchSaga({
    action: actionTypes[STUDENT_PERSONAL_INFO],
    api: API.studentPersonalInfo,
  })
}

function* watchStudentPersonalInfoSuccess() {
  yield takeEvery(actionTypes[STUDENT_PERSONAL_INFO].fulfilled, function* ({ payload }) {
    const id = prop('id', payload)

    // yield put(
    //   actions.student({
    //     id,
    //   }),
    // )

    toast.success(<SuccessToast text='Personal information updated' />)
  })
}

function watchStudentContactInfo() {
  return watchSaga({
    action: actionTypes[STUDENT_CONTACT_INFO],
    api: API.studentContactInfo,
  })
}

function* watchStudentContactInfoSuccess() {
  yield takeEvery(actionTypes[STUDENT_CONTACT_INFO].fulfilled, function* ({ payload }) {
    const id = prop('id', payload)

    // yield put(
    //   actions.student({
    //     id,
    //   }),
    // )

    toast.success(<SuccessToast text='Contact information updated' />)
  })
}

function watchStudentStatusUpdate() {
  return watchSaga({
    action: actionTypes[STUDENT_STATUS_UPDATE],
    api: API.updateStudentStatus,
  })
}

function watchStudentStatusUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[STUDENT_STATUS_UPDATE],
    message: {
      title: 'Success',
    },
  })
}

function watchStudentStatusUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[STUDENT_STATUS_UPDATE],
    message: true,
  })
}

function watchGenerateTranscript() {
  return watchSaga({
    action: actionTypes[GENERATE_TRANSCRIPT],
    api: API.generateTranscript,
  })
}

function watchGenerateTranscriptFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[GENERATE_TRANSCRIPT],
    message: {
      title: 'Success',
    },
    autoDownloadParams: {
      path: ['url'],
    },
  })
}

export function* studentSaga() {
  yield all([
    fork(watchStudent),
    fork(watchStudentDelete),
    fork(watchStudentList),
    fork(watchStudentAll),
    fork(watchStudentGuardCreate),
    fork(watchStudentGuardList),
    fork(watchStudentUsername),
    fork(watchStudentPersonalInfo),
    fork(watchStudentPersonalInfoSuccess),
    fork(watchStudentContactInfo),
    fork(watchStudentContactInfoSuccess),
    fork(watchStudentStatusUpdate),
    fork(watchStudentStatusUpdateFulfilled),
    fork(watchStudentStatusUpdateRejected),
    fork(watchGenerateTranscript),
    fork(watchGenerateTranscriptFulfilled),
    fork(watchStudentAchievementRecords),
    fork(watchStudentAchievementRecordsRejected),
  ])
}

export const ST_COURSE = 'st_course/'
export const ST_COURSE_APPLICATION_FORM = `${ST_COURSE}application_form/`
export const ST_COURSE_APPLICATION = `${ST_COURSE}application/`
export const ST_COURSE_APPLICATION_TYPES = `${ST_COURSE_APPLICATION}types`
export const ST_COURSE_APPLICATION_STATUS = `${ST_COURSE_APPLICATION}status/`
export const ST_COURSE_APPLICATION_ALL = `${ST_COURSE_APPLICATION_FORM}all`
export const ST_COURSE_APPLICATION_FIELD_BATCH = `${ST_COURSE_APPLICATION_FORM}field/batch/`

export const ST_COURSE_APPLICATION_CATEGORY = `${ST_COURSE_APPLICATION_FORM}category/`
export const ST_COURSE_APPLICATION_CATEGORY_ALL = `${ST_COURSE_APPLICATION_CATEGORY}all/`

export const ST_COURSE_APPLICATION_REQUEST = ST_COURSE_APPLICATION
export const ST_COURSE_APPLICATION_REQUEST_ALL = `${ST_COURSE_APPLICATION_REQUEST}all/`
export const ST_COURSE_APPLICATION_REQUEST_EDIT = `${ST_COURSE_APPLICATION_REQUEST}edit/`
export const ST_COURSE_APPLICATION_REQUEST_UPDATE = `${ST_COURSE_APPLICATION_REQUEST}update`

export const ST_COURSE_APPLICATION_SIGNUP = `${ST_COURSE_APPLICATION_FORM}signup`
export const ST_COURSE_APPLICATION_SMS_SEND = `${ST_COURSE_APPLICATION_FORM}sms/send`

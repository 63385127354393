import { createAction } from '@helpers/createAction'

export const STAFF = 'STAFF'
export const STAFF_DELETE = 'STAFF_DELETE'
export const STAFF_LIST = 'STAFF_LIST'
export const STAFF_ALL = 'STAFF_ALL'
export const STAFF_USERNAME = 'STAFF_USERNAME'

export default {
  [STAFF]: createAction(STAFF),
  [STAFF_DELETE]: createAction(STAFF_DELETE),
  [STAFF_LIST]: createAction(STAFF_LIST),
  [STAFF_ALL]: createAction(STAFF_ALL),
  [STAFF_USERNAME]: createAction(STAFF_USERNAME),
}

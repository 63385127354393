import { toCamelCase } from '@helpers/toCamelCase'

export const CATEGORY = 'CATEGORY'
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const CATEGORY_ALL = 'CATEGORY_ALL'
export const CATEGORY_CREATE = 'CATEGORY_CREATE'
export const CATEGORY_DELETE = 'CATEGORY_DELETE'

export const GLOBAL_STATE = 'Category'

export const actionNames = {
  [toCamelCase(CATEGORY)]: CATEGORY,
  [toCamelCase(CATEGORY_LIST)]: CATEGORY_LIST,
  [toCamelCase(CATEGORY_ALL)]: CATEGORY_ALL,
  [toCamelCase(CATEGORY_CREATE)]: CATEGORY_CREATE,
  [toCamelCase(CATEGORY_DELETE)]: CATEGORY_DELETE,
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, STAFF, STAFF_ALL, STAFF_LIST, STAFF_USERNAME } from '../constants/state'

export const staffAllSelector = createSelector(path([GLOBAL_STATE, STAFF_ALL]), data => data)

export const staffSelector = createSelector(path([GLOBAL_STATE, STAFF]), data => data)

export const staffUsernameSelector = createSelector(path([GLOBAL_STATE, STAFF_USERNAME]), data => data)

export const staffListSelector = createSelector(path([GLOBAL_STATE, STAFF_LIST]), data => data)

export const staffSelectors = {
  staffAllSelector,
  staffSelector,
  staffUsernameSelector,
  staffListSelector,
}

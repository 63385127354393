import { path } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, STATE, STATE_ALL, STATE_CREATE, STATE_LIST } from '../constants/state'

export const stateSelector = createSelector(path([GLOBAL_STATE, STATE]), data => data)

export const stateListSelector = createSelector(path([GLOBAL_STATE, STATE_LIST]), data => data)

export const stateAllSelector = createSelector(path([GLOBAL_STATE, STATE_ALL]), data => data)

export const stateCreateSelector = createSelector(path([GLOBAL_STATE, STATE_CREATE]), data => data)

export const countryStateSelectors = {
  stateSelector,
  stateListSelector,
  stateAllSelector,
  stateCreateSelector,
}

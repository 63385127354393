import { getDataFromState } from '@helpers/get'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const generalApplicationSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION, STATE.GLOBAL_STATE),
  data => data
)

export const generalApplicationListSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_LIST, STATE.GLOBAL_STATE),
  data => data
)

export const generalApplicationTypesSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_TYPES, STATE.GLOBAL_STATE),
  data => data
)

export const generalApplicationStatusSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_STATUS, STATE.GLOBAL_STATE),
  data => data
)

export const generalApplicationAllSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const generalApplicationFieldBatchSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_FIELD_BATCH, STATE.GLOBAL_STATE),
  data => data
)

// category
export const generalApplicationCategorySelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_CATEGORY, STATE.GLOBAL_STATE),
  data => data
)
export const generalApplicationCategoryListSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_CATEGORY_LIST, STATE.GLOBAL_STATE),
  data => data
)
export const generalApplicationCategoryAllSelector = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_CATEGORY_ALL, STATE.GLOBAL_STATE),
  data => data
)

// request
export const generalApplicationRequestAll = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_REQUEST_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const generalApplicationRequestEdit = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_REQUEST_EDIT, STATE.GLOBAL_STATE),
  data => data
)

export const generalApplicationRequestUpdate = createSelector(
  getDataFromState(STATE.GENERAL_APPLICATION_REQUEST_UPDATE, STATE.GLOBAL_STATE),
  data => data
)

export const generalAdmissionSelectors = {
  generalApplicationSelector,
  generalApplicationListSelector,
  generalApplicationAllSelector,
  generalApplicationFieldBatchSelector,
  generalApplicationCategorySelector,
  generalApplicationCategoryListSelector,
  generalApplicationCategoryAllSelector,
  generalApplicationRequestAll,
  generalApplicationRequestEdit,
  generalApplicationRequestUpdate,
  generalApplicationTypesSelector,
  generalApplicationStatusSelector,
}

// import { string } from 'prop-types'
import { prop } from 'ramda'

import { getInstance } from '../../../helpers/httpClient'
import {
  INQUIRY_COMMENT,
  INQUIRY_COMMENT_CREATE,
  INQUIRY_COMMENT_DELETE,
  INQUIRY_STATUS_TYPES,
  INQUIRY_TYPES,
  RAPORT_INQUIRY_ASSIGNED,
  RAPORT_UPDATE_STATUS,
  REPORT_INQUIRY_ALL,
  REPORT_INQUIRY_CREATE,
  REPORT_INQUIRY_DELETE,
  REPORT_INQUIRY_ITEM,
  REPORT_INQUIRY_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

const Instance = getInstance()

const inquiryTypes = (data: Record<string, any>) => Instance.get(API.INQUIRY_TYPES, data)

const inquiryStatusTypes = (data: Record<string, any>) => Instance.get(API.INQUIRY_STATUS_TYPES, data)

const reportInquiryAll = (data: { [key: string]: string }) => Instance.get(API.REPORT_INQUIRY_ALL, { params: data })

const reportInquiryCreate = (data: Record<string, any>) => Instance.post(API.REPORT_INQUIRY_CREATE, data)

const inquiryList = (data: Record<string, any>) => Instance.get(API.REPORT_INQUIRY, { params: data })

const inquiryAssigned = (data: Record<string, any>) => Instance.get(API.RAPORT_INQUIRY_ASSIGNED, { params: data })

const inquiryItemDelete = (data: Record<string, any>) => Instance.delete(`${API.REPORT_INQUIRY}${prop('id', data)}`)

const inquiryItem = (data: Record<string, any>) => Instance.get(`${API.REPORT_INQUIRY}${prop('id', data)}`)

const raportUpdateStatus = (data: Record<string, any>) => Instance.post(API.RAPORT_UPDATE_STATUS, data)

const inquiryCommnet = (data: Record<string, any>) => Instance.get(API.INQUIRY_COMMENT, data)

const inquiryCommnetCreate = (data: Record<string, any>) => Instance.post(API.INQUIRY_COMMENT_CREATE, data)

const inquiryCommnetDelete = (data: Record<string, any>) =>
  Instance.delete(`${API.INQUIRY_COMMENT_DELETE}${prop('id', data)}`)

export default {
  [REPORT_INQUIRY_ALL]: reportInquiryAll,
  [REPORT_INQUIRY_CREATE]: reportInquiryCreate,
  [REPORT_INQUIRY_DELETE]: inquiryItemDelete,
  [REPORT_INQUIRY_ITEM]: inquiryItem,
  [REPORT_INQUIRY_LIST]: inquiryList,
  [INQUIRY_TYPES]: inquiryTypes,
  [INQUIRY_STATUS_TYPES]: inquiryStatusTypes,
  [RAPORT_UPDATE_STATUS]: raportUpdateStatus,
  [RAPORT_INQUIRY_ASSIGNED]: inquiryAssigned,
  [INQUIRY_COMMENT]: inquiryCommnet,
  [INQUIRY_COMMENT_CREATE]: inquiryCommnetCreate,
  [INQUIRY_COMMENT_DELETE]: inquiryCommnetDelete,
}

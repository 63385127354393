import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const assessment = data =>
  instance.get(`${API.ASSESSMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const assessmentCreate = data => instance.post(API.ASSESSMENT, data)

export const assessmentStudentUpload = data => instance.post(API.ASSESSMENT_STUDENT_UPLOAD, data)

export const assessmentDelete = data => instance.delete(`${API.ASSESSMENT}${prop('id', data)}`)

export const assessmentList = data =>
  instance.get(API.ASSESSMENT, {
    params: data,
  })

export const assessmentSingle = data => instance.get(API.ASSESSMENT_SINGLE, { params: data })

export const assessmentLog = data => instance.post(API.ASSESSMENT_LOG, data)

export const assessmentBulk = data => instance.post(API.ASSESSMENT_BULK, data)

export const assessmentAttempt = data => instance.post(API.ASSESSMENT_ATTEMPT, data)

export const getAssessmentAttemptById = data =>
  instance.get(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

export const assessmentAttemptDelete = data => instance.delete(`${API.ASSESSMENT_ATTEMPT}${prop('id', data)}`)

export const assessmentCopy = data => instance.post(API.ASSESSMENT_COPY, data)

export const assessmentSubmit = data => instance.post(API.ASSESSMENT_SUBMIT, data)

export const assessmentCriteria = data =>
  instance.get(API.ASSESSMENT_CRITERIA, {
    params: data,
  })

export const assessmentCriteriaUpdate = data => instance.post(API.ASSESSMENT_CRITERIA, data)

export const assessmentCriteriaList = data =>
  instance.get(API.ASSESSMENT_CRITERIA_LIST, {
    params: data,
  })

export const attemptDelete = data =>
  instance.delete(API.ASSESSMENT_ATTEMPT, {
    params: data,
  })

export const assessmentCriteriaTypes = data =>
  instance.get(API.ASSESSMENT_CRITERIA_TYPES, {
    params: data,
  })

export const assessmentStudent = data =>
  instance.get(API.ASSESSMENT_STUDENT, {
    params: data,
  })

export const GLOBAL_STATE = 'StCourseAdmission'

export const ST_COURSE_APPLICATION = 'application'
export const ST_COURSE_APPLICATIONS = 'applications'
export const ST_COURSE_APPLICATION_LIST = 'applicationList'
export const ST_COURSE_APPLICATION_ALL = 'applicationAll'
export const ST_COURSE_APPLICATION_STATUS = 'applicationStatus'
export const ST_COURSE_APPLICATION_TYPES = 'applicationTypes'

export const ST_COURSE_APPLICATION_FIELD_BATCH = 'applicationFieldBatch'

// category
export const ST_COURSE_APPLICATION_CATEGORY = 'category'
export const ST_COURSE_APPLICATION_CATEGORY_LIST = 'categoryList'
export const ST_COURSE_APPLICATION_CATEGORY_ALL = 'categoryAll'

// request
export const ST_COURSE_APPLICATION_REQUEST_ALL = 'requestAll'
export const ST_COURSE_APPLICATION_REQUEST_EDIT = 'requestEdit'
export const ST_COURSE_APPLICATION_REQUEST_UPDATE = 'requestUpdate'

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const assessmentAmendment = data =>
  getInstance().get(`${API.ASSESSMENT_AMENDMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const assessmentAmendmentCreate = data => getInstance().post(API.ASSESSMENT_AMENDMENT, data)

export const assessmentAmendmentDelete = data => getInstance().delete(`${API.ASSESSMENT_AMENDMENT}${prop('id', data)}`)

export const assessmentAmendmentList = data =>
  getInstance().get(API.ASSESSMENT_AMENDMENT, {
    params: data,
  })

export const assessmentAmendmentAll = data =>
  getInstance().get(API.ASSESSMENT_AMENDMENT_ALL, {
    params: data,
  })

export const assessmentAmendmentPublish = data => getInstance().put(API.ASSESSMENT_AMENDMENT_PUBLISH, data)

export const assessmentMarkChangeStatus = data => getInstance().put(API.ASSESSMENT_MARK_CHANGE_STATUS, data)

import { paths } from '@src/types/schema'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { prop, type } from 'ramda'

import { API_HOST, API_PROJECT, API_SCHEME, API_URL, API_V1, API_WS_SCHEME } from '../constants/api'
import { getInstitution, removeInstitution, removeSelectedInstitution } from './storageInstitution'
import { getLanguage } from './storageLanguage'
import { getToken, removeToken } from './storageToken'

export type IRequestParams<T> = T & AxiosRequestConfig

export type apiPath<A extends keyof paths> = paths[A]

export type method<T, path extends keyof paths> = T extends keyof apiPath<path> ? apiPath<path>[T] : never

export const apiBase = `${API_PROJECT}${API_V1}` as const

export type ApiFunc<
  Endpoint extends keyof paths extends `${typeof apiBase}${infer Remaining}` ? Remaining : never,
  MethodKey extends keyof paths[`${typeof apiBase}${Endpoint}`],
  Return = method<MethodKey, `${typeof apiBase}${Endpoint}`> extends Record<'responses', infer U>
    ? U extends Record<'200', infer A>
      ? U['200'][keyof A]
      : never
    : never,
  Params = MethodKey extends 'get' | 'delete'
    ? method<MethodKey, `${typeof apiBase}${Endpoint}`> extends Record<'parameters', infer U>
      ? method<MethodKey, `${typeof apiBase}${Endpoint}`>['parameters'][keyof U]
      : Partial<Record<string, unknown>> | void
    : MethodKey extends 'post' | 'put'
    ? method<MethodKey, `${typeof apiBase}${Endpoint}`> extends Record<'parameters', infer U>
      ? U extends Record<'body', infer A>
        ? U['body'][keyof A]
        : U extends Record<'path' | 'query', infer A>
        ? A
        : never
      : never
    : never
> = (data: Params) => Promise<AxiosResponse<Return>>

export interface AxiosError {
  error: string
  message: string
  path: string
  status: number
  timestamp: number
}

export function getHost() {
  if (process.env.API_HOST) {
    return `${API_SCHEME}://${process.env.API_HOST as typeof API_HOST}/` as const
  }

  return `${API_SCHEME}://${API_URL}/` as const
}

export function getWebSocketHost() {
  if (process.env.API_HOST) {
    return `${API_WS_SCHEME}://${process.env.API_HOST}/ws?token=${getToken()}`
  }

  return `${API_WS_SCHEME}://${API_URL}/ws`
}

export function getInstance(config?: AxiosRequestConfig | ((defaultConfig: AxiosRequestConfig) => AxiosRequestConfig)) {
  const defaultConfig = {
    baseURL: getHost(),
    headers: {
      'Access-Control-Allow-Headers': '*',
    },
  }
  const instance = axios.create({
    ...defaultConfig,
    ...(typeof config === 'function' ? config(defaultConfig) : type(config) === 'Object' ? config : {}),
  })

  instance.interceptors.request.use(config => {
    config.headers.common['Authorization'] = `Bearer ${getToken()}`
    config.headers.common['institution'] = getInstitution()
    config.headers.common['Accept-Language'] = getLanguage() || 'en'

    return config
  })

  instance.interceptors.response.use(
    payload => payload,
    ({ response }) => {
      const status = prop('status', response)

      if (status === 302 || status === 401) {
        removeToken()
        removeInstitution()
        removeSelectedInstitution()
      }

      if (status === 403) {
        // removeToken()
      }

      return Promise.reject(response)
    }
  )

  return instance
}

export function getNotAuthInstance(
  config?: AxiosRequestConfig | ((defaultConfig: AxiosRequestConfig) => AxiosRequestConfig)
) {
  const defaultConfig = {
    baseURL: getHost(),
    timeout: 5000,
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Accept-Language': getLanguage() || 'en',
    },
  }
  const instance = axios.create({
    ...defaultConfig,
    ...(typeof config === 'function' ? config(defaultConfig) : type(config) === 'Object' ? config : {}),
  })

  instance.interceptors.request.use(config => {
    config.headers.common['Accept-Language'] = getLanguage() || 'en'
    return config
  })

  // instance.interceptors.response.use(responseFulfilledStats)

  return instance
}

import { WatchSaga, watchSaga } from '@helpers/customSaga'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../../api/level'
import actionTypes, { LEVEL, LEVEL_ALL, LEVEL_DELETE, LEVEL_LIST } from '../../constants/level/actionTypes'
import * as actions from '../../redux/level/actions'

function watchLevel() {
  return watchSaga({
    action: actionTypes[LEVEL],
    api: API.level,
  })
}

function watchLevelDelete() {
  return watchSaga({
    action: actionTypes[LEVEL_DELETE],
    api: API.levelDelete,
  })
}

function* watchLevelDeleteFulfilled() {
  yield takeEvery(actionTypes[LEVEL_DELETE].fulfilled, function* () {
    yield put(actions.levelList())
  })
}

function watchLevelList() {
  return watchSaga({
    action: actionTypes[LEVEL_LIST],
    api: API.levelList,
  })
}

const levelAllSaga = new WatchSaga({ actionType: actionTypes[LEVEL_ALL], api: API.levelAll })

export function* levelSaga() {
  yield all([
    fork(watchLevel),
    fork(watchLevelDelete),
    fork(watchLevelDeleteFulfilled),
    fork(watchLevelList),
    fork(levelAllSaga.watch),
    fork(levelAllSaga.watchFulfilled),
    fork(levelAllSaga.watchRejected),
  ])
}

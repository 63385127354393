import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import {
  JOB_POSITION,
  JOB_POSITION_ACTIVATE,
  JOB_POSITION_ALL,
  JOB_POSITION_CREATE,
  JOB_POSITION_DELETE,
  JOB_POSITION_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const jobPositionList = data =>
  getInstance().get(API.JOB_POSITION, {
    params: data,
  })

export const jobPositionCreate = data => getInstance().post(API.JOB_POSITION, data)

export const jobPositionDelete = data => getInstance().delete(`${API.JOB_POSITION}${prop('id', data)}`)

export const jobPositionGetByID = data =>
  getInstance().get(`${API.JOB_POSITION}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const jobPositionAll = data =>
  getInstance().get(API.JOB_POSITION_ALL, {
    params: data,
  })

export const jobPositionActivate = data => getInstance().put(`${API.JOB_POSITION_ACTIVATE}${prop('id', data)}`)

export default {
  [JOB_POSITION_LIST]: jobPositionList,
  [JOB_POSITION]: jobPositionGetByID,
  [JOB_POSITION_ALL]: jobPositionAll,
  [JOB_POSITION_CREATE]: jobPositionCreate,
  [JOB_POSITION_DELETE]: jobPositionDelete,
  [JOB_POSITION_ACTIVATE]: jobPositionActivate,
}

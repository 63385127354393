/* eslint-disable */
import { DispatchFunc } from '@src/hooks/reduxHooks'
import { forEach, isEmpty, prop } from 'ramda'

type actionObjType = {
  action: (args?: Record<string, any>) => Record<string, unknown>
  params: Record<string, any>
}

export type dispatchArgsType = {
  actions: actionObjType[]
  dispatch?: ReturnType<DispatchFunc>
  fetchParams: Record<string, unknown>
}

export function dispatchActions(dispatchArgs: dispatchArgsType): void {
  const { dispatch } = dispatchArgs
  !isEmpty(prop('actions', dispatchArgs)) &&
    forEach((actionObj: actionObjType) => {
      const action = actionObj?.action
      const params = actionObj?.params
      dispatch && dispatch(action(params))
    })(dispatchArgs.actions)
}

import actionTypes,
{
  SEND_MAIL,
  EMAIL_TEMPLATE,
  EMAIL_TEMPLATE_ALL,
  EMAIL_TEMPLATE_CREATE,
  EMAIL_TEMPLATE_DELETE,
  EMAIL_TEMPLATE_LIST,
} from '../constants/actionTypes'

export const sendMail = payload => ({
  type: actionTypes[SEND_MAIL].pending,
  payload,
})

export const sendMailClear = () => ({
  type: actionTypes[SEND_MAIL].clear,
})


export const emailTemplate = payload => ({
  type: actionTypes[EMAIL_TEMPLATE].pending,
  payload,
})

export const emailTemplateClear = () => ({
  type: actionTypes[EMAIL_TEMPLATE].clear,
})

export const emailTemplateCreate = payload => ({
  type: actionTypes[EMAIL_TEMPLATE_CREATE].pending,
  payload,
})

export const emailTemplateDelete = payload => ({
  type: actionTypes[EMAIL_TEMPLATE_DELETE].pending,
  payload,
})

export const emailTemplateList = payload => ({
  type: actionTypes[EMAIL_TEMPLATE_LIST].pending,
  payload,
})

export const emailTemplateListClear = () => ({
  type: actionTypes[EMAIL_TEMPLATE_LIST].clear,
})

export const emailTemplateAll = payload => ({
  type: actionTypes[EMAIL_TEMPLATE_ALL].pending,
  payload,
})

export const emailTemplateAllClear = () => ({
  type: actionTypes[EMAIL_TEMPLATE_ALL].clear,
})

import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const modulesOutcomeCreate = data => getInstance().post(API.MODULES_OUTCOME_LIST, data)

export const modulesOutcomeList = data =>
  getInstance().get(API.MODULES_OUTCOME_LIST, {
    params: data,
  })

export const modulesOutcome = data =>
  getInstance().get(API.MODULES_OUTCOME, {
    params: data,
  })

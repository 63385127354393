import actionTypes, {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_DELETE,
  ACHIEVEMENT_RECORDS_LIST,
} from '../constants/actionTypes'

export const achievementRecords = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS].pending,
  payload,
})

export const achievementRecordsAll = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_ALL].pending,
  payload,
})

export const achievementRecordsAllClear = () => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_ALL].clear,
})

export const achievementRecordsList = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_LIST].pending,
  payload,
})

export const achievementRecordsListClear = () => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_LIST].clear,
})

export const achievementRecordsTypes = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_TYPES].pending,
  payload,
})

export const achievementRecordsChangeStatus = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_CHANGE_STATUS].pending,
  payload,
})

export const achievementRecordsTypesClear = () => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_TYPES].clear,
})

export const achievementRecordsCreate = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_CREATE].pending,
  payload,
})

export const achievementRecordsStudent = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_STUDENT].pending,
  payload,
})

export const achievementRecordsStudentClear = () => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_STUDENT].clear,
})

export const achievementRecordsDelete = payload => ({
  type: actionTypes[ACHIEVEMENT_RECORDS_DELETE].pending,
  payload,
})

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as TYPES from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.APPLICATION]: createState(TYPES.APPLICATION),
  [STATE.APPLICATION_LIST]: createState(TYPES.APPLICATION_LIST),
  [STATE.APPLICATION_ALL]: createState(TYPES.APPLICATION_ALL),
  [STATE.APPLICATION_TYPES]: createState(TYPES.APPLICATION_TYPES),
  [STATE.APPLICATION_STATUS]: createState(TYPES.APPLICATION_STATUS),
  [STATE.APPLICATION_FIELD_BATCH]: createState(TYPES.APPLICATION_FIELD_BATCH),
  [STATE.APPLICATION_CATEGORY]: createState(TYPES.APPLICATION_CATEGORY),
  [STATE.APPLICATION_CATEGORY_LIST]: createState(TYPES.APPLICATION_CATEGORY_LIST),
  [STATE.APPLICATION_CATEGORY_ALL]: createState(TYPES.APPLICATION_CATEGORY_ALL),
  [STATE.APPLICATION_REQUEST_ALL]: createState(TYPES.APPLICATION_REQUEST_ALL),
  [STATE.APPLICATION_REQUEST_EDIT]: createState(TYPES.APPLICATION_REQUEST_EDIT),
  [STATE.APPLICATION_REQUEST_UPDATE]: createState(TYPES.APPLICATION_REQUEST_UPDATE),
  [STATE.APPLICATION_FIELDS]: createState(TYPES.APPLICATION_FIELDS),
  [STATE.APPLICATION_GENERATE]: createState(TYPES.APPLICATION_GENERATE),
})

export const STUDENT_ADMISSION = STATE.GLOBAL_STATE

export default reducer

import actionTypes, {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_CATEGORY,
  APPLICATION_CATEGORY_ALL,
  APPLICATION_CATEGORY_CREATE,
  APPLICATION_CATEGORY_DELETE,
  APPLICATION_CATEGORY_LIST,
  APPLICATION_CREATE,
  APPLICATION_DELETE,
  APPLICATION_FIELD_BATCH,
  APPLICATION_FIELD_BATCH_CREATE,
  APPLICATION_LIST,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'

export const application = payload => ({
  type: actionTypes[APPLICATION].pending,
  payload,
})

export const applicationClear = () => ({
  type: actionTypes[APPLICATION].clear,
})

export const applicationCreate = payload => ({
  type: actionTypes[APPLICATION_CREATE].pending,
  payload,
})

export const applicationDelete = payload => ({
  type: actionTypes[APPLICATION_DELETE].pending,
  payload,
})

export const applicationList = payload => ({
  type: actionTypes[APPLICATION_LIST].pending,
  payload,
})

export const applicationListClear = payload => ({
  type: actionTypes[APPLICATION_LIST].clear,
  payload,
})

export const applicationAll = payload => ({
  type: actionTypes[APPLICATION_ALL].pending,
  payload,
})

export const applicationAllClear = () => ({
  type: actionTypes[APPLICATION_ALL].clear,
})

export const applicationTypes = payload => ({
  type: actionTypes[APPLICATION_TYPES].pending,
  payload,
})

export const applicationTypesClear = () => ({
  type: actionTypes[APPLICATION_TYPES].clear,
})

export const applicationChangeStatus = payload => ({
  type: actionTypes[APPLICATION_STATUS].pending,
  payload,
})

export const applicationFieldBatch = payload => ({
  type: actionTypes[APPLICATION_FIELD_BATCH].pending,
  payload,
})

export const applicationFieldBatchClear = () => ({
  type: actionTypes[APPLICATION_FIELD_BATCH].clear,
})

export const applicationFieldBatchCreate = payload => ({
  type: actionTypes[APPLICATION_FIELD_BATCH_CREATE].pending,
  payload,
})

export const applicationCategory = payload => ({
  type: actionTypes[APPLICATION_CATEGORY].pending,
  payload,
})

export const applicationCategoryCreate = payload => ({
  type: actionTypes[APPLICATION_CATEGORY_CREATE].pending,
  payload,
})

export const applicationCategoryDelete = payload => ({
  type: actionTypes[APPLICATION_CATEGORY_DELETE].pending,
  payload,
})

export const applicationCategoryList = payload => ({
  type: actionTypes[APPLICATION_CATEGORY_LIST].pending,
  payload,
})

export const applicationCategoryListClear = () => ({
  type: actionTypes[APPLICATION_CATEGORY_LIST].clear,
})

export const applicationCategoryAll = payload => ({
  type: actionTypes[APPLICATION_CATEGORY_ALL].pending,
  payload,
})

export const applicationRequest = payload => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST].pending,
  payload,
})

export const applicationRequestAll = payload => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST_ALL].pending,
  payload,
})

export const applicationRequestEdit = payload => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST_EDIT].pending,
  payload,
})

export const applicationRequestEditClear = () => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST_EDIT].clear,
})

export const applicationRequestUpdate = payload => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST_UPDATE].pending,
  payload,
})

export const applicationRequestUpdateClear = () => ({
  type: actionTypes[TYPES.APPLICATION_REQUEST_UPDATE].clear,
})

export const applicationFields = payload => ({
  type: actionTypes[TYPES.APPLICATION_FIELDS].pending,
  payload,
})

export const applicationFieldsClear = () => ({
  type: actionTypes[TYPES.APPLICATION_FIELDS].clear,
})

export const applicationGenerate = payload => ({
  type: actionTypes[TYPES.APPLICATION_GENERATE].pending,
  payload,
})

export const applicationGenerateClear = () => ({
  type: actionTypes[TYPES.APPLICATION_GENERATE].clear,
})

import actionTypes, {
  GROUP_GROUPING,
  GROUP_ALL,
  GROUP_EDIT,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
} from '../constants/actionTypes'

export const groupAll = payload => ({
  type: actionTypes[GROUP_ALL].pending,
  payload,
})

export const groupAllClear = () => ({
  type: actionTypes[GROUP_ALL].clear,
})

export const groupGrouping = payload => ({
  type: actionTypes[GROUP_GROUPING].pending,
  payload,
})

export const groupGroupingClear = () => ({
  type: actionTypes[GROUP_GROUPING].clear,
})

export const groupShuffle = payload => ({
  type: actionTypes[GROUP_SHUFFLE].pending,
  payload,
})

export const groupEdit = payload => ({
  type: actionTypes[GROUP_EDIT].pending,
  payload,
})

export const groupShuffleClear = () => ({
  type: actionTypes[GROUP_SHUFFLE].clear,
})

export const groupStudentTransfer = payload => ({
  type: actionTypes[GROUP_STUDENT_TRANSFER].pending,
  payload,
})

export const groupStudentTransferClear = () => ({
  type: actionTypes[GROUP_STUDENT_TRANSFER].clear,
})

export const groupAssign = payload => ({
  type: actionTypes[GROUP_ASSIGN].pending,
  payload,
})

export const groupAssignClear = () => ({
  type: actionTypes[GROUP_ASSIGN].clear,
})

export const groupAssignedList = payload => ({
  type: actionTypes[GROUP_ASSIGNED_LIST].pending,
  payload,
})

export const groupAssignedListClear = () => ({
  type: actionTypes[GROUP_ASSIGNED_LIST].clear,
})

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.COURSE_CAMPAIGN]: createState(actionTypes.COURSE_CAMPAIGN),
  [STATE.COURSE_CAMPAIGN_CREATE]: createState(actionTypes.COURSE_CAMPAIGN_CREATE),
  [STATE.COURSE_CAMPAIGN_ALL]: createState(actionTypes.COURSE_CAMPAIGN_ALL),
  [STATE.COURSE_CAMPAIGN_LIST]: createState(actionTypes.COURSE_CAMPAIGN_LIST),
})

export const COURSE_CAMPAIGN = STATE.GLOBAL_STATE

export default reducer

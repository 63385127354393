import { createAction } from '@helpers/createAction'

export const FORM = 'FORM'
export const FORM_CREATE = 'FORM_CREATE'
export const FORM_DELETE = 'FORM_DELETE'
export const FORM_LIST = 'FORM_LIST'
export const FORM_ALL = 'FORM_ALL'
export const FORM_ACTION_TYPE = 'FORM_ACTION_TYPE'
export const FORM_FIELD_CREATE = 'FORM_FIELD_CREATE'
export const FORM_FIELD_DELETE = 'FORM_FIELD_DELETE'
export const FORM_FIELD_BATCH = 'FORM_FIELD_BATCH'
export const FORM_FIELD_BATCH_CREATE = 'FORM_FIELD_BATCH_CREATE'
export const FORM_TYPE = 'FORM_TYPE'
export const FORM_TYPE_CREATE = 'FORM_TYPE_CREATE'
export const FORM_TYPE_DELETE = 'FORM_TYPE_DELETE'
export const FORM_TYPE_LIST = 'FORM_TYPE_LIST'
export const FORM_TYPE_ALL = 'FORM_TYPE_ALL'
export const FORM_REQUEST = 'FORM_REQUEST'
export const FORM_REQUEST_STATUS = 'FORM_REQUEST_STATUS'
export const FORM_REQUEST_ITEM = 'FORM_REQUEST_ITEM'
export const FORM_REQUEST_CREATE = 'FORM_REQUEST_CREATE'
export const FORM_REQUEST_DELETE = 'FORM_REQUEST_DELETE'
export const FORM_REQUEST_LIST = 'FORM_REQUEST_LIST'
export const FORM_REQUEST_ALL = 'FORM_REQUEST_ALL'
export const FORM_REQUEST_CHANGE_STATUS = 'FORM_REQUEST_CHANGE_STATUS'
export const FORM_REQUEST_FIELDS = 'FORM_REQUEST_FIELDS'
export const FORM_REQUEST_CERTIFICATE = 'FORM_REQUEST_CERTIFICATE'

export default {
  [FORM]: createAction(FORM),
  [FORM_CREATE]: createAction(FORM_CREATE),
  [FORM_DELETE]: createAction(FORM_DELETE),
  [FORM_LIST]: createAction(FORM_LIST),
  [FORM_ALL]: createAction(FORM_ALL),
  [FORM_ACTION_TYPE]: createAction(FORM_ACTION_TYPE),
  [FORM_FIELD_CREATE]: createAction(FORM_FIELD_CREATE),
  [FORM_FIELD_DELETE]: createAction(FORM_FIELD_DELETE),
  [FORM_FIELD_BATCH]: createAction(FORM_FIELD_BATCH),
  [FORM_FIELD_BATCH_CREATE]: createAction(FORM_FIELD_BATCH_CREATE),
  [FORM_TYPE]: createAction(FORM_TYPE),
  [FORM_TYPE_CREATE]: createAction(FORM_TYPE_CREATE),
  [FORM_TYPE_DELETE]: createAction(FORM_TYPE_DELETE),
  [FORM_TYPE_LIST]: createAction(FORM_TYPE_LIST),
  [FORM_TYPE_ALL]: createAction(FORM_TYPE_ALL),
  [FORM_REQUEST]: createAction(FORM_REQUEST),
  [FORM_REQUEST_STATUS]: createAction(FORM_REQUEST_STATUS),
  [FORM_REQUEST_ITEM]: createAction(FORM_REQUEST_ITEM),
  [FORM_REQUEST_CREATE]: createAction(FORM_REQUEST_CREATE),
  [FORM_REQUEST_DELETE]: createAction(FORM_REQUEST_DELETE),
  [FORM_REQUEST_LIST]: createAction(FORM_REQUEST_LIST),
  [FORM_REQUEST_ALL]: createAction(FORM_REQUEST_ALL),
  [FORM_REQUEST_CHANGE_STATUS]: createAction(FORM_REQUEST_CHANGE_STATUS),
  [FORM_REQUEST_FIELDS]: createAction(FORM_REQUEST_FIELDS),
  [FORM_REQUEST_CERTIFICATE]: createAction(FORM_REQUEST_CERTIFICATE),
}

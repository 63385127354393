import { propOr } from 'ramda'

import { STORAGE_LANGUAGE } from '../constants/storage'

export const getLanguage = () => {
  const availableLangs = {
    en: 'en',
    uz: 'uz',
  }
  return propOr('uz', localStorage.getItem(STORAGE_LANGUAGE), availableLangs)
}

export const setLanguage = lang => {
  localStorage.setItem(STORAGE_LANGUAGE, lang)
}

export const removeLanguage = () => {
  localStorage.removeItem(STORAGE_LANGUAGE)
}

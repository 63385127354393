import { getInstance, getNotAuthInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const userOpenLink = data => getInstance().post(API.USER_OPEN_LINK, data)

export const checkUserOpenLink = data =>
  getNotAuthInstance().get(API.USER_OPEN_LINK, {
    params: data,
  })

export const userPublicInfo = data =>
  getNotAuthInstance().get(API.USER_PUBLIC_INFO, {
    params: data,
  })

import { watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { all, fork } from 'redux-saga/effects'

import guestApi from '../api'
import { GUEST_ALL, GUEST_LIST } from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchGuestList() {
  return watchSaga({
    action: actionTypes[GUEST_LIST],
    api: guestApi[GUEST_LIST],
  })
}

function watchGuestListRejected() {
  return watchSagaRejected({
    action: actionTypes[GUEST_LIST],
    message: true,
  })
}

function watchGuestAll() {
  return watchSaga({
    action: actionTypes[GUEST_ALL],
    api: guestApi[GUEST_ALL],
  })
}

function watchGuestAllRejected() {
  return watchSagaRejected({
    action: actionTypes[GUEST_ALL],
    message: true,
  })
}

export function* guestUsersSaga() {
  yield all([fork(watchGuestList), fork(watchGuestListRejected), fork(watchGuestAll), fork(watchGuestAllRejected)])
}

export const GLOBAL_STATE = 'Lesson'

export const LESSON = 'lesson'
export const LESSON_LIST = 'lessonList'
export const LESSON_ALL = 'lessonAll'
export const LESSON_CREATE = 'lessonCreate'
export const LESSON_SORTING = 'lessonSorting'
export const LESSON_HOMEWORK_UPLOAD = 'lessonHomeworkUpload'
export const LESSON_ASSESSMENT = 'lessonAssessment'
export const LESSON_ASSESSMENT_ALL = 'lessonAssessmentAll'
export const LESSON_MARK_ASSESSMENT = 'lessonMarkAssessment'
export const LESSON_ASSESSMENT_LIST = 'lessonAssessmentList'

import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { routerSelector } from '@store/selector'
import { prop } from 'ramda'

import {
  GLOBAL_STATE,
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_COMPLETE,
  STC_STEP_CREATE,
  STC_STEP_LIST,
} from '../constants/actionTypes'

export const stcGlobalSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const stcSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC]
)

export const currentChapterSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => stcSelector(state),
  (state: RootState) => routerSelector(state).query['chapter'],
  (_, stc, chapterId) => {
    // if (stc['data'] && chapterId) {
    //   return stc['data'].chapters.find(item => item.id === Number(chapterId))
    // }
    return null
  }
)

export const currentTopicSelector = createSelector(
  (state: RootState) => state,
  currentChapterSelector,
  (state: RootState) => routerSelector(state).query['topic'],
  (_, chapter, topicId) => {
    // if (topicId && chapter) {
    //   return chapter.topics.find(item => item.id === Number(topicId))
    // }
    return null
  }
)

export const currentStepSelector = createSelector(
  (state: RootState) => state,
  currentTopicSelector,
  (state: RootState) => routerSelector(state).query['step'],
  (_, topic, stepId) => {
    // if (topic && topic) {
    //   return topic.steps.find(item => item.id === Number(stepId))
    // }
    return null
  }
)

export const stcCreateSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_CREATE]
)
export const stcAllSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_ALL]
)
export const stcListSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_LIST]
)
export const stcCategorySelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_CATEGORY]
)
export const stcCategoryAllSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_CATEGORY_ALL]
)
export const stcCategoryCreateSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_CATEGORY_CREATE]
)
export const stcCategoryListSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_CATEGORY_LIST]
)
export const stcStepSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_STEP]
)
export const stcStepAllSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_STEP_ALL]
)
export const stcStepListSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_STEP_LIST]
)
export const stcStepCreateSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_STEP_CREATE]
)
export const stcStepCompleteSelector = createSelector(
  stcGlobalSelector,
  (state: ReturnType<typeof stcGlobalSelector>) => state[STC_STEP_COMPLETE]
)

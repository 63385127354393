import { toCamelCase } from '@helpers/toCamelCase'

export const CONTRACT_AGREEMENT = 'CONTRACT_AGREEMENT'
export const CONTRACT_AGREEMENT_CURRENT = 'CONTRACT_AGREEMENT_CURRENT'
export const CONTRACT_AGREEMENT_ACCEPT = 'CONTRACT_AGREEMENT_ACCEPT'
export const CONTRACT_AGREEMENT_OPEN = 'CONTRACT_AGREEMENT_OPEN'
export const CONTRACT_DOWNLOAD = 'CONTRACT_DOWNLOAD'

export const GLOBAL_STATE = 'ContractAgreement'

export const actionNames = {
  [toCamelCase(CONTRACT_AGREEMENT)]: CONTRACT_AGREEMENT,
  [toCamelCase(CONTRACT_AGREEMENT_ACCEPT)]: CONTRACT_AGREEMENT_ACCEPT,
  [toCamelCase(CONTRACT_AGREEMENT_OPEN)]: CONTRACT_AGREEMENT_OPEN,
  [toCamelCase(CONTRACT_AGREEMENT_CURRENT)]: CONTRACT_AGREEMENT_CURRENT,
  [toCamelCase(CONTRACT_DOWNLOAD)]: CONTRACT_DOWNLOAD,
}

import { path, propOr } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_LIST,
  GLOBAL_STATE,
} from '../constants/state'

export const academicYearSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR]), data => data)

export const academicYearAllSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_ALL]), data => data)

export const academicYearListSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_LIST]), data => data)

export const academicYearCreateSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_CREATE]), data => data)

export const academicYearAllOptionsSelector = createSelector(academicYearAllSelector, academicYearAll => {
  return {
    loading: academicYearAll.loading,
    error: academicYearAll.error,
    options: Array.isArray(academicYearAll.data)
      ? academicYearAll.data.map(item => ({ label: propOr('', 'name', item), value: item.id }))
      : [],
  }
})

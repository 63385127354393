import {
  getInstitution,
  getSelectedInstitution,
  setInstitutionId,
  setSelectedInstitution,
} from '@helpers/storageInstitution'
import { useAppDispatch } from '@hooks'
import { updateUserSettings } from '@store/actions'
import { createContext, useState } from 'react'

const defaultValue = {
  institution_id: getInstitution(),
  institution: getSelectedInstitution(),
  key: `institution_id=${getInstitution()}`,
}

export const UserSettingsContext = createContext(defaultValue)

export const UserSettingsProvider = ({ children }) => {
  const dispatch = useAppDispatch()
  // const userInfo = useAppSelector(authUserInfo)
  // const storageUserInfo = getUserInfo()
  const [state, setState] = useState(defaultValue)

  // useEffect(() => {
  //   if (userInfo?.id) {
  //     setState(prevState => ({
  //       ...prevState,
  //       institution_id: path(['selected_institution', 'value'], userInfo),
  //       institution: prop('selected_institution', userInfo),
  //     }))
  //   }
  // }, [userInfo?.id])

  function setInstitution(option) {
    dispatch(
      updateUserSettings({
        institution_id: option?.value,
        onFulfilled: () => {
          setInstitutionId(option?.value)
          setSelectedInstitution(JSON.stringify(option))
          setState(prevState => ({
            ...prevState,
            institution_id: option?.value,
            institution: option,
            key: `institution_id=${option?.value}`,
          }))
        },
      })
    )
  }

  return (
    <UserSettingsContext.Provider
      key={state.key}
      value={{
        institution_id: state.institution_id,
        institution: state.institution,
        setInstitution,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

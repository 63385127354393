import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const eLibraryList = data =>
  getInstance().get(API.E_LIBRARY, {
    params: data,
  })

export const eLibraryCreate = data => getInstance().post(API.E_LIBRARY, data)

export const eLibraryDelete = data => getInstance().delete(`${API.E_LIBRARY}${prop('id', data)}`)

export const eLibrary = data =>
  getInstance().get(`${API.E_LIBRARY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const eLibraryTypes = data =>
  getInstance().get(API.E_LIBRARY_TYPES, {
    params: dissoc('id', data),
  })

export const eLibraryAll = data =>
  getInstance().get(API.E_LIBRARY_ALL, {
    params: data,
  })

export default {
  [E_LIBRARY_DELETE]: eLibraryDelete,
  [E_LIBRARY]: eLibrary,
  [E_LIBRARY_TYPES]: eLibraryTypes,
  [E_LIBRARY_CREATE]: eLibraryCreate,
  [E_LIBRARY_ALL]: eLibraryAll,
  [E_LIBRARY_LIST]: eLibraryList,
}

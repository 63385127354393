import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { push } from 'connected-react-router'
import { prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_CREATE,
  FORM_DELETE,
  FORM_FIELD_BATCH,
  FORM_FIELD_BATCH_CREATE,
  FORM_FIELD_CREATE,
  FORM_FIELD_DELETE,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_DELETE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_ITEM,
  FORM_REQUEST_LIST,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_CREATE,
  FORM_TYPE_DELETE,
  FORM_TYPE_LIST,
} from '../constants/actionTypes'
import * as ROUTE from '../constants/route'

function watchForm() {
  return watchSaga({ action: actionTypes[FORM], api: API.form })
}

function watchFormCreate() {
  return watchSaga({ action: actionTypes[FORM_CREATE], api: API.formCreate })
}

function watchFormCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_CREATE],
    dispatchActions: actions.formList(),
  })
}

function watchFormDelete() {
  return watchSaga({ action: actionTypes[FORM_DELETE], api: API.formDelete })
}

function watchFormDeleteFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_DELETE],
    dispatchActions: actions.formList(),
  })
}

function watchFormList() {
  return watchSaga({ action: actionTypes[FORM_LIST], api: API.formList })
}

function watchFormAll() {
  return watchSaga({ action: actionTypes[FORM_ALL], api: API.formAll })
}

function watchFormActionType() {
  return watchSaga({ action: actionTypes[FORM_ACTION_TYPE], api: API.formActionType })
}

function watchFormFieldCreate() {
  return watchSaga({ action: actionTypes[FORM_FIELD_CREATE], api: API.formFieldCreate })
}

function watchFormFieldDelete() {
  return watchSaga({ action: actionTypes[FORM_FIELD_DELETE], api: API.formFieldDelete })
}

function watchFormFieldBatch() {
  return watchSaga({ action: actionTypes[FORM_FIELD_BATCH], api: API.formFieldBatch })
}

function watchFormFieldBatchCreate() {
  return watchSaga({ action: actionTypes[FORM_FIELD_BATCH_CREATE], api: API.formFieldBatchCreate })
}

function* watchFormFieldBatchCreateFulfilled() {
  yield takeEvery(actionTypes[FORM_FIELD_BATCH_CREATE].fulfilled, function* ({ args }) {
    const form = prop('form', args)
    const id = prop('id', form)

    yield put(
      actions.formFieldBatch({
        id,
      })
    )

    toast.success(<SuccessToast text='Form is created' />)
  })
}

function watchFormType() {
  return watchSaga({ action: actionTypes[FORM_TYPE], api: API.formType })
}

function watchFormTypeCreate() {
  return watchSaga({ action: actionTypes[FORM_TYPE_CREATE], api: API.formTypeCreate })
}

function watchFormTypeCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_TYPE_CREATE],
    dispatchActions: actions.formTypeList(),
  })
}

function watchFormTypeDelete() {
  return watchSaga({ action: actionTypes[FORM_TYPE_DELETE], api: API.formDelete })
}

function watchFormTypeDeleteFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_TYPE_DELETE],
    dispatchActions: actions.formTypeList(),
  })
}

function watchFormTypeList() {
  return watchSaga({ action: actionTypes[FORM_TYPE_LIST], api: API.formTypeList })
}

function watchFormTypeAll() {
  return watchSaga({ action: actionTypes[FORM_TYPE_ALL], api: API.formTypeAll })
}

function watchFormRequest() {
  return watchSaga({ action: actionTypes[FORM_REQUEST], api: API.formRequest })
}

function watchFormRequestItem() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_ITEM], api: API.formRequest })
}

function watchFormRequestItemFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_REQUEST_ITEM],
    shouldOpenModal: true,
    shouldSetNewItem: true,
  })
}

function watchFormRequestCreate() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_CREATE], api: API.formRequestCreate })
}

function watchFormRequestCertificate() {
  return watchSaga({
    action: actionTypes[FORM_REQUEST_CERTIFICATE],
    api: API.formRequestCertificate,
  })
}

function watchFormRequestCertificateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_REQUEST_CERTIFICATE],
    dispatchActions: actions.formRequestItem,
    shouldCloseModal: true,
    filter: {
      filterAction: actions.formRequestList,
    },
    message: {
      title: 'Successfully submitted',
      description: 'Your form was successfully submitted!',
    },
    // autoDownloadParams: {
    //   path: ['url'],
    // },
  })
}

function watchFormRequestCertificateRejected() {
  return watchSagaRejected({
    action: actionTypes[FORM_REQUEST_CERTIFICATE],
    message: true,
  })
}

function watchFormRequestCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_REQUEST_CREATE],
    message: {
      title: 'Successfully submitted',
      description: 'Your form was successfully submitted!',
    },
    dispatchActions: push(ROUTE.FORM_FORMS_FOR_STUDENT),
  })
}

function watchFormRequestCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[FORM_REQUEST_CREATE],
    message: true,
  })
}

function watchFormRequestDelete() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_DELETE], api: API.formRequestDelete })
}

function watchFormRequestList() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_LIST], api: API.formRequestList })
}

function watchFormRequestAll() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_ALL], api: API.formRequestAll })
}

function watchFormRequestChangeStatus() {
  return watchSaga({
    action: actionTypes[FORM_REQUEST_CHANGE_STATUS],
    api: API.formRequestChangeStatus,
  })
}

function watchFormRequestChangeStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[FORM_REQUEST_CHANGE_STATUS],
    dispatchActions: actions.formRequestList(),
  })
}

function watchFormRequestFields() {
  return watchSaga({ action: actionTypes[FORM_REQUEST_FIELDS], api: API.formRequestFields })
}

export function* formSaga() {
  yield all([
    fork(watchForm),
    fork(watchFormCreate),
    fork(watchFormCreateFulfilled),
    fork(watchFormDelete),
    fork(watchFormDeleteFulfilled),
    fork(watchFormList),
    fork(watchFormAll),
    fork(watchFormActionType),
    fork(watchFormFieldCreate),
    fork(watchFormFieldDelete),
    fork(watchFormFieldBatch),
    fork(watchFormFieldBatchCreate),
    fork(watchFormFieldBatchCreateFulfilled),
    fork(watchFormType),
    fork(watchFormTypeCreate),
    fork(watchFormTypeCreateFulfilled),
    fork(watchFormTypeDelete),
    fork(watchFormTypeDeleteFulfilled),
    fork(watchFormTypeList),
    fork(watchFormTypeAll),
    fork(watchFormRequest),
    fork(watchFormRequestCreate),
    fork(watchFormRequestCreateFulfilled),
    fork(watchFormRequestCreateRejected),
    fork(watchFormRequestDelete),
    fork(watchFormRequestList),
    fork(watchFormRequestAll),
    fork(watchFormRequestChangeStatus),
    fork(watchFormRequestChangeStatusFulfilled),
    fork(watchFormRequestFields),
    fork(watchFormRequestItem),
    fork(watchFormRequestItemFulfilled),
    fork(watchFormRequestCertificateFulfilled),
    fork(watchFormRequestCertificateRejected),
    fork(watchFormRequestCertificate),
  ])
}

import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  EMAIL_TEMPLATE,
  EMAIL_TEMPLATE_ALL,
  EMAIL_TEMPLATE_CREATE,
  EMAIL_TEMPLATE_DELETE,
  EMAIL_TEMPLATE_LIST,
  SEND_MAIL,
} from '../constants/actionTypes'

function watchSendMail() {
  return watchSaga({
    action: actionTypes[SEND_MAIL],
    api: API.sendMail,
  })
}

function watchSendMailFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[SEND_MAIL],
    message: {
      title: 'Mail has been sent!',
    },
  })
}

function watchSendMailRejected() {
  return watchSagaRejected({
    action: actionTypes[SEND_MAIL],
    message: true,
  })
}

function watchEmailTemplate() {
  return watchSaga({
    action: actionTypes[EMAIL_TEMPLATE],
    api: API.emailTemplate,
  })
}

function watchEmailTemplateCreate() {
  return watchSaga({
    action: actionTypes[EMAIL_TEMPLATE_CREATE],
    api: API.emailTemplateCreate,
  })
}

function watchEmailTemplateCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[EMAIL_TEMPLATE_CREATE],
    message: {
      title: 'Success',
      description: 'EmailTemplate has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchEmailTemplateDelete() {
  return watchSaga({
    action: actionTypes[EMAIL_TEMPLATE_DELETE],
    api: API.emailTemplateDelete,
  })
}

function* watchEmailTemplateDeleteFulfilled() {
  yield takeEvery(actionTypes[EMAIL_TEMPLATE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.emailTemplateList())
  })
}

function* watchEmailTemplateDeleteRejected() {
  yield takeEvery(actionTypes[EMAIL_TEMPLATE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchEmailTemplateList() {
  return watchSaga({
    action: actionTypes[EMAIL_TEMPLATE_LIST],
    api: API.emailTemplateList,
  })
}

function watchEmailTemplateAll() {
  return watchSaga({
    action: actionTypes[EMAIL_TEMPLATE_ALL],
    api: API.emailTemplateAll,
  })
}

export function* mailSaga() {
  yield all([
    fork(watchSendMail),
    fork(watchSendMailFulfilled),
    fork(watchSendMailRejected),
    fork(watchEmailTemplate),
    fork(watchEmailTemplateCreate),
    fork(watchEmailTemplateCreateFulfilled),
    fork(watchEmailTemplateDelete),
    fork(watchEmailTemplateDeleteFulfilled),
    fork(watchEmailTemplateDeleteRejected),
    fork(watchEmailTemplateList),
    fork(watchEmailTemplateAll),
  ])
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const application = data =>
  getInstance().get(`${API.APPLICATION_FORM}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const applicationCreate = data => getInstance().post(API.APPLICATION_FORM, data)

export const applicationDelete = data => getInstance().delete(`${API.APPLICATION_FORM}${prop('id', data)}`)

export const applicationList = data =>
  getInstance().get(API.APPLICATION, {
    params: data,
  })

export const applicationAll = data =>
  getInstance().get(API.APPLICATION_ALL, {
    params: data,
  })

export const applicationFields = data =>
  getInstance().get(API.APPLICATION_FIELDS, {
    params: data,
  })

export const applicationTypes = data =>
  getInstance().get(API.APPLICATION_TYPES, {
    params: data,
  })

export const applicationStatus = data => getInstance().put(API.APPLICATION_STATUS, data)

export const applicationFieldBatchCreate = data => getInstance().post(API.APPLICATION_FIELD_BATCH, data)

export const applicationGenerate = data => getInstance().post(API.APPLICATION_GENERATE, data)

export const applicationFieldBatch = data =>
  getInstance().get(`${API.APPLICATION_FIELD_BATCH}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

import actionTypes, {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_DELETE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
} from '../constants/actionTypes'

export const enrollment = payload => ({
  type: actionTypes[ENROLLMENT].pending,
  payload,
})

export const enrollmentClear = () => ({
  type: actionTypes[ENROLLMENT].clear,
})

export const enrollmentCreate = payload => ({
  type: actionTypes[ENROLLMENT_CREATE].pending,
  payload,
})

export const enrollmentCreateClear = () => ({
  type: actionTypes[ENROLLMENT_CREATE].clear,
})

export const enrollmentDelete = payload => ({
  type: actionTypes[ENROLLMENT_DELETE].pending,
  payload,
})

export const enrollmentList = payload => ({
  type: actionTypes[ENROLLMENT_LIST].pending,
  payload,
})

export const enrollmentListClear = () => ({
  type: actionTypes[ENROLLMENT_LIST].clear,
})

export const enrollmentAll = payload => ({
  type: actionTypes[ENROLLMENT_ALL].pending,
  payload,
})

export const enrollmentAllClear = () => ({
  type: actionTypes[ENROLLMENT_ALL].clear,
})

export const enrollmentStatus = payload => ({
  type: actionTypes[ENROLLMENT_STATUS].pending,
  payload,
})

export const enrollmentStatusClear = () => ({
  type: actionTypes[ENROLLMENT_STATUS].clear,
})

export const enrollmentCourses = payload => ({
  type: actionTypes[ENROLLMENT_COURSES].pending,
  payload,
})

export const enrollmentCoursesClear = () => ({
  type: actionTypes[ENROLLMENT_COURSES].clear,
})

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import { ROLE, ROLE_ALL, ROLE_CREATE, ROLE_LIST } from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ROLE]: createState(ROLE),
  [STATE.ROLE_CREATE]: createState(ROLE_CREATE),
  [STATE.ROLE_LIST]: createState(ROLE_LIST),
  [STATE.ROLE_ALL]: createState(ROLE_ALL),
})

export const ROLES = STATE.GLOBAL_STATE

export default reducer

import { getInstance } from '@helpers/httpClient'
import { equals, flatten, join, prop, type } from 'ramda'

import * as API from '../constants/api'

export const extenuatingCircumstances = (data = {}) => {
  const adjustedObj = {}
  for (const key in data) {
    if (data[key]) {
      if (equals(type(data[key], 'Array'))) {
        adjustedObj[key] = join(',', flatten([data[key]]))
      }
    }
  }
  return getInstance().get(API.ASSESSMENT_EC, {
    params: adjustedObj,
  })
}

export const extenuatingCircumstancesPaper = data => {
  return getInstance().post(API.ASSESSMENT_EC_PAPER, data)
}

export const extenuatingCircumstancesUpdate = data => {
  return getInstance().post(API.ASSESSMENT_EC_UPDATE, data)
}

export const extenuatingCircumstancesOutcomeReport = data => {
  return getInstance().post(API.ASSESSMENT_EC_OUTCOME_REPORT, data)
}

export const extenuatingCircumstancesChangeStatus = data => getInstance().put(API.ASSESSMENT_EC_CHANGE_STATUS, data)
export const extenuatingCircumstancesCreate = data => getInstance().post(API.ASSESSMENT_EC, data)

export const extenuatingCircumstancesGetById = data => getInstance().delete(`${API.ASSESSMENT_EC}${prop('id', data)}`)

export const extenuatingCircumstancesAll = data =>
  getInstance().get(API.ASSESSMENT_EC_ALL, {
    params: data,
  })
export const extenuatingCircumstancesDeleteById = data =>
  getInstance().delete(`${API.ASSESSMENT_EC}${prop('id', data)}`)

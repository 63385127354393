import { createAction } from '@helpers/createAction'

export const ASSESSMENT_EC = 'ASSESSMENT_EC'
export const ASSESSMENT_EC_CREATE = 'ASSESSMENT_EC_CREATE'
export const ASSESSMENT_EC_ALL = 'ASSESSMENT_EC_ALL'
export const ASSESSMENT_EC_DELETE = 'ASSESSMENT_EC_DELETE'
export const ASSESSMENT_EC_STATUS = 'ASSESSMENT_EC_STATUS'
export const ASSESSMENT_EC_UPDATE = 'ASSESSMENT_EC_UPDATE'
export const ASSESSMENT_EC_OUTCOME_REPORT = 'ASSESSMENT_EC_OUTCOME_REPORT'
export const ASSESSMENT_EC_PAPER = 'ASSESSMENT_EC_PAPER'

export default {
  [ASSESSMENT_EC]: createAction(ASSESSMENT_EC),
  [ASSESSMENT_EC_CREATE]: createAction(ASSESSMENT_EC_CREATE),
  [ASSESSMENT_EC_ALL]: createAction(ASSESSMENT_EC_ALL),
  [ASSESSMENT_EC_DELETE]: createAction(ASSESSMENT_EC_DELETE),
  [ASSESSMENT_EC_STATUS]: createAction(ASSESSMENT_EC_STATUS),
  [ASSESSMENT_EC_UPDATE]: createAction(ASSESSMENT_EC_UPDATE),
  [ASSESSMENT_EC_OUTCOME_REPORT]: createAction(ASSESSMENT_EC_OUTCOME_REPORT),
  [ASSESSMENT_EC_PAPER]: createAction(ASSESSMENT_EC_PAPER),
}

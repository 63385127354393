import { forEachObjIndexed, includes, isEmpty, path } from 'ramda'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { createAction } from './createAction'
import { createState } from './createState'
import { toCamelCase } from './toCamelCase'

function transferData(data) {
  if (data && !isEmpty(data)) {
    return data
  }
  return {}
}

function actionGenerator(type) {
  if (includes('PENDING', type)) {
    return payload => ({
      type,
      payload,
    })
  } else if (includes('CLEAR', type)) {
    return () => ({
      type,
    })
  }
}

export function createReduxEssentials(actionNames = {}, globalState = '') {
  const actionTypes = {}
  const reducers = {}
  let actions = {}
  let selectors = {}
  actions = new Proxy(actions, {
    get(target, prop) {
      const formatted = toCamelCase(prop)
      if (formatted in target) {
        return target[formatted]
      } else {
        return target[prop]
      }
    },
  })
  selectors = new Proxy(selectors, {
    get(target, prop) {
      const formatted = toCamelCase(prop)
      if (formatted in target) {
        return target[formatted]
      } else {
        return target[prop]
      }
    },
  })
  forEachObjIndexed((value, key) => {
    const actionStages = createAction(value)
    actionTypes[value] = actionStages
    actions[key] = {
      fetch: actionGenerator(actionStages.pending),
      clear: actionGenerator(actionStages.clear),
    }
    reducers[key] = createState(value)
    selectors[key] = createSelector(path([globalState, key]), transferData)
  })(actionNames)
  const reducer = combineReducers(reducers)
  return { reducer, actionTypes, actions, selectors }
}

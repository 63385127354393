import { createAction } from '../../../../helpers/createAction'

export const MODULES = 'MODULES'
export const MODULES_CREATE = 'MODULES_CREATE'
export const MODULES_DELETE = 'MODULES_DELETE'
export const MODULES_LIST = 'MODULES_LIST'
export const MODULES_ALL = 'MODULES_ALL'

export default {
  [MODULES]: createAction(MODULES),
  [MODULES_CREATE]: createAction(MODULES_CREATE),
  [MODULES_DELETE]: createAction(MODULES_DELETE),
  [MODULES_LIST]: createAction(MODULES_LIST),
  [MODULES_ALL]: createAction(MODULES_ALL),
}

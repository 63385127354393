import actionTypes, {
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_DELETE,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
} from '../constants/actionTypes'

export const assessmentEC = payload => ({
  type: actionTypes[ASSESSMENT_EC].pending,
  payload,
})

export const assessmentECCreate = payload => ({
  type: actionTypes[ASSESSMENT_EC_CREATE].pending,
  payload,
})

export const assessmentECUpdate = payload => ({
  type: actionTypes[ASSESSMENT_EC_UPDATE].pending,
  payload,
})

export const assessmentECOutcomeReport = payload => ({
  type: actionTypes[ASSESSMENT_EC_OUTCOME_REPORT].pending,
  payload,
})

export const assessmentECPaper = payload => ({
  type: actionTypes[ASSESSMENT_EC_PAPER].pending,
  payload,
})

export const assessmentECChangeStatus = payload => ({
  type: actionTypes[ASSESSMENT_EC_STATUS].pending,
  payload,
})

export const assessmentECAll = payload => ({
  type: actionTypes[ASSESSMENT_EC_ALL].pending,
  payload,
})

export const assessmentECClear = () => ({
  type: actionTypes[ASSESSMENT_EC_ALL].clear,
})

export const assessmentECDelete = payload => ({
  type: actionTypes[ASSESSMENT_EC_DELETE].pending,
  payload,
})

export const APPLICATION_FORM = 'application_form/'
export const APPLICATION = 'application/'
export const APPLICATION_GENERATE = `${APPLICATION}generate`
export const APPLICATION_TYPES = `${APPLICATION}types`
export const APPLICATION_FIELDS = `${APPLICATION}fields`
export const APPLICATION_STATUS = `${APPLICATION}status/`
export const APPLICATION_ALL = `${APPLICATION_FORM}all`
export const APPLICATION_FIELD_BATCH = `${APPLICATION_FORM}field/batch/`

export const APPLICATION_CATEGORY = `${APPLICATION_FORM}category/`
export const APPLICATION_CATEGORY_ALL = `${APPLICATION_CATEGORY}all/`

export const APPLICATION_REQUEST = APPLICATION
export const APPLICATION_REQUEST_ALL = `${APPLICATION_REQUEST}all/`
export const APPLICATION_REQUEST_EDIT = `${APPLICATION_REQUEST}edit/`
export const APPLICATION_REQUEST_UPDATE = `${APPLICATION_REQUEST}update`

export const APPLICATION_SIGNUP = `${APPLICATION_FORM}signup`
export const APPLICATION_SMS_SEND = `${APPLICATION_FORM}sms/send`

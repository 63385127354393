import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.CALENDAR_EVENT]: createState(actionTypes.CALENDAR_EVENT),
  [STATE.CALENDAR_EVENT_CREATE]: createState(actionTypes.CALENDAR_EVENT_CREATE),
  [STATE.CALENDAR_EVENT_LIST]: createState(actionTypes.CALENDAR_EVENT_LIST),
  [STATE.CALENDAR_EVENT_ALL]: createState(actionTypes.CALENDAR_EVENT_ALL),
  [STATE.CALENDAR_EVENT_LIST_STUDENT]: createState(actionTypes.CALENDAR_EVENT_LIST_STUDENT),
})

export const CALENDAR = STATE.GLOBAL_STATE

export default reducer

import { toCamelCase } from '@helpers/toCamelCase'

export const E_LIBRARY = 'E_LIBRARY'
export const E_LIBRARY_LIST = 'E_LIBRARY_LIST'
export const E_LIBRARY_ALL = 'E_LIBRARY_ALL'
export const E_LIBRARY_CREATE = 'E_LIBRARY_CREATE'
export const E_LIBRARY_DELETE = 'E_LIBRARY_DELETE'
export const E_LIBRARY_TYPES = 'E_LIBRARY_TYPES'

export const GLOBAL_STATE = 'FileExchanger'

export const actionNames = {
  [toCamelCase(E_LIBRARY)]: E_LIBRARY,
  [toCamelCase(E_LIBRARY_TYPES)]: E_LIBRARY_TYPES,
  [toCamelCase(E_LIBRARY_LIST)]: E_LIBRARY_LIST,
  [toCamelCase(E_LIBRARY_ALL)]: E_LIBRARY_ALL,
  [toCamelCase(E_LIBRARY_CREATE)]: E_LIBRARY_CREATE,
  [toCamelCase(E_LIBRARY_DELETE)]: E_LIBRARY_DELETE,
}

import { createAction } from '@helpers/createAction'

export const COURSE_CAMPAIGN = 'COURSE_CAMPAIGN'
export const COURSE_CAMPAIGN_CREATE = 'COURSE_CAMPAIGN_CREATE'
export const COURSE_CAMPAIGN_DELETE = 'COURSE_CAMPAIGN_DELETE'
export const COURSE_CAMPAIGN_LIST = 'COURSE_CAMPAIGN_LIST'
export const COURSE_CAMPAIGN_ALL = 'COURSE_CAMPAIGN_ALL'

export default {
  [COURSE_CAMPAIGN]: createAction(COURSE_CAMPAIGN),
  [COURSE_CAMPAIGN_CREATE]: createAction(COURSE_CAMPAIGN_CREATE),
  [COURSE_CAMPAIGN_DELETE]: createAction(COURSE_CAMPAIGN_DELETE),
  [COURSE_CAMPAIGN_LIST]: createAction(COURSE_CAMPAIGN_LIST),
  [COURSE_CAMPAIGN_ALL]: createAction(COURSE_CAMPAIGN_ALL),
}

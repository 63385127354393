import { ErrorToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { path, pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as APi from '../api'
import actionTypes, { CHECK_USER_OPEN_LINK, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'

function watchGenerateUserOpenLink() {
  return watchSaga({
    action: actionTypes[USER_OPEN_LINK],
    api: APi.userOpenLink,
  })
}

function watchGenerateUserOpenLinkFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[USER_OPEN_LINK],
    message: {
      title: 'Success',
      description: 'Successfully generated',
    },
  })
}

function watchGenerateUserOpenLinkRejected() {
  return watchSagaRejected({
    action: actionTypes[USER_OPEN_LINK],
    message: true,
  })
}

function watchCheckUserOpenLink() {
  return watchSaga({
    action: actionTypes[CHECK_USER_OPEN_LINK],
    api: APi.checkUserOpenLink,
  })
}

function watchCheckUserOpenLinkFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[CHECK_USER_OPEN_LINK],
  })
}

function watchCheckUserOpenLinkRejected() {
  return watchSagaRejected({
    action: actionTypes[CHECK_USER_OPEN_LINK],
    message: true,
  })
}

function watchUserPublicInfo() {
  return watchSaga({
    action: actionTypes[USER_PUBLIC_INFO],
    api: APi.userPublicInfo,
  })
}

function* watchUserPublicInfoFulfilled() {
  yield takeEvery(actionTypes[USER_PUBLIC_INFO].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['payload', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchUserPublicInfoRejected() {
  yield takeEvery(actionTypes[USER_PUBLIC_INFO].rejected, function* (action) {
    const description = path(['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['payload', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

export function* userOpenLinkSaga() {
  yield all([
    fork(watchGenerateUserOpenLink),
    fork(watchGenerateUserOpenLinkFulfilled),
    fork(watchGenerateUserOpenLinkRejected),
    fork(watchCheckUserOpenLink),
    fork(watchCheckUserOpenLinkFulfilled),
    fork(watchCheckUserOpenLinkRejected),
    fork(watchUserPublicInfo),
    fork(watchUserPublicInfoFulfilled),
    fork(watchUserPublicInfoRejected),
  ])
}

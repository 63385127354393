import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { GLOBAL_STATE, MEAL, MEAL_ACTIVATION, MEAL_ACTIVATION_ALL, MEAL_ALL, MEAL_LIST } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const MEALS = GLOBAL_STATE

export const { reducer: mealReducer } = createSlice({
  name: MEALS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.meal.pending, state => {
        state[MEAL].loading = true
      })
      .addCase(actionCreators.meal.rejected, (state, action) => {
        state[MEAL].loading = false
        state[MEAL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.meal.fulfilled, (state, action) => {
        state[MEAL].loading = false
        state[MEAL].error = null
        state[MEAL].data = action.payload
      })

    builder
      .addCase(actionCreators.mealList.pending, state => {
        state[MEAL_LIST].loading = true
      })
      .addCase(actionCreators.mealList.rejected, (state, action) => {
        state[MEAL_LIST].loading = false
        state[MEAL_LIST].error = action.payload as AxiosError
      })
      .addCase(actionCreators.mealList.fulfilled, (state, action) => {
        state[MEAL_LIST].loading = false
        state[MEAL_LIST].error = null
        state[MEAL_LIST].data = action.payload
      })

    builder
      .addCase(actionCreators.mealAll.pending, state => {
        state[MEAL_ALL].loading = true
      })
      .addCase(actionCreators.mealAll.rejected, (state, action) => {
        state[MEAL_ALL].loading = false
        state[MEAL_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.mealAll.fulfilled, (state, action) => {
        state[MEAL_ALL].loading = false
        state[MEAL_ALL].error = null
        state[MEAL_ALL].data = action.payload
      })

    builder
      .addCase(actionCreators.mealActivation.pending, state => {
        state[MEAL_ACTIVATION].loading = true
      })
      .addCase(actionCreators.mealActivation.rejected, (state, action) => {
        state[MEAL_ACTIVATION].loading = false
        state[MEAL_ACTIVATION].error = action.payload as AxiosError
      })
      .addCase(actionCreators.mealActivation.fulfilled, (state, action) => {
        state[MEAL_ACTIVATION].loading = false
        state[MEAL_ACTIVATION].error = null
        state[MEAL_ACTIVATION].data = action.payload
      })

    builder
      .addCase(actionCreators.mealActivationAll.pending, state => {
        state[MEAL_ACTIVATION_ALL].loading = true
      })
      .addCase(actionCreators.mealActivationAll.rejected, (state, action) => {
        state[MEAL_ACTIVATION_ALL].loading = false
        state[MEAL_ACTIVATION_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.mealActivationAll.fulfilled, (state, action) => {
        state[MEAL_ACTIVATION_ALL].loading = false
        state[MEAL_ACTIVATION_ALL].error = null
        state[MEAL_ACTIVATION_ALL].data = action.payload
      })

    builder.addCase(actionCreators.mealClear, state => {
      state[MEAL] = defaultState()
    })

    builder.addCase(actionCreators.mealListClear, state => {
      state[MEAL_LIST] = defaultState()
    })

    builder.addCase(actionCreators.mealAllClear, state => {
      state[MEAL_ALL] = defaultState()
    })

    builder.addCase(actionCreators.mealActivationClear, state => {
      state[MEAL_ACTIVATION] = defaultState()
    })

    builder.addCase(actionCreators.mealActivationListClear, state => {
      state[MEAL_ACTIVATION_ALL] = defaultState()
    })
  },
})

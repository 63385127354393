import { toCamelCase } from '@helpers/toCamelCase'

export const GUEST_LIST = 'GUEST_LIST'
export const GUEST_ALL = 'GUEST_ALL'

export const GLOBAL_STATE = 'Guests'

export const actionNames = {
  [toCamelCase(GUEST_ALL)]: GUEST_ALL,
  [toCamelCase(GUEST_LIST)]: GUEST_LIST,
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ATTENDANCE_GROUP]: createState(actionTypes.ATTENDANCE_GROUP),
  [STATE.ATTENDANCE]: createState(actionTypes.ATTENDANCE),
  [STATE.ATTENDANCE_TABLE_STUDENT]: createState(actionTypes.ATTENDANCE_TABLE_STUDENT),
  [STATE.ATTENDANCE_TABLE]: createState(actionTypes.ATTENDANCE_TABLE),
  [STATE.ATTENDANCE_CSV]: createState(actionTypes.ATTENDANCE_CSV),
  [STATE.ATTENDANCE_TABLE_MODULE]: createState(actionTypes.ATTENDANCE_TABLE_MODULE),
})

export const ATTENDANCE = STATE.GLOBAL_STATE

export default reducer

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { DEPARTMENT_ALL, DEPARTMENT_CREATE, DEPARTMENT_LABELS, DEPARTMENT_LIST, GLOBAL_STATE } from '../constants/state'

export const departmentListAllSelector = createSelector(path([GLOBAL_STATE, DEPARTMENT_ALL]), data => data)

export const departmentListSelector = createSelector(path([GLOBAL_STATE, DEPARTMENT_LIST]), data => data)

export const departmentCreateSelector = createSelector(path([GLOBAL_STATE, DEPARTMENT_CREATE]), data => data)

export const departmentLabelsSelector = createSelector(path([GLOBAL_STATE, DEPARTMENT_LABELS]), data => data)

export const departmentSelectors = {
  departmentListAllSelector,
  departmentListSelector,
  departmentCreateSelector,
  departmentLabelsSelector,
}

import actionTypes, {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_DELETE,
  EDUCATION_PLACE_LIST,
} from '../constants/actionTypes'

export const educationPlace = payload => ({
  type: actionTypes[EDUCATION_PLACE].pending,
  payload,
})

export const educationPlaceClear = () => ({
  type: actionTypes[EDUCATION_PLACE].clear,
})

export const educationPlaceCreate = payload => ({
  type: actionTypes[EDUCATION_PLACE_CREATE].pending,
  payload,
})

export const educationPlaceDelete = payload => ({
  type: actionTypes[EDUCATION_PLACE_DELETE].pending,
  payload,
})

export const educationPlaceList = payload => ({
  type: actionTypes[EDUCATION_PLACE_LIST].pending,
  payload,
})

export const educationPlaceListClear = () => ({
  type: actionTypes[EDUCATION_PLACE_LIST].clear,
})

export const educationPlaceAll = payload => ({
  type: actionTypes[EDUCATION_PLACE_ALL].pending,
  payload,
})

export const educationPlaceAllClear = () => ({
  type: actionTypes[EDUCATION_PLACE_ALL].clear,
})

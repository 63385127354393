import { ErrorToast, SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import * as stateSelectors from '@store/selector'
import { path, pathOr, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
} from '../constants/actionTypes'

function watchAttendance() {
  return watchSaga({ action: actionTypes[ATTENDANCE], api: API.attendance })
}

function watchAttendanceCsv() {
  return watchSaga({ action: actionTypes[ATTENDANCE_CSV], api: API.attendanceCsv })
}

function watchAttendanceCsvRejected() {
  return watchSagaRejected({ action: actionTypes[ATTENDANCE_CSV], message: true })
}

function watchAttendanceCsvFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ATTENDANCE_CSV],
    message: {
      title: 'Success',
    },
    autoDownloadParams: {
      path: ['url'],
    },
  })
}

function* watchAttendanceFulfilled() {
  yield takeEvery(actionTypes[ATTENDANCE].fulfilled, function* (payload) {
    const { query } = yield select(stateSelectors.routerSelector)
    yield put(
      actions.attendanceGroup({
        lesson_id: +propOr('', 'lessonId', query),
        group_id: path(['args', 'group_id'], payload),
      })
    )
    toast.success(<SuccessToast text='Success' />)
  })
}

function watchAttendanceRejected() {
  return watchSagaRejected({
    action: actionTypes[ATTENDANCE],
    message: true,
  })
}

function watchAttendanceGroup() {
  return watchSaga({ action: actionTypes[ATTENDANCE_GROUP], api: API.attendanceGroup })
}

function watchAttendanceTable() {
  return watchSaga({ action: actionTypes[ATTENDANCE_TABLE], api: API.attendanceTable })
}

function watchAttendanceTableStudent() {
  return watchSaga({
    action: actionTypes[ATTENDANCE_TABLE_STUDENT],
    api: API.attendanceTableStudent,
  })
}

function watchAttendanceTableStudentFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ATTENDANCE_TABLE_STUDENT],
  })
}

function watchAttendanceTableFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ATTENDANCE_TABLE_STUDENT],
  })
}

function watchAttendanceTableStudentRejected() {
  return watchSagaRejected({
    action: actionTypes[ATTENDANCE_TABLE_STUDENT],
    message: true,
  })
}

function watchAttendanceTableRejected() {
  return watchSagaRejected({
    action: actionTypes[ATTENDANCE_TABLE_STUDENT],
    message: true,
  })
}

function watchAttendanceTableModule() {
  return watchSaga({
    action: actionTypes[ATTENDANCE_TABLE_MODULE],
    api: API.attendanceTableModule,
  })
}

function* watchAttendanceTableModuleFulfilled() {
  yield takeEvery(actionTypes[ATTENDANCE_TABLE_MODULE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
  })
}

function* watchAttendanceTableModuleRejected() {
  yield takeEvery(actionTypes[ATTENDANCE_TABLE_MODULE].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    onRejected(action)
    toast.error(<ErrorToast text={pathOr('', ['payload', 'message'], action)} />)
  })
}

export function* attendanceSaga() {
  yield all([
    fork(watchAttendance),
    fork(watchAttendanceGroup),
    fork(watchAttendanceTable),
    fork(watchAttendanceTableStudent),
    fork(watchAttendanceTableRejected),
    fork(watchAttendanceTableStudentRejected),
    fork(watchAttendanceTableFulfilled),
    fork(watchAttendanceTableStudentFulfilled),
    fork(watchAttendanceFulfilled),
    fork(watchAttendanceRejected),
    fork(watchAttendanceCsv),
    fork(watchAttendanceCsvRejected),
    fork(watchAttendanceCsvFulfilled),
    fork(watchAttendanceTableModule),
    fork(watchAttendanceTableModuleFulfilled),
    fork(watchAttendanceTableModuleRejected),
  ])
}

import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import { GLOBAL_STATE, MODULES, MODULES_LIST, MODULES_ALL } from '../constants/state'

export const modulesSelector = createSelector(path([GLOBAL_STATE, MODULES]), data => data)

export const modulesListSelector = createSelector(path([GLOBAL_STATE, MODULES_LIST]), data => data)

export const modulesAllSelector = createSelector(path([GLOBAL_STATE, MODULES_ALL]), data => data)

export const moduleSelectors = {
  modulesSelector,
  modulesListSelector,
  modulesAllSelector,
}

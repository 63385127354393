import { WatchSaga } from '@helpers/customSaga'
import { all, fork } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/actionTypes'

const moduleOutcome = new WatchSaga({ actionType: actionTypes[MODULES_OUTCOME], api: API.modulesOutcome })

const moduleOutcomeCreate = new WatchSaga({
  actionType: actionTypes[MODULES_OUTCOME_CREATE],
  api: API.modulesOutcomeCreate,
})

const moduleOutcomeList = new WatchSaga({ actionType: actionTypes[MODULES_OUTCOME_LIST], api: API.modulesOutcomeList })

export function* modulesOutcomeSaga() {
  yield all([
    fork(moduleOutcome.watch),
    fork(moduleOutcome.watchRejected),
    fork(moduleOutcome.watchFulfilled),
    fork(moduleOutcomeList.watch),
    fork(moduleOutcomeList.watchRejected),
    fork(moduleOutcomeList.watchFulfilled),
    fork(moduleOutcomeCreate.watch),
    fork(moduleOutcomeCreate.watchFulfilled),
    fork(moduleOutcomeCreate.watchRejected),
  ])
}

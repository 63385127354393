import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as TYPES from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ST_COURSE_APPLICATION]: createState(TYPES.ST_COURSE_APPLICATION),
  [STATE.ST_COURSE_APPLICATION_LIST]: createState(TYPES.ST_COURSE_APPLICATION_LIST),
  [STATE.ST_COURSE_APPLICATION_ALL]: createState(TYPES.ST_COURSE_APPLICATION_ALL),
  [STATE.ST_COURSE_APPLICATION_TYPES]: createState(TYPES.ST_COURSE_APPLICATION_TYPES),
  [STATE.ST_COURSE_APPLICATION_STATUS]: createState(TYPES.ST_COURSE_APPLICATION_STATUS),
  [STATE.ST_COURSE_APPLICATION_FIELD_BATCH]: createState(TYPES.ST_COURSE_APPLICATION_FIELD_BATCH),
  [STATE.ST_COURSE_APPLICATION_CATEGORY]: createState(TYPES.ST_COURSE_APPLICATION_CATEGORY),
  [STATE.ST_COURSE_APPLICATION_CATEGORY_LIST]: createState(TYPES.ST_COURSE_APPLICATION_CATEGORY_LIST),
  [STATE.ST_COURSE_APPLICATION_CATEGORY_ALL]: createState(TYPES.ST_COURSE_APPLICATION_CATEGORY_ALL),
  [STATE.ST_COURSE_APPLICATION_REQUEST_ALL]: createState(TYPES.ST_COURSE_APPLICATION_REQUEST_ALL),
  [STATE.ST_COURSE_APPLICATION_REQUEST_EDIT]: createState(TYPES.ST_COURSE_APPLICATION_REQUEST_EDIT),
  [STATE.ST_COURSE_APPLICATION_REQUEST_UPDATE]: createState(TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE),
})

export const ST_COURSE = STATE.GLOBAL_STATE

export default reducer

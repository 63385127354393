import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const generalApplicationRequest = data => getInstance().post(API.GENERAL_APPLICATION_REQUEST, data)

export const generalApplicationRequestAll = data =>
  getInstance().get(API.GENERAL_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const generalApplicationRequestEdit = data =>
  getInstance().get(API.GENERAL_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const generalApplicationRequestUpdate = data => getInstance().put(API.GENERAL_APPLICATION_REQUEST_UPDATE, data)

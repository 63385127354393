import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'
import * as actions from './actions'

function watchModuleFilesList() {
  return watchSaga({
    action: actionTypes[MODULE_FILE_LIST],
    api: API.moduleFileList,
  })
}

function watchModuleFilesAll() {
  return watchSaga({
    action: actionTypes[MODULE_FILE_ALL],
    api: API.moduleFileAll,
  })
}

function watchModuleFilesCreate() {
  return watchSaga({
    action: actionTypes[MODULE_FILE_CREATE],
    api: API.moduleFileCreate,
  })
}

function watchModuleFilesDelete() {
  return watchSaga({
    action: actionTypes[MODULE_FILE_DELETE],
    api: API.moduleFileDelete,
  })
}

function watchModuleFilesCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[MODULE_FILE_CREATE],
    message: {
      title: 'Success',
    },
    dispatchActions: actions.moduleFileList(),
  })
}

function* watchModuleFilesDeleteFulfilled() {
  yield takeEvery(actionTypes[MODULE_FILE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.moduleFileList())
  })
}

function* watchModuleFilesDeleteRejected() {
  yield takeEvery(actionTypes[MODULE_FILE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchModuleFilesCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[MODULE_FILE_CREATE],
    message: true,
  })
}

function watchModuleFilesActivate() {
  return watchSaga({
    action: actionTypes[MODULE_FILE],
    api: API.moduleFileActivate,
  })
}

function watchModuleFilesActivateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[MODULE_FILE],
    message: {
      title: 'Success',
    },
  })
}

function watchModuleFilesActivateRejected() {
  return watchSagaRejected({
    action: actionTypes[MODULE_FILE],
    message: true,
  })
}

export function* moduleFilesSaga() {
  yield all([
    fork(watchModuleFilesList),
    fork(watchModuleFilesCreate),
    fork(watchModuleFilesDelete),
    fork(watchModuleFilesDeleteFulfilled),
    fork(watchModuleFilesDeleteRejected),
    fork(watchModuleFilesAll),
    fork(watchModuleFilesCreateFulfilled),
    fork(watchModuleFilesCreateRejected),
    fork(watchModuleFilesActivate),
    fork(watchModuleFilesActivateFulfilled),
    fork(watchModuleFilesActivateRejected),
  ])
}

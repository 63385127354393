import { getInstance } from '@helpers/httpClient'

import {
  CONTRACT_AGREEMENT,
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
  CONTRACT_DOWNLOAD,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const contractAgreement = data =>
  getInstance().get(API.CONTRACT_AGREEMENT_ALL, {
    params: data,
  })

export const contractAgreementOpen = data =>
  getInstance().get(API.CONTRACT_AGREEMENT_OPEN, {
    params: data,
  })

export const contractAgreementCurrent = data =>
  getInstance().get(API.CONTRACT_AGREEMENT_CURRENT, {
    params: data,
  })

export const contractAgreementAccept = data => getInstance().post(API.CONTRACT_AGREEMENT_ACCEPT, data)

export const contractDownload = data => getInstance().post(API.CONTRACT_DOWNLOAD, data)

export default {
  [CONTRACT_AGREEMENT]: contractAgreement,
  [CONTRACT_AGREEMENT_CURRENT]: contractAgreementCurrent,
  [CONTRACT_AGREEMENT_ACCEPT]: contractAgreementAccept,
  [CONTRACT_AGREEMENT_OPEN]: contractAgreementOpen,
  [CONTRACT_DOWNLOAD]: contractDownload,
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  REPORT,
  REPORT_ALL,
  REPORT_CREATE,
  REPORT_FIELDS,
  REPORT_FIELDS_FILTER,
  REPORT_LIST,
} from '../constants/state'

export const reportAllSelector = createSelector(path([GLOBAL_STATE, REPORT_ALL]), data => data)

export const reportListSelector = createSelector(path([GLOBAL_STATE, REPORT_LIST]), data => data)

export const reportSelector = createSelector(path([GLOBAL_STATE, REPORT_CREATE]), data => data)

export const reportSingleSelector = createSelector(path([GLOBAL_STATE, REPORT]), data => data)

export const reportFieldsSelector = createSelector(path([GLOBAL_STATE, REPORT_FIELDS]), data => data)

export const reportFieldsFilterSelector = createSelector(path([GLOBAL_STATE, REPORT_FIELDS_FILTER]), data => data)

export const reportSelectors = {
  reportAllSelector,
  reportListSelector,
  reportSelector,
}

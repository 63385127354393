import { createAction } from '@helpers/createAction'

export const ST_COURSE_APPLICATION = 'ST_COURSE_APPLICATION'
export const ST_COURSE_APPLICATIONS = 'ST_COURSE_APPLICATIONS'
export const ST_COURSE_APPLICATION_CREATE = 'ST_COURSE_APPLICATION_CREATE'
export const ST_COURSE_APPLICATION_DELETE = 'ST_COURSE_APPLICATION_DELETE'
export const ST_COURSE_APPLICATION_LIST = 'ST_COURSE_APPLICATION_LIST'
export const ST_COURSE_APPLICATION_ALL = 'ST_COURSE_APPLICATION_ALL'
export const ST_COURSE_APPLICATION_TYPES = 'ST_COURSE_APPLICATION_TYPES'
export const ST_COURSE_APPLICATION_STATUS = 'ST_COURSE_APPLICATION_STATUS'

export const ST_COURSE_APPLICATION_FIELD_BATCH = 'ST_COURSE_APPLICATION_FIELD_BATCH'
export const ST_COURSE_APPLICATION_FIELD_BATCH_CREATE = 'ST_COURSE_APPLICATION_FIELD_BATCH_CREATE'

// category
export const ST_COURSE_APPLICATION_CATEGORY = 'ST_COURSE_APPLICATION_CATEGORY'
export const ST_COURSE_APPLICATION_CATEGORY_CREATE = 'ST_COURSE_APPLICATION_CATEGORY_CREATE'
export const ST_COURSE_APPLICATION_CATEGORY_DELETE = 'ST_COURSE_APPLICATION_CATEGORY_DELETE'
export const ST_COURSE_APPLICATION_CATEGORY_LIST = 'ST_COURSE_APPLICATION_CATEGORY_LIST'
export const ST_COURSE_APPLICATION_CATEGORY_ALL = 'ST_COURSE_APPLICATION_CATEGORY_ALL'

// request
export const ST_COURSE_APPLICATION_REQUEST = 'ST_COURSE_APPLICATION_REQUEST'
export const ST_COURSE_APPLICATION_REQUEST_ALL = 'ST_COURSE_APPLICATION_REQUEST_ALL'
export const ST_COURSE_APPLICATION_REQUEST_EDIT = 'ST_COURSE_APPLICATION_REQUEST_EDIT'
export const ST_COURSE_APPLICATION_REQUEST_UPDATE = 'ST_COURSE_APPLICATION_REQUEST_UPDATE'

export default {
  [ST_COURSE_APPLICATION]: createAction(ST_COURSE_APPLICATION),
  [ST_COURSE_APPLICATION_CREATE]: createAction(ST_COURSE_APPLICATION_CREATE),
  [ST_COURSE_APPLICATION_DELETE]: createAction(ST_COURSE_APPLICATION_DELETE),
  [ST_COURSE_APPLICATION_STATUS]: createAction(ST_COURSE_APPLICATION_STATUS),
  [ST_COURSE_APPLICATION_LIST]: createAction(ST_COURSE_APPLICATION_LIST),
  [ST_COURSE_APPLICATION_ALL]: createAction(ST_COURSE_APPLICATION_ALL),
  [ST_COURSE_APPLICATION_TYPES]: createAction(ST_COURSE_APPLICATION_TYPES),
  [ST_COURSE_APPLICATION_FIELD_BATCH]: createAction(ST_COURSE_APPLICATION_FIELD_BATCH),
  [ST_COURSE_APPLICATION_FIELD_BATCH_CREATE]: createAction(ST_COURSE_APPLICATION_FIELD_BATCH_CREATE),
  [ST_COURSE_APPLICATION_CATEGORY]: createAction(ST_COURSE_APPLICATION_CATEGORY),
  [ST_COURSE_APPLICATION_CATEGORY_CREATE]: createAction(ST_COURSE_APPLICATION_CATEGORY_CREATE),
  [ST_COURSE_APPLICATION_CATEGORY_DELETE]: createAction(ST_COURSE_APPLICATION_CATEGORY_DELETE),
  [ST_COURSE_APPLICATION_CATEGORY_LIST]: createAction(ST_COURSE_APPLICATION_CATEGORY_LIST),
  [ST_COURSE_APPLICATION_CATEGORY_ALL]: createAction(ST_COURSE_APPLICATION_CATEGORY_ALL),
  [ST_COURSE_APPLICATION_REQUEST]: createAction(ST_COURSE_APPLICATION_REQUEST),
  [ST_COURSE_APPLICATION_REQUEST_ALL]: createAction(ST_COURSE_APPLICATION_REQUEST_ALL),
  [ST_COURSE_APPLICATION_REQUEST_EDIT]: createAction(ST_COURSE_APPLICATION_REQUEST_EDIT),
  [ST_COURSE_APPLICATION_REQUEST_UPDATE]: createAction(ST_COURSE_APPLICATION_REQUEST_UPDATE),
}

import { watchSaga } from '@helpers/customSaga'
import { all, fork } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { DASHBOARD_STUDENT } from '../constants/actionTypes'

function watchDashboardStudent() {
  return watchSaga({
    action: actionTypes[DASHBOARD_STUDENT],
    api: API.dashboardStudent,
  })
}

export function* studentDashboard() {
  yield all([fork(watchDashboardStudent)])
}

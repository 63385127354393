import { AxiosError } from '@src/helpers/httpClient'
import { DefaultState } from '@store/types'

import {
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_COMPLETE,
  STC_STEP_CREATE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const initialState: () => {
  [STC]: DefaultState<`/${typeof API.STC}{id}`, 'get'>
  [STC_LIST]: DefaultState<`/${typeof API.STC}`, 'get'>
  [STC_ALL]: DefaultState<`/${typeof API.STC_ALL}`, 'get'>
  [STC_CREATE]: DefaultState<`/${typeof API.STC}`, 'post'>
  [STC_CATEGORY]: DefaultState<`/${typeof API.STC_CATEGORY}{id}`, 'get'>
  [STC_CATEGORY_ALL]: DefaultState<`/${typeof API.STC_CATEGORY_ALL}`, 'get'>
  [STC_CATEGORY_LIST]: DefaultState<`/${typeof API.STC_CATEGORY}`, 'get'>
  [STC_CATEGORY_CREATE]: DefaultState<`/${typeof API.STC_CATEGORY}`, 'post'>

  [STC_STEP]: DefaultState<`/${typeof API.STC_STEP}{id}`, 'get'>
  [STC_STEP_ALL]: DefaultState<`/${typeof API.STC_STEP_ALL}`, 'get'>
  [STC_STEP_COMPLETE]: DefaultState<`/${typeof API.STC_STEP_COMPLETE}`, 'post'>
  [STC_STEP_CREATE]: DefaultState<`/${typeof API.STC_STEP}`, 'post'>
  [STC_STEP_LIST]: DefaultState<`/${typeof API.STC_STEP}`, 'get'>
} = () => ({
  [STC]: { loading: false, error: null, data: null },
  [STC_LIST]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_ALL]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_CREATE]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_CATEGORY]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_CATEGORY_ALL]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_CATEGORY_LIST]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_CATEGORY_CREATE]: {
    loading: false,
    error: null,
    data: null,
  },

  [STC_STEP]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_STEP_ALL]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_STEP_COMPLETE]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_STEP_CREATE]: {
    loading: false,
    error: null,
    data: null,
  },
  [STC_STEP_LIST]: {
    loading: false,
    error: null,
    data: null,
  },
})

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const productItem = createAsyncThunk(
  TYPES.PRODUCT_ITEM,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productItem(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemClear = createAction(`${TYPES.PRODUCT_ITEM}/clear`)

export const productItemClient = createAsyncThunk(
  TYPES.PRODUCT_ITEM_CLIENT,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM_CLIENT}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productItemClient(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemClientClear = createAction(`${TYPES.PRODUCT_ITEM_CLIENT}/clear`)

export const productItemList = createAsyncThunk(
  TYPES.PRODUCT_ITEM_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.productItemList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemListClear = createAction(`${TYPES.PRODUCT_ITEM_LIST}/clear`)

export const productItemCreate = createAsyncThunk(
  TYPES.PRODUCT_ITEM_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.productItemCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemDelete = createAsyncThunk(
  TYPES.PRODUCT_ITEM_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productItemDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemAll = createAsyncThunk(
  TYPES.PRODUCT_ITEM_ALL,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productItemAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemAllShort = createAsyncThunk(
  TYPES.PRODUCT_ITEM_ALL_SHORT,
  async (
    params: PayloadCreatorParams<`${typeof API.PRODUCT_ITEM_ALL_SHORT}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.productItemAllShort(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productItemAllShortClear = createAction(`${TYPES.PRODUCT_ITEM_ALL_SHORT}/clear`)

// Category
export const productCategoryList = createAsyncThunk(
  TYPES.PRODUCT_CATEGORY_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_CATEGORY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productCategoryList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productCategoryCreate = createAsyncThunk(
  TYPES.PRODUCT_CATEGORY_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_CATEGORY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.productCategoryCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productCategoryDelete = createAsyncThunk(
  TYPES.PRODUCT_CATEGORY_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_CATEGORY}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productCategoryDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productCategoryAll = createAsyncThunk(
  TYPES.PRODUCT_CATEGORY_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.PRODUCT_CATEGORY_ALL}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.productCategoryAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// Colors
export const productColorAll = createAsyncThunk(
  TYPES.PRODUCT_COLOR_ALL,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_COLOR_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.productColorAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const productColorCreate = createAsyncThunk(
//   TYPES.PRODUCT_COLOR_CREATE,
//   async (params: PayloadCreatorParams<`${typeof API.PRODUCT_COLOR}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.productColorCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const productColorDelete = createAsyncThunk(
  TYPES.PRODUCT_COLOR_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_COLOR}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productColorDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productColorList = createAsyncThunk(
  TYPES.PRODUCT_COLOR_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_COLOR}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productColorList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// Sizes
export const productSizeAll = createAsyncThunk(
  TYPES.PRODUCT_SIZE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_SIZE_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.productSizeAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const productSizeCreate = createAsyncThunk(
//   TYPES.PRODUCT_SIZE_CREATE,
//   async (params: PayloadCreatorParams<`${typeof API.PRODUCT_SIZE}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.productSizeCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const productSizeDelete = createAsyncThunk(
  TYPES.PRODUCT_SIZE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_SIZE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productSizeDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productSizeList = createAsyncThunk(
  TYPES.PRODUCT_SIZE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_SIZE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productSizeList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// Materials
export const productMaterialAll = createAsyncThunk(
  TYPES.PRODUCT_MATERIAL_ALL,
  async (
    params: PayloadCreatorParams<`${typeof API.PRODUCT_MATERIAL_ALL}`, 'get'> | undefined,
    { rejectWithValue }
  ) => {
    try {
      const response = await api.productMaterialAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productMaterialCreate = createAsyncThunk(
  TYPES.PRODUCT_MATERIAL_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_MATERIAL}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.productMaterialCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productMaterialDelete = createAsyncThunk(
  TYPES.PRODUCT_MATERIAL_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_MATERIAL}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productMaterialDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productMaterialList = createAsyncThunk(
  TYPES.PRODUCT_MATERIAL_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_MATERIAL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.productMaterialList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// Trade
export const productOrder = createAsyncThunk(
  TYPES.PRODUCT_ORDER,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.productOrder(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeAll = createAsyncThunk(
  TYPES.PRODUCT_TRADE_ALL,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeCreate = createAsyncThunk(
  TYPES.PRODUCT_TRADE_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeDelete = createAsyncThunk(
  TYPES.PRODUCT_TRADE_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeList = createAsyncThunk(
  TYPES.PRODUCT_TRADE_LIST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeBuyerAll = createAsyncThunk(
  TYPES.PRODUCT_TRADE_BUYER_ALL,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE_BUYER_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeBuyerAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeBuyerAllClear = createAction(`${TYPES.PRODUCT_TRADE_BUYER_ALL}/clear`)

// Trade Actions
export const productTradeAccept = createAsyncThunk(
  TYPES.PRODUCT_TRADE_ACCEPT,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE_ACCEPT}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeAccept(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeMark = createAsyncThunk(
  TYPES.PRODUCT_TRADE_MARK,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE_MARK}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeMark(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const productTradeRequest = createAsyncThunk(
  TYPES.PRODUCT_TRADE_REQUEST,
  async (params: PayloadCreatorParams<`${typeof API.PRODUCT_TRADE_REQUEST}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.productTradeRequest(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

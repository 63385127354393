import actionTypes, {
  MODULE_FILE,
  MODULE_FILE_ALL,
  MODULE_FILE_CREATE,
  MODULE_FILE_DELETE,
  MODULE_FILE_LIST,
} from '../constants/actionTypes'

export const moduleFile = payload => ({
  type: actionTypes[MODULE_FILE].pending,
  payload,
})

export const moduleFileCreate = payload => ({
  type: actionTypes[MODULE_FILE_CREATE].pending,
  payload,
})

export const moduleFileDelete = payload => ({
  type: actionTypes[MODULE_FILE_DELETE].pending,
  payload,
})

export const moduleFileAll = payload => ({
  type: actionTypes[MODULE_FILE_ALL].pending,
  payload,
})

export const moduleFileAllClear = () => ({
  type: actionTypes[MODULE_FILE_ALL].clear,
})

export const moduleFileList = payload => ({
  type: actionTypes[MODULE_FILE_LIST].pending,
  payload,
})

export const moduleFileListClear = () => ({
  type: actionTypes[MODULE_FILE_LIST].clear,
})

import { getInstance } from '@helpers/httpClient'
import { dissoc, omit, prop } from 'ramda'

import * as API from '../constants/api'

export const form = data =>
  getInstance().get(`${API.FORM}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const formCreate = data => getInstance().post(API.FORM, data)

export const formDelete = data => getInstance().delete(`${API.FORM}${prop('id', data)}`)

export const formList = data =>
  getInstance().get(API.FORM, {
    params: data,
  })

export const formAll = data =>
  getInstance().get(API.FORM_ALL, {
    params: data,
  })

export const formActionType = data =>
  getInstance().get(API.FORM_ACTION_TYPE, {
    params: data,
  })

export const formFieldCreate = data =>
  getInstance().post(API.FORM_FIELD, {
    params: data,
  })

export const formFieldUpdate = data =>
  getInstance().put(`${API.FORM_FIELD}${prop('id', data)}`, {
    params: data,
  })

export const formFieldDelete = data =>
  getInstance().delete(API.FORM_FIELD, {
    params: data,
  })

export const formFieldBatch = data =>
  getInstance().get(`${API.FORM_FIELD_BATCH}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const formFieldBatchCreate = data => getInstance().post(API.FORM_FIELD_BATCH, data)

// formType
export const formType = data =>
  getInstance().get(`${API.FORM_TYPE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const formTypeCreate = data => getInstance().post(API.FORM_TYPE, data)

export const formTypeDelete = data => getInstance().delete(`${API.FORM_TYPE}${prop('id', data)}`)

export const formTypeList = data =>
  getInstance().get(API.FORM_TYPE, {
    params: data,
  })

export const formTypeAll = data =>
  getInstance().get(API.FORM_TYPE_ALL, {
    params: data,
  })

// formRequest
export const formRequest = data => {
  return getInstance().get(`${API.FORM_REQUEST}${prop('id', data)}`, {
    params: omit(['id', 'openModal'], data),
  })
}

export const formRequestCreate = data => getInstance().post(API.FORM_REQUEST, data)

export const formRequestDelete = data => getInstance().delete(`${API.FORM_REQUEST}${prop('id', data)}`)

export const formRequestList = data =>
  getInstance().get(API.FORM_REQUEST, {
    params: data,
  })

export const formRequestAll = data =>
  getInstance().get(API.FORM_REQUEST_ALL, {
    params: data,
  })

export const formRequestChangeStatus = data => getInstance().post(API.FORM_REQUEST_CHANGE_STATUS, data)

export const formRequestFields = data => getInstance().post(API.FORM_REQUEST_FIELDS, data)

export const formRequestCertificate = data => getInstance().post(API.FORM_REQUEST_CERTIFICATE, data)

import { getInstance } from '@helpers/httpClient'

import { GUEST_ALL, GUEST_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const guestList = data =>
  getInstance().get(API.USER_GUEST, {
    params: data,
  })

export const guestAll = data =>
  getInstance().get(API.USER_GUEST_ALL, {
    params: data,
  })

export default {
  [GUEST_LIST]: guestList,
  [GUEST_ALL]: guestAll,
}

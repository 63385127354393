import { SuccessToast } from '@components/Toasts'
import * as ROUTE from '@constants/routes'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as stateSelectors from '@store/selector'
import { push } from 'connected-react-router'
import { pathOr, prop, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API_APPLICATION from '../api/application'
import * as API_APPLICATION_CATEGORY from '../api/applicationCategory'
import * as API_APPLICATION_REQUEST from '../api/applicationRequest'
import actionTypes, {
  HR_APPLICATION,
  HR_APPLICATION_ALL,
  HR_APPLICATION_CATEGORY,
  HR_APPLICATION_CATEGORY_ALL,
  HR_APPLICATION_CATEGORY_CREATE,
  HR_APPLICATION_CATEGORY_DELETE,
  HR_APPLICATION_CATEGORY_LIST,
  HR_APPLICATION_CREATE,
  HR_APPLICATION_DELETE,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_FIELD_BATCH_CREATE,
  HR_APPLICATION_LIST,
  HR_APPLICATION_STATUS,
  HR_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'
import * as actions from './actions'

function watchHRApplication() {
  return watchSaga({ action: actionTypes[HR_APPLICATION], api: API_APPLICATION.hrApplication })
}

function watchHRApplicationCreate() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CREATE],
    api: API_APPLICATION.hrApplicationCreate,
  })
}

function watchHRApplicationDelete() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_DELETE],
    api: API_APPLICATION.hrApplicationDelete,
  })
}

function watchHRApplicationTypes() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_TYPES],
    api: API_APPLICATION.hrApplicationTypes,
  })
}

function watchHRApplicationTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[HR_APPLICATION_TYPES],
    message: true,
  })
}

function watchHRApplicationStatus() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_STATUS],
    api: API_APPLICATION.hrApplicationStatus,
  })
}

function* watchHRApplicationStatusFulfilled() {
  yield takeEvery(actionTypes[HR_APPLICATION_STATUS].fulfilled, function* (payload) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], payload)
    onFulfilled()
    yield toast.success(<SuccessToast text='Status has been changed!' />)
  })
}

function watchHRApplicationStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[HR_APPLICATION_STATUS],
    message: true,
  })
}

function* watchHRApplicationDeleteFulfilled() {
  yield takeEvery(actionTypes[HR_APPLICATION_DELETE].fulfilled, function* () {
    yield put(actions.hrApplicationList())
  })
}

function watchHRApplicationList() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_LIST],
    api: API_APPLICATION.hrApplicationList,
  })
}

function watchHRApplicationListRejected() {
  return watchSagaRejected({
    action: actionTypes[HR_APPLICATION_LIST],
    message: true,
  })
}

function watchHRApplicationAll() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_ALL],
    api: API_APPLICATION.hrApplicationAll,
  })
}

function watchHRApplicationFieldBatch() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_FIELD_BATCH],
    api: API_APPLICATION.hrApplicationFieldBatch,
  })
}

function watchHRApplicationFieldBatchCreate() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_FIELD_BATCH_CREATE],
    api: API_APPLICATION.hrApplicationFieldBatchCreate,
  })
}

function* watchHRApplicationFieldBatchCreateFulfilled() {
  yield takeEvery(actionTypes[HR_APPLICATION_FIELD_BATCH_CREATE].fulfilled, function* ({ args }) {
    const application = prop('application', args)
    const id = prop('id', application)

    yield put(push(ROUTE.HR_ADMIN_APPLICATION_FORM))
  })
}

function watchHRApplicationCategory() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CATEGORY],
    api: API_APPLICATION_CATEGORY.hrApplicationCategory,
  })
}

function watchHRApplicationCategoryCreate() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CATEGORY_CREATE],
    api: API_APPLICATION_CATEGORY.hrApplicationCategoryCreate,
  })
}

function* watchHRApplicationCategoryCreateFulfilled() {
  yield takeEvery(actionTypes[HR_APPLICATION_CATEGORY_CREATE].fulfilled, function* () {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    yield put(actions.hrApplicationCategoryList({ ...get }))
  })
}

function watchHRApplicationCategoryDelete() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CATEGORY_DELETE],
    api: API_APPLICATION_CATEGORY.hrApplicationCategoryDelete,
  })
}

function* watchHRApplicationCategoryFulfilled() {
  yield takeEvery(actionTypes[HR_APPLICATION_CATEGORY_DELETE].fulfilled, function* () {
    yield put(actions.hrApplicationCategoryList())
  })
}

function watchHRApplicationCategoryList() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CATEGORY_LIST],
    api: API_APPLICATION_CATEGORY.hrApplicationCategoryList,
  })
}

function watchHRApplicationCategoryAll() {
  return watchSaga({
    action: actionTypes[HR_APPLICATION_CATEGORY_ALL],
    api: API_APPLICATION_CATEGORY.hrApplicationCategoryAll,
  })
}

function watchHRApplicationRequest() {
  return watchSaga({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST],
    api: API_APPLICATION_REQUEST.hrApplicationRequest,
  })
}

function* watchHRApplicationRequestFulfilled() {
  yield takeEvery(actionTypes[TYPES.HR_APPLICATION_REQUEST].fulfilled, function* ({ payload, args }) {
    const onFulfilled = propOr(() => {}, 'onFulfilled', args)
    yield onFulfilled(payload)
  })
}

function watchHRApplicationRequestAll() {
  return watchSaga({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST_ALL],
    api: API_APPLICATION_REQUEST.hrApplicationRequestAll,
  })
}

function watchHRApplicationRequestEdit() {
  return watchSaga({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST_EDIT],
    api: API_APPLICATION_REQUEST.hrApplicationRequestEdit,
  })
}

function watchHRApplicationRequestUpdate() {
  return watchSaga({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST_UPDATE],
    api: API_APPLICATION_REQUEST.hrApplicationRequestUpdate,
  })
}

function watchHRApplicationRequestUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST_UPDATE],
    shouldDoFulfilledFunction: true,
    message: {
      description: 'Your changes have been saved!',
    },
  })
}

function watchHRApplicationRequestUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[TYPES.HR_APPLICATION_REQUEST_UPDATE],
    message: {
      description: 'Could not save your changes',
    },
  })
}

export function* hrAdmissionSaga() {
  yield all([
    fork(watchHRApplication),
    fork(watchHRApplicationCreate),
    fork(watchHRApplicationDelete),
    fork(watchHRApplicationDeleteFulfilled),
    fork(watchHRApplicationList),
    fork(watchHRApplicationListRejected),
    fork(watchHRApplicationAll),
    fork(watchHRApplicationFieldBatch),
    fork(watchHRApplicationFieldBatchCreate),
    fork(watchHRApplicationFieldBatchCreateFulfilled),
    fork(watchHRApplicationCategory),
    fork(watchHRApplicationCategoryCreate),
    fork(watchHRApplicationCategoryCreateFulfilled),
    fork(watchHRApplicationCategoryDelete),
    fork(watchHRApplicationCategoryFulfilled),
    fork(watchHRApplicationCategoryList),
    fork(watchHRApplicationCategoryAll),
    fork(watchHRApplicationRequest),
    fork(watchHRApplicationRequestFulfilled),
    fork(watchHRApplicationRequestAll),
    fork(watchHRApplicationRequestEdit),
    fork(watchHRApplicationRequestUpdate),
    fork(watchHRApplicationRequestUpdateFulfilled),
    fork(watchHRApplicationRequestUpdateRejected),
    fork(watchHRApplicationTypes),
    fork(watchHRApplicationTypesRejected),
    fork(watchHRApplicationStatus),
    fork(watchHRApplicationStatusFulfilled),
    fork(watchHRApplicationStatusRejected),
  ])
}

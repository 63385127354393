export const HR = 'hr/'
export const HR_APPLICATION_FORM = `${HR}application_form/`
export const HR_APPLICATION = `${HR}application/`
export const HR_APPLICATION_TYPES = `${HR_APPLICATION}types`
export const HR_APPLICATION_STATUS = `${HR_APPLICATION}status/`
export const HR_APPLICATION_ALL = `${HR_APPLICATION_FORM}all`
export const HR_APPLICATION_FIELD_BATCH = `${HR_APPLICATION_FORM}field/batch/`

export const HR_APPLICATION_CATEGORY = `${HR_APPLICATION_FORM}category/`
export const HR_APPLICATION_CATEGORY_ALL = `${HR_APPLICATION_CATEGORY}all/`

export const HR_APPLICATION_REQUEST = HR_APPLICATION
export const HR_APPLICATION_REQUEST_ALL = `${HR_APPLICATION_REQUEST}all/`
export const HR_APPLICATION_REQUEST_EDIT = `${HR_APPLICATION_REQUEST}edit/`
export const HR_APPLICATION_REQUEST_UPDATE = `${HR_APPLICATION_REQUEST}update`

export const HR_APPLICATION_SIGNUP = `${HR_APPLICATION_FORM}signup`
export const HR_APPLICATION_SMS_SEND = `${HR_APPLICATION_FORM}sms/send`

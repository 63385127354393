import actionTypes, {
  ACCOUNT,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  ACCOUNT_ALL,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_REQUIRED_ACTIONS,
  ACCOUNT_PROFILE_PIC,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_TYPES,
} from '../constants/actionTypes'

export const account = payload => ({
  type: actionTypes[ACCOUNT].pending,
  payload,
})

export const accountCreate = payload => ({
  type: actionTypes[ACCOUNT_CREATE].pending,
  payload,
})

export const accountDelete = payload => ({
  type: actionTypes[ACCOUNT_DELETE].pending,
  payload,
})

export const accountList = payload => ({
  type: actionTypes[ACCOUNT_LIST].pending,
  payload,
})

export const accountAll = payload => ({
  type: actionTypes[ACCOUNT_ALL].pending,
  payload,
})

export const accountListClear = () => ({
  type: actionTypes[ACCOUNT_LIST].clear,
})

export const accountPasswordReset = payload => ({
  type: actionTypes[ACCOUNT_PASSWORD_RESET].pending,
  payload,
})

export const accountRequiredActions = payload => ({
  type: actionTypes[ACCOUNT_REQUIRED_ACTIONS].pending,
  payload,
})
export const accountProfilePic = payload => {
  return {
    type: actionTypes[ACCOUNT_PROFILE_PIC].pending,
    payload,
  }
}
export const accountProfileUpdate = payload => {
  return {
    type: actionTypes[ACCOUNT_PROFILE_UPDATE].pending,
    payload,
  }
}

export const accountTypes = payload => ({
  type: actionTypes[ACCOUNT_TYPES].pending,
  payload,
})

export const accountTypesClear = () => ({
  type: actionTypes[ACCOUNT_TYPES].clear,
})

import actionTypes, {
  REPORT_ALL,
  REPORT,
  REPORT_CREATE,
  REPORT_LIST,
  REPORT_FIELDS,
  REPORT_FIELDS_FILTER,
} from '../constants/actionTypes'

export const report = payload => ({
  type: actionTypes[REPORT].pending,
  payload,
})

export const reportCreate = payload => ({
  type: actionTypes[REPORT_CREATE].pending,
  payload,
})

export const reportAll = payload => ({
  type: actionTypes[REPORT_ALL].pending,
  payload,
})

export const reportAllClear = () => ({
  type: actionTypes[REPORT_ALL].clear,
})

export const reportList = payload => ({
  type: actionTypes[REPORT_LIST].pending,
  payload,
})

export const reportListClear = () => ({
  type: actionTypes[REPORT_LIST].clear,
})

export const reportClear = () => ({
  type: actionTypes[REPORT_LIST].clear,
})

export const reportFields = payload => ({
  type: actionTypes[REPORT_FIELDS].pending,
  payload,
})

export const reportFieldsClear = () => ({
  type: actionTypes[REPORT_FIELDS].clear,
})

export const reportFieldsFilter = payload => ({
  type: actionTypes[REPORT_FIELDS_FILTER].pending,
  payload,
})

export const reportFieldsFilterClear = () => ({
  type: actionTypes[REPORT_FIELDS_FILTER].clear,
})

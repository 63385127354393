export const SHOP = '/merch/' as const
export const PRODUCT_ITEM = `${SHOP}item/` as const
export const PRODUCT_ITEM_CLIENT = `${SHOP}item/client/` as const
export const PRODUCT_ITEM_ALL = `${PRODUCT_ITEM}all` as const
export const PRODUCT_ITEM_ALL_SHORT = `${PRODUCT_ITEM_ALL}/short` as const

export const PRODUCT_CATEGORY = `${SHOP}category/` as const
export const PRODUCT_CATEGORY_ALL = `${PRODUCT_CATEGORY}all` as const

export const PRODUCT_COLOR = `${SHOP}color/` as const
export const PRODUCT_COLOR_ALL = `${PRODUCT_COLOR}all` as const

export const PRODUCT_SIZE = `${SHOP}size/` as const
export const PRODUCT_SIZE_ALL = `${PRODUCT_SIZE}all` as const

export const PRODUCT_MATERIAL = `${SHOP}material/` as const
export const PRODUCT_MATERIAL_ALL = `${PRODUCT_MATERIAL}all` as const

export const PRODUCT_TRADE = `${SHOP}trade/` as const
export const PRODUCT_TRADE_ALL = `${PRODUCT_TRADE}all` as const

export const PRODUCT_TRADE_BUYER_ALL = `${PRODUCT_TRADE}by_buyer/all` as const

export const PRODUCT_TRADE_RETURN = `${PRODUCT_TRADE}return/` as const
export const PRODUCT_TRADE_ACCEPT = `${PRODUCT_TRADE_RETURN}accept/{id}` as const
export const PRODUCT_TRADE_MARK = `${PRODUCT_TRADE_RETURN}mark/{id}` as const
export const PRODUCT_TRADE_REQUEST = `${PRODUCT_TRADE_RETURN}request/{id}` as const

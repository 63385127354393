import AssessmentOffence, { ASSESSMENT_OFFENCE } from '@module/AcademicMisconduct/redux/reducer'
import AcademicYears, { ACADEMIC_YEARS } from '@module/AcademicYears/redux/reducer'
import Account, { ACCOUNT } from '@module/Account/redux/reducer'
import AchievementRecords, { ACHIEVEMENT_RECORDS } from '@module/AchievementRecords/redux/reducer'
import Assessment, { ASSESSMENT } from '@module/Assessment/redux/reducer'
import AssessmentEC, { ASSESSMENT_EC } from '@module/AssessmentEC/redux/reducer'
import AssessmentMark, { ASSESSMENT_MARK } from '@module/AssessmentMarks/redux/reducer'
import Attendance, { ATTENDANCE } from '@module/Attendance/redux/reducer'
import { AUTH, authReducer } from '@module/Auth/redux/reducer'
import Calendar, { CALENDAR } from '@module/Calendar/redux/reducer'
import { CATEGORY, reducer as Category } from '@module/Category/redux/reduxEssentials'
import { CHAT, reducer as Chat } from '@module/Chat/redux/reduxEssentials'
import City, { CITY } from '@module/City/redux/reducer'
import { COLOR_PALETTES, colorPaletteReducer } from '@module/ColorPalette/redux/reducer'
import Mail, { MAIL } from '@module/ComposeEmail/redux/reducer'
import { CONTRACT_AGREEMENT, reducer as ContractAgreement } from '@module/ContractInformation/redux/reduxEssentials'
import Country, { COUNTRY } from '@module/Country/redux/reducer'
import CourseCampaign, { COURSE_CAMPAIGN } from '@module/Course/activate/redux/reducer'
import CourseCreate, { COURSE_CREATE } from '@module/Course/create/redux/reducer'
import Departments, { DEPARTMENTS } from '@module/Departments/redux/reducer'
import EducationPlace, { EDUCATION_PLACE } from '@module/EducationPlace/redux/reducer'
import Enrollment, { ENROLLMENT } from '@module/Enrollment/redux/reducer'
import { FEEDBACK, reducer as Feedback } from '@module/Feedback/redux/reduxEssentials'
import ModuleFile, { MODULE_FILE } from '@module/Files/ModuleFiles/redux/reducer'
import Form, { FORM } from '@module/Forms/redux/reducer'
import GeneralApplications, { GENERAL_ADMISSION } from '@module/GeneralAdmission/redux/reducer'
import Grouping, { GROUPING } from '@module/Groups/redux/reducer'
import { GUESTS, reducer as GuestUsers } from '@module/GuestUsers/redux/reduxEssentials'
import HRApplications, { HR } from '@module/HRAdmission/redux/reducer'
import { reducer as Inquiry, REPORT_INQUIRY } from '@module/Inquiry/redux/reduxEssentials'
import Videos, { VIDEOS } from '@module/IntroVideos/redux/reducer'
import { JOB_POSITION, reducer as JobPosition } from '@module/JobPosition/redux/reduxEssentials'
import Lesson, { LESSON } from '@module/Lesson/redux/reducer'
import { reducer as ELibrary, E_LIBRARY } from '@module/Library/redux/reduxEssentials'
import { MEALS, mealReducer } from '@module/Meals/redux/reducer'
import ModulesOutcome, { MODULES_OUTCOME } from '@module/ModuleOutcome/redux/reducer'
import CCLSModule, { CCLS_MODULE } from '@module/Modules/activate/redux/reducer'
import Modules, { MODULES } from '@module/Modules/create/redux/reducer'
import OpenLink, { OPEN_LINK } from '@module/OpenLink/redux/reducer'
import Player, { PLAYER } from '@module/Player/redux/reducer'
import Level, { LEVEL } from '@module/QualityAssurance/redux/level/reducer'
import Report, { REPORT } from '@module/Reports/redux/reducer'
import Role, { ROLES } from '@module/Roles/redux/reducer'
import Semester, { SEMESTER } from '@module/Semester/redux/reducer'
import { SHOP, shopReducer } from '@module/Shop/redux/reducer'
import StCourseApplications, { ST_COURSE } from '@module/ShortCourseAdmission/redux/reducer'
import { SHORT_TERM_COURSE, stcReducer } from '@module/ShortTermCourse/redux/reducer'
import Staff, { STAFF } from '@module/Staff/redux/reducer'
import States, { STATES } from '@module/State/redux/reducer'
import { STATS_MAIN, statsReducer } from '@module/Stats/redux/reducer'
import Student, { STUDENT } from '@module/Student/redux/reducer'
import EnrollmentApplications, { STUDENT_ADMISSION } from '@module/StudentAdmission/redux/reducer'
import StudentDashboard, { STUDENT_DASHBOARD } from '@module/StudentDashboard/redux/reducer'
import { TIMETABLE, timetableReducer } from '@module/Timetable/redux/reducer'
import { TYPE, typesReducer } from '@module/Types/redux/reducer'
import { VACANCY_POSITION, reducer as VacancyPosition } from '@module/VacancyPosition/redux/reduxEssentials'
import { combineReducers } from '@reduxjs/toolkit'
import { layoutReducer } from '@src/redux/layout/reducer'
import { connectRouter } from 'connected-react-router'

import { history } from './store'

const rootReducer = combineReducers({
  router: connectRouter(history),
  // navbar,
  layout: layoutReducer,
  [AUTH]: authReducer,
  [ACCOUNT]: Account,
  [ROLES]: Role,
  [ACADEMIC_YEARS]: AcademicYears,
  [LESSON]: Lesson,
  [COURSE_CAMPAIGN]: CourseCampaign,
  [LEVEL]: Level,
  [SEMESTER]: Semester,
  [CCLS_MODULE]: CCLSModule,
  [CALENDAR]: Calendar,
  [GROUPING]: Grouping,
  [STAFF]: Staff,
  [COURSE_CREATE]: CourseCreate,
  [MODULES]: Modules,
  [STUDENT]: Student,
  [ENROLLMENT]: Enrollment,
  [ASSESSMENT]: Assessment,
  [ASSESSMENT_MARK]: AssessmentMark,
  [ASSESSMENT_OFFENCE]: AssessmentOffence,
  [ASSESSMENT_EC]: AssessmentEC,
  [REPORT]: Report,
  [MODULES_OUTCOME]: ModulesOutcome,
  [MODULE_FILE]: ModuleFile,
  [ACHIEVEMENT_RECORDS]: AchievementRecords,
  [ATTENDANCE]: Attendance,
  [STUDENT_DASHBOARD]: StudentDashboard,
  [FORM]: Form,
  [OPEN_LINK]: OpenLink,
  [PLAYER]: Player,
  [VIDEOS]: Videos,
  [DEPARTMENTS]: Departments,
  [COLOR_PALETTES]: colorPaletteReducer,
  [HR]: HRApplications,
  [CITY]: City,
  [STATES]: States,
  [COUNTRY]: Country,
  [JOB_POSITION]: JobPosition,
  [VACANCY_POSITION]: VacancyPosition,
  [MAIL]: Mail,
  [MEALS]: mealReducer,
  [ST_COURSE]: StCourseApplications,
  [CATEGORY]: Category,
  [E_LIBRARY]: ELibrary,
  [STUDENT_ADMISSION]: EnrollmentApplications,
  [EDUCATION_PLACE]: EducationPlace,
  [GUESTS]: GuestUsers,
  [STATS_MAIN]: statsReducer,
  [CONTRACT_AGREEMENT]: ContractAgreement,
  [TYPE]: typesReducer,
  [GENERAL_ADMISSION]: GeneralApplications,
  [FEEDBACK]: Feedback,
  [REPORT_INQUIRY]: Inquiry,
  [SHORT_TERM_COURSE]: stcReducer,
  [SHOP]: shopReducer,
  [CHAT]: Chat,
  [TIMETABLE]: timetableReducer,
})

const reducers = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { router, navbar, layout } = state
    state = { router, navbar, layout }
  }
  return rootReducer(state, action)
}

export default reducers

export const STC = 'course' as const
export const STC_LIST = 'courseList' as const
export const STC_ALL = 'courseAll' as const
export const STC_CREATE = 'courseCreate' as const
export const STC_DELETE = 'courseDelete' as const

export const STC_CATEGORY = 'courseCategory' as const
export const STC_CATEGORY_ALL = 'courseCategoryAll' as const
export const STC_CATEGORY_LIST = 'courseCategoryList' as const
export const STC_CATEGORY_CREATE = 'courseCategoryCreate' as const
export const STC_CATEGORY_DELETE = 'courseCategoryDelete' as const

export const STC_STEP = `${STC}Step` as const
export const STC_STEP_LIST = `${STC_STEP}List` as const
export const STC_STEP_ALL = `${STC_STEP}All` as const
export const STC_STEP_DELETE = `${STC_STEP}Delete` as const
export const STC_STEP_CREATE = `${STC_STEP}Create` as const
export const STC_STEP_COMPLETE = `${STC_STEP}Complete` as const

export const GLOBAL_STATE = 'ShortTermCourse' as const

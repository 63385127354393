import { toCamelCase } from '../../../helpers/toCamelCase'

export const REPORT_INQUIRY_LIST = 'REPORT_INQUIRY_LIST'
export const REPORT_INQUIRY_CREATE = 'REPORT_INQUIRY_CREATE'
export const REPORT_INQUIRY_DELETE = 'REPORT_INQUIRY_DELETE'
export const REPORT_INQUIRY = 'REPORT_INQUIRY'
export const REPORT_INQUIRY_ALL = 'REPORT_INQUIRY_ALL'
export const REPORT_INQUIRY_ITEM = 'REPORT_INQUIRY_ITEM'
export const INQUIRY_TYPES = 'INQUIRY_TYPES'
export const INQUIRY_STATUS_TYPES = 'INQUIRY_STATUS_TYPES'
export const RAPORT_UPDATE_STATUS = 'RAPORT_UPDATE_STATUS'
export const RAPORT_INQUIRY_ASSIGNED = 'RAPORT_INQUIRY_ASSIGNED'
export const INQUIRY_COMMENT = 'INQUIRY_COMMENT'
export const INQUIRY_COMMENT_CREATE = 'INQUIRY_COMMENT_CREATE'
export const INQUIRY_COMMENT_DELETE = 'INQUIRY_COMMENT_DELETE'

export const GLOBAL_STATE = 'ReportInquiry'

export const actionNames = {
  [toCamelCase(REPORT_INQUIRY_LIST)]: REPORT_INQUIRY_LIST,
  [toCamelCase(REPORT_INQUIRY_CREATE)]: REPORT_INQUIRY_CREATE,
  [toCamelCase(REPORT_INQUIRY_DELETE)]: REPORT_INQUIRY_DELETE,
  [toCamelCase(REPORT_INQUIRY)]: REPORT_INQUIRY,
  [toCamelCase(REPORT_INQUIRY_ALL)]: REPORT_INQUIRY_ALL,
  [toCamelCase(REPORT_INQUIRY_ITEM)]: REPORT_INQUIRY_ITEM,
  [toCamelCase(INQUIRY_TYPES)]: INQUIRY_TYPES,
  [toCamelCase(INQUIRY_STATUS_TYPES)]: INQUIRY_STATUS_TYPES,
  [toCamelCase(RAPORT_UPDATE_STATUS)]: RAPORT_UPDATE_STATUS,
  [toCamelCase(RAPORT_INQUIRY_ASSIGNED)]: RAPORT_INQUIRY_ASSIGNED,
  [toCamelCase(INQUIRY_COMMENT)]: INQUIRY_COMMENT,
  [toCamelCase(INQUIRY_COMMENT_CREATE)]: INQUIRY_COMMENT_CREATE,
  [toCamelCase(INQUIRY_COMMENT_DELETE)]: INQUIRY_COMMENT_DELETE,
}

import actionTypes, { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_DELETE, COURSE_LIST } from '../constants/actionTypes'

export const course = payload => ({
  type: actionTypes[COURSE].pending,
  payload,
})

export const courseCreate = payload => ({
  type: actionTypes[COURSE_CREATE].pending,
  payload,
})

export const courseDelete = payload => ({
  type: actionTypes[COURSE_DELETE].pending,
  payload,
})

export const courseList = payload => ({
  type: actionTypes[COURSE_LIST].pending,
  payload,
})

export const courseListClear = () => ({
  type: actionTypes[COURSE_LIST].clear,
})

export const courseAll = payload => ({
  type: actionTypes[COURSE_ALL].pending,
  payload,
})

export const courseAllClear = () => ({
  type: actionTypes[COURSE_ALL].clear,
})

import { createAction } from '@helpers/createAction'

export const SEND_MAIL = 'SEND_MAIL'
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE'
export const EMAIL_TEMPLATE_CREATE = 'EMAIL_TEMPLATE_CREATE'
export const EMAIL_TEMPLATE_DELETE = 'EMAIL_TEMPLATE_DELETE'
export const EMAIL_TEMPLATE_LIST = 'EMAIL_TEMPLATE_LIST'
export const EMAIL_TEMPLATE_ALL = 'EMAIL_TEMPLATE_ALL'

export default {
  [SEND_MAIL]: createAction(SEND_MAIL),
  [EMAIL_TEMPLATE]: createAction(EMAIL_TEMPLATE),
  [EMAIL_TEMPLATE_CREATE]: createAction(EMAIL_TEMPLATE_CREATE),
  [EMAIL_TEMPLATE_DELETE]: createAction(EMAIL_TEMPLATE_DELETE),
  [EMAIL_TEMPLATE_LIST]: createAction(EMAIL_TEMPLATE_LIST),
  [EMAIL_TEMPLATE_ALL]: createAction(EMAIL_TEMPLATE_ALL),
}

import { WatchSaga, watchSaga } from '@helpers/customSaga'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { SEMESTER, SEMESTER_ALL, SEMESTER_DELETE, SEMESTER_LIST } from '../constants/actionTypes'
import * as actions from './actions'

function watchSemester() {
  return watchSaga({
    action: actionTypes[SEMESTER],
    api: API.semester,
  })
}

function watchSemesterDelete() {
  return watchSaga({
    action: actionTypes[SEMESTER_DELETE],
    api: API.semesterDelete,
  })
}

function* watchSemesterDeleteFulfilled() {
  yield takeEvery(actionTypes[SEMESTER_DELETE].fulfilled, function* () {
    yield put(actions.semesterList())
  })
}

function watchSemesterList() {
  return watchSaga({
    action: actionTypes[SEMESTER_LIST],
    api: API.semesterList,
  })
}

const semesterAllSaga = new WatchSaga({ actionType: actionTypes[SEMESTER_ALL], api: API.semesterAll })

export function* semesterSaga() {
  yield all([
    fork(watchSemester),
    fork(watchSemesterDelete),
    fork(watchSemesterDeleteFulfilled),
    fork(watchSemesterList),
    fork(semesterAllSaga.watch),
    fork(semesterAllSaga.watchFulfilled),
    fork(semesterAllSaga.watchRejected),
  ])
}

import actionTypes, { CITY_DELETE, CITY, CITY_ALL, CITY_CREATE, CITY_LIST } from '../constants/actionTypes'

export const city = payload => ({
  type: actionTypes[CITY].pending,
  payload,
})

export const cityClear = () => ({
  type: actionTypes[CITY].clear,
})

export const cityCreate = payload => ({
  type: actionTypes[CITY_CREATE].pending,
  payload,
})

export const cityDelete = payload => ({
  type: actionTypes[CITY_DELETE].pending,
  payload,
})

export const cityList = payload => ({
  type: actionTypes[CITY_LIST].pending,
  payload,
})

export const cityListClear = () => ({
  type: actionTypes[CITY_LIST].clear,
})

export const cityAll = payload => ({
  type: actionTypes[CITY_ALL].pending,
  payload,
})

export const cityAllClear = () => ({
  type: actionTypes[CITY_ALL].clear,
})

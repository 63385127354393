import { watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { STATE, STATE_ALL, STATE_CREATE, STATE_DELETE, STATE_LIST } from '../constants/actionTypes'
import * as actions from './actions'

function watchState() {
  return watchSaga({
    action: actionTypes[STATE],
    api: API.state,
  })
}

function watchStateCreate() {
  return watchSaga({
    action: actionTypes[STATE_CREATE],
    api: API.stateCreate,
  })
}

function watchStateCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[STATE_CREATE],
    message: {
      title: 'Success',
      description: 'State has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchStateDelete() {
  return watchSaga({
    action: actionTypes[STATE_DELETE],
    api: API.stateDelete,
  })
}

function* watchStateDeleteFulfilled() {
  yield takeEvery(actionTypes[STATE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.stateList())
  })
}

function* watchStateDeleteRejected() {
  yield takeEvery(actionTypes[STATE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchStateList() {
  return watchSaga({
    action: actionTypes[STATE_LIST],
    api: API.stateList,
  })
}

function watchStateAll() {
  return watchSaga({
    action: actionTypes[STATE_ALL],
    api: API.stateAll,
  })
}

export function* stateSaga() {
  yield all([
    fork(watchState),
    fork(watchStateCreate),
    fork(watchStateCreateFulfilled),
    fork(watchStateDelete),
    fork(watchStateDeleteFulfilled),
    fork(watchStateDeleteRejected),
    fork(watchStateList),
    fork(watchStateAll),
  ])
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.INTRO_VIDEOS]: createState(actionTypes.INTRO_VIDEOS),
})

export const VIDEOS = STATE.GLOBAL_STATE

export default reducer

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const calendarEvent = data =>
  getInstance().get(`${API.CALENDAR_EVENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const calendarEventCreate = data => getInstance().post(API.CALENDAR_EVENT, data)

export const calendarEventDelete = data => getInstance().delete(`${API.CALENDAR_EVENT}${prop('id', data)}`)

export const calendarEventList = data =>
  getInstance().get(API.CALENDAR_EVENT, {
    params: data,
  })

export const calendarEventListStudent = data =>
  getInstance().get(API.CALENDAR_EVENT_STUDENT, {
    params: data,
  })

export const calendarEventAll = data =>
  getInstance().get(API.CALENDAR_EVENT_ALL, {
    params: data,
  })

import { ErrorToast, SuccessToast } from '@components/Toasts'
import * as ROUTE from '@constants/routes'
import { WatchSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as isUser from '@helpers/isUser'
import { setUserInfoToStorage } from '@src/module/Auth/redux/reducer'
import * as actions from '@store/actions'
import * as stateSelectors from '@store/selector'
import { push } from 'connected-react-router'
import { dissoc, path, pathOr, prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ACCOUNT,
  ACCOUNT_ALL,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_PROFILE_PIC,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_REQUIRED_ACTIONS,
  ACCOUNT_TYPES,
} from '../constants/actionTypes'

function watchAccount() {
  return watchSaga({
    action: actionTypes[ACCOUNT],
    api: API.account,
  })
}

function watchAccountTypes() {
  return watchSaga({
    action: actionTypes[ACCOUNT_TYPES],
    api: API.accountTypes,
  })
}

function* watchAccountTypesRejected() {
  yield takeEvery(actionTypes[ACCOUNT_TYPES].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    const description = pathOr('', ['payload', 'message'], action)
    onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchAccountCreate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_CREATE],
    api: API.accountCreate,
  })
}

function* watchAccountCreateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_CREATE].fulfilled, function* (payload) {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], payload)
    onFulfilled()
    yield put(actions.accountList({ ...get }))
  })
}

function watchAccountCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACCOUNT_CREATE],
    message: true,
  })
}

function watchAccountDelete() {
  return watchSaga({
    action: actionTypes[ACCOUNT_DELETE],
    api: API.accountDelete,
  })
}

function* watchAccountDeleteFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.accountList())
  })
}

function* watchAccountDeleteRejected() {
  yield takeEvery(actionTypes[ACCOUNT_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchAccountList() {
  return watchSaga({
    action: actionTypes[ACCOUNT_LIST],
    api: API.accountList,
  })
}

function watchAccountAll() {
  return watchSaga({
    action: actionTypes[ACCOUNT_ALL],
    api: API.accountAll,
  })
}

function watchAccountPasswordReset() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PASSWORD_RESET],
    api: API.accountPasswordReset,
  })
}

function* watchAccountPasswordResetFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_PASSWORD_RESET].fulfilled, function* (payload) {
    const token = path(['args', 'auth', 'token'], payload)
    const authInfo = path(['args', 'auth'], payload)
    const newAuthInfo = prop('payload', payload)
    setUserInfoToStorage({
      token,
      ...authInfo,
      ...newAuthInfo,
    })
    yield put(actions.authUserInfo())
    if (isUser.isBachelorStudent(prop('userInfo', authInfo)) || isUser.isTestStudent(prop('userInfo', authInfo))) {
      yield put(push(ROUTE.STUDENT_DASHBOARD))
    } else {
      yield put(push(ROUTE.ACCOUNTS))
    }
  })
}

const accountRequiredActions = new WatchSaga({
  actionType: actionTypes[ACCOUNT_REQUIRED_ACTIONS],
  api: API.accountRequiredAction,
})

function watchAccountProfilePicUpdate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PROFILE_PIC],
    api: API.accountProfilePic,
  })
}

function* watchAccountProfilePicUpdateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_PIC].fulfilled, function* () {
    yield put(actions.dashboardStudent())
    toast.success(<SuccessToast text='Profile updated!' />)
  })
}

function* watchAccountProfilePicUpdateRejected() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_PIC].rejected, function* (action) {
    const callback = pathOr(() => {}, ['args', 'callback'], action)
    yield callback(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
  })
}

function watchAccountProfileUpdate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PROFILE_UPDATE],
    api: API.accountProfileUpdate,
  })
}

function* watchAccountProfileUpdateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_UPDATE].fulfilled, function* () {
    yield put(actions.dashboardStudent())
    toast.success(<SuccessToast text='Profile updated!' />)
  })
}

function watchAccountProfileUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACCOUNT_PROFILE_UPDATE],
    message: true,
  })
}

export function* accountSaga() {
  yield all([
    fork(watchAccount),
    fork(watchAccountCreate),
    fork(watchAccountCreateFulfilled),
    fork(watchAccountCreateRejected),
    fork(watchAccountDelete),
    fork(watchAccountDeleteFulfilled),
    fork(watchAccountDeleteRejected),
    fork(watchAccountList),
    fork(watchAccountAll),
    fork(watchAccountPasswordReset),
    fork(watchAccountPasswordResetFulfilled),
    fork(accountRequiredActions.watch),
    fork(accountRequiredActions.watchFulfilled),
    fork(accountRequiredActions.watchRejected),
    fork(watchAccountProfilePicUpdate),
    fork(watchAccountProfilePicUpdateFulfilled),
    fork(watchAccountProfilePicUpdateRejected),
    fork(watchAccountProfileUpdate),
    fork(watchAccountProfileUpdateFulfilled),
    fork(watchAccountProfileUpdateRejected),
    fork(watchAccountTypes),
    fork(watchAccountTypesRejected),
  ])
}

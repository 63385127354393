import { ErrorToast, SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as actions from '@store/actions'
import * as stateSelectors from '@store/selector'
import { parse } from 'qs'
import { pathOr, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  GROUP_ALL,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
  GROUP_EDIT,
  GROUP_GROUPING,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/actionTypes'

function watchGroupAll() {
  return watchSaga({ action: actionTypes[GROUP_ALL], api: API.groupAll })
}

function watchGroupGrouping() {
  return watchSaga({ action: actionTypes[GROUP_GROUPING], api: API.groupGrouping })
}

function watchGroupShuffle() {
  return watchSaga({ action: actionTypes[GROUP_SHUFFLE], api: API.groupShuffle })
}

function watchGroupEdit() {
  return watchSaga({ action: actionTypes[GROUP_EDIT], api: API.groupEdit })
}

function watchGroupEditFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[GROUP_EDIT],
    message: {
      title: 'Success',
      description: 'Group has been updated',
    },
    filter: {
      filterAction: actions.groupAll,
    },
  })
}

function watchGroupEditRejected() {
  return watchSagaRejected({
    action: actionTypes[GROUP_EDIT],
    message: true,
  })
}

function watchGroupShuffleRejected() {
  return watchSagaRejected({ action: actionTypes[GROUP_SHUFFLE], message: true })
}

function watchGroupStudentTransfer() {
  return watchSaga({ action: actionTypes[GROUP_STUDENT_TRANSFER], api: API.groupStudentTransfer })
}

// function watchGroupStudentTransferFulfilled() {
//   return watchSagaFulfilled({
//     action: actionTypes[GROUP_STUDENT_TRANSFER],
//     message: { title: 'Success', description: 'Student transfer successfully' },
//     dispatchActions: actions.groupAll(),
//   })
// }

function* watchGroupStudentTransferFulfilled() {
  yield takeEvery(actionTypes[GROUP_STUDENT_TRANSFER].fulfilled, function* (payload) {
    const { location } = yield select(stateSelectors.routerSelector)
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    const queryIds = parse(location.search, { ignoreQueryPrefix: true })
    yield put(actions.groupAll(getPage(queryIds)))
  })
}

function* watchGroupStudentTransferRejected() {
  yield takeEvery(actionTypes[GROUP_STUDENT_TRANSFER].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchGroupAssign() {
  return watchSaga({
    action: actionTypes[GROUP_ASSIGN],
    api: API.groupAssign,
  })
}

function* watchGroupAssignFulfilled() {
  yield takeEvery(actionTypes[GROUP_ASSIGN].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
    toast.success(<SuccessToast />)
  })
}

function* watchGroupAssignRejected() {
  yield takeEvery(actionTypes[GROUP_ASSIGN].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    onRejected(action)
    toast.error(<ErrorToast text={propOr('', 'payload', action)} />)
  })
}

function watchGroupAssignedList() {
  return watchSaga({
    action: actionTypes[GROUP_ASSIGNED_LIST],
    api: API.groupAssignedList,
  })
}

function* watchGroupAssignedListFulfilled() {
  yield takeEvery(actionTypes[GROUP_ASSIGNED_LIST].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
  })
}

function* watchGroupAssignedListRejected() {
  yield takeEvery(actionTypes[GROUP_ASSIGNED_LIST].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    onRejected(action)
    toast.error(<ErrorToast text={propOr('', 'payload', action)} />)
  })
}

export function* groupSaga() {
  yield all([
    fork(watchGroupAll),
    fork(watchGroupGrouping),
    fork(watchGroupShuffle),
    fork(watchGroupShuffleRejected),
    fork(watchGroupStudentTransfer),
    fork(watchGroupStudentTransferFulfilled),
    fork(watchGroupStudentTransferRejected),
    fork(watchGroupEdit),
    fork(watchGroupEditFulfilled),
    fork(watchGroupEditRejected),
    fork(watchGroupAssign),
    fork(watchGroupAssignFulfilled),
    fork(watchGroupAssignRejected),
    fork(watchGroupAssignedList),
    fork(watchGroupAssignedListFulfilled),
    fork(watchGroupAssignedListRejected),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_LIST,
  GLOBAL_STATE,
} from '../constants/state'

export const educationPlaceSelector = createSelector(path([GLOBAL_STATE, EDUCATION_PLACE]), data => data)

export const educationPlaceListSelector = createSelector(path([GLOBAL_STATE, EDUCATION_PLACE_LIST]), data => data)

export const educationPlaceAllSelector = createSelector(path([GLOBAL_STATE, EDUCATION_PLACE_ALL]), data => data)

export const educationPlaceCreateSelector = createSelector(path([GLOBAL_STATE, EDUCATION_PLACE_CREATE]), data => data)

export const educationPlaceSelectors = {
  educationPlaceSelector,
  educationPlaceListSelector,
  educationPlaceAllSelector,
  educationPlaceCreateSelector,
}

import actionTypes, { USER_OPEN_LINK, CHECK_USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/actionTypes'

export const generateOpenLink = payload => ({
  type: actionTypes[USER_OPEN_LINK].pending,
  payload,
})

export const openLinkClear = () => ({
  type: actionTypes[USER_OPEN_LINK].clear,
})

export const checkOpenLink = payload => ({
  type: actionTypes[CHECK_USER_OPEN_LINK].pending,
  payload,
})

export const userPublicInfo = payload => ({
  type: actionTypes[USER_PUBLIC_INFO].pending,
  payload,
})

export const userPublicInfoClear = () => ({
  type: actionTypes[USER_PUBLIC_INFO].clear,
})

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.STAFF]: createState(actionTypes.STAFF),
  [STATE.STAFF_LIST]: createState(actionTypes.STAFF_LIST),
  [STATE.STAFF_USERNAME]: createState(actionTypes.STAFF_USERNAME),
  [STATE.STAFF_ALL]: createState(actionTypes.STAFF_ALL),
})

export const STAFF = STATE.GLOBAL_STATE

export default reducer

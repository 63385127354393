import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LAYOUT_CONTENT_WIDTH, LAYOUT_MENU_COLLAPSED, LAYOUT_MENU_HIDDEN, LAYOUT_RTL, LAYOUT_SKIN } from './actionTypes'
// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = (): boolean => {
  try {
    const item = window.localStorage.getItem('menuCollapsed')
    return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
  } catch (e) {
    console.warn(e)
  }
  return false
  //** Parse stored json or if none return initialValue
}


// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  skin: 'light',
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    layoutContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },
    layoutMenuCollapsed: {
      reducer(state, action: PayloadAction<boolean>) {
        state.menuCollapsed = action.payload
      },
      prepare(value: boolean) {
        window.localStorage.setItem('menuCollapsed', `${value}`)
        return { payload: value }
      }
    },
    layoutMenuHidden: (state, action) => {
      state.menuHidden = action.payload
    },
    trackCurrentSkin: (state, action) => {
      state.skin = action.payload
    }
  },
})

export const {
  reducer: layoutReducer,
  actions: { layoutContentWidth, layoutMenuHidden, trackCurrentSkin, layoutMenuCollapsed }
} = layoutSlice

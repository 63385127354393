import { watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  COUNTRY,
  COUNTRY_ALL,
  COUNTRY_CREATE,
  COUNTRY_DELETE,
  COUNTRY_LIST,
} from '../constants/actionTypes'
import * as actions from './actions'

function watchCountry() {
  return watchSaga({
    action: actionTypes[COUNTRY],
    api: API.country,
  })
}

function watchCountryCreate() {
  return watchSaga({
    action: actionTypes[COUNTRY_CREATE],
    api: API.countryCreate,
  })
}

function watchCountryCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[COUNTRY_CREATE],
    message: {
      title: 'Success',
      description: 'Country has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchCountryDelete() {
  return watchSaga({
    action: actionTypes[COUNTRY_DELETE],
    api: API.countryDelete,
  })
}

function* watchCountryDeleteFulfilled() {
  yield takeEvery(actionTypes[COUNTRY_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.countryList())
  })
}

function* watchCountryDeleteRejected() {
  yield takeEvery(actionTypes[COUNTRY_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCountryList() {
  return watchSaga({
    action: actionTypes[COUNTRY_LIST],
    api: API.countryList,
  })
}

function watchCountryAll() {
  return watchSaga({
    action: actionTypes[COUNTRY_ALL],
    api: API.countryAll,
  })
}

export function* countrySaga() {
  yield all([
    fork(watchCountry),
    fork(watchCountryCreate),
    fork(watchCountryCreateFulfilled),
    fork(watchCountryDelete),
    fork(watchCountryDeleteFulfilled),
    fork(watchCountryDeleteRejected),
    fork(watchCountryList),
    fork(watchCountryAll),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
  GLOBAL_STATE,
} from '../constants/state'

export const achievementRecordsListSelector = createSelector(
  path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_LIST]),
  data => data
)

export const achievementRecordsAllSelector = createSelector(path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_ALL]), data => data)

export const achievementRecordsStudentSelector = createSelector(
  path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_STUDENT]),
  data => data
)

export const achievementRecordsTypesSelector = createSelector(
  path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_TYPES]),
  data => data
)

export const achievementRecordsStatusChangeSelector = createSelector(
  path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_CHANGE_STATUS]),
  data => data
)

export const achievementRecordsCreateSelector = createSelector(
  path([GLOBAL_STATE, ACHIEVEMENT_RECORDS_CREATE]),
  data => data
)

import { toCamelCase } from '@helpers/toCamelCase'

export const JOB_POSITION_LIST = 'JOB_POSITION_LIST'
export const JOB_POSITION_ALL = 'JOB_POSITION_ALL'
export const JOB_POSITION_CREATE = 'JOB_POSITION_CREATE'
export const JOB_POSITION_DELETE = 'JOB_POSITION_DELETE'
export const JOB_POSITION = 'JOB_POSITION'
export const JOB_POSITION_ACTIVATE = 'JOB_POSITION_ACTIVATE'

export const GLOBAL_STATE = 'JobPosition'

export const actionNames = {
  [toCamelCase(JOB_POSITION_LIST)]: JOB_POSITION_LIST,
  [toCamelCase(JOB_POSITION_ALL)]: JOB_POSITION_ALL,
  [toCamelCase(JOB_POSITION_CREATE)]: JOB_POSITION_CREATE,
  [toCamelCase(JOB_POSITION_DELETE)]: JOB_POSITION_DELETE,
  [toCamelCase(JOB_POSITION)]: JOB_POSITION,
  [toCamelCase(JOB_POSITION_ACTIVATE)]: JOB_POSITION_ACTIVATE,
}

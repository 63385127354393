import { SuccessToast } from '@components/Toasts'
import { WatchSaga, watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import vacancyPositionApis from '../api'
import {
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CLOSE,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
  VACANCY_POSITION_OPEN,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchVacancyPosition() {
  return watchSaga({
    action: actionTypes[VACANCY_POSITION],
    api: vacancyPositionApis[VACANCY_POSITION],
  })
}

function watchVacancyPositionRejected() {
  return watchSagaRejected({
    action: actionTypes[VACANCY_POSITION],
    message: true,
  })
}

function watchVacancyPositionList() {
  return watchSaga({
    action: actionTypes[VACANCY_POSITION_LIST],
    api: vacancyPositionApis[VACANCY_POSITION_LIST],
  })
}

function watchVacancyPositionListRejected() {
  return watchSagaRejected({
    action: actionTypes[VACANCY_POSITION_LIST],
    message: true,
  })
}

function watchVacancyPositionAll() {
  return watchSaga({
    action: actionTypes[VACANCY_POSITION_ALL],
    api: vacancyPositionApis[VACANCY_POSITION_ALL],
  })
}

function watchVacancyPositionAllRejected() {
  return watchSagaRejected({
    action: actionTypes[VACANCY_POSITION_ALL],
    message: true,
  })
}

function watchVacancyPositionCreate() {
  return watchSaga({
    action: actionTypes[VACANCY_POSITION_CREATE],
    api: vacancyPositionApis[VACANCY_POSITION_CREATE],
  })
}

function* watchVacancyPositionCreateFulfilled() {
  yield takeEvery(actionTypes[VACANCY_POSITION_CREATE].fulfilled, function* (action) {
    toast.success(<SuccessToast text='Successfully created' />)
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled()
  })
}

function watchVacancyPositionCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[VACANCY_POSITION_CREATE],
    message: true,
  })
}

const watchVacancyPositionActivate = new WatchSaga({
  actionType: actionTypes[VACANCY_POSITION_ACTIVATE],
  api: vacancyPositionApis[VACANCY_POSITION_ACTIVATE],
})
const watchVacancyPositionDelete = new WatchSaga({
  actionType: actionTypes[VACANCY_POSITION_DELETE],
  api: vacancyPositionApis[VACANCY_POSITION_DELETE],
})
const watchVacancyPositionOpen = new WatchSaga({
  actionType: actionTypes[VACANCY_POSITION_OPEN],
  api: vacancyPositionApis[VACANCY_POSITION_OPEN],
})
const watchVacancyPositionClose = new WatchSaga({
  actionType: actionTypes[VACANCY_POSITION_CLOSE],
  api: vacancyPositionApis[VACANCY_POSITION_CLOSE],
})

export function* vacancyPositionSaga() {
  yield all([
    fork(watchVacancyPosition),
    fork(watchVacancyPositionRejected),
    fork(watchVacancyPositionList),
    fork(watchVacancyPositionListRejected),
    fork(watchVacancyPositionAll),
    fork(watchVacancyPositionAllRejected),
    fork(watchVacancyPositionCreate),
    fork(watchVacancyPositionCreateFulfilled),
    fork(watchVacancyPositionCreateRejected),
    fork(watchVacancyPositionDelete.watch),
    fork(watchVacancyPositionDelete.watchFulfilled),
    fork(watchVacancyPositionDelete.watchRejected),
    fork(watchVacancyPositionActivate.watch),
    fork(watchVacancyPositionActivate.watchFulfilled),
    fork(watchVacancyPositionActivate.watchRejected),
    fork(watchVacancyPositionOpen.watch),
    fork(watchVacancyPositionOpen.watchFulfilled),
    fork(watchVacancyPositionOpen.watchRejected),
    fork(watchVacancyPositionClose.watch),
    fork(watchVacancyPositionClose.watchFulfilled),
    fork(watchVacancyPositionClose.watchRejected),
  ])
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const offence = data =>
  getInstance().get(API.OFFENCE, {
    params: data,
  })

export const offenceReportList = data =>
  getInstance().get(API.OFFENCE_REPORT, {
    params: data,
  })

export const offenceReportCreate = data => getInstance().post(API.OFFENCE_REPORT, data)

export const offenceReportAll = data =>
  getInstance().get(API.OFFENCE_REPORT_ALL, {
    params: data,
  })

export const offenceCreate = data => getInstance().post(API.OFFENCE, data)

export const offencePublish = data => {
  return getInstance().put(API.ASSESSMENT_OFFENCE_PUBLISH, data)
}
export const offenceUnpublish = data => getInstance().put(API.ASSESSMENT_OFFENCE_UNPUBLISH, data)

export const offenceGetById = data =>
  getInstance().get(`${API.OFFENCE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const offenceDeleteById = data => getInstance().delete(`${API.OFFENCE}${prop('id', data)}`)

export const offenceAll = data =>
  getInstance().get(API.OFFENCE_ALL, {
    params: data,
  })

export const offenceInvigilation = data =>
  getInstance().get(API.OFFENCE_INVIGILATION, {
    params: data,
  })

export const offenceInvigilationCreate = data => getInstance().post(API.OFFENCE_INVIGILATION, data)

export const offenceInvigilationGetById = data =>
  getInstance().get(`${API.OFFENCE_INVIGILATION}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const offenceInvigilationDeleteById = data =>
  getInstance().delete(`${API.OFFENCE_INVIGILATION}${prop('id', data)}`)

export const offenceInvigilationAll = data =>
  getInstance().get(API.OFFENCE_INVIGILATION_ALL, {
    params: data,
  })

export const offenceInvigilationMinutes = data =>
  getInstance().get(API.OFFENCE_INVIGILATION_MINUTES, {
    params: data,
  })

export const offenceInvigilationMinutesCreate = data => getInstance().post(API.OFFENCE_INVIGILATION_MINUTES, data)

export const offenceInvigilationMinutesGetById = data =>
  getInstance().get(`${API.OFFENCE_INVIGILATION_MINUTES}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const offenceInvigilationMinutesDeleteById = data =>
  getInstance().delete(`${API.OFFENCE_INVIGILATION_MINUTES}${prop('id', data)}`)

export const offenceInvigilationMinutesAll = data =>
  getInstance().get(API.OFFENCE_INVIGILATION_MINUTES_ALL, {
    params: data,
  })

export const offenceInvigilationConsultations = data =>
  getInstance().get(API.OFFENCE_INVIGILATION_CONSULTATIONS, {
    params: data,
  })

export const offenceInvigilationConsultationsCreate = data =>
  getInstance().post(API.OFFENCE_INVIGILATION_CONSULTATIONS, data)

export const offenceInvigilationConsultationsGetById = data =>
  getInstance().get(`${API.OFFENCE_INVIGILATION_CONSULTATIONS}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const offenceInvigilationConsultationsDeleteById = data =>
  getInstance().delete(`${API.OFFENCE_INVIGILATION_CONSULTATIONS}${prop('id', data)}`)

export const offenceInvigilationConsultationsAll = data =>
  getInstance().get(API.OFFENCE_INVIGILATION_CONSULTATIONS_ALL, {
    params: data,
  })

export const offenceType = data =>
  getInstance().get(API.OFFENCE_TYPE, {
    params: data,
  })

export const offenceTypeCreate = data => getInstance().post(API.OFFENCE_TYPE, data)

export const offenceTypeGetById = data =>
  getInstance().get(`${API.OFFENCE_TYPE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const offenceTypeDeleteById = data => getInstance().delete(`${API.OFFENCE_TYPE}${prop('id', data)}`)

export const offenceTypeAll = data =>
  getInstance().get(API.OFFENCE_TYPE_ALL, {
    params: data,
  })
export const offenceChangeStatus = data => getInstance().put(API.OFFENCE_CHANGE_STATUS, data)

export const offenceChangeSubjectBoardStatus = data => getInstance().put(API.OFFENCE_CHANGE_SUBJECT_BOARD_STATUS, data)

export const reportMisconduct = data => getInstance().post(API.REPORT_MISCONDUCT, data)

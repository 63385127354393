import { createAction } from '@helpers/createAction'

export const GROUP_ALL = 'GROUP_ALL'
export const GROUP_ASSIGN = 'GROUP_ASSIGN'
export const GROUP_ASSIGNED_LIST = 'GROUP_ASSIGNED_LIST'
export const GROUP_EDIT = 'GROUP_EDIT'
export const GROUP_GROUPING = 'GROUP_GROUPING'
export const GROUP_SHUFFLE = 'GROUP_SHUFFLE'
export const GROUP_STUDENT_TRANSFER = 'GROUP_STUDENT_TRANSFER'

export default {
  [GROUP_ALL]: createAction(GROUP_ALL),
  [GROUP_GROUPING]: createAction(GROUP_GROUPING),
  [GROUP_SHUFFLE]: createAction(GROUP_SHUFFLE),
  [GROUP_EDIT]: createAction(GROUP_EDIT),
  [GROUP_STUDENT_TRANSFER]: createAction(GROUP_STUDENT_TRANSFER),
  [GROUP_ASSIGN]: createAction(GROUP_ASSIGN),
  [GROUP_ASSIGNED_LIST]: createAction(GROUP_ASSIGNED_LIST),
}

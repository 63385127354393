import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import categoryApis from '../api'
import { CATEGORY, CATEGORY_ALL, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_LIST } from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchCategory() {
  return watchSaga({
    action: actionTypes[CATEGORY],
    api: categoryApis[CATEGORY],
  })
}

function watchCategoryRejected() {
  return watchSagaRejected({
    action: actionTypes[CATEGORY],
    message: true,
  })
}

function watchCategoryList() {
  return watchSaga({
    action: actionTypes[CATEGORY_LIST],
    api: categoryApis[CATEGORY_LIST],
  })
}

function watchCategoryListRejected() {
  return watchSagaRejected({
    action: actionTypes[CATEGORY_LIST],
    message: true,
  })
}

function watchCategoryAll() {
  return watchSaga({
    action: actionTypes[CATEGORY_ALL],
    api: categoryApis[CATEGORY_ALL],
  })
}

function watchCategoryAllRejected() {
  return watchSagaRejected({
    action: actionTypes[CATEGORY_ALL],
    message: true,
  })
}

function watchCategoryCreate() {
  return watchSaga({
    action: actionTypes[CATEGORY_CREATE],
    api: categoryApis[CATEGORY_CREATE],
  })
}

function watchCategoryCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[CATEGORY_CREATE],
    message: {
      description: 'Successfully created',
    },
  })
}

function watchCategoryCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[CATEGORY_CREATE],
    message: true,
  })
}

function watchCategoryDelete() {
  return watchSaga({
    action: actionTypes[CATEGORY_DELETE],
    api: categoryApis[CATEGORY_DELETE],
  })
}

function* watchCategoryDeleteFulfilled() {
  yield takeEvery(actionTypes[CATEGORY_DELETE].fulfilled, function* ({ payload }) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
  })
}

function* watchCategoryDeleteRejected() {
  yield takeEvery(actionTypes[CATEGORY_DELETE].rejected, function* ({ payload }) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

export function* categorySaga() {
  yield all([
    fork(watchCategory),
    fork(watchCategoryRejected),
    fork(watchCategoryList),
    fork(watchCategoryListRejected),
    fork(watchCategoryAll),
    fork(watchCategoryAllRejected),
    fork(watchCategoryCreate),
    fork(watchCategoryCreateFulfilled),
    fork(watchCategoryCreateRejected),
    fork(watchCategoryDelete),
    fork(watchCategoryDeleteFulfilled),
    fork(watchCategoryDeleteRejected),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { DASHBOARD_STUDENT, GLOBAL_STATE } from '../constants/state'

export const studentDashboardSelector = createSelector(path([GLOBAL_STATE, DASHBOARD_STUDENT]), data => data)

export const studentDashboardSelectors = {
  studentDashboardSelector,
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_OFFENCE,
  ASSESSMENT_OFFENCE_ALL,
  ASSESSMENT_OFFENCE_CHANGE_STATUS,
  ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS,
  ASSESSMENT_OFFENCE_INVIGILATION_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL,
  ASSESSMENT_OFFENCE_REPORT_ALL,
  ASSESSMENT_OFFENCE_REPORT_CREATE,
  ASSESSMENT_OFFENCE_REPORT_LIST,
  ASSESSMENT_OFFENCE_TYPE_ALL,
  GLOBAL_STATE,
  REPORT_MISCONDUCT,
} from '../constants/state'

export const assessmentOffenceListSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_OFFENCE]), data => data)

export const assessmentOffenceInvigilationAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_INVIGILATION_ALL]),
  data => data
)

export const invigilationConsultationsAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL]),
  data => data
)

export const assessmentOffenceInvigilationMinutesAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL]),
  data => data
)

export const assessmentOffenceStatusSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_CHANGE_STATUS]),
  data => data
)

export const assessmentOffenceAllSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_OFFENCE_ALL]), data => data)

export const assessmentOffenceReportAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_REPORT_ALL]),
  data => data
)

export const assessmentOffenceReportListSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_REPORT_LIST]),
  data => data
)

export const subjectBoardSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS]),
  data => data
)

export const assessmentOffenceTypeAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_TYPE_ALL]),
  data => data
)

export const reportMisconductSelector = createSelector(path([GLOBAL_STATE, REPORT_MISCONDUCT]), data => data)

export const reportMisconductCreateSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_OFFENCE_REPORT_CREATE]),
  data => data
)

export const assessmentOffenceSelectors = {
  assessmentOffenceListSelector,
  assessmentOffenceInvigilationAllSelector,
  invigilationConsultationsAllSelector,
  assessmentOffenceInvigilationMinutesAllSelector,
  assessmentOffenceStatusSelector,
  assessmentOffenceAllSelector,
  assessmentOffenceReportAllSelector,
  assessmentOffenceReportListSelector,
  subjectBoardSelector,
  assessmentOffenceTypeAllSelector,
  reportMisconductSelector,
  reportMisconductCreateSelector,
}

import actionTypes, {
  FORM,
  FORM_CREATE,
  FORM_DELETE,
  FORM_LIST,
  FORM_ALL,
  FORM_ACTION_TYPE,
  FORM_FIELD_CREATE,
  FORM_FIELD_DELETE,
  FORM_FIELD_BATCH,
  FORM_FIELD_BATCH_CREATE,
  FORM_TYPE,
  FORM_TYPE_CREATE,
  FORM_TYPE_DELETE,
  FORM_TYPE_LIST,
  FORM_TYPE_ALL,
  FORM_REQUEST,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_DELETE,
  FORM_REQUEST_LIST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CHANGE_STATUS,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_ITEM,
  FORM_REQUEST_CERTIFICATE,
} from '../constants/actionTypes'

export const form = payload => ({
  type: actionTypes[FORM].pending,
  payload,
})

export const formClear = () => ({
  type: actionTypes[FORM].clear,
})

export const formCreate = payload => ({
  type: actionTypes[FORM_CREATE].pending,
  payload,
})

export const formDelete = payload => ({
  type: actionTypes[FORM_DELETE].pending,
  payload,
})

export const formList = payload => ({
  type: actionTypes[FORM_LIST].pending,
  payload,
})

export const formListClear = () => ({
  type: actionTypes[FORM_LIST].clear,
})

export const formAll = payload => ({
  type: actionTypes[FORM_ALL].pending,
  payload,
})

export const formAllClear = () => ({
  type: actionTypes[FORM_ALL].clear,
})

export const formActionType = payload => ({
  type: actionTypes[FORM_ACTION_TYPE].pending,
  payload,
})

export const formFieldCreate = payload => ({
  type: actionTypes[FORM_FIELD_CREATE].pending,
  payload,
})

export const formFieldDelete = payload => ({
  type: actionTypes[FORM_FIELD_DELETE].pending,
  payload,
})

export const formFieldBatch = payload => ({
  type: actionTypes[FORM_FIELD_BATCH].pending,
  payload,
})

export const formFieldBatchClear = () => ({
  type: actionTypes[FORM_FIELD_BATCH].clear,
})

export const formFieldBatchCreate = payload => ({
  type: actionTypes[FORM_FIELD_BATCH_CREATE].pending,
  payload,
})

export const formType = payload => ({
  type: actionTypes[FORM_TYPE].pending,
  payload,
})

export const formTypeCreate = payload => ({
  type: actionTypes[FORM_TYPE_CREATE].pending,
  payload,
})

export const formTypeDelete = payload => ({
  type: actionTypes[FORM_TYPE_DELETE].pending,
  payload,
})

export const formTypeList = payload => ({
  type: actionTypes[FORM_TYPE_LIST].pending,
  payload,
})

export const formTypeListClear = () => ({
  type: actionTypes[FORM_TYPE_LIST].clear,
})

export const formTypeAll = payload => ({
  type: actionTypes[FORM_TYPE_ALL].pending,
  payload,
})

export const formTypeAllClear = () => ({
  type: actionTypes[FORM_TYPE_ALL].clear,
})

export const formRequest = payload => ({
  type: actionTypes[FORM_REQUEST].pending,
  payload,
})

export const formRequestItem = payload => ({
  type: actionTypes[FORM_REQUEST_ITEM].pending,
  payload,
})

export const formRequestItemClear = () => ({
  type: actionTypes[FORM_REQUEST_ITEM].clear,
})

export const formRequestClear = () => ({
  type: actionTypes[FORM_REQUEST].clear,
})

export const formRequestCreate = payload => ({
  type: actionTypes[FORM_REQUEST_CREATE].pending,
  payload,
})

export const formRequestDelete = payload => ({
  type: actionTypes[FORM_REQUEST_DELETE].pending,
  payload,
})

export const formRequestList = payload => ({
  type: actionTypes[FORM_REQUEST_LIST].pending,
  payload,
})

export const formRequestListClear = () => ({
  type: actionTypes[FORM_REQUEST_LIST].clear,
})

export const formRequestAll = payload => ({
  type: actionTypes[FORM_REQUEST_ALL].pending,
  payload,
})

export const formRequestAllClear = () => ({
  type: actionTypes[FORM_REQUEST_ALL].clear,
})

export const formRequestFields = payload => ({
  type: actionTypes[FORM_REQUEST_FIELDS].pending,
  payload,
})

export const formRequestChangeStatus = payload => ({
  type: actionTypes[FORM_REQUEST_CHANGE_STATUS].pending,
  payload,
})

export const formRequestCertificate = payload => ({
  type: actionTypes[FORM_REQUEST_CERTIFICATE].pending,
  payload,
})

export const formRequestFieldsClear = () => ({
  type: actionTypes[FORM_REQUEST_FIELDS].clear,
})

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
  GLOBAL_STATE,
} from '../constants/state'

export const assessmentECUpdateSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC_UPDATE]), data => data)

export const assessmentECCreateSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC_CREATE]), data => data)

export const assessmentECAllSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC_ALL]), data => data)

export const assessmentECListSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC]), data => data)

export const assessmentECPaperSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC_PAPER]), data => data)

export const assessmentECStatusSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_EC_STATUS]), data => data)

export const assessmentECOutcomeReportSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_EC_OUTCOME_REPORT]),
  data => data
)

export const assessmentECSelectors = {
  assessmentECUpdateSelector,
  assessmentECCreateSelector,
  assessmentECAllSelector,
  assessmentECListSelector,
  assessmentECPaperSelector,
  assessmentECStatusSelector,
  assessmentECOutcomeReportSelector,
}

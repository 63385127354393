import axios from 'axios'

import actionTypes, {
  LESSON,
  LESSON_ALL,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_DELETE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
  LESSON_SORTING,
} from '../constants/actionTypes'

export const lesson = payload => ({
  type: actionTypes[LESSON].pending,
  payload,
})

export const lessonClear = () => ({
  type: actionTypes[LESSON].clear,
})

export const lessonCreate = payload => ({
  type: actionTypes[LESSON_CREATE].pending,
  payload,
})

export const lessonDelete = payload => ({
  type: actionTypes[LESSON_DELETE].pending,
  payload,
})

export const lessonList = payload => ({
  type: actionTypes[LESSON_LIST].pending,
  payload,
})

export const lessonListClear = () => ({
  type: actionTypes[LESSON_LIST].clear,
})

export const lessonAll = payload => ({
  type: actionTypes[LESSON_ALL].pending,
  payload,
})

export const lessonSorting = payload => ({
  type: actionTypes[LESSON_SORTING].pending,
  payload,
})

export const lessonAllClear = () => ({
  type: actionTypes[LESSON_ALL].clear,
})

export const lessonHomeworkUpload = payload => ({
  type: actionTypes[LESSON_HOMEWORK_UPLOAD].pending,
  payload,
})

export const lessonHomeworkUploadClear = () => ({
  type: actionTypes[LESSON_HOMEWORK_UPLOAD].clear,
})

export const lessonAssessment = payload => {
  const source = axios.CancelToken.source()
  return {
    type: actionTypes[LESSON_ASSESSMENT].pending,
    payload: {
      ...payload,
      axiosSource: source,
    },
  }
}

export const lessonAssessmentClear = () => ({
  type: actionTypes[LESSON_ASSESSMENT].clear,
})

export const lessonAssessmentAll = payload => {
  const source = axios.CancelToken.source()
  return {
    type: actionTypes[LESSON_ASSESSMENT_ALL].pending,
    payload: {
      ...payload,
      axiosSource: source,
    },
  }
}

export const lessonAssessmentAllClear = () => ({
  type: actionTypes[LESSON_ASSESSMENT_ALL].clear,
})

export const lessonMarkAssessment = payload => ({
  type: actionTypes[LESSON_MARK_ASSESSMENT].pending,
  payload,
})

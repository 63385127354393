import { watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { all, fork } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { INTRO_VIDEOS } from '../constants/actionTypes'

function watchVideoTutorial() {
  return watchSaga({
    action: actionTypes[INTRO_VIDEOS],
    api: API.videoTutorial,
  })
}

function watchVideoTutorialRejected() {
  return watchSagaRejected({
    action: actionTypes[INTRO_VIDEOS],
    message: true,
  })
}

export function* videoTutorialSaga() {
  yield all([fork(watchVideoTutorial), fork(watchVideoTutorialRejected)])
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.GROUP_ALL]: createState(actionTypes.GROUP_ALL),
  [STATE.GROUP_GROUPING]: createState(actionTypes.GROUP_GROUPING),
  [STATE.GROUP_STUDENT_TRANSFER]: createState(actionTypes.GROUP_STUDENT_TRANSFER),
  [STATE.GROUP_SHUFFLE]: createState(actionTypes.GROUP_SHUFFLE),
  [STATE.GROUP_ASSIGN_TEACHER]: createState(actionTypes.GROUP_ASSIGN),
  [STATE.GROUP_ASSIGNED_LIST]: createState(actionTypes.GROUP_ASSIGNED_LIST),
})

export const GROUPING = STATE.GLOBAL_STATE

export default reducer

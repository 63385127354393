import { getDataFromState } from '@helpers/get'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const stCourseApplicationSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseApplicationListSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_LIST, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseApplicationTypesSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_TYPES, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseApplicationStatusSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_STATUS, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseApplicationAllSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const stCourseApplicationFieldBatchSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_FIELD_BATCH, STATE.GLOBAL_STATE),
  data => data
)

// category
export const stCourseApplicationCategorySelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_CATEGORY, STATE.GLOBAL_STATE),
  data => data
)
export const stCourseApplicationCategoryListSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_CATEGORY_LIST, STATE.GLOBAL_STATE),
  data => data
)
export const stCourseApplicationCategoryAllSelector = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_CATEGORY_ALL, STATE.GLOBAL_STATE),
  data => data
)

// request
export const stCourseApplicationRequestAll = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_REQUEST_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const stCourseApplicationRequestEdit = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_REQUEST_EDIT, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseApplicationRequestUpdate = createSelector(
  getDataFromState(STATE.ST_COURSE_APPLICATION_REQUEST_UPDATE, STATE.GLOBAL_STATE),
  data => data
)

export const stCourseAdmissionSelectors = {
  stCourseApplicationSelector,
  stCourseApplicationListSelector,
  stCourseApplicationAllSelector,
  stCourseApplicationFieldBatchSelector,
  stCourseApplicationCategorySelector,
  stCourseApplicationCategoryListSelector,
  stCourseApplicationCategoryAllSelector,
  stCourseApplicationRequestAll,
  stCourseApplicationRequestEdit,
  stCourseApplicationRequestUpdate,
  stCourseApplicationTypesSelector,
  stCourseApplicationStatusSelector,
}

import { createAction } from '@helpers/createAction'

export const CITY = 'CITY'
export const CITY_CREATE = 'CITY_CREATE'
export const CITY_DELETE = 'CITY_DELETE'
export const CITY_LIST = 'CITY_LIST'
export const CITY_ALL = 'CITY_ALL'

export default {
  [CITY]: createAction(CITY),
  [CITY_CREATE]: createAction(CITY_CREATE),
  [CITY_DELETE]: createAction(CITY_DELETE),
  [CITY_LIST]: createAction(CITY_LIST),
  [CITY_ALL]: createAction(CITY_ALL),
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const semester = data =>
  getInstance().get(`${API.SEMESTER}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const semesterCreate = data => getInstance().post(API.SEMESTER, data)

export const semesterDelete = data => getInstance().delete(`${API.SEMESTER}${prop('id', data)}`)

export const semesterList = data =>
  getInstance().get(API.SEMESTER, {
    params: data,
  })

export const semesterAll = data =>
  getInstance().get(API.SEMESTER_ALL, {
    params: data,
  })

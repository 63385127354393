import { forEach, includes, prop, toLower } from 'ramda'
import { useState } from 'react'

function useSearch() {
  const [searchValue, setSearchValue] = useState('')

  function handleChange(v) {
    setSearchValue(v)
  }

  function filtered(iterable = []) {
    if (!searchValue) {
      return iterable
    }
    if (Array.isArray(iterable)) {
      let sub = []
      let filteredItems = []
      forEach(item => {
        sub = [...sub, ...(prop('children', item) ? filtered(prop('children', item)) : [])]
        filteredItems = [
          ...filteredItems,
          ...(includes(toLower(searchValue), toLower(prop('name', item))) ? [item] : []),
        ]
      })(iterable)
      return [...sub, ...filteredItems]
    }
  }

  return [handleChange, searchValue, filtered]
}

export default useSearch

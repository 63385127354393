import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import { CATEGORY, CATEGORY_ALL, CATEGORY_CREATE, CATEGORY_DELETE, CATEGORY_LIST } from '../constants/actionTypes'
import * as API from '../constants/api'

export const categoryList = data =>
  getInstance().get(API.CATEGORY, {
    params: data,
  })

export const categoryCreate = data => getInstance().post(API.CATEGORY, data)

export const categoryDelete = data => getInstance().delete(`${API.CATEGORY}${prop('id', data)}`)

export const category = data =>
  getInstance().get(`${API.CATEGORY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const categoryAll = data =>
  getInstance().get(API.CATEGORY_ALL, {
    params: data,
  })

export default {
  [CATEGORY]: category,
  [CATEGORY_ALL]: categoryAll,
  [CATEGORY_CREATE]: categoryCreate,
  [CATEGORY_DELETE]: categoryDelete,
  [CATEGORY_LIST]: categoryList,
}

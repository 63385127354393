import { WatchSaga, watchSaga } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_ALL,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_DELETE,
  ACADEMIC_YEAR_LIST,
} from '../constants/actionTypes'
import * as actions from './actions'

function watchAcademicYear() {
  return watchSaga({
    action: actionTypes[ACADEMIC_YEAR],
    api: API.academicYear,
  })
}

function watchAcademicYearCreate() {
  return watchSaga({
    action: actionTypes[ACADEMIC_YEAR_CREATE],
    api: API.academicYearCreate,
  })
}

export function* watchAcademicYearCreateFulfilled() {
  yield takeEvery(actionTypes[ACADEMIC_YEAR_CREATE].fulfilled, function* () {
    yield put(actions.academicYearList())
  })
}

function watchAcademicYearDelete() {
  return watchSaga({
    action: actionTypes[ACADEMIC_YEAR_DELETE],
    api: API.academicYearDelete,
  })
}

export function* watchAcademicYearDeleteFulfilled() {
  yield takeEvery(actionTypes[ACADEMIC_YEAR_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.academicYearList())
  })
}

function* watchAcademicYearDeleteRejected() {
  yield takeEvery(actionTypes[ACADEMIC_YEAR_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchAcademicYearList() {
  return watchSaga({
    action: actionTypes[ACADEMIC_YEAR_LIST],
    api: API.academicYearList,
  })
}

const academicYearAllSaga = new WatchSaga({ actionType: actionTypes[ACADEMIC_YEAR_ALL], api: API.academicYearAll })

export function* academicYearSaga() {
  yield all([
    fork(watchAcademicYear),
    fork(watchAcademicYearCreate),
    fork(watchAcademicYearCreateFulfilled),
    fork(watchAcademicYearDelete),
    fork(watchAcademicYearDeleteFulfilled),
    fork(watchAcademicYearDeleteRejected),
    fork(watchAcademicYearList),
    fork(academicYearAllSaga.watch),
    fork(academicYearAllSaga.watchFulfilled),
    fork(academicYearAllSaga.watchRejected),
  ])
}

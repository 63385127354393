import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
} from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.DEPARTMENT]: createState(DEPARTMENT),
  [STATE.DEPARTMENT_CREATE]: createState(DEPARTMENT_CREATE),
  [STATE.DEPARTMENT_LIST]: createState(DEPARTMENT_LIST),
  [STATE.DEPARTMENT_ALL]: createState(DEPARTMENT_ALL),
  [STATE.DEPARTMENT_LABELS]: createState(DEPARTMENT_LABELS),
})

export const DEPARTMENTS = STATE.GLOBAL_STATE

export default reducer

export const REPORT_INQUIRY = 'inquiry/'
export const REPORT_INQUIRY_ALL = `${REPORT_INQUIRY}all`

export const REPORT_INQUIRY_CREATE = `${REPORT_INQUIRY}`
export const REPORT_INQUIRY_DELETE = `${REPORT_INQUIRY}`
export const INQUIRY_TYPES = 'types/measurement'
export const INQUIRY_STATUS_TYPES = 'types/inquiry_status'
export const RAPORT_UPDATE_STATUS = `${REPORT_INQUIRY}update-status`
export const RAPORT_INQUIRY_ASSIGNED = `${REPORT_INQUIRY}assigned`

//commnet-template
export const INQUIRY_COMMENT = `${REPORT_INQUIRY}comment-template/`
export const INQUIRY_COMMENT_CREATE = INQUIRY_COMMENT
export const INQUIRY_COMMENT_DELETE = INQUIRY_COMMENT

import { path } from 'ramda'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const roleListAllSelector = createSelector(path(['Roles', STATE.ROLE_ALL]), data => data)

export const roleListSelector = createSelector(path(['Roles', STATE.ROLE_LIST]), data => data)

export const roleSelectors = {
  roleListAllSelector,
  roleListSelector,
}

import { watchSaga } from '@helpers/customSaga'
import { all, fork } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { STAFF, STAFF_ALL, STAFF_DELETE, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'

function watchStaff() {
  return watchSaga({
    action: actionTypes[STAFF],
    api: API.staff,
  })
}

function watchStaffDelete() {
  return watchSaga({
    action: actionTypes[STAFF_DELETE],
    api: API.staffDelete,
  })
}

function watchStaffList() {
  return watchSaga({
    action: actionTypes[STAFF_LIST],
    api: API.staffList,
  })
}

function watchStaffAll() {
  return watchSaga({
    action: actionTypes[STAFF_ALL],
    api: API.staffAll,
  })
}

function watchStaffUsername() {
  return watchSaga({
    action: actionTypes[STAFF_USERNAME],
    api: API.staffUsername,
  })
}

export function* staffSaga() {
  yield all([
    fork(watchStaff),
    fork(watchStaffDelete),
    fork(watchStaffList),
    fork(watchStaffAll),
    fork(watchStaffUsername),
  ])
}

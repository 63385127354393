import actionTypes, {
  ST_COURSE_APPLICATION,
  ST_COURSE_APPLICATION_CREATE,
  ST_COURSE_APPLICATION_DELETE,
  ST_COURSE_APPLICATION_LIST,
  ST_COURSE_APPLICATION_ALL,
  ST_COURSE_APPLICATION_FIELD_BATCH,
  ST_COURSE_APPLICATION_FIELD_BATCH_CREATE,
  ST_COURSE_APPLICATION_CATEGORY,
  ST_COURSE_APPLICATION_CATEGORY_CREATE,
  ST_COURSE_APPLICATION_CATEGORY_DELETE,
  ST_COURSE_APPLICATION_CATEGORY_LIST,
  ST_COURSE_APPLICATION_CATEGORY_ALL,
  ST_COURSE_APPLICATION_TYPES,
  ST_COURSE_APPLICATION_STATUS,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'

export const stCourseApplication = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION].pending,
  payload,
})

export const stCourseApplicationClear = () => ({
  type: actionTypes[ST_COURSE_APPLICATION].clear,
})

export const stCourseApplicationCreate = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CREATE].pending,
  payload,
})

export const stCourseApplicationDelete = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_DELETE].pending,
  payload,
})

export const stCourseApplicationList = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_LIST].pending,
  payload,
})

export const stCourseApplicationListClear = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_LIST].clear,
  payload,
})

export const stCourseApplicationAll = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_ALL].pending,
  payload,
})

export const stCourseApplicationAllClear = () => ({
  type: actionTypes[ST_COURSE_APPLICATION_ALL].clear,
})

export const stCourseApplicationTypes = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_TYPES].pending,
  payload,
})

export const stCourseApplicationTypesClear = () => ({
  type: actionTypes[ST_COURSE_APPLICATION_TYPES].clear,
})

export const stCourseApplicationChangeStatus = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_STATUS].pending,
  payload,
})

export const stCourseApplicationFieldBatch = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH].pending,
  payload,
})

export const stCourseApplicationFieldBatchClear = () => ({
  type: actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH].clear,
})

export const stCourseApplicationFieldBatchCreate = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_FIELD_BATCH_CREATE].pending,
  payload,
})

export const stCourseApplicationCategory = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY].pending,
  payload,
})

export const stCourseApplicationCategoryCreate = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY_CREATE].pending,
  payload,
})

export const stCourseApplicationCategoryDelete = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY_DELETE].pending,
  payload,
})

export const stCourseApplicationCategoryList = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY_LIST].pending,
  payload,
})

export const stCourseApplicationCategoryListClear = () => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY_LIST].clear,
})

export const stCourseApplicationCategoryAll = payload => ({
  type: actionTypes[ST_COURSE_APPLICATION_CATEGORY_ALL].pending,
  payload,
})

export const stCourseApplicationRequest = payload => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST].pending,
  payload,
})

export const stCourseApplicationRequestAll = payload => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_ALL].pending,
  payload,
})

export const stCourseApplicationRequestEdit = payload => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_EDIT].pending,
  payload,
})

export const stCourseApplicationRequestEditClear = () => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_EDIT].clear,
})

export const stCourseApplicationRequestUpdate = payload => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE].pending,
  payload,
})

export const stCourseApplicationRequestUpdateClear = () => ({
  type: actionTypes[TYPES.ST_COURSE_APPLICATION_REQUEST_UPDATE].clear,
})

export const USER_INFO = 'userInfo'
export const AUTH_LOGIN = 'login'
export const AUTH_LOGOUT = 'logout'
export const USER_UPDATE_PASSWORD = 'userUpdatePassword'
export const USER_SETTINGS = 'userSettings'
export const TOKEN = 'token'

export const RESET_PASSWORD_REQUEST = 'resetPasswordRequest'
export const RESET_PASSWORD = 'resetPassword'

export const SIGN_UP = 'signUp'
export const SMS_SEND = 'smsSend'
export const VERIFY_EMAIL = 'verifyEmail'

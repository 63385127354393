import XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

function useExport() {
  const handleExport = ({ fileFormat = 'xlsx', targetRef, fileName = '', callback = () => {} }) => {
    callback()
    const bookType = fileFormat
    const wb = XLSX.utils.table_to_book(targetRef.current, { sheet: 'Sheet JS' })
    const wbout = XLSX.write(wb, { bookType, bookSST: true, type: 'binary' })

    const s2ab = s => {
      const buf = new ArrayBuffer(s.length)
      const view = new Uint8Array(buf)
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
      return buf
    }
    const file = fileName.length ? `${fileName}.${fileFormat}` : `excel-sheet.${fileFormat}`

    return FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), file)
  }

  return { handleExport }
}

export default useExport

import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { COLOR_PALETTE, GLOBAL_STATE } from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const COLOR_PALETTES = GLOBAL_STATE

export const { reducer: colorPaletteReducer } = createSlice({
  name: COLOR_PALETTES,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actionCreators.colorPalette.pending, state => {
        state[COLOR_PALETTE].loading = true
      })
      .addCase(actionCreators.colorPalette.rejected, (state, action) => {
        state[COLOR_PALETTE].loading = false
        state[COLOR_PALETTE].error = action.payload as AxiosError
      })
      .addCase(actionCreators.colorPalette.fulfilled, (state, action) => {
        state[COLOR_PALETTE].loading = false
        state[COLOR_PALETTE].error = null
        state[COLOR_PALETTE].data = action.payload
      })

    builder.addCase(actionCreators.colorPaletteClear, state => {
      state[COLOR_PALETTE] = defaultState()
    })
  },
})

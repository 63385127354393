import { DefaultState } from '@src/redux/types'

import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [TYPES.PRODUCT_ITEM]: DefaultState<`${typeof API.PRODUCT_ITEM}{id}`, 'get'>
  [TYPES.PRODUCT_ITEM_CLIENT]: DefaultState<`${typeof API.PRODUCT_ITEM_CLIENT}{id}`, 'get'>
  [TYPES.PRODUCT_ITEM_LIST]: DefaultState<`${typeof API.PRODUCT_ITEM}`, 'get'>
  [TYPES.PRODUCT_ITEM_ALL_SHORT]: DefaultState<`${typeof API.PRODUCT_ITEM_ALL_SHORT}`, 'get'>
  [TYPES.PRODUCT_CATEGORY_LIST]: DefaultState<`${typeof API.PRODUCT_CATEGORY}`, 'get'>
  [TYPES.PRODUCT_COLOR_LIST]: DefaultState<`${typeof API.PRODUCT_COLOR}`, 'get'>
  [TYPES.PRODUCT_SIZE_LIST]: DefaultState<`${typeof API.PRODUCT_SIZE}`, 'get'>
  [TYPES.PRODUCT_MATERIAL_LIST]: DefaultState<`${typeof API.PRODUCT_MATERIAL}`, 'get'>
  [TYPES.PRODUCT_TRADE_LIST]: DefaultState<`${typeof API.PRODUCT_TRADE}`, 'get'>
  [TYPES.PRODUCT_TRADE_BUYER_ALL]: DefaultState<`${typeof API.PRODUCT_TRADE_BUYER_ALL}`, 'get'>
} = () => ({
  [TYPES.PRODUCT_ITEM]: defaultState(),
  [TYPES.PRODUCT_ITEM_CLIENT]: defaultState(),
  [TYPES.PRODUCT_ITEM_LIST]: defaultState(),
  [TYPES.PRODUCT_ITEM_ALL_SHORT]: defaultState(),
  [TYPES.PRODUCT_CATEGORY_LIST]: defaultState(),
  [TYPES.PRODUCT_COLOR_LIST]: defaultState(),
  [TYPES.PRODUCT_SIZE_LIST]: defaultState(),
  [TYPES.PRODUCT_MATERIAL_LIST]: defaultState(),
  [TYPES.PRODUCT_TRADE_LIST]: defaultState(),
  [TYPES.PRODUCT_TRADE_BUYER_ALL]: defaultState(),
})

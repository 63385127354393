import { createAction } from '@helpers/createAction'

export const USER_OPEN_LINK = 'USER_OPEN_LINK'
export const CHECK_USER_OPEN_LINK = 'CHECK_USER_OPEN_LINK'
export const USER_PUBLIC_INFO = 'USER_PUBLIC_INFO'

export default {
  [USER_OPEN_LINK]: createAction(USER_OPEN_LINK),
  [CHECK_USER_OPEN_LINK]: createAction(CHECK_USER_OPEN_LINK),
  [USER_PUBLIC_INFO]: createAction(USER_PUBLIC_INFO),
}

export const APP_MENU = 'AppMenu'
export const LAYOUT = 'layout'
export const SETTINGS = 'Settings'
export const ROLE = 'Role'
export const ACADEMIC_YEAR = 'AcademicYear'
export const COURSE = 'Course'
export const COURSE_CAMPAIGN = 'CourseCampaign'
export const MODULE = 'Module'
export const CCLS_MODULE = 'CclsModule'
export const SEMESTER = 'Semester'
export const LANGUAGE = 'Language'
export const LEVEL = 'Level'
export const LESSON = 'Lesson'
export const ENROLLMENT = 'Enrollment'
export const STUDENT = 'Student'
export const STAFF = 'Staff'
export const DASHBOARD = 'Dashboard'
export const GROUP = 'Group'
export const FORM = 'Form'
export const ATTENDANCE = 'Attendance'
export const CALENDAR_EVENT = 'CalendarEvent'
export const INSTITUTION = 'Institution'
export const ASSESSMENT = 'Assessment'
export const INVIGILATION_REPORT = 'InvigilationReport'
export const REPORT = 'Report'
export const MODULE_FILES = 'ModuleFiles'
export const ACHIEVEMENT_RECORDS = 'AchievementRecords'
export const USER_OPEN_LINK = 'UserOpenLink'

import { getDataFromState } from '@helpers/get'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const hrApplicationSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION, STATE.GLOBAL_STATE),
  data => data
)

export const hrApplicationListSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_LIST, STATE.GLOBAL_STATE),
  data => data
)

export const hrApplicationTypesSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_TYPES, STATE.GLOBAL_STATE),
  data => data
)

export const hrApplicationStatusSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_STATUS, STATE.GLOBAL_STATE),
  data => data
)

export const hrApplicationAllSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const hrApplicationFieldBatchSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_FIELD_BATCH, STATE.GLOBAL_STATE),
  data => data
)

// category
export const hrApplicationCategorySelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_CATEGORY, STATE.GLOBAL_STATE),
  data => data
)
export const hrApplicationCategoryListSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_CATEGORY_LIST, STATE.GLOBAL_STATE),
  data => data
)
export const hrApplicationCategoryAllSelector = createSelector(
  getDataFromState(STATE.HR_APPLICATION_CATEGORY_ALL, STATE.GLOBAL_STATE),
  data => data
)

// request
export const hrApplicationRequestAll = createSelector(
  getDataFromState(STATE.HR_APPLICATION_REQUEST_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const hrApplicationRequestEdit = createSelector(
  getDataFromState(STATE.HR_APPLICATION_REQUEST_EDIT, STATE.GLOBAL_STATE),
  data => data
)

export const hrApplicationRequestUpdate = createSelector(
  getDataFromState(STATE.HR_APPLICATION_REQUEST_UPDATE, STATE.GLOBAL_STATE),
  data => data
)

export const hrAdmissionSelectors = {
  hrApplicationSelector,
  hrApplicationListSelector,
  hrApplicationAllSelector,
  hrApplicationFieldBatchSelector,
  hrApplicationCategorySelector,
  hrApplicationCategoryListSelector,
  hrApplicationCategoryAllSelector,
  hrApplicationRequestAll,
  hrApplicationRequestEdit,
  hrApplicationRequestUpdate,
  hrApplicationTypesSelector,
  hrApplicationStatusSelector,
}

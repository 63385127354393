const STUDENT = 'student'
export const STUDENT_ADMISSION = `/${STUDENT}-admission`
export const MY_APPLICATIONS = `/guest-application/enrollment`
export const APPLICATIONS = `/${STUDENT}-applications`
export const MY_APPLICATIONS_CREATE = `${MY_APPLICATIONS}/create`
export const APPLICATIONS_EDIT = `${APPLICATIONS}/edit`

export const ADMIN = `${STUDENT_ADMISSION}/admin`
export const ADMIN_APPLICATION_FORM = `${ADMIN}/application-form`
export const ADMIN_APPLICATION_CREATE = `${ADMIN_APPLICATION_FORM}/create`
export const ADMIN_APPLICATION_CATEGORY = `${ADMIN}/category`

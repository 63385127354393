import { createAction } from '@helpers/createAction'

export const ENROLLMENT = 'ENROLLMENT'
export const ENROLLMENT_CREATE = 'ENROLLMENT_CREATE'
export const ENROLLMENT_DELETE = 'ENROLLMENT_DELETE'
export const ENROLLMENT_LIST = 'ENROLLMENT_LIST'
export const ENROLLMENT_ALL = 'ENROLLMENT_ALL'
export const ENROLLMENT_STATUS = 'ENROLLMENT_STATUS'
export const ENROLLMENT_COURSES = 'ENROLLMENT_COURSES'

export default {
  [ENROLLMENT]: createAction(ENROLLMENT),
  [ENROLLMENT_CREATE]: createAction(ENROLLMENT_CREATE),
  [ENROLLMENT_DELETE]: createAction(ENROLLMENT_DELETE),
  [ENROLLMENT_LIST]: createAction(ENROLLMENT_LIST),
  [ENROLLMENT_ALL]: createAction(ENROLLMENT_ALL),
  [ENROLLMENT_STATUS]: createAction(ENROLLMENT_STATUS),
  [ENROLLMENT_COURSES]: createAction(ENROLLMENT_COURSES),
}

import { createAction } from '@helpers/createAction'

export const ROLE = 'ROLE'
export const ROLE_CREATE = 'ROLE_CREATE'
export const ROLE_DELETE = 'ROLE_DELETE'
export const ROLE_LIST = 'ROLE_LIST'
export const ROLE_ALL = 'ROLE_LIST_ALL'

export default {
  [ROLE]: createAction(ROLE),
  [ROLE_CREATE]: createAction(ROLE_CREATE),
  [ROLE_DELETE]: createAction(ROLE_DELETE),
  [ROLE_LIST]: createAction(ROLE_LIST),
  [ROLE_ALL]: createAction(ROLE_ALL),
}

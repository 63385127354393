import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const hrApplicationRequest = data => getInstance().post(API.HR_APPLICATION_REQUEST, data)

export const hrApplicationRequestAll = data =>
  getInstance().get(API.HR_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const hrApplicationRequestEdit = data =>
  getInstance().get(API.HR_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const hrApplicationRequestUpdate = data => getInstance().put(API.HR_APPLICATION_REQUEST_UPDATE, data)

import actionTypes, {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_LIST,
  COURSE_CAMPAIGN_ALL,
} from '../constants/actionTypes'

export const courseCampaign = payload => ({
  type: actionTypes[COURSE_CAMPAIGN].pending,
  payload,
})

export const courseCampaignClear = () => ({
  type: actionTypes[COURSE_CAMPAIGN].clear,
})

export const courseCampaignCreate = payload => ({
  type: actionTypes[COURSE_CAMPAIGN_CREATE].pending,
  payload,
})

export const courseCampaignDelete = payload => ({
  type: actionTypes[COURSE_CAMPAIGN_DELETE].pending,
  payload,
})

export const courseCampaignList = payload => ({
  type: actionTypes[COURSE_CAMPAIGN_LIST].pending,
  payload,
})

export const courseCampaignListClear = () => ({
  type: actionTypes[COURSE_CAMPAIGN_LIST].clear,
})

export const courseCampaignAll = payload => ({
  type: actionTypes[COURSE_CAMPAIGN_ALL].pending,
  payload,
})

export const courseCampaignAllClear = () => ({
  type: actionTypes[COURSE_CAMPAIGN_ALL].clear,
})

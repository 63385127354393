import { WatchSaga, watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import contractApi from '../api'
import {
  CONTRACT_AGREEMENT,
  CONTRACT_AGREEMENT_ACCEPT,
  CONTRACT_AGREEMENT_CURRENT,
  CONTRACT_AGREEMENT_OPEN,
  CONTRACT_DOWNLOAD,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchContractAgreement() {
  return watchSaga({
    action: actionTypes[CONTRACT_AGREEMENT],
    api: contractApi[CONTRACT_AGREEMENT],
  })
}

function* watchContractAgreementFulfilled() {
  yield takeEvery(actionTypes[CONTRACT_AGREEMENT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
  })
}

function watchContractAgreementRejected() {
  return watchSagaRejected({
    action: actionTypes[CONTRACT_AGREEMENT],
    message: true,
  })
}

function watchContractAgreementAccept() {
  return watchSaga({
    action: actionTypes[CONTRACT_AGREEMENT_ACCEPT],
    api: contractApi[CONTRACT_AGREEMENT_ACCEPT],
  })
}

function* watchContractAgreementAcceptFulfilled() {
  yield takeEvery(actionTypes[CONTRACT_AGREEMENT_ACCEPT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
  })
}

function watchContractAgreementAcceptRejected() {
  return watchSagaRejected({
    action: actionTypes[CONTRACT_AGREEMENT_ACCEPT],
    message: true,
  })
}

function watchContractAgreementOpen() {
  return watchSaga({
    action: actionTypes[CONTRACT_AGREEMENT_OPEN],
    api: contractApi[CONTRACT_AGREEMENT_OPEN],
  })
}

function watchContractAgreementOpenRejected() {
  return watchSagaRejected({
    action: actionTypes[CONTRACT_AGREEMENT_OPEN],
    message: true,
  })
}

const contractAgreementCurrent = new WatchSaga({
  actionType: actionTypes[CONTRACT_AGREEMENT_CURRENT],
  api: contractApi[CONTRACT_AGREEMENT_CURRENT],
})

const contractDownload = new WatchSaga({
  actionType: actionTypes[CONTRACT_DOWNLOAD],
  api: contractApi[CONTRACT_DOWNLOAD],
})

export function* contractAgreementSaga() {
  yield all([
    fork(watchContractAgreement),
    fork(watchContractAgreementRejected),
    fork(watchContractAgreementFulfilled),
    fork(watchContractAgreementAccept),
    fork(watchContractAgreementAcceptFulfilled),
    fork(watchContractAgreementAcceptRejected),
    fork(watchContractAgreementOpen),
    fork(watchContractAgreementOpenRejected),
    fork(contractAgreementCurrent.watch),
    fork(contractAgreementCurrent.watchRejected),
    fork(contractAgreementCurrent.watchFulfilled),
    fork(contractDownload.watch),
    fork(contractDownload.watchRejected),
    fork(contractDownload.watchFulfilled),
  ])
}

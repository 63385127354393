import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.REPORT]: createState(actionTypes.REPORT),
  [STATE.REPORT_ALL]: createState(actionTypes.REPORT_ALL),
  [STATE.REPORT_CREATE]: createState(actionTypes.REPORT_CREATE),
  [STATE.REPORT_LIST]: createState(actionTypes.REPORT_LIST),
  [STATE.REPORT_FIELDS]: createState(actionTypes.REPORT_FIELDS),
  [STATE.REPORT_FIELDS_FILTER]: createState(actionTypes.REPORT_FIELDS_FILTER),
})

export const REPORT = STATE.GLOBAL_STATE

export default reducer

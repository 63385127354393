import actionTypes, { DASHBOARD_STUDENT } from '../constants/actionTypes'

export const dashboardStudent = payload => ({
  type: actionTypes[DASHBOARD_STUDENT].pending,
  payload,
})

export const dashboardStudentClear = () => ({
  type: actionTypes[DASHBOARD_STUDENT].clear,
})

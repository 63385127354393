import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.STATE_LIST]: createState(actionTypes.STATE_LIST),
  [STATE.STATE_CREATE]: createState(actionTypes.STATE_CREATE),
  [STATE.STATE_ALL]: createState(actionTypes.STATE_ALL),
  [STATE.STATE]: createState(actionTypes.STATE),
})

export const STATES = STATE.GLOBAL_STATE

export default reducer

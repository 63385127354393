import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'

import { GLOBAL_STATE, STATS } from '../constants/actionNames'

export const statsGlobalSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const statsSelector = createSelector(
  statsGlobalSelector,
  (state: ReturnType<typeof statsGlobalSelector>) => state[STATS]
)

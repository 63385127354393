export const GENERAL = 'general/'
export const GENERAL_APPLICATION_FORM = `${GENERAL}application_form/`
export const GENERAL_APPLICATION = `${GENERAL}application/`
export const GENERAL_APPLICATION_TYPES = `${GENERAL_APPLICATION}types`
export const GENERAL_APPLICATION_STATUS = `${GENERAL_APPLICATION}status/`
export const GENERAL_APPLICATION_ALL = `${GENERAL_APPLICATION_FORM}all`
export const GENERAL_APPLICATION_FIELD_BATCH = `${GENERAL_APPLICATION_FORM}field/batch/`

export const GENERAL_APPLICATION_CATEGORY = `${GENERAL_APPLICATION_FORM}category/`
export const GENERAL_APPLICATION_CATEGORY_ALL = `${GENERAL_APPLICATION_CATEGORY}all/`

export const GENERAL_APPLICATION_REQUEST = GENERAL_APPLICATION
export const GENERAL_APPLICATION_REQUEST_ALL = `${GENERAL_APPLICATION_REQUEST}all/`
export const GENERAL_APPLICATION_REQUEST_EDIT = `${GENERAL_APPLICATION_REQUEST}edit/`
export const GENERAL_APPLICATION_REQUEST_UPDATE = `${GENERAL_APPLICATION_REQUEST}update`

export const GENERAL_APPLICATION_SIGNUP = `${GENERAL_APPLICATION_FORM}signup`
export const GENERAL_APPLICATION_SMS_SEND = `${GENERAL_APPLICATION_FORM}sms/send`

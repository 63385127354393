import { toCamelCase } from '../../../helpers/toCamelCase'

export const CHAT_MESSAGE_LIST = 'CHAT_MESSAGE_LIST'
export const CHAT_MESSAGE_POST = 'CHAT_MESSAGE_POST'
export const CHAT_MESSAGE_ALL = 'CHAT_MESSAGE_ALL'

export const GLOBAL_STATE = 'Chat'

export const actionNames = {
  [toCamelCase(CHAT_MESSAGE_LIST)]: CHAT_MESSAGE_LIST,
  [toCamelCase(CHAT_MESSAGE_POST)]: CHAT_MESSAGE_POST,
  [toCamelCase(CHAT_MESSAGE_ALL)]: CHAT_MESSAGE_ALL,
}

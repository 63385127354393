import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
  ATTENDANCE_TABLE_STUDENT,
  GLOBAL_STATE,
} from '../constants/state'

export const attendanceGroupSelector = createSelector(path([GLOBAL_STATE, ATTENDANCE_GROUP]), data => data)

export const attendanceTableSelector = createSelector(path([GLOBAL_STATE, ATTENDANCE_TABLE]), data => data)

export const attendanceTableCSVSelector = createSelector(path([GLOBAL_STATE, ATTENDANCE_CSV]), data => data)

export const attendanceSelector = createSelector(path([GLOBAL_STATE, ATTENDANCE]), data => data)

export const attendanceTableStudentSelector = createSelector(
  path([GLOBAL_STATE, ATTENDANCE_TABLE_STUDENT]),
  data => data
)

export const attendanceTableModuleSelector = createSelector(path([GLOBAL_STATE, ATTENDANCE_TABLE_MODULE]), data => data)

export const attendanceSelectors = {
  attendanceGroupSelector,
  attendanceTableSelector,
  attendanceTableCSVSelector,
  attendanceSelector,
  attendanceTableStudentSelector,
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.USER_OPEN_LINK]: createState(actionTypes.USER_OPEN_LINK),
  [STATE.CHECK_USER_OPEN_LINK]: createState(actionTypes.CHECK_USER_OPEN_LINK),
  [STATE.USER_PUBLIC_INFO]: createState(actionTypes.USER_PUBLIC_INFO),
})

export const OPEN_LINK = STATE.GLOBAL_STATE

export default reducer

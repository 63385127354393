import { createAction } from '@helpers/createAction'

export const MODULE_FILE = 'MODULE_FILE'
export const MODULE_FILE_CREATE = 'MODULE_FILE_CREATE'
export const MODULE_FILE_DELETE = 'MODULE_FILE_DELETE'
export const MODULE_FILE_LIST = 'MODULE_FILE_LIST'
export const MODULE_FILE_ALL = 'MODULE_FILE_ALL'

export default {
  [MODULE_FILE]: createAction(MODULE_FILE),
  [MODULE_FILE_CREATE]: createAction(MODULE_FILE_CREATE),
  [MODULE_FILE_DELETE]: createAction(MODULE_FILE_DELETE),
  [MODULE_FILE_LIST]: createAction(MODULE_FILE_LIST),
  [MODULE_FILE_ALL]: createAction(MODULE_FILE_ALL),
}

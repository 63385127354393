import { createAction } from '@helpers/createAction'

export const ATTENDANCE = 'ATTENDANCE'
export const ATTENDANCE_GROUP = 'ATTENDANCE_GROUP'
export const ATTENDANCE_CSV = 'ATTENDANCE_CSV'
export const ATTENDANCE_TABLE = 'ATTENDANCE_TABLE'
export const ATTENDANCE_TABLE_MODULE = 'ATTENDANCE_TABLE_MODULE'
export const ATTENDANCE_TABLE_STUDENT = 'ATTENDANCE_TABLE_STUDENT'

export default {
  [ATTENDANCE]: createAction(ATTENDANCE),
  [ATTENDANCE_GROUP]: createAction(ATTENDANCE_GROUP),
  [ATTENDANCE_CSV]: createAction(ATTENDANCE_CSV),
  [ATTENDANCE_TABLE]: createAction(ATTENDANCE_TABLE),
  [ATTENDANCE_TABLE_STUDENT]: createAction(ATTENDANCE_TABLE_STUDENT),
  [ATTENDANCE_TABLE_MODULE]: createAction(ATTENDANCE_TABLE_MODULE),
}

import { createAction } from '@helpers/createAction'

export const ASSESSMENT_AMENDMENT = 'ASSESSMENT_AMENDMENT'
export const ASSESSMENT_AMENDMENT_CREATE = 'ASSESSMENT_AMENDMENT_CREATE'
export const ASSESSMENT_AMENDMENT_DELETE = 'ASSESSMENT_AMENDMENT_DELETE'
export const ASSESSMENT_AMENDMENT_LIST = 'ASSESSMENT_AMENDMENT_LIST'
export const ASSESSMENT_AMENDMENT_ALL = 'ASSESSMENT_AMENDMENT_ALL'
export const ASSESSMENT_AMENDMENT_PUBLISH = 'ASSESSMENT_AMENDMENT_PUBLISH'
export const ASSESSMENT_MARK_STATUS = 'ASSESSMENT_MARK_STATUS'

export default {
  [ASSESSMENT_AMENDMENT]: createAction(ASSESSMENT_AMENDMENT),
  [ASSESSMENT_AMENDMENT_CREATE]: createAction(ASSESSMENT_AMENDMENT_CREATE),
  [ASSESSMENT_AMENDMENT_DELETE]: createAction(ASSESSMENT_AMENDMENT_DELETE),
  [ASSESSMENT_AMENDMENT_LIST]: createAction(ASSESSMENT_AMENDMENT_LIST),
  [ASSESSMENT_AMENDMENT_ALL]: createAction(ASSESSMENT_AMENDMENT_ALL),
  [ASSESSMENT_AMENDMENT_PUBLISH]: createAction(ASSESSMENT_AMENDMENT_PUBLISH),
  [ASSESSMENT_MARK_STATUS]: createAction(ASSESSMENT_MARK_STATUS),
}

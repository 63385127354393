import { getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

export const report = data => getInstance().get(`${API.REPORT}${data?.id}`, { params: dissoc('id', data) })

export const reportCreate = data => getInstance().post(API.REPORT, data)

export const reportMisconductListCreate = data => getInstance().post(API.REPORT_MISCONDUCT_LIST, data)

export const reportAll = data => getInstance().get(API.REPORT_ALL, { params: data })

export const reportList = data => getInstance().get(API.REPORT_LIST, { params: data })

export const reportFields = data => getInstance().get(API.REPORT_FIELDS, { params: data })

export const reportFieldsFilter = data => getInstance().get(API.REPORT_FIELDS_FILTER, { params: data })

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.LESSON_LIST]: createState(actionTypes.LESSON_LIST),
  [STATE.LESSON_CREATE]: createState(actionTypes.LESSON_CREATE),
  [STATE.LESSON_ALL]: createState(actionTypes.LESSON_ALL),
  [STATE.LESSON]: createState(actionTypes.LESSON),
  [STATE.LESSON_SORTING]: createState(actionTypes.LESSON_SORTING),
  [STATE.LESSON_HOMEWORK_UPLOAD]: createState(actionTypes.LESSON_HOMEWORK_UPLOAD),
  [STATE.LESSON_ASSESSMENT]: createState(actionTypes.LESSON_ASSESSMENT),
  [STATE.LESSON_ASSESSMENT_ALL]: createState(actionTypes.LESSON_ASSESSMENT_ALL),
  [STATE.LESSON_ASSESSMENT_LIST]: createState(actionTypes.LESSON_ASSESSMENT_LIST),
  [STATE.LESSON_MARK_ASSESSMENT]: createState(actionTypes.LESSON_MARK_ASSESSMENT),
})

export const LESSON = STATE.GLOBAL_STATE

export default reducer

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
  GLOBAL_STATE,
} from '../constants/state'

export const calendarEventAllSelector = createSelector(path([GLOBAL_STATE, CALENDAR_EVENT_ALL]), data => data)

export const calendarStudentEventAllSelector = createSelector(
  path([GLOBAL_STATE, CALENDAR_EVENT_LIST_STUDENT]),
  data => data
)

export const calendarEventSelector = createSelector(path([GLOBAL_STATE, CALENDAR_EVENT]), data => data)

export const calendarEventCreateSelector = createSelector(path([GLOBAL_STATE, CALENDAR_EVENT_CREATE]), data => data)

export const calendarEventListSelector = createSelector(path([GLOBAL_STATE, CALENDAR_EVENT_LIST]), data => data)

export const calendarEventSelectors = {
  calendarEventAllSelector,
  calendarStudentEventAllSelector,
  calendarEventSelector,
  calendarEventCreateSelector,
  calendarEventListSelector,
}

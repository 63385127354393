import { ErrorToast } from '@src/components/Toasts'
import axios from 'axios'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'

export const toastError = (e: unknown) => {
  let error = {}

  if (axios.isAxiosError(e)) {
    error = e.response?.data
    toast.error(<ErrorToast text={e.response?.data.message as string} />)
  } else {
    error = (e as Record<string, Error>)?.data
    toast.error(<ErrorToast text={pathOr('Something went wrong', ['data', 'message'], e)} />)
  }
  return error
}

import actionTypes, {
  DEPARTMENT,
  DEPARTMENT_CREATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LIST,
  DEPARTMENT_ALL,
  DEPARTMENT_LABELS,
} from '../constants/actionTypes'

export const department = payload => ({
  type: actionTypes[DEPARTMENT].pending,
  payload,
})

export const departmentCreate = payload => ({
  type: actionTypes[DEPARTMENT_CREATE].pending,
  payload,
})

export const departmentDelete = payload => ({
  type: actionTypes[DEPARTMENT_DELETE].pending,
  payload,
})

export const departmentList = payload => ({
  type: actionTypes[DEPARTMENT_LIST].pending,
  payload,
})

export const departmentListClear = () => ({
  type: actionTypes[DEPARTMENT_LIST].clear,
})

export const departmentAll = payload => ({
  type: actionTypes[DEPARTMENT_ALL].pending,
  payload,
})

export const departmentAllClear = () => ({
  type: actionTypes[DEPARTMENT_ALL].clear,
})

export const departmentLabels = payload => ({
  type: actionTypes[DEPARTMENT_LABELS].pending,
  payload,
})

export const departmentLabelsClear = () => ({
  type: actionTypes[DEPARTMENT_LABELS].clear,
})

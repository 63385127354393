/* eslint-disable */
import { definitions } from '@src/types/schema'
import { any, prop } from 'ramda'
import { useSelector } from 'react-redux'

import { authUserInfo } from '../redux/selector'

export type RolesModule = typeof import('../constants/userRoles')

export type roleConstants = RolesModule[keyof RolesModule]

function useCheckRoles() {
  const userInfo = useSelector(authUserInfo) as definitions['UserInfo']

  /**
   * Returns true if at least one of user roles of the list match the predicate.
   *
   * Returns null if no roles found in userInfo
   *
   * @param predicate array of roles "['ADMIN', 'MODERATOR']", a string, RegExp or a predicate function
   *
   */
  function amI(
    predicate:
      | roleConstants[]
      | roleConstants
      | RegExp
      | ((roles: roleConstants[], roles_map: definitions['UserInfo']['roles_map']) => boolean)
  ) {
    const roles_map = prop('roles_map', userInfo)
    const roles = prop('roles', userInfo) as unknown as roleConstants[]

    if (!roles || !roles.length) return false

    if (!roles_map || !predicate) {
      return null //amI returns null if userInfo data is not present
    }
    if (Array.isArray(predicate)) {
      return any(role => Object.prototype.hasOwnProperty.call(roles_map, role), predicate)
    }
    if (typeof predicate === 'string') {
      return Object.prototype.hasOwnProperty.call(roles_map, predicate)
    }
    if (predicate instanceof RegExp) {
      return any(role => predicate.test(role), roles)
    }
    if (typeof predicate === 'function') {
      return predicate(roles, roles_map)
    }
  }

  return { amI, userInfoLoading: !userInfo }
}

export default useCheckRoles

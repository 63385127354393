import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

export const reducer = combineReducers({
  [STATE.ASSESSMENT_EC]: createState(actionTypes.ASSESSMENT_EC),
  [STATE.ASSESSMENT_EC_UPDATE]: createState(actionTypes.ASSESSMENT_EC_UPDATE),
  [STATE.ASSESSMENT_EC_OUTCOME_REPORT]: createState(actionTypes.ASSESSMENT_EC_OUTCOME_REPORT),
  [STATE.ASSESSMENT_EC_PAPER]: createState(actionTypes.ASSESSMENT_EC_PAPER),
  [STATE.ASSESSMENT_EC_CREATE]: createState(actionTypes.ASSESSMENT_EC_CREATE),
  [STATE.ASSESSMENT_EC_ALL]: createState(actionTypes.ASSESSMENT_EC_ALL),
  [STATE.ASSESSMENT_EC_STATUS]: createState(actionTypes.ASSESSMENT_EC_STATUS),
})

export const ASSESSMENT_EC = STATE.GLOBAL_STATE

export default reducer

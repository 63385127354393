import { SuccessToast } from '@components/Toasts'
import * as ROUTE from '@constants/routes'
import { WatchSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as stateSelectors from '@store/selector'
import { push } from 'connected-react-router'
import { pathOr, prop, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API_APPLICATION from '../api/application'
import * as API_APPLICATION_CATEGORY from '../api/applicationCategory'
import * as API_APPLICATION_REQUEST from '../api/applicationRequest'
import actionTypes, {
  APPLICATION,
  APPLICATION_ALL,
  APPLICATION_CATEGORY,
  APPLICATION_CATEGORY_ALL,
  APPLICATION_CATEGORY_CREATE,
  APPLICATION_CATEGORY_DELETE,
  APPLICATION_CATEGORY_LIST,
  APPLICATION_CREATE,
  APPLICATION_DELETE,
  APPLICATION_FIELDS,
  APPLICATION_FIELD_BATCH,
  APPLICATION_FIELD_BATCH_CREATE,
  APPLICATION_GENERATE,
  APPLICATION_LIST,
  APPLICATION_STATUS,
  APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'
import * as actions from './actions'

function watchApplication() {
  return watchSaga({ action: actionTypes[APPLICATION], api: API_APPLICATION.application })
}

function watchApplicationCreate() {
  return watchSaga({
    action: actionTypes[APPLICATION_CREATE],
    api: API_APPLICATION.applicationCreate,
  })
}

function watchApplicationDelete() {
  return watchSaga({
    action: actionTypes[APPLICATION_DELETE],
    api: API_APPLICATION.applicationDelete,
  })
}

function watchApplicationTypes() {
  return watchSaga({
    action: actionTypes[APPLICATION_TYPES],
    api: API_APPLICATION.applicationTypes,
  })
}

const applicationTypesSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_TYPES],
  api: API_APPLICATION.applicationTypes,
})

const applicationStatusSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_STATUS],
  api: API_APPLICATION.applicationStatus,
})

function* watchApplicationDeleteFulfilled() {
  yield takeEvery(actionTypes[APPLICATION_DELETE].fulfilled, function* () {
    yield put(actions.applicationList())
  })
}

const applicationListSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_LIST],
  api: API_APPLICATION.applicationList,
})

const applicationAllSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_ALL],
  api: API_APPLICATION.applicationAll,
})

function watchApplicationFieldBatch() {
  return watchSaga({
    action: actionTypes[APPLICATION_FIELD_BATCH],
    api: API_APPLICATION.applicationFieldBatch,
  })
}

function watchApplicationFieldBatchCreate() {
  return watchSaga({
    action: actionTypes[APPLICATION_FIELD_BATCH_CREATE],
    api: API_APPLICATION.applicationFieldBatchCreate,
  })
}

function* watchApplicationFieldBatchCreateFulfilled() {
  yield takeEvery(actionTypes[APPLICATION_FIELD_BATCH_CREATE].fulfilled, function* ({ args }) {
    const application = prop('application', args)
    const id = prop('id', application)

    yield put(push(ROUTE.ADMIN_APPLICATION_FORM))
  })
}

function watchApplicationCategory() {
  return watchSaga({
    action: actionTypes[APPLICATION_CATEGORY],
    api: API_APPLICATION_CATEGORY.applicationCategory,
  })
}

function watchApplicationCategoryCreate() {
  return watchSaga({
    action: actionTypes[APPLICATION_CATEGORY_CREATE],
    api: API_APPLICATION_CATEGORY.applicationCategoryCreate,
  })
}

function* watchApplicationCategoryCreateFulfilled() {
  yield takeEvery(actionTypes[APPLICATION_CATEGORY_CREATE].fulfilled, function* () {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    yield put(actions.applicationCategoryList({ ...get }))
  })
}

function watchApplicationCategoryDelete() {
  return watchSaga({
    action: actionTypes[APPLICATION_CATEGORY_DELETE],
    api: API_APPLICATION_CATEGORY.applicationCategoryDelete,
  })
}

function* watchApplicationCategoryFulfilled() {
  yield takeEvery(actionTypes[APPLICATION_CATEGORY_DELETE].fulfilled, function* () {
    yield put(actions.applicationCategoryList())
  })
}

function watchApplicationCategoryList() {
  return watchSaga({
    action: actionTypes[APPLICATION_CATEGORY_LIST],
    api: API_APPLICATION_CATEGORY.applicationCategoryList,
  })
}

function watchApplicationCategoryAll() {
  return watchSaga({
    action: actionTypes[APPLICATION_CATEGORY_ALL],
    api: API_APPLICATION_CATEGORY.applicationCategoryAll,
  })
}

function watchApplicationRequest() {
  return watchSaga({
    action: actionTypes[TYPES.APPLICATION_REQUEST],
    api: API_APPLICATION_REQUEST.applicationRequest,
  })
}

function* watchApplicationRequestFulfilled() {
  yield takeEvery(actionTypes[TYPES.APPLICATION_REQUEST].fulfilled, function* ({ payload, args }) {
    const onFulfilled = propOr(() => {}, 'onFulfilled', args)
    yield onFulfilled(payload)
  })
}

function watchApplicationRequestAll() {
  return watchSaga({
    action: actionTypes[TYPES.APPLICATION_REQUEST_ALL],
    api: API_APPLICATION_REQUEST.applicationRequestAll,
  })
}

function watchApplicationRequestEdit() {
  return watchSaga({
    action: actionTypes[TYPES.APPLICATION_REQUEST_EDIT],
    api: API_APPLICATION_REQUEST.applicationRequestEdit,
  })
}

function watchApplicationRequestUpdate() {
  return watchSaga({
    action: actionTypes[TYPES.APPLICATION_REQUEST_UPDATE],
    api: API_APPLICATION_REQUEST.applicationRequestUpdate,
  })
}

function watchApplicationRequestUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TYPES.APPLICATION_REQUEST_UPDATE],
    shouldDoFulfilledFunction: true,
    message: {
      description: 'Your changes have been saved!',
    },
  })
}

function watchApplicationRequestUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[TYPES.APPLICATION_REQUEST_UPDATE],
    message: {
      description: 'Could not save your changes',
    },
  })
}

const applicationGenerateSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_GENERATE],
  api: API_APPLICATION.applicationGenerate,
})

const applicationFieldsSaga = new WatchSaga({
  actionType: actionTypes[APPLICATION_FIELDS],
  api: API_APPLICATION.applicationFields,
})

export function* admissionSaga() {
  yield all([
    fork(watchApplication),
    fork(watchApplicationCreate),
    fork(watchApplicationDelete),
    fork(watchApplicationDeleteFulfilled),
    fork(applicationListSaga.watch),
    fork(applicationListSaga.watchFulfilled),
    fork(applicationListSaga.watchRejected),
    fork(applicationAllSaga.watch),
    fork(applicationAllSaga.watchFulfilled),
    fork(applicationAllSaga.watchRejected),
    fork(watchApplicationFieldBatch),
    fork(watchApplicationFieldBatchCreate),
    fork(watchApplicationFieldBatchCreateFulfilled),
    fork(watchApplicationCategory),
    fork(watchApplicationCategoryCreate),
    fork(watchApplicationCategoryCreateFulfilled),
    fork(watchApplicationCategoryDelete),
    fork(watchApplicationCategoryFulfilled),
    fork(watchApplicationCategoryList),
    fork(watchApplicationCategoryAll),
    fork(watchApplicationRequest),
    fork(watchApplicationRequestFulfilled),
    fork(watchApplicationRequestAll),
    fork(watchApplicationRequestEdit),
    fork(watchApplicationRequestUpdate),
    fork(watchApplicationRequestUpdateFulfilled),
    fork(watchApplicationRequestUpdateRejected),
    fork(watchApplicationTypes),
    fork(applicationTypesSaga.watch),
    fork(applicationTypesSaga.watchFulfilled),
    fork(applicationTypesSaga.watchRejected),
    fork(applicationStatusSaga.watch),
    fork(applicationStatusSaga.watchFulfilled),
    fork(applicationStatusSaga.watchRejected),
    fork(applicationGenerateSaga.watch),
    fork(applicationGenerateSaga.watchRejected),
    fork(applicationGenerateSaga.watchFulfilled),
    fork(applicationFieldsSaga.watch),
    fork(applicationFieldsSaga.watchRejected),
    fork(applicationFieldsSaga.watchFulfilled),
  ])
}

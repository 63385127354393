import { path, propOr } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, SEMESTER, SEMESTER_ALL, SEMESTER_LIST } from '../constants/state'

export const semesterSelector = createSelector(path([GLOBAL_STATE, SEMESTER]), data => data)

export const semesterListSelector = createSelector(path([GLOBAL_STATE, SEMESTER_LIST]), data => data)

export const semesterAllSelector = createSelector(path([GLOBAL_STATE, SEMESTER_ALL]), data => data)

export const semesterAllOptionsSelector = createSelector(semesterAllSelector, semesterAll => ({
  loading: semesterAll.loading,
  error: semesterAll.error,
  options: Array.isArray(semesterAll.data)
    ? semesterAll.data.map(item => ({ label: propOr('', 'title', item), value: item.id }))
    : [],
}))

export const semesterSelectors = {
  semesterSelector,
  semesterListSelector,
  semesterAllSelector,
  semesterAllOptionsSelector,
}

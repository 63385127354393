import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/redux/store'
import { prop } from 'ramda'

import { AUTH_LOGIN, SIGN_UP, SMS_SEND, TOKEN, USER_INFO, VERIFY_EMAIL } from '../constants/actionTypes'
import { AUTH } from './reducer'

export const authSelector = (state: RootState) => prop(AUTH, state)

export const authToken = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[TOKEN])

export const authUserInfo = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[USER_INFO])

export const authLogin = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[AUTH_LOGIN])

export const authSignUp = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[SIGN_UP])

export const smsSend = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[SMS_SEND])

export const emailVerify = createSelector(authSelector, (state: ReturnType<typeof authSelector>) => state[VERIFY_EMAIL])

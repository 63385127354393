import { createAction } from '@helpers/createAction'

export const GENERAL_APPLICATION = 'GENERAL_APPLICATION'
export const GENERAL_APPLICATION_CREATE = 'GENERAL_APPLICATION_CREATE'
export const GENERAL_APPLICATION_DELETE = 'GENERAL_APPLICATION_DELETE'
export const GENERAL_APPLICATION_LIST = 'GENERAL_APPLICATION_LIST'
export const GENERAL_APPLICATION_ALL = 'GENERAL_APPLICATION_ALL'
export const GENERAL_APPLICATION_TYPES = 'GENERAL_APPLICATION_TYPES'
export const GENERAL_APPLICATION_STATUS = 'GENERAL_APPLICATION_STATUS'

export const GENERAL_APPLICATION_FIELD_BATCH = 'GENERAL_APPLICATION_FIELD_BATCH'
export const GENERAL_APPLICATION_FIELD_BATCH_CREATE = 'GENERAL_APPLICATION_FIELD_BATCH_CREATE'

// category
export const GENERAL_APPLICATION_CATEGORY = 'GENERAL_APPLICATION_CATEGORY'
export const GENERAL_APPLICATION_CATEGORY_CREATE = 'GENERAL_APPLICATION_CATEGORY_CREATE'
export const GENERAL_APPLICATION_CATEGORY_DELETE = 'GENERAL_APPLICATION_CATEGORY_DELETE'
export const GENERAL_APPLICATION_CATEGORY_LIST = 'GENERAL_APPLICATION_CATEGORY_LIST'
export const GENERAL_APPLICATION_CATEGORY_ALL = 'GENERAL_APPLICATION_CATEGORY_ALL'

// request
export const GENERAL_APPLICATION_REQUEST = 'GENERAL_APPLICATION_REQUEST'
export const GENERAL_APPLICATION_REQUEST_ALL = 'GENERAL_APPLICATION_REQUEST_ALL'
export const GENERAL_APPLICATION_REQUEST_EDIT = 'GENERAL_APPLICATION_REQUEST_EDIT'
export const GENERAL_APPLICATION_REQUEST_UPDATE = 'GENERAL_APPLICATION_REQUEST_UPDATE'

export default {
  [GENERAL_APPLICATION]: createAction(GENERAL_APPLICATION),
  [GENERAL_APPLICATION_CREATE]: createAction(GENERAL_APPLICATION_CREATE),
  [GENERAL_APPLICATION_DELETE]: createAction(GENERAL_APPLICATION_DELETE),
  [GENERAL_APPLICATION_STATUS]: createAction(GENERAL_APPLICATION_STATUS),
  [GENERAL_APPLICATION_LIST]: createAction(GENERAL_APPLICATION_LIST),
  [GENERAL_APPLICATION_ALL]: createAction(GENERAL_APPLICATION_ALL),
  [GENERAL_APPLICATION_TYPES]: createAction(GENERAL_APPLICATION_TYPES),
  [GENERAL_APPLICATION_FIELD_BATCH]: createAction(GENERAL_APPLICATION_FIELD_BATCH),
  [GENERAL_APPLICATION_FIELD_BATCH_CREATE]: createAction(GENERAL_APPLICATION_FIELD_BATCH_CREATE),
  [GENERAL_APPLICATION_CATEGORY]: createAction(GENERAL_APPLICATION_CATEGORY),
  [GENERAL_APPLICATION_CATEGORY_CREATE]: createAction(GENERAL_APPLICATION_CATEGORY_CREATE),
  [GENERAL_APPLICATION_CATEGORY_DELETE]: createAction(GENERAL_APPLICATION_CATEGORY_DELETE),
  [GENERAL_APPLICATION_CATEGORY_LIST]: createAction(GENERAL_APPLICATION_CATEGORY_LIST),
  [GENERAL_APPLICATION_CATEGORY_ALL]: createAction(GENERAL_APPLICATION_CATEGORY_ALL),
  [GENERAL_APPLICATION_REQUEST]: createAction(GENERAL_APPLICATION_REQUEST),
  [GENERAL_APPLICATION_REQUEST_ALL]: createAction(GENERAL_APPLICATION_REQUEST_ALL),
  [GENERAL_APPLICATION_REQUEST_EDIT]: createAction(GENERAL_APPLICATION_REQUEST_EDIT),
  [GENERAL_APPLICATION_REQUEST_UPDATE]: createAction(GENERAL_APPLICATION_REQUEST_UPDATE),
}

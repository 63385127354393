import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const enrollment = data =>
  getInstance().get(`${API.ENROLLMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const enrollmentCreate = data => getInstance().post(API.ENROLLMENT, data)

export const enrollmentDelete = data => getInstance().delete(`${API.ENROLLMENT}${prop('id', data)}`)

export const enrollmentList = data =>
  getInstance().get(API.ENROLLMENT, {
    params: data,
  })

export const enrollmentAll = data =>
  getInstance().get(API.ENROLLMENT_ALL, {
    params: data,
  })

export const enrollmentStatus = data => getInstance().put(API.ENROLLMENT_STATUS, data)

export const enrollmentStudentOwn = data =>
  getInstance().get(API.ENROLLMENT_STUDENT_OWN, {
    params: data,
  })

export const enrollmentCourses = data =>
  getInstance().get(API.ENROLLMENT_COURSES, {
    params: data,
  })

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const city = data =>
  getInstance('edulab').get(`${API.CITY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const cityCreate = data => getInstance('edulab').post(API.CITY, data)

export const cityDelete = data => getInstance('edulab').delete(`${API.CITY}${prop('id', data)}`)

export const cityList = data =>
  getInstance('edulab').get(API.CITY, {
    params: data,
  })

export const cityAll = data =>
  getInstance('edulab').get(API.CITY_ALL, {
    params: data,
  })

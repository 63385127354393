import { path } from 'ramda'
import { createSelector } from 'reselect'

import { COUNTRY, COUNTRY_ALL, COUNTRY_CREATE, COUNTRY_LIST, GLOBAL_STATE } from '../constants/state'

export const countrySelector = createSelector(path([GLOBAL_STATE, COUNTRY]), data => data)

export const countryListSelector = createSelector(path([GLOBAL_STATE, COUNTRY_LIST]), data => data)

export const countryAllSelector = createSelector(path([GLOBAL_STATE, COUNTRY_ALL]), data => data)

export const countryCreateSelector = createSelector(path([GLOBAL_STATE, COUNTRY_CREATE]), data => data)

export const countrySelectors = {
  countrySelector,
  countryListSelector,
  countryAllSelector,
  countryCreateSelector,
}

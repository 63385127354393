import { ApiFunc, getInstance } from '@helpers/httpClient'

import { TYPES, TYPES_CHILD } from '../constants/api'

export const types: ApiFunc<`${typeof TYPES}`, 'get'> = data => {
  const variable = 'variable' in data ? data.variable : ''
  const newApi = TYPES.replace('{variable}', variable)
  return getInstance().get(newApi, { params: data })
}

export const typesChild: ApiFunc<`${typeof TYPES_CHILD}`, 'get'> = data => {
  const variable = 'variable' in data ? data.variable : ''
  const child = 'child' in data ? data.child : ''
  const newApi = TYPES.replace('{variable}', variable).replace('{child}', child)
  return getInstance().get(newApi, { params: data })
}

import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { path, pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ACHIEVEMENT_RECORDS,
  ACHIEVEMENT_RECORDS_ALL,
  ACHIEVEMENT_RECORDS_CHANGE_STATUS,
  ACHIEVEMENT_RECORDS_CREATE,
  ACHIEVEMENT_RECORDS_DELETE,
  ACHIEVEMENT_RECORDS_LIST,
  ACHIEVEMENT_RECORDS_STUDENT,
  ACHIEVEMENT_RECORDS_TYPES,
} from '../constants/actionTypes'

function watchAchievementRecordsList() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_LIST],
    api: API.achievementRecordsList,
  })
}

function watchAchievementRecordsListRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_LIST],
    message: true,
  })
}

function watchAchievementRecordsAll() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_ALL],
    api: API.achievementRecordsAll,
  })
}

function watchAchievementRecordsAllRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_ALL],
    message: true,
  })
}

function watchAchievementRecordsTypes() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_TYPES],
    api: API.achievementRecordsTypes,
  })
}

function watchAchievementRecordsTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_TYPES],
    message: true,
  })
}

function watchAchievementRecordsCreate() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_CREATE],
    api: API.achievementRecordsCreate,
  })
}

// function watchAchievementRecordsCreateFulfilled() {
//   return watchSagaFulfilled({
//     action: actionTypes[ACHIEVEMENT_RECORDS_CREATE],
//     message: {
//       title: 'Success',
//       description: 'Successfully created',
//     },
//     shouldCloseModal: true,
//     dispatchActions: actions.achievementRecordsList(),
//   })
// }

function* watchAchievementRecordsCreateFulfilled() {
  yield takeEvery(actionTypes[ACHIEVEMENT_RECORDS_CREATE].fulfilled, function* (payload) {
    const close = pathOr(() => {}, ['args', 'close'], payload)
    close()
    if (path(['args', 'id'], payload)) {
      toast.success(<SuccessToast text='Success' />)
      yield put(
        actions.achievementRecordsStudent({
          id: path(['args', 'studentId'], payload),
        })
      )
    } else yield put(actions.achievementRecordsList())
  })
}

function watchAchievementRecordsCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_CREATE],
    message: true,
  })
}

function watchAchievementRecordsDelete() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_DELETE],
    api: API.achievementRecordsDelete,
  })
}

function watchAchievementRecordsDeleteFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ACHIEVEMENT_RECORDS_DELETE],
    message: {
      title: 'Success',
      description: 'Successfully deleted',
    },
  })
}

function watchAchievementRecordsDeleteRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_DELETE],
    message: true,
  })
}

function watchGetAchievementRecordsById() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS],
    api: API.getAchievementRecordById,
  })
}

function watchGetAchievementRecordsByIdFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ACHIEVEMENT_RECORDS],
    message: {
      title: 'Success',
    },
  })
}

function watchGetAchievementRecordsByIdRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS],
    message: true,
  })
}

function watchAchievementRecordsChangeStatus() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_CHANGE_STATUS],
    api: API.achievementRecordsChangeStatus,
  })
}

function watchAchievementRecordsChangeStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ACHIEVEMENT_RECORDS_CHANGE_STATUS],
    message: {
      title: 'Success',
    },
  })
}

function watchAchievementRecordsChangeStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_CHANGE_STATUS],
    message: true,
  })
}

function watchAchievementRecordsStudent() {
  return watchSaga({
    action: actionTypes[ACHIEVEMENT_RECORDS_STUDENT],
    api: API.achievementRecordsStudent,
  })
}

// function watchAchievementRecordsStudentFulfilled() {
//   return watchSagaFulfilled({
//     action: actionTypes[ACHIEVEMENT_RECORDS_STUDENT],
//     message: {
//       title: 'Success',
//     },
//   })
// }

function watchAchievementRecordsStudentRejected() {
  return watchSagaRejected({
    action: actionTypes[ACHIEVEMENT_RECORDS_STUDENT],
    message: true,
  })
}

export function* achievementRecordsSaga() {
  yield all([
    fork(watchAchievementRecordsList),
    fork(watchAchievementRecordsListRejected),
    fork(watchAchievementRecordsAll),
    fork(watchAchievementRecordsTypes),
    fork(watchAchievementRecordsTypesRejected),
    fork(watchAchievementRecordsAllRejected),
    fork(watchAchievementRecordsCreate),
    fork(watchAchievementRecordsCreateFulfilled),
    fork(watchAchievementRecordsCreateRejected),
    fork(watchAchievementRecordsDelete),
    fork(watchAchievementRecordsDeleteFulfilled),
    fork(watchAchievementRecordsDeleteRejected),
    fork(watchGetAchievementRecordsById),
    fork(watchGetAchievementRecordsByIdFulfilled),
    fork(watchGetAchievementRecordsByIdRejected),
    fork(watchAchievementRecordsChangeStatus),
    fork(watchAchievementRecordsChangeStatusFulfilled),
    fork(watchAchievementRecordsChangeStatusRejected),
    fork(watchAchievementRecordsStudentRejected),
    fork(watchAchievementRecordsStudent),
  ])
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.CITY_LIST]: createState(actionTypes.CITY_LIST),
  [STATE.CITY_CREATE]: createState(actionTypes.CITY_CREATE),
  [STATE.CITY_ALL]: createState(actionTypes.CITY_ALL),
  [STATE.CITY]: createState(actionTypes.CITY),
})

export const CITY = STATE.GLOBAL_STATE

export default reducer

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const stCourseApplicationCategory = data =>
  getInstance().get(`${API.ST_COURSE_APPLICATION_CATEGORY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const stCourseApplicationCategoryCreate = data => getInstance().post(API.ST_COURSE_APPLICATION_CATEGORY, data)

export const stCourseApplicationCategoryDelete = data =>
  getInstance().delete(`${API.ST_COURSE_APPLICATION_CATEGORY}${prop('id', data)}`)

export const stCourseApplicationCategoryList = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_CATEGORY, {
    params: data,
  })

export const stCourseApplicationCategoryAll = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_CATEGORY_ALL, {
    params: data,
  })

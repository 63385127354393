import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const applicationRequest = data => getInstance().post(API.APPLICATION_REQUEST, data)

export const applicationRequestAll = data =>
  getInstance().get(API.APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const applicationRequestEdit = data =>
  getInstance().get(API.APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const applicationRequestUpdate = data => getInstance().put(API.APPLICATION_REQUEST_UPDATE, data)

import actionTypes, {
  ATTENDANCE,
  ATTENDANCE_CSV,
  ATTENDANCE_TABLE_STUDENT,
  ATTENDANCE_GROUP,
  ATTENDANCE_TABLE,
  ATTENDANCE_TABLE_MODULE,
} from '../constants/actionTypes'

export const attendanceCreate = payload => ({
  type: actionTypes[ATTENDANCE].pending,
  payload,
})
export const attendanceGroup = payload => ({
  type: actionTypes[ATTENDANCE_GROUP].pending,
  payload,
})

export const attendanceGroupClear = () => ({
  type: actionTypes[ATTENDANCE_GROUP].clear,
})

export const attendanceTable = payload => ({
  type: actionTypes[ATTENDANCE_TABLE].pending,
  payload,
})

export const attendanceTableStudent = payload => ({
  type: actionTypes[ATTENDANCE_TABLE_STUDENT].pending,
  payload,
})

export const attendanceTableCsv = payload => ({
  type: actionTypes[ATTENDANCE_CSV].pending,
  payload,
})

export const attendanceTableStudentClear = () => ({
  type: actionTypes[ATTENDANCE_TABLE_STUDENT].clear,
})
export const attendanceTableClear = () => ({
  type: actionTypes[ATTENDANCE_TABLE].clear,
})

export const attendanceTableModule = payload => ({
  type: actionTypes[ATTENDANCE_TABLE_MODULE].pending,
  payload,
})

export const attendanceTableModuleClear = () => ({
  type: actionTypes[ATTENDANCE_TABLE_MODULE].clear,
})

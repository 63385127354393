import actionTypes, { MODULES_OUTCOME, MODULES_OUTCOME_LIST, MODULES_OUTCOME_CREATE } from '../constants/actionTypes'

export const modulesOutcome = payload => ({
  type: actionTypes[MODULES_OUTCOME].pending,
  payload,
})

export const modulesOutcomeClear = () => ({
  type: actionTypes[MODULES_OUTCOME].clear,
})

export const modulesOutcomeCreate = payload => ({
  type: actionTypes[MODULES_OUTCOME_CREATE].pending,
  payload,
})

export const modulesOutcomeList = payload => ({
  type: actionTypes[MODULES_OUTCOME_LIST].pending,
  payload,
})

export const modulesOutcomeListClear = () => ({
  type: actionTypes[MODULES_OUTCOME_LIST].clear,
})

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ACADEMIC_YEAR]: createState(actionTypes.ACADEMIC_YEAR),
  [STATE.ACADEMIC_YEAR_LIST]: createState(actionTypes.ACADEMIC_YEAR_LIST),
  [STATE.ACADEMIC_YEAR_ALL]: createState(actionTypes.ACADEMIC_YEAR_ALL),
  [STATE.ACADEMIC_YEAR_CREATE]: createState(actionTypes.ACADEMIC_YEAR_CREATE),
})

export const ACADEMIC_YEARS = STATE.GLOBAL_STATE

export default reducer

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const educationPlace = data =>
  getInstance('edulab').get(`${API.EDUCATION_PLACE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const educationPlaceCreate = data => getInstance('edulab').post(API.EDUCATION_PLACE, data)

export const educationPlaceDelete = data => getInstance('edulab').delete(`${API.EDUCATION_PLACE}${prop('id', data)}`)

export const educationPlaceList = data =>
  getInstance('edulab').get(API.EDUCATION_PLACE, {
    params: data,
  })

export const educationPlaceAll = data =>
  getInstance('edulab').get(API.EDUCATION_PLACE_ALL, {
    params: data,
  })

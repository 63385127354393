import actionTypes, {
  SEMESTER,
  SEMESTER_ALL,
  SEMESTER_CREATE,
  SEMESTER_DELETE,
  SEMESTER_LIST,
} from '../constants/actionTypes'

export const semester = payload => ({
  type: actionTypes[SEMESTER].pending,
  payload,
})

export const semesterCreate = payload => ({
  type: actionTypes[SEMESTER_CREATE].pending,
  payload,
})

export const semesterDelete = payload => ({
  type: actionTypes[SEMESTER_DELETE].pending,
  payload,
})

export const semesterList = payload => ({
  type: actionTypes[SEMESTER_LIST].pending,
  payload,
})

export const semesterListClear = () => ({
  type: actionTypes[SEMESTER_LIST].clear,
})

export const semesterAll = payload => ({
  type: actionTypes[SEMESTER_ALL].pending,
  payload,
})

export const semesterAllClear = () => ({
  type: actionTypes[SEMESTER_ALL].clear,
})

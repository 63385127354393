import { createAction } from '@helpers/createAction'

export const REPORT = 'REPORT'
export const REPORT_CREATE = 'REPORT_CREATE'
export const REPORT_ALL = 'REPORT_ALL'
export const REPORT_LIST = 'REPORT_LIST'
export const REPORT_FIELDS = 'REPORT_FIELDS'
export const REPORT_FIELDS_FILTER = 'REPORT_FIELDS_FILTER'

export default {
  [REPORT]: createAction(REPORT),
  [REPORT_CREATE]: createAction(REPORT_CREATE),
  [REPORT_ALL]: createAction(REPORT_ALL),
  [REPORT_LIST]: createAction(REPORT_LIST),
  [REPORT_FIELDS]: createAction(REPORT_FIELDS),
  [REPORT_FIELDS_FILTER]: createAction(REPORT_FIELDS_FILTER),
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { CHECK_USER_OPEN_LINK, GLOBAL_STATE, USER_OPEN_LINK, USER_PUBLIC_INFO } from '../constants/state'

export const generatedLinkSelector = createSelector(path([GLOBAL_STATE, USER_OPEN_LINK]), data => data)

export const checkOpenLinkSelector = createSelector(path([GLOBAL_STATE, CHECK_USER_OPEN_LINK]), data => data)

export const userPublicInfoSelector = createSelector(path([GLOBAL_STATE, USER_PUBLIC_INFO]), data => data)

export const openLinkSelectors = {
  generatedLinkSelector,
  checkOpenLinkSelector,
  userPublicInfoSelector,
}

import actionTypes, { STAFF, STAFF_ALL, STAFF_DELETE, STAFF_LIST, STAFF_USERNAME } from '../constants/actionTypes'

export const staff = payload => ({
  type: actionTypes[STAFF].pending,
  payload,
})

export const staffDelete = payload => ({
  type: actionTypes[STAFF_DELETE].pending,
  payload,
})

export const staffList = payload => ({
  type: actionTypes[STAFF_LIST].pending,
  payload,
})

export const staffListClear = () => ({
  type: actionTypes[STAFF_LIST].clear,
})

export const staffAll = payload => ({
  type: actionTypes[STAFF_ALL].pending,
  payload,
})

export const staffAllClear = () => ({
  type: actionTypes[STAFF_ALL].clear,
})

export const staffUsername = payload => ({
  type: actionTypes[STAFF_USERNAME].pending,
  payload,
})

export const staffUsernameClear = () => ({
  type: actionTypes[STAFF_USERNAME].clear,
})

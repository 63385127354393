import actionTypes, { ROLE, ROLE_CREATE, ROLE_DELETE, ROLE_LIST, ROLE_ALL } from '../constants/actionTypes'

export const role = payload => ({
  type: actionTypes[ROLE].pending,
  payload,
})

export const roleCreate = payload => ({
  type: actionTypes[ROLE_CREATE].pending,
  payload,
})

export const roleDelete = payload => ({
  type: actionTypes[ROLE_DELETE].pending,
  payload,
})

export const roleList = payload => ({
  type: actionTypes[ROLE_LIST].pending,
  payload,
})

export const roleListClear = () => ({
  type: actionTypes[ROLE_LIST].clear,
})

export const roleAll = payload => ({
  type: actionTypes[ROLE_ALL].pending,
  payload,
})

export const roleAllClear = () => ({
  type: actionTypes[ROLE_ALL].clear,
})

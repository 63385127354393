import { getInstance } from '@helpers/httpClient'
import { prop } from 'ramda'

import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_FORM,
  FEEDBACK_FORM_ALL,
  FEEDBACK_FORM_CREATE,
  FEEDBACK_FORM_DELETE,
  FEEDBACK_FORM_LIST,
  FEEDBACK_LIST,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
} from '../constants/actionTypes'
import * as API from '../constants/api'

const feedbackAll = data => getInstance().get(API.FEEDBACK_ALL, { params: data })

const feedbackList = data => getInstance().get(API.FEEDBACK, { params: data })

const feedbackCreate = data => getInstance().post(API.FEEDBACK, data)

const feedbackTypes = data => getInstance().get(API.FEEDBACK_TYPES, { params: data })

const feedback = data => getInstance().get(API.FEEDBACK_EDIT, { params: data })

const feedbackUpdate = data => getInstance().put(API.FEEDBACK_UPDATE, data)

const feedbackForm = data => getInstance().get(`${API.FEEDBACK_FORM}${prop('id', data)}`, { params: data })

const feedbackFormAll = data => getInstance().get(API.FEEDBACK_FORM_ALL, { params: data })

const FeedbackFormList = data => getInstance().get(API.FEEDBACK_FORM, { params: data })

const feedbackFormCreate = data => getInstance().post(API.FEEDBACK_FORM, data)

const feedbackFormDelete = data => getInstance().delete(`${API.FEEDBACK_FORM}${prop('id', data)}`)

export default {
  [FEEDBACK_ALL]: feedbackAll,
  [FEEDBACK_CREATE]: feedbackCreate,
  [FEEDBACK_EDIT]: feedback,
  [FEEDBACK_LIST]: feedbackList,
  [FEEDBACK_TYPES]: feedbackTypes,
  [FEEDBACK_UPDATE]: feedbackUpdate,

  [FEEDBACK_FORM]: feedbackForm,
  [FEEDBACK_FORM_LIST]: FeedbackFormList,
  [FEEDBACK_FORM_ALL]: feedbackFormAll,
  [FEEDBACK_FORM_CREATE]: feedbackFormCreate,
  [FEEDBACK_FORM_DELETE]: feedbackFormDelete,
}

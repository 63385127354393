import { createAsyncThunk } from '@reduxjs/toolkit'
import { PayloadCreatorParams } from '@src/redux/types'
import axios from 'axios'
import { omit } from 'ramda'
import { toast } from 'react-toastify'

import {
  stc,
  stcAll,
  stcCategory,
  stcCategoryAll,
  stcCategoryCreate,
  stcCategoryList,
  stcCreate,
  stcList,
} from '../api/stcOrigin'
import { stcStep, stcStepAll, stcStepComplete, stcStepCreate, stcStepList } from '../api/stcStep'
import {
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_COMPLETE,
  STC_STEP_CREATE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

const toastError = (e: unknown) => {
  let error = {}
  if (axios.isAxiosError(e)) {
    error = e.response?.data
    toast.error(e.response?.data.message as string, { style: { wordBreak: 'break-word' } })
  } else {
    console.error(e)
  }
  return error
}

export const stcAction = createAsyncThunk(
  STC,
  async (params: PayloadCreatorParams<`/${typeof API.STC}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stc(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcListAction = createAsyncThunk(
  STC_LIST,
  async (params: PayloadCreatorParams<`/${typeof API.STC}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcAllAction = createAsyncThunk(
  STC_ALL,
  async (params: PayloadCreatorParams<`/${typeof API.STC_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCreateAction = createAsyncThunk(
  STC_CREATE,
  async (params: PayloadCreatorParams<`/${typeof API.STC}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await stcCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryAction = createAsyncThunk(
  STC_CATEGORY,
  async (params: PayloadCreatorParams<`/${typeof API.STC_CATEGORY}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcCategory(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryAllAction = createAsyncThunk(
  STC_CATEGORY_ALL,
  async (params: PayloadCreatorParams<`/${typeof API.STC_CATEGORY_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcCategoryAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryListAction = createAsyncThunk(
  STC_CATEGORY_LIST,
  async (params: PayloadCreatorParams<`/${typeof API.STC_CATEGORY}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcCategoryList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcCategoryCreateAction = createAsyncThunk(
  STC_CATEGORY_CREATE,
  async (params: PayloadCreatorParams<`/${typeof API.STC_CATEGORY}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await stcCategoryCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepAction = createAsyncThunk(
  STC_STEP,
  async (params: PayloadCreatorParams<`/${typeof API.STC_STEP}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcStep(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepAllAction = createAsyncThunk(
  STC_STEP_ALL,
  async (params: PayloadCreatorParams<`/${typeof API.STC_STEP_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcStepAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepListAction = createAsyncThunk(
  STC_STEP_LIST,
  async (params: PayloadCreatorParams<`/${typeof API.STC_STEP}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await stcStepList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepCreateAction = createAsyncThunk(
  STC_STEP_CREATE,
  async (params: PayloadCreatorParams<`/${typeof API.STC_STEP}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await stcStepCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const stcStepCompleteAction = createAsyncThunk(
  STC_STEP_COMPLETE,
  async (params: PayloadCreatorParams<`/${typeof API.STC_STEP_COMPLETE}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await stcStepComplete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

import { useCallback } from 'react'

export type RefItem<T> = ((element: T | null) => void) | React.MutableRefObject<T | null> | null | undefined

export function useMergeRef<T>(...args: RefItem<T>[]) {
  const refCallback = useCallback((element: T | null) => {
    args.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(element)
      } else {
        ref.current = element
      }
    })
  }, args)

  return refCallback
}

export function combineRefs<T>(...args: RefItem<T>[]) {
  return (element: T | null) =>
    args.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(element)
      } else {
        ref.current = element
      }
    })
}

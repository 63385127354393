import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ASSESSMENT_OFFENCE]: createState(actionTypes.ASSESSMENT_OFFENCE),
  [STATE.ASSESSMENT_OFFENCE_ALL]: createState(actionTypes.ASSESSMENT_OFFENCE_ALL),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION]: createState(actionTypes.ASSESSMENT_OFFENCE_INVIGILATION),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION_ALL]: createState(actionTypes.ASSESSMENT_OFFENCE_INVIGILATION_ALL),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS]: createState(
    actionTypes.ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS
  ),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL]: createState(
    actionTypes.ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL
  ),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION_MINUTES]: createState(actionTypes.ASSESSMENT_OFFENCE_INVIGILATION_MINUTES),
  [STATE.ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL]: createState(
    actionTypes.ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL
  ),
  [STATE.ASSESSMENT_OFFENCE_TYPE]: createState(actionTypes.ASSESSMENT_OFFENCE_TYPE),
  [STATE.ASSESSMENT_OFFENCE_TYPE_ALL]: createState(actionTypes.ASSESSMENT_OFFENCE_TYPE_ALL),
  [STATE.ASSESSMENT_OFFENCE_CHANGE_STATUS]: createState(actionTypes.ASSESSMENT_OFFENCE_CHANGE_STATUS),
  [STATE.ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS]: createState(
    actionTypes.ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS
  ),
  [STATE.ASSESSMENT_OFFENCE_REPORT_ALL]: createState(actionTypes.ASSESSMENT_OFFENCE_REPORT_ALL),
  [STATE.ASSESSMENT_OFFENCE_REPORT_LIST]: createState(actionTypes.ASSESSMENT_OFFENCE_REPORT_LIST),
  [STATE.ASSESSMENT_OFFENCE_REPORT_CREATE]: createState(actionTypes.ASSESSMENT_OFFENCE_REPORT_CREATE),
  [STATE.REPORT_MISCONDUCT]: createState(actionTypes.REPORT_MISCONDUCT),
})

export const ASSESSMENT_OFFENCE = STATE.GLOBAL_STATE

export default reducer

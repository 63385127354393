import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.MODULES_OUTCOME]: createState(actionTypes.MODULES_OUTCOME),
  [STATE.MODULES_OUTCOME_CREATE]: createState(actionTypes.MODULES_OUTCOME_CREATE),
  [STATE.MODULES_OUTCOME_LIST]: createState(actionTypes.MODULES_OUTCOME_LIST),
})

export const MODULES_OUTCOME = STATE.GLOBAL_STATE

export default reducer

import { getDataFromState } from '@helpers/get'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const applicationSelector = createSelector(getDataFromState(STATE.APPLICATION, STATE.GLOBAL_STATE), data => data)

export const applicationListSelector = createSelector(
  getDataFromState(STATE.APPLICATION_LIST, STATE.GLOBAL_STATE),
  data => data
)

export const applicationTypesSelector = createSelector(
  getDataFromState(STATE.APPLICATION_TYPES, STATE.GLOBAL_STATE),
  data => data
)

export const applicationStatusSelector = createSelector(
  getDataFromState(STATE.APPLICATION_STATUS, STATE.GLOBAL_STATE),
  data => data
)

export const applicationAllSelector = createSelector(
  getDataFromState(STATE.APPLICATION_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const applicationFieldBatchSelector = createSelector(
  getDataFromState(STATE.APPLICATION_FIELD_BATCH, STATE.GLOBAL_STATE),
  data => data
)

// category
export const applicationCategorySelector = createSelector(
  getDataFromState(STATE.APPLICATION_CATEGORY, STATE.GLOBAL_STATE),
  data => data
)
export const applicationCategoryListSelector = createSelector(
  getDataFromState(STATE.APPLICATION_CATEGORY_LIST, STATE.GLOBAL_STATE),
  data => data
)
export const applicationCategoryAllSelector = createSelector(
  getDataFromState(STATE.APPLICATION_CATEGORY_ALL, STATE.GLOBAL_STATE),
  data => data
)

// request
export const applicationRequestAll = createSelector(
  getDataFromState(STATE.APPLICATION_REQUEST_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const applicationRequestEdit = createSelector(
  getDataFromState(STATE.APPLICATION_REQUEST_EDIT, STATE.GLOBAL_STATE),
  data => data
)

export const applicationRequestUpdate = createSelector(
  getDataFromState(STATE.APPLICATION_REQUEST_UPDATE, STATE.GLOBAL_STATE),
  data => data
)

export const applicationFieldsSelector = createSelector(prop(STATE.GLOBAL_STATE), prop(STATE.APPLICATION_FIELDS))

export const applicationGenerateSelector = createSelector(prop(STATE.GLOBAL_STATE), prop(STATE.APPLICATION_GENERATE))

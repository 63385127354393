import * as GLOBAL_STATE from '@constants/state'
import { path, prop } from 'ramda'
import { createSelector } from 'reselect'

import { RootState } from './store'

export * from '@module/Auth/redux/selector'
export * from '@module/Account/redux/selector'
export * from '@module/Roles/redux/selector'
export * from '@module/AcademicYears/redux/selector'
export * from '@module/Lesson/redux/selector'
export * from '@module/Course/activate/redux/selector'
export * from '@module/QualityAssurance/redux/level/selector'
export * from '@module/Semester/redux/selector'
export * from '@module/Modules/activate/redux/selector'
export * from '@module/Modules/create/redux/selector'
export * from '@module/Calendar/redux/selector'
export * from '@module/Groups/redux/selector'
export * from '@module/Staff/redux/selector'
export * from '@module/Course/create/redux/selector'
export * from '@module/Student/redux/selector'
export * from '@module/Enrollment/redux/selector'
export * from '@module/Assessment/redux/selector'
export * from '@module/AssessmentMarks/redux/selector'
export * from '@module/AcademicMisconduct/redux/selector'
export * from '@module/AssessmentEC/redux/selector'
export * from '@module/Reports/redux/selector'
export * from '@module/ModuleOutcome/redux/selector'
export * from '@module/Files/ModuleFiles/redux/selector'
export * from '@module/AchievementRecords/redux/selector'
export * from '@module/Attendance/redux/selector'
export * from '@module/StudentDashboard/redux/selector'
export * from '@module/Forms/redux/selector'
export * from '@module/OpenLink/redux/selector'
export * from '@module/Player/redux/selector'
export * from '@module/IntroVideos/redux/selector'
export * from '@module/Departments/redux/selector'
export * from '@module/ShortTermCourseRegistration/redux/selector'
export * from '@module/HRAdmission/redux/selector'
export * from '@module/City/redux/selector'
export * from '@module/Country/redux/selector'
export * from '@module/State/redux/selector'
export * from '@module/ComposeEmail/redux/selector'
export * from '@module/ColorPalette/redux/selector'
export * from '@module/ShortCourseAdmission/redux/selector'
export * from '@module/StudentAdmission/redux/selector'
export * from '@module/GeneralAdmission/redux/selector'
export * from '@module/EducationPlace/redux/selector'
export * from '@module/Meals/redux/selector'
export * from '@module/ShortTermCourse/redux/selectors'
export * from '@module/Shop/redux/selector'
export * from '@module/Timetable/redux/selectors'
export { selectors as categorySelectors } from '@module/Category/redux/reduxEssentials'
export { selectors as eLibrarySelectors } from '@module/Library/redux/reduxEssentials'
export { selectors as jobPositionSelectors } from '@module/JobPosition/redux/reduxEssentials'
export { selectors as vacancyPositionSelectors } from '@module/VacancyPosition/redux/reduxEssentials'
export { selectors as guestUsersSelectors } from '@module/GuestUsers/redux/reduxEssentials'
export { statsSelector } from '@module/Stats/redux/selectors'
export { selectors as contractSelectors } from '@module/ContractInformation/redux/reduxEssentials'
export * from '@module/Types/redux/selector'
export { selectors as feedbackSelectors } from '@module/Feedback/redux/reduxEssentials'
export { selectors as inquirySelectors } from '@module/Inquiry/redux/reduxEssentials'
export { selectors as chatSelectors } from '@module/Chat/redux/reduxEssentials'

export const skinSelector = createSelector(path([GLOBAL_STATE.LAYOUT, 'skin']), data => {
  try {
    return data || JSON.parse(localStorage.getItem('skin') || JSON.stringify('light'))
  } catch (error) {}
})

export const routerSelector = createSelector(
  (store: RootState) => ({
    location: store.router.location,
    pathname: prop('pathname', store.router.location),
    query: prop('query', store.router.location),
    state: prop('state', store.router.location),
  }),
  data => data
)

export const querySearchSelector = createSelector(routerSelector, selected => selected.query.search)

export const querySizeSelector = createSelector(routerSelector, selected => selected.query.size)

export const queryPageSelector = createSelector(routerSelector, selected => selected.query.page)

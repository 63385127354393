import { watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  EDUCATION_PLACE,
  EDUCATION_PLACE_ALL,
  EDUCATION_PLACE_CREATE,
  EDUCATION_PLACE_DELETE,
  EDUCATION_PLACE_LIST,
} from '../constants/actionTypes'
import * as actions from './actions'

function watchEducationPlace() {
  return watchSaga({
    action: actionTypes[EDUCATION_PLACE],
    api: API.educationPlace,
  })
}

function watchEducationPlaceCreate() {
  return watchSaga({
    action: actionTypes[EDUCATION_PLACE_CREATE],
    api: API.educationPlaceCreate,
  })
}

function watchEducationPlaceCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[EDUCATION_PLACE_CREATE],
    message: {
      title: 'Success',
      description: 'EducationPlace has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchEducationPlaceDelete() {
  return watchSaga({
    action: actionTypes[EDUCATION_PLACE_DELETE],
    api: API.educationPlaceDelete,
  })
}

function* watchEducationPlaceDeleteFulfilled() {
  yield takeEvery(actionTypes[EDUCATION_PLACE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.educationPlaceList())
  })
}

function* watchEducationPlaceDeleteRejected() {
  yield takeEvery(actionTypes[EDUCATION_PLACE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchEducationPlaceList() {
  return watchSaga({
    action: actionTypes[EDUCATION_PLACE_LIST],
    api: API.educationPlaceList,
  })
}

function watchEducationPlaceAll() {
  return watchSaga({
    action: actionTypes[EDUCATION_PLACE_ALL],
    api: API.educationPlaceAll,
  })
}

export function* educationPlaceSaga() {
  yield all([
    fork(watchEducationPlace),
    fork(watchEducationPlaceCreate),
    fork(watchEducationPlaceCreateFulfilled),
    fork(watchEducationPlaceDelete),
    fork(watchEducationPlaceDeleteFulfilled),
    fork(watchEducationPlaceDeleteRejected),
    fork(watchEducationPlaceList),
    fork(watchEducationPlaceAll),
  ])
}

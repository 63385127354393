import createCSV from '@helpers/createCSV'
import { WatchSaga, watchSaga } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { dissoc, map, pathOr, pipe, prop } from 'ramda'
import { jsonToCSV } from 'react-papaparse'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../../create/api'
import actionTypes, { COURSE, COURSE_ALL, COURSE_CREATE, COURSE_DELETE, COURSE_LIST } from '../constants/actionTypes'

function watchCourse() {
  return watchSaga({
    action: actionTypes[COURSE],
    api: API.course,
  })
}

function watchCourseCreate() {
  return watchSaga({
    action: actionTypes[COURSE_CREATE],
    api: API.courseCreate,
  })
}

function* watchCourseCreateFulfilled() {
  yield takeEvery(actionTypes[COURSE_CREATE].fulfilled, function* () {
    yield put(actions.courseList())
  })
}

function watchCourseDelete() {
  return watchSaga({
    action: actionTypes[COURSE_DELETE],
    api: API.courseDelete,
  })
}

function* watchCourseDeleteFulfilled() {
  yield takeEvery(actionTypes[COURSE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.courseList())
  })
}

function* watchCourseDeleteRejected() {
  yield takeEvery(actionTypes[COURSE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCourseList() {
  return watchSaga({
    action: actionTypes[COURSE_LIST],
    api: API.courseList,
  })
}

const courseAllSaga = new WatchSaga({ actionType: actionTypes[COURSE_ALL], api: API.courseAll })

export function* courseCreateSaga() {
  yield all([
    fork(watchCourse),
    fork(watchCourseCreate),
    fork(watchCourseCreateFulfilled),
    fork(watchCourseDelete),
    fork(watchCourseDeleteFulfilled),
    fork(watchCourseDeleteRejected),
    fork(watchCourseList),
    fork(courseAllSaga.watch),
    fork(courseAllSaga.watchFulfilled),
    fork(courseAllSaga.watchRejected),
  ])
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.FORM]: createState(actionTypes.FORM),
  [STATE.FORM_LIST]: createState(actionTypes.FORM_LIST),
  [STATE.FORM_ALL]: createState(actionTypes.FORM_ALL),
  [STATE.FORM_FIELD_BATCH]: createState(actionTypes.FORM_FIELD_BATCH),
  [STATE.FORM_ACTION_TYPE]: createState(actionTypes.FORM_ACTION_TYPE),
  [STATE.FORM_TYPE]: createState(actionTypes.FORM_TYPE),
  [STATE.FORM_TYPE_LIST]: createState(actionTypes.FORM_TYPE_LIST),
  [STATE.FORM_TYPE_ALL]: createState(actionTypes.FORM_TYPE_ALL),
  [STATE.FORM_REQUEST]: createState(actionTypes.FORM_REQUEST),
  [STATE.FORM_REQUEST_ITEM]: createState(actionTypes.FORM_REQUEST_ITEM),
  [STATE.FORM_REQUEST_STATUS]: createState(actionTypes.FORM_REQUEST_CHANGE_STATUS),
  [STATE.FORM_REQUEST_CREATE]: createState(actionTypes.FORM_REQUEST_CREATE),
  [STATE.FORM_REQUEST_LIST]: createState(actionTypes.FORM_REQUEST_LIST),
  [STATE.FORM_REQUEST_ALL]: createState(actionTypes.FORM_REQUEST_ALL),
  [STATE.FORM_REQUEST_FIELDS]: createState(actionTypes.FORM_REQUEST_FIELDS),
  [STATE.FORM_REQUEST_CERTIFICATE]: createState(actionTypes.FORM_REQUEST_CERTIFICATE),
})

export const FORM = STATE.GLOBAL_STATE

export default reducer

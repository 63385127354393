import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as actions from '@store/actions'
import * as selectors from '@store/selector'
import { dissoc, pathOr, prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_DELETE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_AMENDMENT_PUBLISH,
  ASSESSMENT_MARK_STATUS,
} from '../constants/actionTypes'

function watchAssessmentAmendment() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT],
    api: API.assessmentAmendment,
  })
}

function watchAssessmentAmendmentCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT_CREATE],
    api: API.assessmentAmendmentCreate,
  })
}

function* watchAssessmentAmendmentCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_AMENDMENT_CREATE].fulfilled, function* (payload) {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    callback()

    yield put(
      actions.assessmentList({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )

    toast.success(<SuccessToast text='Amendment created' />)
  })
}

function watchAssessmentAmendmentCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_AMENDMENT_CREATE],
    message: true,
  })
}

function watchAssessmentAmendmentDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT_DELETE],
    api: API.assessmentAmendmentDelete,
  })
}

function watchAssessmentAmendmentList() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT_LIST],
    api: API.assessmentAmendmentList,
  })
}

function watchAssessmentAmendmentAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT_ALL],
    api: API.assessmentAmendmentAll,
  })
}

function watchAssessmentAmendmentPublish() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_AMENDMENT_PUBLISH],
    api: API.assessmentAmendmentPublish,
  })
}

function* watchAssessmentAmendmentPublishFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_AMENDMENT_PUBLISH].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)

    yield put(
      actions.assessmentList({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )

    toast.success(<SuccessToast text='Amendment published to student' />)
  })
}

function watchAssessmentAmendmentPublishRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_AMENDMENT_PUBLISH],
    message: true,
  })
}

function watchAssessmentMarkStatus() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_MARK_STATUS],
    api: API.assessmentMarkChangeStatus,
  })
}

function watchAssessmentMarkStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_MARK_STATUS],
    message: {
      title: 'Mark status has been changed',
    },
    // filter: {
    //   filterAction: actions.assessmentOffence,
    // },
  })
}

function watchAssessmentMarkStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_MARK_STATUS],
    message: true,
  })
}

export function* assessmentMarkSaga() {
  yield all([
    fork(watchAssessmentAmendment),
    fork(watchAssessmentAmendmentCreate),
    fork(watchAssessmentAmendmentCreateFulfilled),
    fork(watchAssessmentAmendmentCreateRejected),
    fork(watchAssessmentAmendmentDelete),
    fork(watchAssessmentAmendmentList),
    fork(watchAssessmentAmendmentAll),
    fork(watchAssessmentAmendmentPublish),
    fork(watchAssessmentAmendmentPublishFulfilled),
    fork(watchAssessmentAmendmentPublishRejected),
    fork(watchAssessmentMarkStatus),
    fork(watchAssessmentMarkStatusFulfilled),
    fork(watchAssessmentMarkStatusRejected),
  ])
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, omit, prop } from 'ramda'
import { CANCEL } from 'redux-saga'

import * as API from '../constants/api'

export const lesson = data =>
  getInstance().get(`${API.LESSON}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const lessonCreate = data => getInstance().post(API.LESSON, data)

export const lessonHomeworkUpload = data => getInstance().post(API.LESSON_HOMEWORK_UPLOAD, data)

export const lessonSorting = data => getInstance().put(API.LESSON_SORTING, data)

export const lessonDelete = data => getInstance().delete(`${API.LESSON}${prop('id', data)}`)

export const lessonList = data =>
  getInstance().get(API.LESSON, {
    params: data,
  })

export const lessonAll = data =>
  getInstance().get(API.LESSON_ALL, {
    params: data,
  })

export const lessonAssessment = data => {
  const cancelToken = data?.axiosSource ? data.axiosSource.token : null
  return getInstance().get(`${API.LESSON_ASSESSMENT}${prop('id', data)}`, {
    params: omit(['axiosSource', 'id'], data),
    cancelToken,
  })
}

export const lessonAssessmentAll = data => {
  const cancelToken = data?.axiosSource ? data.axiosSource.token : null
  const axiosSource = data?.axiosSource ? data.axiosSource : null
  const promise = getInstance().get(API.LESSON_ASSESSMENT_ALL, {
    params: omit(['axiosSource'], data),
    cancelToken,
  })
  promise[CANCEL] = () => axiosSource.cancel('Cancelled request')
  return promise
}

export const lessonMarkAssessment = data => getInstance().post(API.LESSON_ASSESSMENT, data)

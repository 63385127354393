import actionTypes, {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_DELETE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_AMENDMENT_PUBLISH,
  ASSESSMENT_MARK_STATUS,
} from '../constants/actionTypes'

export const assessmentAmendment = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT].pending,
  payload,
})

export const assessmentAmendmentClear = () => ({
  type: actionTypes[ASSESSMENT_AMENDMENT].clear,
})

export const assessmentAmendmentCreate = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_CREATE].pending,
  payload,
})

export const assessmentAmendmentDelete = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_DELETE].pending,
  payload,
})

export const assessmentAmendmentList = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_LIST].pending,
  payload,
})

export const assessmentAmendmentListClear = () => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_LIST].clear,
})

export const assessmentAmendmentAll = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_ALL].pending,
  payload,
})

export const assessmentAmendmentAllClear = () => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_ALL].clear,
})

export const assessmentAmendmentPublish = payload => ({
  type: actionTypes[ASSESSMENT_AMENDMENT_PUBLISH].pending,
  payload,
})

export const assessmentMarkStatusChange = payload => ({
  type: actionTypes[ASSESSMENT_MARK_STATUS].pending,
  payload,
})

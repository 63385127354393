import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import {
  GLOBAL_STATE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_CREATE,
  CCLS_MODULE,
  CCLS_MODULE_ALL,
  CCLS_MODULES_FILTER,
} from '../constants/state'

export const cclsModuleSelector = createSelector(path([GLOBAL_STATE, CCLS_MODULE]), data => data)

export const cclsModuleCreateSelector = createSelector(path([GLOBAL_STATE, CCLS_MODULE_CREATE]), data => data)

export const cclsModuleListSelector = createSelector(path([GLOBAL_STATE, CCLS_MODULE_LIST]), data => data)

export const cclsModuleAllSelector = createSelector(path([GLOBAL_STATE, CCLS_MODULE_ALL]), data => data)

export const cclsModuleAllOptionsSelector = createSelector(cclsModuleAllSelector, cclsModuleAll => ({
  loading: cclsModuleAll.loading,
  error: cclsModuleAll.error,
  options: Array.isArray(cclsModuleAll.data)
    ? cclsModuleAll.data.map(item => ({ label: item.title || '', value: item.id }))
    : [],
}))

export const cclsModulesFilter = createSelector(path([GLOBAL_STATE, CCLS_MODULES_FILTER]), data => data)

export const cclsModuleSelectors = {
  cclsModuleSelector,
  cclsModuleCreateSelector,
  cclsModuleListSelector,
  cclsModuleAllSelector,
  cclsModulesFilter,
  cclsModuleAllOptionsSelector,
}

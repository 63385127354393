import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { equals, path, type } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ASSESSMENT_EC,
  ASSESSMENT_EC_ALL,
  ASSESSMENT_EC_CREATE,
  ASSESSMENT_EC_DELETE,
  ASSESSMENT_EC_OUTCOME_REPORT,
  ASSESSMENT_EC_PAPER,
  ASSESSMENT_EC_STATUS,
  ASSESSMENT_EC_UPDATE,
} from '../constants/actionTypes'

function watchAssessmentEC() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC],
    api: API.extenuatingCircumstances,
  })
}

function watchAssessmentECRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC],
    message: true,
  })
}

function watchAssessmentECChangeStatus() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_STATUS],
    api: API.extenuatingCircumstancesChangeStatus,
  })
}

function watchAssessmentECChangeStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_EC_STATUS],
    message: {
      title: 'Success',
      description: 'Status has been changed',
    },
    dispatchActions: actions.assessmentEC(),
  })
}

function watchAssessmentECChangeStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC_STATUS],
    message: true,
  })
}

function* watchAssessmentECCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_EC_CREATE].fulfilled, function* (payload) {
    yield put(actions.assessmentEC())
    const reset = path(['args', 'reset'], payload)
    const close = path(['args', 'close'], payload)
    equals(type(reset), 'Function') && reset()
    equals(type(close), 'Function') && close()
    toast.success(<SuccessToast text='EC has been created' />)
  })
}

function watchAssessmentECCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC_CREATE],
    message: true,
  })
}

function watchAssessmentECCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_CREATE],
    api: API.extenuatingCircumstancesCreate,
  })
}

function watchAssessmentECPaper() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_PAPER],
    api: API.extenuatingCircumstancesPaper,
  })
}

function watchAssessmentECPaperFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_EC_PAPER],
    message: {
      title: 'Success',
    },
    dispatchActions: actions.assessmentEC(),
    shouldCloseModal: true,
  })
}

function watchAssessmentECPaperRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC_PAPER],
    message: true,
  })
}

function watchAssessmentECUpdate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_UPDATE],
    api: API.extenuatingCircumstancesUpdate,
  })
}

function watchAssessmentECOutcomeReport() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_OUTCOME_REPORT],
    api: API.extenuatingCircumstancesOutcomeReport,
  })
}

function watchAssessmentECOutcomeReportFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_EC_OUTCOME_REPORT],
    message: {
      title: 'Success',
    },
    filter: {
      filterAction: actions.assessmentEC,
    },
  })
}

function watchAssessmentECOutcomeReportRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC_OUTCOME_REPORT],
    message: true,
  })
}

function watchAssessmentECUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_EC_UPDATE],
    message: {
      title: 'Success',
      description: 'EC has been updated',
    },
    dispatchActions: actions.assessmentEC(),
  })
}

function watchAssessmentECUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_EC_UPDATE],
    message: true,
  })
}

function watchAssessmentECDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_DELETE],
    api: API.extenuatingCircumstancesDeleteById,
  })
}

function watchAssessmentECAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_EC_ALL],
    api: API.extenuatingCircumstancesAll,
  })
}

export function* assessmentECSaga() {
  yield all([
    fork(watchAssessmentEC),
    fork(watchAssessmentECRejected),
    fork(watchAssessmentECChangeStatus),
    fork(watchAssessmentECChangeStatusFulfilled),
    fork(watchAssessmentECChangeStatusRejected),
    fork(watchAssessmentECCreateFulfilled),
    fork(watchAssessmentECCreateRejected),
    fork(watchAssessmentECCreate),
    fork(watchAssessmentECPaper),
    fork(watchAssessmentECPaperFulfilled),
    fork(watchAssessmentECPaperRejected),
    fork(watchAssessmentECUpdate),
    fork(watchAssessmentECOutcomeReport),
    fork(watchAssessmentECOutcomeReportFulfilled),
    fork(watchAssessmentECOutcomeReportRejected),
    fork(watchAssessmentECUpdateFulfilled),
    fork(watchAssessmentECUpdateRejected),
    fork(watchAssessmentECDelete),
    fork(watchAssessmentECAll),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  FORM,
  FORM_ACTION_TYPE,
  FORM_ALL,
  FORM_FIELD_BATCH,
  FORM_LIST,
  FORM_REQUEST,
  FORM_REQUEST_ALL,
  FORM_REQUEST_CERTIFICATE,
  FORM_REQUEST_CREATE,
  FORM_REQUEST_FIELDS,
  FORM_REQUEST_ITEM,
  FORM_REQUEST_LIST,
  FORM_REQUEST_STATUS,
  FORM_TYPE,
  FORM_TYPE_ALL,
  FORM_TYPE_LIST,
  GLOBAL_STATE,
} from '../constants/state'

export const formListSelector = createSelector(path([GLOBAL_STATE, FORM_LIST]), data => data)

export const formSelector = createSelector(path([GLOBAL_STATE, FORM]), data => data)

export const formAllSelector = createSelector(path([GLOBAL_STATE, FORM_ALL]), data => data)

export const formBatchSelector = createSelector(path([GLOBAL_STATE, FORM_FIELD_BATCH]), data => data)

export const formActionTypeSelector = createSelector(path([GLOBAL_STATE, FORM_ACTION_TYPE]), data => data)

export const formTypeSelector = createSelector(path([GLOBAL_STATE, FORM_TYPE]), data => data)

export const formTypeListSelector = createSelector(path([GLOBAL_STATE, FORM_TYPE_LIST]), data => data)

export const formTypeAllSelector = createSelector(path([GLOBAL_STATE, FORM_TYPE_ALL]), data => data)

export const formRequestSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST]), data => data)

export const formRequestItemSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_ITEM]), data => data)

export const formRequestStatusSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_STATUS]), data => data)

export const formRequestCreateSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_CREATE]), data => data)

export const formRequestListSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_LIST]), data => data)

export const formRequestAllSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_ALL]), data => data)

export const formRequestFieldsSelector = createSelector(path([GLOBAL_STATE, FORM_REQUEST_FIELDS]), data => data)

export const formRequestCertificateSelector = createSelector(
  path([GLOBAL_STATE, FORM_REQUEST_CERTIFICATE]),
  data => data
)

export const formSelectors = {
  formListSelector,
  formSelector,
  formAllSelector,
  formBatchSelector,
  formActionTypeSelector,
  formTypeSelector,
  formTypeListSelector,
  formTypeAllSelector,
  formRequestSelector,
  formRequestCreateSelector,
  formRequestListSelector,
  formRequestAllSelector,
  formRequestFieldsSelector,
  formRequestCertificateSelector,
  formRequestStatusSelector,
  formRequestItemSelector,
}

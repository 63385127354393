import { has } from 'ramda'

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (has(action.type, handlers)) {
      return handlers[action.type](state, action)
    }
    return state
  }
}

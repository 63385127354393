import actionTypes, {
  LEVEL,
  LEVEL_ALL,
  LEVEL_CREATE,
  LEVEL_DELETE,
  LEVEL_LIST,
} from '../../constants/level/actionTypes'

export const level = payload => ({
  type: actionTypes[LEVEL].pending,
  payload,
})

export const levelCreate = payload => ({
  type: actionTypes[LEVEL_CREATE].pending,
  payload,
})

export const levelDelete = payload => ({
  type: actionTypes[LEVEL_DELETE].pending,
  payload,
})

export const levelList = payload => ({
  type: actionTypes[LEVEL_LIST].pending,
  payload,
})

export const levelListClear = () => ({
  type: actionTypes[LEVEL_LIST].clear,
})

export const levelAll = payload => ({
  type: actionTypes[LEVEL_ALL].pending,
  payload,
})

export const levelAllClear = () => ({
  type: actionTypes[LEVEL_ALL].clear,
})

export const GLOBAL_STATE = 'AssessmentOffence'

export const ASSESSMENT_OFFENCE = 'assessmentOffence'
export const ASSESSMENT_OFFENCE_ALL = 'assessmentOffenceAll'
export const ASSESSMENT_OFFENCE_INVIGILATION = 'assessmentOffenceInvigilation'
export const ASSESSMENT_OFFENCE_INVIGILATION_ALL = 'assessmentOffenceInvigilationAll'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS = 'assessmentOffenceInvigilationConsultations'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL = 'assessmentOffenceInvigilationConsultationsAll'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES = 'assessmentOffenceInvigilationMinutes'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL = 'assessmentOffenceInvigilationMinutesAll'
export const ASSESSMENT_OFFENCE_TYPE = 'assessmentOffenceType'
export const ASSESSMENT_OFFENCE_TYPE_ALL = 'assessmentOffenceTypeAll'
export const ASSESSMENT_OFFENCE_CHANGE_STATUS = 'assessmentOffenceChangeStatus'
export const ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS = 'assessmentOffenceChangeSubjectBoardStatus'
export const ASSESSMENT_OFFENCE_REPORT_ALL = 'assessmentReportAll'
export const ASSESSMENT_OFFENCE_REPORT_LIST = 'assessmentReportList'
export const ASSESSMENT_OFFENCE_REPORT_CREATE = 'assessmentReportCreate'
export const REPORT_MISCONDUCT = 'misconductReport'

import { all, fork } from 'redux-saga/effects'

import { WatchSaga } from '../../../helpers/customSaga'
import api from '../api'
import {
  INQUIRY_COMMENT,
  INQUIRY_COMMENT_CREATE,
  INQUIRY_COMMENT_DELETE,
  INQUIRY_STATUS_TYPES,
  INQUIRY_TYPES,
  RAPORT_INQUIRY_ASSIGNED,
  RAPORT_UPDATE_STATUS,
  REPORT_INQUIRY_ALL,
  REPORT_INQUIRY_CREATE,
  REPORT_INQUIRY_DELETE,
  REPORT_INQUIRY_ITEM,
  REPORT_INQUIRY_LIST,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

const reportInquiryAllSaga = new WatchSaga({
  actionType: actionTypes[REPORT_INQUIRY_ALL],
  api: api[REPORT_INQUIRY_ALL],
})

const reportInquiryCreate = new WatchSaga({
  actionType: actionTypes[REPORT_INQUIRY_CREATE],
  api: api[REPORT_INQUIRY_CREATE],
})

const inquiryListSaga = new WatchSaga({
  actionType: actionTypes[REPORT_INQUIRY_LIST],
  api: api[REPORT_INQUIRY_LIST],
})

const inquiryItemDeleteSaga = new WatchSaga({
  actionType: actionTypes[REPORT_INQUIRY_DELETE],
  api: api[REPORT_INQUIRY_DELETE],
})

const inquiryItemSaga = new WatchSaga({
  actionType: actionTypes[REPORT_INQUIRY_ITEM],
  api: api[REPORT_INQUIRY_ITEM],
})

const inquiryTypes = new WatchSaga({
  actionType: actionTypes[INQUIRY_TYPES],
  api: api[INQUIRY_TYPES],
})

const inquiryStatusTypes = new WatchSaga({
  actionType: actionTypes[INQUIRY_STATUS_TYPES],
  api: api[INQUIRY_STATUS_TYPES],
})

const raportUpdateStatus = new WatchSaga({
  actionType: actionTypes[RAPORT_UPDATE_STATUS],
  api: api[RAPORT_UPDATE_STATUS],
})

const raportInquiryAssigned = new WatchSaga({
  actionType: actionTypes[RAPORT_INQUIRY_ASSIGNED],
  api: api[RAPORT_INQUIRY_ASSIGNED],
})

const inquiryComment = new WatchSaga({
  actionType: actionTypes[INQUIRY_COMMENT],
  api: api[INQUIRY_COMMENT],
})

const inquiryCommentCreate = new WatchSaga({
  actionType: actionTypes[INQUIRY_COMMENT_CREATE],
  api: api[INQUIRY_COMMENT_CREATE],
})

const inquiryCommentDelete = new WatchSaga({
  actionType: actionTypes[INQUIRY_COMMENT_DELETE],
  api: api[INQUIRY_COMMENT_DELETE],
})

export function* reportInquirySagas() {
  yield all([
    fork(reportInquiryAllSaga.watch),
    fork(reportInquiryAllSaga.watchFulfilled),
    fork(reportInquiryAllSaga.watchRejected),

    fork(reportInquiryCreate.watch),
    fork(reportInquiryCreate.watchFulfilled),
    fork(reportInquiryCreate.watchRejected),

    fork(inquiryListSaga.watch),
    fork(inquiryListSaga.watchFulfilled),
    fork(inquiryListSaga.watchRejected),

    fork(inquiryItemDeleteSaga.watch),
    fork(inquiryItemDeleteSaga.watchFulfilled),
    fork(inquiryItemDeleteSaga.watchRejected),

    fork(inquiryItemSaga.watch),
    fork(inquiryItemSaga.watchFulfilled),
    fork(inquiryItemSaga.watchRejected),

    fork(inquiryTypes.watch),
    fork(inquiryTypes.watchFulfilled),
    fork(inquiryTypes.watchRejected),

    fork(inquiryStatusTypes.watch),
    fork(inquiryStatusTypes.watchFulfilled),
    fork(inquiryStatusTypes.watchRejected),

    fork(raportUpdateStatus.watch),
    fork(raportUpdateStatus.watchFulfilled),
    fork(raportUpdateStatus.watchRejected),

    fork(raportInquiryAssigned.watchFulfilled),
    fork(raportInquiryAssigned.watchRejected),
    fork(raportInquiryAssigned.watch),

    fork(inquiryComment.watch),
    fork(inquiryComment.watchFulfilled),
    fork(inquiryComment.watchRejected),

    fork(inquiryCommentCreate.watch),
    fork(inquiryCommentCreate.watchFulfilled),
    fork(inquiryCommentCreate.watchRejected),

    fork(inquiryCommentDelete.watchFulfilled),
    fork(inquiryCommentDelete.watchRejected),
    fork(inquiryCommentDelete.watch),
  ])
}

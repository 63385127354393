import { prop } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import actionTypes, { SET_PLAYER, SET_PLAYER_PIP } from '../constants/actionTypes'

function* watchPlayerSet() {
  yield takeEvery(actionTypes[SET_PLAYER].pending, function* (payload) {
    yield put({
      type: actionTypes[SET_PLAYER].fulfilled,
      payload: prop('payload', payload),
      args: prop('args', payload),
    })
  })
}

function* watchPlayerSetPip() {
  yield takeEvery(actionTypes[SET_PLAYER_PIP].pending, function* (payload) {
    try {
      console.log('payload', payload)
      yield put({
        type: actionTypes[SET_PLAYER_PIP].fulfilled,
        payload: prop('payload', payload),
        args: prop('args', payload),
      })
    } catch (e) {
      console.log('err', e)
    }
  })
}

export function* playerSaga() {
  yield all([fork(watchPlayerSet), fork(watchPlayerSetPip)])
}

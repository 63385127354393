import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../../constants/level/actionTypes'
import * as STATE from '../../constants/level/state'

const reducer = combineReducers({
  [STATE.LEVEL]: createState(actionTypes.LEVEL),
  [STATE.LEVEL_CREATE]: createState(actionTypes.LEVEL_CREATE),
  [STATE.LEVEL_LIST]: createState(actionTypes.LEVEL_LIST),
  [STATE.LEVEL_ALL]: createState(actionTypes.LEVEL_ALL),
})

export const LEVEL = STATE.GLOBAL_STATE

export default reducer

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  MEAL,
  MEAL_ACTIVATION,
  MEAL_ACTIVATION_ALL,
  MEAL_ACTIVATION_DELETE,
  MEAL_ACTIVATION_UPDATE_STATUS,
  MEAL_ALL,
  MEAL_CREATE,
  MEAL_DELETE,
  MEAL_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const meal = createAsyncThunk(
  MEAL,
  async (params: PayloadCreatorParams<`${typeof API.MEAL}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.meal(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealList = createAsyncThunk(
  MEAL_LIST,
  async (params: PayloadCreatorParams<`${typeof API.MEAL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.mealList(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealAll = createAsyncThunk(
  MEAL_ALL,
  async (params: PayloadCreatorParams<`${typeof API.MEAL_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.mealAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealCreate = createAsyncThunk(
  MEAL_CREATE,
  async (params: PayloadCreatorParams<`${typeof API.MEAL}`, 'post'>, { rejectWithValue }) => {
    try {
      const response = await api.mealCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealDelete = createAsyncThunk(
  MEAL_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.MEAL}{ids}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.mealDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealActivation = createAsyncThunk(
  MEAL_ACTIVATION,
  async (params: PayloadCreatorParams<`${typeof API.MEAL_ACTIVATION}{id}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.mealActivation(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealActivationAll = createAsyncThunk(
  MEAL_ACTIVATION_ALL,
  async (params: PayloadCreatorParams<`${typeof API.MEAL_ACTIVATION_ALL}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.mealActivationAll(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const mealActivationCreate = createAsyncThunk(
//   MEAL_ACTIVATION_CREATE,
//   async (params: PayloadCreatorParams<`${typeof API.MEAL_ACTIVATION}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.mealActivationCreate(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const mealActivationDelete = createAsyncThunk(
  MEAL_ACTIVATION_DELETE,
  async (params: PayloadCreatorParams<`${typeof API.MEAL_ACTIVATION}{id}`, 'delete'>, { rejectWithValue }) => {
    try {
      const response = await api.mealActivationDelete(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealActivationUpdateStatus = createAsyncThunk(
  MEAL_ACTIVATION_UPDATE_STATUS,
  async (params: PayloadCreatorParams<`${typeof API.MEAL_ACTIVATION_STATUS}`, 'put'>, { rejectWithValue }) => {
    try {
      const response = await api.mealActivationUpdateStatus(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const mealListClear = createAction(`${MEAL_LIST}/clear`)

export const mealAllClear = createAction(`${MEAL_ALL}/clear`)

export const mealClear = createAction(`${MEAL}/clear`)

export const mealActivationListClear = createAction(`${MEAL_ACTIVATION_ALL}/clear`)

export const mealActivationClear = createAction(`${MEAL_ACTIVATION}/clear`)

import { DefaultState } from '@src/redux/types'

import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  // [TYPES.TIMETABLE]: DefaultState<`${typeof API.TIMETABLE}`, 'get'>
  [TYPES.TIMETABLE_UPDATED]: DefaultState<`${typeof API.TIMETABLE_UPDATED}`, 'get'>
  [TYPES.TIMETABLE_ALL]: DefaultState<`${typeof API.TIMETABLE_ALL}`, 'get'>
  // [TYPES.TIMETABLE_GENERATE]: DefaultState<`${typeof API.TIMETABLE_GENERATE}`, 'post'>
} = () => ({
  // [TYPES.TIMETABLE]: defaultState(),
  [TYPES.TIMETABLE_UPDATED]: defaultState(),
  [TYPES.TIMETABLE_ALL]: defaultState(),
  // [TYPES.TIMETABLE_GENERATE]: defaultState(),
})

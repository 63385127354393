import { ErrorToast, SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr, prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  LESSON,
  LESSON_ALL,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_DELETE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
  LESSON_SORTING,
} from '../constants/actionTypes'
import { lessonAssessmentAllSelector, lessonAssessmentSelector } from './selector'

function watchLesson() {
  return watchSaga({
    action: actionTypes[LESSON],
    api: API.lesson,
  })
}

function watchLessonCreate() {
  return watchSaga({
    action: actionTypes[LESSON_CREATE],
    api: API.lessonCreate,
  })
}

function watchLessonCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[LESSON_CREATE],
    message: {
      title: 'Success',
      description: 'Lesson has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function* watchLessonCreateRejected() {
  yield takeEvery(actionTypes[LESSON_CREATE].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchLessonDelete() {
  return watchSaga({
    action: actionTypes[LESSON_DELETE],
    api: API.lessonDelete,
  })
}

function* watchLessonDeleteFulfilled() {
  yield takeEvery(actionTypes[LESSON_DELETE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchLessonDeleteRejected() {
  yield takeEvery(actionTypes[LESSON_DELETE].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
  })
}

function watchLessonList() {
  return watchSaga({
    action: actionTypes[LESSON_LIST],
    api: API.lessonList,
  })
}

function watchLessonAll() {
  return watchSaga({
    action: actionTypes[LESSON_ALL],
    api: API.lessonAll,
  })
}

function watchLessonSorting() {
  return watchSaga({
    action: actionTypes[LESSON_SORTING],
    api: API.lessonSorting,
  })
}

function* watchLessonSortingFulfilled() {
  yield takeEvery(actionTypes[LESSON_SORTING].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchLessonSortingRejected() {
  yield takeEvery(actionTypes[LESSON_SORTING].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action)
    toast.error(<ErrorToast text={description} />)
  })
}

function watchLessonHomeworkUpload() {
  return watchSaga({
    action: actionTypes[LESSON_HOMEWORK_UPLOAD],
    api: API.lessonHomeworkUpload,
  })
}

function* watchLessonHomeworkUploadFulfilled() {
  yield takeEvery(actionTypes[LESSON_HOMEWORK_UPLOAD].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
    toast.success(<SuccessToast />)
  })
}

function* watchLessonHomeworkUploadRejected() {
  yield takeEvery(actionTypes[LESSON_HOMEWORK_UPLOAD].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
    toast.error(<ErrorToast text={description} />)
  })
}

function watchLessonAssessment() {
  return watchSaga({
    action: actionTypes[LESSON_ASSESSMENT],
    api: API.lessonAssessment,
    selector: lessonAssessmentSelector,
  })
}

function* watchLessonAssessmentFulfilled() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchLessonAssessmentRejected() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
    toast.error(<ErrorToast text={description} />)
  })
}

function* watchLessonAssessmentCancelled() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT].cancel, function* (action) {
    const lessonAssessmentState = yield select(lessonAssessmentSelector)
    const axiosSource = prop('axiosSource', lessonAssessmentState)
    if (axiosSource) {
      axiosSource.cancel('Cancelled')
      const onCancelled = pathOr(() => {}, ['args', 'onCancelled'], action)
      yield onCancelled(action)
    }
  })
}

function watchLessonAssessmentAll() {
  return watchSaga({
    action: actionTypes[LESSON_ASSESSMENT_ALL],
    api: API.lessonAssessmentAll,
    selector: lessonAssessmentAllSelector,
  })
}

function* watchLessonAssessmentAllFulfilled() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT_ALL].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchLessonAssessmentAllRejected() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT_ALL].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
    toast.error(<ErrorToast text={description} />)
  })
}

function* watchLessonAssessmentAllCancelled() {
  yield takeEvery(actionTypes[LESSON_ASSESSMENT_ALL].cancel, function* (action) {
    const onCancelled = pathOr(() => {}, ['args', 'onCancelled'], action)
    yield onCancelled(action)
  })
}

function watchLessonMarkAssessment() {
  return watchSaga({
    action: actionTypes[LESSON_MARK_ASSESSMENT],
    api: API.lessonMarkAssessment,
  })
}

function* watchLessonMarkAssessmentFulfilled() {
  yield takeEvery(actionTypes[LESSON_MARK_ASSESSMENT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
    toast.success(<SuccessToast />)
  })
}

function* watchLessonMarkAssessmentRejected() {
  yield takeEvery(actionTypes[LESSON_MARK_ASSESSMENT].rejected, function* (action) {
    const description = pathOr('', ['payload', 'message'], action)
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
    toast.error(<ErrorToast text={description} />)
  })
}

export function* lessonSaga() {
  yield all([
    fork(watchLesson),
    fork(watchLessonCreate),
    fork(watchLessonCreateFulfilled),
    fork(watchLessonCreateRejected),
    fork(watchLessonDelete),
    fork(watchLessonDeleteFulfilled),
    fork(watchLessonDeleteRejected),
    fork(watchLessonList),
    fork(watchLessonAll),
    fork(watchLessonSorting),
    fork(watchLessonSortingFulfilled),
    fork(watchLessonSortingRejected),
    fork(watchLessonHomeworkUpload),
    fork(watchLessonHomeworkUploadFulfilled),
    fork(watchLessonHomeworkUploadRejected),
    fork(watchLessonAssessment),
    fork(watchLessonAssessmentFulfilled),
    fork(watchLessonAssessmentRejected),
    fork(watchLessonAssessmentCancelled),
    fork(watchLessonAssessmentAll),
    fork(watchLessonAssessmentAllFulfilled),
    fork(watchLessonAssessmentAllRejected),
    fork(watchLessonAssessmentAllCancelled),
    fork(watchLessonMarkAssessment),
    fork(watchLessonMarkAssessmentFulfilled),
    fork(watchLessonMarkAssessmentRejected),
  ])
}

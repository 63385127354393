import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  PRODUCT_CATEGORY_LIST,
  PRODUCT_COLOR_LIST,
  PRODUCT_ITEM,
  PRODUCT_ITEM_ALL_SHORT,
  PRODUCT_ITEM_CLIENT,
  PRODUCT_ITEM_LIST,
  PRODUCT_MATERIAL_LIST,
  PRODUCT_SIZE_LIST,
  PRODUCT_TRADE_BUYER_ALL,
  PRODUCT_TRADE_LIST,
} from '../constants/actionTypes'

const selector = (state: RootState) => prop(GLOBAL_STATE, state)

export const productItemSelector = createSelector(selector, data => data[PRODUCT_ITEM])

export const productItemClientSelector = createSelector(selector, data => data[PRODUCT_ITEM_CLIENT])

export const productItemAllShortSelector = createSelector(selector, data => data[PRODUCT_ITEM_ALL_SHORT])

export const productCategoryListSelector = createSelector(selector, data => data[PRODUCT_CATEGORY_LIST])

export const productColorListSelector = createSelector(selector, data => data[PRODUCT_COLOR_LIST])

export const productItemListSelector = createSelector(selector, data => data[PRODUCT_ITEM_LIST])

export const productMaterialListSelector = createSelector(selector, data => data[PRODUCT_MATERIAL_LIST])

export const productSizeListSelector = createSelector(selector, data => data[PRODUCT_SIZE_LIST])

export const productTradeListSelector = createSelector(selector, data => data[PRODUCT_TRADE_LIST])

export const productTradeBuyerAllSelector = createSelector(selector, data => data[PRODUCT_TRADE_BUYER_ALL])

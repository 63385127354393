import { createAction } from '@helpers/createAction'

export const STATE = 'STATE'
export const STATE_CREATE = 'STATE_CREATE'
export const STATE_DELETE = 'STATE_DELETE'
export const STATE_LIST = 'STATE_LIST'
export const STATE_ALL = 'STATE_ALL'

export default {
  [STATE]: createAction(STATE),
  [STATE_CREATE]: createAction(STATE_CREATE),
  [STATE_DELETE]: createAction(STATE_DELETE),
  [STATE_LIST]: createAction(STATE_LIST),
  [STATE_ALL]: createAction(STATE_ALL),
}

import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import * as TYPES from '../constants/actionTypes'
import * as actions from './actions'
import { initialState } from './initialState'

export const TIMETABLE = 'Timetable'

const timetableSlice = createSlice({
  name: TIMETABLE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    // builder
    //   .addCase(actions.timetable.pending, state => {
    //     state[TYPES.TIMETABLE].loading = true
    //   })
    //   .addCase(actions.timetable.rejected, (state, action) => {
    //     state[TYPES.TIMETABLE].loading = false
    //     state[TYPES.TIMETABLE].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.timetable.fulfilled, (state, action) => {
    //     state[TYPES.TIMETABLE].loading = false
    //     state[TYPES.TIMETABLE].error = null
    //     state[TYPES.TIMETABLE].data = action.payload
    //   })

    builder
      .addCase(actions.timetableUpdated.pending, state => {
        state[TYPES.TIMETABLE_UPDATED].loading = true
      })
      .addCase(actions.timetableUpdated.rejected, (state, action) => {
        state[TYPES.TIMETABLE_UPDATED].loading = false
        state[TYPES.TIMETABLE_UPDATED].error = action.payload as AxiosError
      })
      .addCase(actions.timetableUpdated.fulfilled, (state, action) => {
        state[TYPES.TIMETABLE_UPDATED].loading = false
        state[TYPES.TIMETABLE_UPDATED].error = null
        state[TYPES.TIMETABLE_UPDATED].data = action.payload
      })

    builder
      .addCase(actions.timetableAll.pending, state => {
        state[TYPES.TIMETABLE_ALL].loading = true
      })
      .addCase(actions.timetableAll.rejected, (state, action) => {
        state[TYPES.TIMETABLE_ALL].loading = false
        state[TYPES.TIMETABLE_ALL].error = action.payload as AxiosError
      })
      .addCase(actions.timetableAll.fulfilled, (state, action) => {
        state[TYPES.TIMETABLE_ALL].loading = false
        state[TYPES.TIMETABLE_ALL].error = null
        state[TYPES.TIMETABLE_ALL].data = action.payload
      })

    // builder
    //   .addCase(actions.timetableGenerate.pending, state => {
    //     state[TYPES.TIMETABLE_GENERATE].loading = true
    //   })
    //   .addCase(actions.timetableGenerate.rejected, (state, action) => {
    //     state[TYPES.TIMETABLE_GENERATE].loading = false
    //     state[TYPES.TIMETABLE_GENERATE].error = action.payload as AxiosError
    //   })
    //   .addCase(actions.timetableGenerate.fulfilled, (state, action) => {
    //     state[TYPES.TIMETABLE_GENERATE].loading = false
    //     state[TYPES.TIMETABLE_GENERATE].error = null
    //     state[TYPES.TIMETABLE_GENERATE].data = action.payload
    //   })
  },
})

export const { reducer: timetableReducer } = timetableSlice

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const applicationCategory = data =>
  getInstance().get(`${API.APPLICATION_CATEGORY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const applicationCategoryCreate = data => getInstance().post(API.APPLICATION_CATEGORY, data)

export const applicationCategoryDelete = data => getInstance().delete(`${API.APPLICATION_CATEGORY}${prop('id', data)}`)

export const applicationCategoryList = data =>
  getInstance().get(API.APPLICATION_CATEGORY, {
    params: data,
  })

export const applicationCategoryAll = data =>
  getInstance().get(API.APPLICATION_CATEGORY_ALL, {
    params: data,
  })

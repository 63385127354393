export const FORM = '/form/'
export const FORM_ALL = `${FORM}all`
export const FORM_ACTION_TYPE = `${FORM}action_type/`
export const FORM_FIELD = `${FORM}field/`
export const FORM_FIELD_BATCH = `${FORM_FIELD}batch/`
export const FORM_TYPE = `${FORM}type/`
export const FORM_TYPE_ALL = `${FORM_TYPE}all`
export const FORM_TYPE_ACTIVATE = `${FORM_TYPE}activate/`
export const FORM_REQUEST = `${FORM}request/`
export const FORM_REQUEST_ALL = `${FORM_REQUEST}all`
export const FORM_REQUEST_CHANGE_STATUS = `${FORM_REQUEST}change/status`
export const FORM_REQUEST_FIELDS = `${FORM_REQUEST}fields`
export const FORM_REQUEST_CERTIFICATE = `certificate/`

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import {
  VACANCY_POSITION,
  VACANCY_POSITION_ACTIVATE,
  VACANCY_POSITION_ALL,
  VACANCY_POSITION_CLOSE,
  VACANCY_POSITION_CREATE,
  VACANCY_POSITION_DELETE,
  VACANCY_POSITION_LIST,
  VACANCY_POSITION_OPEN,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const vacancyPositionList = data =>
  getInstance().get(API.VACANCY_POSITION, {
    params: data,
  })

export const vacancyPositionCreate = data => getInstance().post(API.VACANCY_POSITION, data)

export const vacancyPositionDelete = data => getInstance().delete(`${API.VACANCY_POSITION}${prop('id', data)}`)

export const vacancyPositionGetByID = data =>
  getInstance().get(`${API.VACANCY_POSITION}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const vacancyPositionAll = data =>
  getInstance().get(API.VACANCY_POSITION_ALL, {
    params: data,
  })

export const vacancyPositionActivate = data => getInstance().put(`${API.VACANCY_POSITION_ACTIVATE}${prop('id', data)}`)
export const vacancyPositionOpen = data => getInstance().put(`${API.VACANCY_POSITION_OPEN}${prop('id', data)}`)
export const vacancyPositionClose = data => getInstance().put(`${API.VACANCY_POSITION_CLOSE}${prop('id', data)}`)

export default {
  [VACANCY_POSITION_LIST]: vacancyPositionList,
  [VACANCY_POSITION]: vacancyPositionGetByID,
  [VACANCY_POSITION_ALL]: vacancyPositionAll,
  [VACANCY_POSITION_CREATE]: vacancyPositionCreate,
  [VACANCY_POSITION_DELETE]: vacancyPositionDelete,
  [VACANCY_POSITION_ACTIVATE]: vacancyPositionActivate,
  [VACANCY_POSITION_OPEN]: vacancyPositionOpen,
  [VACANCY_POSITION_CLOSE]: vacancyPositionClose,
}

import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const authorizedInstance = getInstance()

export const stc: ApiFunc<`/${typeof API.STC}{id}`, 'get'> = data =>
  authorizedInstance.get(`${API.STC}${'id' in data ? data.id : data.name}`)

export const stcAll: ApiFunc<`/${typeof API.STC_ALL}`, 'get'> = data =>
  authorizedInstance.get(API.STC_ALL, {
    params: data,
  })

export const stcList: ApiFunc<`/${typeof API.STC}`, 'get'> = data =>
  authorizedInstance.get(API.STC, {
    params: data,
  })

export const stcCreate: ApiFunc<`/${typeof API.STC}`, 'post'> = data => authorizedInstance.post(API.STC, data)

export const stcCategory: ApiFunc<`/${typeof API.STC_CATEGORY}{id}`, 'get'> = data =>
  authorizedInstance.get(`${API.STC_CATEGORY}${'id' in data ? data.id : ''}`)

export const stcCategoryAll: ApiFunc<`/${typeof API.STC_CATEGORY_ALL}`, 'get'> = data =>
  authorizedInstance.get(API.STC_CATEGORY_ALL, {
    params: data,
  })

export const stcCategoryList: ApiFunc<`/${typeof API.STC_CATEGORY}`, 'get'> = data =>
  authorizedInstance.get(API.STC_CATEGORY, {
    params: data,
  })

export const stcCategoryCreate: ApiFunc<`/${typeof API.STC_CATEGORY}`, 'post'> = data =>
  authorizedInstance.post(API.STC_CATEGORY, data)

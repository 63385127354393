import { useState } from 'react'
import { dispatchActions } from '../helpers/dispatchActions'

export function useCreateModal(
  onOpen = () => {},
  onClose = () => {},
  dispatchArgs = { actions: [], dispatch: () => {}, fetchParams: {} },
  onOpenWithoutItem = () => {}
) {
  const [createModal, setCreateModal] = useState({
    state: false,
    item: undefined,
  })

  function onCreateOpen(event, item) {
    setCreateModal({
      state: true,
      item,
    })

    onOpenWithoutItem(item)

    if (item) {
      onOpen(item)
    }
    dispatchActions(dispatchArgs)
  }

  function onCreateClose() {
    onClose()
    return setCreateModal(state => ({
      ...state,
      state: false,
    }))
  }

  return {
    createModal,
    onCreateOpen,
    onCreateClose,
  }
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, LEVEL, LEVEL_ALL, LEVEL_CREATE, LEVEL_LIST } from '../../constants/level/state'

export const levelSelector = createSelector(path([GLOBAL_STATE, LEVEL]), data => data)

export const levelListSelector = createSelector(path([GLOBAL_STATE, LEVEL_LIST]), data => data)

export const levelAllSelector = createSelector(path([GLOBAL_STATE, LEVEL_ALL]), data => data)

export const levelCreateSelector = createSelector(path([GLOBAL_STATE, LEVEL_CREATE]), data => data)

export const levelAllOptionsSelector = createSelector(levelAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(item => ({ label: item.title || '', value: item.id }))
      : [],
  }
})

export const levelSelectors = {
  levelSelector,
  levelListSelector,
  levelAllSelector,
  levelCreateSelector,
  levelAllOptionsSelector,
}

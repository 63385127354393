import { createAction } from '@helpers/createAction'

export const LEVEL = 'LEVEL'
export const LEVEL_CREATE = 'LEVEL_CREATE'
export const LEVEL_DELETE = 'LEVEL_DELETE'
export const LEVEL_LIST = 'LEVEL_LIST'
export const LEVEL_ALL = 'LEVEL_ALL'

export default {
  [LEVEL]: createAction(LEVEL),
  [LEVEL_CREATE]: createAction(LEVEL_CREATE),
  [LEVEL_DELETE]: createAction(LEVEL_DELETE),
  [LEVEL_LIST]: createAction(LEVEL_LIST),
  [LEVEL_ALL]: createAction(LEVEL_ALL),
}

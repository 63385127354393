import { createAction } from '@helpers/createAction'

export const ACHIEVEMENT_RECORDS = 'ACHIEVEMENT_RECORDS'
export const ACHIEVEMENT_RECORDS_ALL = 'ACHIEVEMENT_RECORDS_ALL'
export const ACHIEVEMENT_RECORDS_TYPES = 'ACHIEVEMENT_RECORDS_TYPES'
export const ACHIEVEMENT_RECORDS_CHANGE_STATUS = 'ACHIEVEMENT_RECORDS_CHANGE_STATUS'
export const ACHIEVEMENT_RECORDS_STUDENT = 'ACHIEVEMENT_RECORDS_STUDENT'
export const ACHIEVEMENT_RECORDS_LIST = 'ACHIEVEMENT_RECORDS_LIST'
export const ACHIEVEMENT_RECORDS_CREATE = 'ACHIEVEMENT_RECORDS_CREATE'
export const ACHIEVEMENT_RECORDS_DELETE = 'ACHIEVEMENT_RECORDS_DELETE'

export default {
  [ACHIEVEMENT_RECORDS]: createAction(ACHIEVEMENT_RECORDS),
  [ACHIEVEMENT_RECORDS_ALL]: createAction(ACHIEVEMENT_RECORDS_ALL),
  [ACHIEVEMENT_RECORDS_LIST]: createAction(ACHIEVEMENT_RECORDS_LIST),
  [ACHIEVEMENT_RECORDS_TYPES]: createAction(ACHIEVEMENT_RECORDS_TYPES),
  [ACHIEVEMENT_RECORDS_CHANGE_STATUS]: createAction(ACHIEVEMENT_RECORDS_CHANGE_STATUS),
  [ACHIEVEMENT_RECORDS_CREATE]: createAction(ACHIEVEMENT_RECORDS_CREATE),
  [ACHIEVEMENT_RECORDS_STUDENT]: createAction(ACHIEVEMENT_RECORDS_STUDENT),
  [ACHIEVEMENT_RECORDS_DELETE]: createAction(ACHIEVEMENT_RECORDS_DELETE),
}

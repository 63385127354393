export const GLOBAL_STATE = 'HRAdmission'

export const HR_APPLICATION = 'application'
export const HR_APPLICATIONS = 'applications'
export const HR_APPLICATION_LIST = 'applicationList'
export const HR_APPLICATION_ALL = 'applicationAll'
export const HR_APPLICATION_STATUS = 'applicationStatus'
export const HR_APPLICATION_TYPES = 'applicationTypes'

export const HR_APPLICATION_FIELD_BATCH = 'applicationFieldBatch'

// category
export const HR_APPLICATION_CATEGORY = 'category'
export const HR_APPLICATION_CATEGORY_LIST = 'categoryList'
export const HR_APPLICATION_CATEGORY_ALL = 'categoryAll'

// request
export const HR_APPLICATION_REQUEST_ALL = 'requestAll'
export const HR_APPLICATION_REQUEST_EDIT = 'requestEdit'
export const HR_APPLICATION_REQUEST_UPDATE = 'requestUpdate'

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const country = data =>
  getInstance('edulab').get(`${API.COUNTRY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const countryCreate = data => getInstance('edulab').post(API.COUNTRY, data)

export const countryDelete = data => getInstance('edulab').delete(`${API.COUNTRY}${prop('id', data)}`)

export const countryList = data =>
  getInstance('edulab').get(API.COUNTRY, {
    params: data,
  })

export const countryAll = data =>
  getInstance('edulab').get(API.COUNTRY_ALL, {
    params: data,
  })

import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as actions from '@store/actions'
import * as selectors from '@store/selector'
import { dissoc, path, prop } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ASSESSMENT_OFFENCE,
  ASSESSMENT_OFFENCE_ALL,
  ASSESSMENT_OFFENCE_CHANGE_STATUS,
  ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS,
  ASSESSMENT_OFFENCE_CREATE,
  ASSESSMENT_OFFENCE_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION,
  ASSESSMENT_OFFENCE_INVIGILATION_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE,
  ASSESSMENT_OFFENCE_PUBLISH,
  ASSESSMENT_OFFENCE_REPORT_ALL,
  ASSESSMENT_OFFENCE_REPORT_CREATE,
  ASSESSMENT_OFFENCE_REPORT_LIST,
  ASSESSMENT_OFFENCE_TYPE,
  ASSESSMENT_OFFENCE_TYPE_ALL,
  ASSESSMENT_OFFENCE_TYPE_CREATE,
  ASSESSMENT_OFFENCE_TYPE_DELETE,
  ASSESSMENT_OFFENCE_UNPUBLISH,
  REPORT_MISCONDUCT,
} from '../constants/actionTypes'

function watchAssessmentOffence() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE],
    api: API.offence,
  })
}

function watchAssessmentOffenceCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_CREATE],
    api: API.offenceCreate,
  })
}

function* watchAssessmentOffenceCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_CREATE].fulfilled, function* (payload) {
    const { query } = yield select(selectors.routerSelector)
    const close = path(['args', 'close'], payload)
    close()
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    toast.success(<SuccessToast text='Offence has been created' />)
  })
}

function watchAssessmentOffenceCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_CREATE],
    message: true,
  })
}

function watchAssessmentOffenceDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_DELETE],
    api: API.offenceDeleteById,
  })
}

function watchAssessmentOffenceAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_ALL],
    api: API.offenceAll,
  })
}

function watchAssessmentOffencePublish() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_PUBLISH],
    api: API.offencePublish,
  })
}

function* watchAssessmentOffencePublishFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_PUBLISH].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    toast.success(<SuccessToast text='Offence published to student' />)
  })
}

function* watchAssessmentOffenceUnpublishFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_UNPUBLISH].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    toast.success(<SuccessToast text='Offence unpublished to student' />)
  })
}

function watchAssessmentOffenceUnpublish() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_UNPUBLISH],
    api: API.offenceUnpublish,
  })
}

function watchAssessmentOffenceUnpublishRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_UNPUBLISH],
    message: true,
  })
}

function watchAssessmentOffencePublishRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_PUBLISH],
    message: true,
  })
}

function watchAssessmentOffenceInvigilation() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION],
    api: API.offenceInvigilation,
  })
}

function watchAssessmentOffenceInvigilationCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CREATE],
    api: API.offenceInvigilationCreate,
  })
}

function* watchAssessmentOffenceInvigilationCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CREATE].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    // yield put(actions.assessmentOffenceInvigilationAll())
    toast.success(<SuccessToast text='Invigilation reports have been added' />)
  })
}

function watchAssessmentOffenceInvigilationCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CREATE],
    message: true,
  })
}

function watchAssessmentOffenceInvigilationDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_DELETE],
    api: API.offenceInvigilationDeleteById,
  })
}

function watchAssessmentOffenceInvigilationAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_ALL],
    api: API.offenceInvigilationAll,
  })
}

function watchAssessmentOffenceInvigilationConsultations() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS],
    api: API.offenceInvigilationConsultations,
  })
}

function watchAssessmentOffenceInvigilationConsultationsCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE],
    api: API.offenceInvigilationConsultationsCreate,
  })
}

function* watchAssessmentOffenceInvigilationConsultationsCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    // yield put(actions.assessmentOffenceInvigilationConsultationsAll())
    toast.success(<SuccessToast text='Consultation reports have been added' />)
  })
}

function watchAssessmentOffenceInvigilationConsultationsCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE],
    message: true,
  })
}

function watchAssessmentOffenceInvigilationConsultationsDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE],
    api: API.offenceInvigilationConsultationsDeleteById,
  })
}

function watchAssessmentOffenceInvigilationConsultationsAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL],
    api: API.offenceInvigilationConsultationsAll,
  })
}

function watchAssessmentOffenceInvigilationMinutesCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE],
    api: API.offenceInvigilationMinutesCreate,
  })
}

function* watchAssessmentOffenceInvigilationMinutesCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE].fulfilled, function* () {
    const { query } = yield select(selectors.routerSelector)
    const get = getPage(query)
    yield put(
      actions.assessmentOffence({
        assessment_criteria_id: prop('id', get),
        ...dissoc('id', get),
        size: 100,
      })
    )
    // yield put(actions.assessmentOffenceInvigilationMinutesAll())
    toast.success(<SuccessToast text='Success' />)
  })
}

function watchAssessmentOffenceInvigilationMinutesCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE],
    message: true,
  })
}

function watchAssessmentOffenceInvigilationMinutesDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE],
    api: API.offenceInvigilationMinutesDeleteById,
  })
}

function watchAssessmentOffenceInvigilationMinutesAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL],
    api: API.offenceInvigilationMinutesAll,
  })
}

function watchAssessmentOffenceType() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_TYPE],
    api: API.offenceType,
  })
}

function* watchAssessmentOffenceTypeCreateFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_OFFENCE_TYPE_CREATE].fulfilled, function* () {
    yield put(actions.assessmentOffenceTypeAll())
  })
}

function watchAssessmentOffenceTypeCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_TYPE_CREATE],
    api: API.offenceTypeCreate,
  })
}

function watchAssessmentOffenceTypeDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_TYPE_DELETE],
    api: API.offenceTypeDeleteById,
  })
}

function watchAssessmentOffenceTypeAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_TYPE_ALL],
    api: API.offenceTypeAll,
  })
}

function watchAssessmentOffenceChangeStatus() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_STATUS],
    api: API.offenceChangeStatus,
  })
}

function watchAssessmentOffenceChangeStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_STATUS],
    message: {
      title: 'Success',
    },
    dispatchActions: actions.assessmentEC(),
  })
}

function watchAssessmentOffenceChangeStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_STATUS],
    message: true,
  })
}

function watchAssessmentOffenceChangeSubjectBoardStatus() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS],
    api: API.offenceChangeSubjectBoardStatus,
  })
}

function watchAssessmentOffenceChangeSubjectBoardStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS],
    message: {
      title: 'Success',
    },
    dispatchActions: actions.assessmentListClear(),
    filter: {
      filterAction: actions.assessmentList,
      params: {
        size: 100,
      },
    },
  })
}

function watchAssessmentOffenceChangeSubjectBoardStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS],
    message: true,
  })
}

function watchAssessmentOffenceReportAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_REPORT_ALL],
    api: API.offenceReportAll,
  })
}

function watchAssessmentOffenceReportCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_REPORT_CREATE],
    api: API.offenceReportCreate,
  })
}

function watchAssessmentOffenceReportFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_OFFENCE_REPORT_CREATE],
    message: {
      title: 'Success',
    },
  })
}

function watchAssessmentOffenceReportList() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_OFFENCE_REPORT_LIST],
    api: API.offenceReportList,
  })
}

function watchAssessmentOffenceReportCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_OFFENCE_REPORT_CREATE],
    message: true,
  })
}

function watchReportMisconduct() {
  return watchSaga({
    action: actionTypes[REPORT_MISCONDUCT],
    api: API.reportMisconduct,
  })
}

function watchReportMisconductFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[REPORT_MISCONDUCT],
    message: {
      title: 'Success',
    },
    autoDownloadParams: {
      path: ['url'],
    },
  })
}

function watchReportMisconductRejected() {
  return watchSagaRejected({
    action: actionTypes[REPORT_MISCONDUCT],
    message: true,
  })
}

export function* assessmentOffenceSaga() {
  yield all([
    fork(watchAssessmentOffence),
    fork(watchAssessmentOffenceCreate),
    fork(watchAssessmentOffenceCreateFulfilled),
    fork(watchAssessmentOffenceCreateRejected),
    fork(watchAssessmentOffenceDelete),
    fork(watchAssessmentOffenceAll),
    fork(watchAssessmentOffencePublish),
    fork(watchAssessmentOffencePublishFulfilled),
    fork(watchAssessmentOffenceUnpublishFulfilled),
    fork(watchAssessmentOffenceUnpublish),
    fork(watchAssessmentOffenceUnpublishRejected),
    fork(watchAssessmentOffencePublishRejected),
    fork(watchAssessmentOffenceInvigilation),
    fork(watchAssessmentOffenceInvigilationCreate),
    fork(watchAssessmentOffenceInvigilationCreateFulfilled),
    fork(watchAssessmentOffenceInvigilationCreateRejected),
    fork(watchAssessmentOffenceInvigilationDelete),
    fork(watchAssessmentOffenceInvigilationAll),
    fork(watchAssessmentOffenceInvigilationConsultations),
    fork(watchAssessmentOffenceInvigilationConsultationsCreate),
    fork(watchAssessmentOffenceInvigilationConsultationsCreateFulfilled),
    fork(watchAssessmentOffenceInvigilationConsultationsCreateRejected),
    fork(watchAssessmentOffenceInvigilationConsultationsDelete),
    fork(watchAssessmentOffenceInvigilationConsultationsAll),
    fork(watchAssessmentOffenceInvigilationMinutesCreate),
    fork(watchAssessmentOffenceInvigilationMinutesCreateFulfilled),
    fork(watchAssessmentOffenceInvigilationMinutesCreateRejected),
    fork(watchAssessmentOffenceInvigilationMinutesDelete),
    fork(watchAssessmentOffenceInvigilationMinutesAll),
    fork(watchAssessmentOffenceType),
    fork(watchAssessmentOffenceTypeCreateFulfilled),
    fork(watchAssessmentOffenceTypeCreate),
    fork(watchAssessmentOffenceTypeDelete),
    fork(watchAssessmentOffenceTypeAll),
    fork(watchAssessmentOffenceChangeStatus),
    fork(watchAssessmentOffenceChangeStatusFulfilled),
    fork(watchAssessmentOffenceChangeStatusRejected),
    fork(watchAssessmentOffenceChangeSubjectBoardStatus),
    fork(watchAssessmentOffenceChangeSubjectBoardStatusFulfilled),
    fork(watchAssessmentOffenceChangeSubjectBoardStatusRejected),
    fork(watchAssessmentOffenceReportAll),
    fork(watchAssessmentOffenceReportCreate),
    fork(watchAssessmentOffenceReportFulfilled),
    fork(watchAssessmentOffenceReportList),
    fork(watchAssessmentOffenceReportCreateRejected),
    fork(watchReportMisconduct),
    fork(watchReportMisconductFulfilled),
    fork(watchReportMisconductRejected),
  ])
}

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const courseCampaign = data =>
  getInstance().get(`${API.COURSE_CAMPAIGN}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const courseCampaignCreate = data => getInstance().post(API.COURSE_CAMPAIGN, data)

export const courseCampaignDelete = data => getInstance().delete(`${API.COURSE_CAMPAIGN}${prop('id', data)}`)

export const courseCampaignList = data =>
  getInstance().get(API.COURSE_CAMPAIGN, {
    params: data,
  })

export const courseCampaignAll = data =>
  getInstance().get(API.COURSE_CAMPAIGN_ALL, {
    params: data,
  })

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ACCOUNT]: createState(actionTypes.ACCOUNT),
  [STATE.ACCOUNT_LIST]: createState(actionTypes.ACCOUNT_LIST),
  [STATE.ACCOUNT_ALL]: createState(actionTypes.ACCOUNT_ALL),
  [STATE.ACCOUNT_CREATE]: createState(actionTypes.ACCOUNT_CREATE),
  [STATE.ACCOUNT_PROFILE_UPDATE]: createState(actionTypes.ACCOUNT_PROFILE_UPDATE),
  [STATE.ACCOUNT_TYPES]: createState(actionTypes.ACCOUNT_TYPES),
})

export const ACCOUNT = STATE.GLOBAL_STATE

export default reducer

import { getPageSaga, watchSaga } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../../Roles/api'
import actionTypes, { ROLE, ROLE_ALL, ROLE_CREATE, ROLE_DELETE, ROLE_LIST } from '../constants/actionTypes'

function watchRole() {
  return watchSaga({
    action: actionTypes[ROLE],
    api: API.role,
  })
}

function watchRoleCreate() {
  return watchSaga({
    action: actionTypes[ROLE_CREATE],
    api: API.roleCreate,
  })
}

function* watchRoleCreateFulfilled() {
  yield takeEvery(actionTypes[ROLE_CREATE].fulfilled, function* () {
    yield getPageSaga({
      action: actions.roleList,
    })
  })
}

function watchRoleDelete() {
  return watchSaga({
    action: actionTypes[ROLE_DELETE],
    api: API.roleDelete,
  })
}

function* watchRoleDeleteFulfilled() {
  yield takeEvery(actionTypes[ROLE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield getPageSaga({
      action: actions.roleList,
    })
  })
}

function* watchRoleDeleteRejected() {
  yield takeEvery(actionTypes[ROLE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchRoleList() {
  return watchSaga({
    action: actionTypes[ROLE_LIST],
    api: API.roleList,
  })
}

function watchRoleAll() {
  return watchSaga({
    action: actionTypes[ROLE_ALL],
    api: API.roleAll,
  })
}

function* rolesSaga() {
  yield all([
    fork(watchRole),
    fork(watchRoleCreate),
    fork(watchRoleCreateFulfilled),
    fork(watchRoleDelete),
    fork(watchRoleDeleteFulfilled),
    fork(watchRoleDeleteRejected),
    fork(watchRoleList),
    fork(watchRoleAll),
  ])
}

export default rolesSaga

import { dissoc, prop, propOr } from 'ramda'

import { getInstance } from '../../../../helpers/httpClient'
import * as API from '../constants/api'

export const cclsModule = data =>
  getInstance().get(`${API.CCLS_MODULE}${propOr('', 'id', data)}`, {
    params: dissoc('id', data),
  })

export const modulesFilter = data =>
  getInstance().get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleCreate = data => getInstance().post(API.CCLS_MODULE, data)

export const cclsModuleUpdate = data => getInstance().put(`${API.CCLS_MODULE}${prop('id', data)}`, dissoc('id', data))

export const cclsModuleTeacherUpdate = data => getInstance().post(API.CCLS_MODULE_TEACHER, data)

export const cclsModuleDelete = data => getInstance().delete(`${API.CCLS_MODULE}${prop('id', data)}`)

export const cclsModuleList = data =>
  getInstance().get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleAll = data =>
  getInstance().get(API.CCLS_MODULE_ALL, {
    params: data,
  })

export const cclsModuleUploadMaterials = data => getInstance().post(API.CCLS_MODULE_UPLOAD_MATERIALS, data)

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_LIST,
  GLOBAL_STATE,
} from '../constants/state'

export const courseCampaignSelector = createSelector(path([GLOBAL_STATE, COURSE_CAMPAIGN]), data => data)

export const courseCampaignAllSelector = createSelector(path([GLOBAL_STATE, COURSE_CAMPAIGN_ALL]), data => data)

export const courseCampaignListSelector = createSelector(path([GLOBAL_STATE, COURSE_CAMPAIGN_LIST]), data => data)

export const courseCampaignCreateSelector = createSelector(path([GLOBAL_STATE, COURSE_CAMPAIGN_CREATE]), data => data)

export const courseCampaignAllOptionsSelector = createSelector(courseCampaignAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data) ? selected.data.map(item => ({ label: item.title, value: item.id })) : [],
  }
})

export const courseCampaignSelectors = {
  courseCampaignSelector,
  courseCampaignAllSelector,
  courseCampaignListSelector,
  courseCampaignCreateSelector,
  courseCampaignAllOptionsSelector,
}

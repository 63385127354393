import { SuccessToast } from '@components/Toasts'
import * as ROUTE from '@constants/routes'
import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import { getPage } from '@helpers/get'
import * as stateSelectors from '@store/selector'
import { push } from 'connected-react-router'
import { pathOr, prop, propOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import * as API_APPLICATION from '../api/application'
import * as API_APPLICATION_CATEGORY from '../api/applicationCategory'
import * as API_APPLICATION_REQUEST from '../api/applicationRequest'
import actionTypes, {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_CATEGORY,
  GENERAL_APPLICATION_CATEGORY_ALL,
  GENERAL_APPLICATION_CATEGORY_CREATE,
  GENERAL_APPLICATION_CATEGORY_DELETE,
  GENERAL_APPLICATION_CATEGORY_LIST,
  GENERAL_APPLICATION_CREATE,
  GENERAL_APPLICATION_DELETE,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_STATUS,
  GENERAL_APPLICATION_TYPES,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'
import * as actions from './actions'

function watchGeneralApplication() {
  return watchSaga({ action: actionTypes[GENERAL_APPLICATION], api: API_APPLICATION.generalApplication })
}

function watchGeneralApplicationCreate() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CREATE],
    api: API_APPLICATION.generalApplicationCreate,
  })
}

function watchGeneralApplicationDelete() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_DELETE],
    api: API_APPLICATION.generalApplicationDelete,
  })
}

function watchGeneralApplicationTypes() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_TYPES],
    api: API_APPLICATION.generalApplicationTypes,
  })
}

function watchGeneralApplicationTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[GENERAL_APPLICATION_TYPES],
    message: true,
  })
}

function watchGeneralApplicationStatus() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_STATUS],
    api: API_APPLICATION.generalApplicationStatus,
  })
}

function* watchGeneralApplicationStatusFulfilled() {
  yield takeEvery(actionTypes[GENERAL_APPLICATION_STATUS].fulfilled, function* (payload) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], payload)
    onFulfilled()
    yield toast.success(<SuccessToast text='Status has been changed!' />)
  })
}

function watchGeneralApplicationStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[GENERAL_APPLICATION_STATUS],
    message: true,
  })
}

function* watchGeneralApplicationDeleteFulfilled() {
  yield takeEvery(actionTypes[GENERAL_APPLICATION_DELETE].fulfilled, function* () {
    yield put(actions.generalApplicationList())
  })
}

function watchGeneralApplicationList() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_LIST],
    api: API_APPLICATION.generalApplicationList,
  })
}

function watchGeneralApplicationListRejected() {
  return watchSagaRejected({
    action: actionTypes[GENERAL_APPLICATION_LIST],
    message: true,
  })
}

function watchGeneralApplicationAll() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_ALL],
    api: API_APPLICATION.generalApplicationAll,
  })
}

function watchGeneralApplicationFieldBatch() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_FIELD_BATCH],
    api: API_APPLICATION.generalApplicationFieldBatch,
  })
}

function watchGeneralApplicationFieldBatchCreate() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_FIELD_BATCH_CREATE],
    api: API_APPLICATION.generalApplicationFieldBatchCreate,
  })
}

function* watchGeneralApplicationFieldBatchCreateFulfilled() {
  yield takeEvery(actionTypes[GENERAL_APPLICATION_FIELD_BATCH_CREATE].fulfilled, function* ({ args }) {
    const application = prop('application', args)
    const id = prop('id', application)

    yield put(push(ROUTE.GENERAL_ADMIN_APPLICATION_FORM))
  })
}

function watchGeneralApplicationCategory() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CATEGORY],
    api: API_APPLICATION_CATEGORY.generalApplicationCategory,
  })
}

function watchGeneralApplicationCategoryCreate() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CATEGORY_CREATE],
    api: API_APPLICATION_CATEGORY.generalApplicationCategoryCreate,
  })
}

function* watchGeneralApplicationCategoryCreateFulfilled() {
  yield takeEvery(actionTypes[GENERAL_APPLICATION_CATEGORY_CREATE].fulfilled, function* () {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    yield put(actions.generalApplicationCategoryList({ ...get }))
  })
}

function watchGeneralApplicationCategoryDelete() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CATEGORY_DELETE],
    api: API_APPLICATION_CATEGORY.generalApplicationCategoryDelete,
  })
}

function* watchGeneralApplicationCategoryFulfilled() {
  yield takeEvery(actionTypes[GENERAL_APPLICATION_CATEGORY_DELETE].fulfilled, function* () {
    yield put(actions.generalApplicationCategoryList())
  })
}

function watchGeneralApplicationCategoryList() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CATEGORY_LIST],
    api: API_APPLICATION_CATEGORY.generalApplicationCategoryList,
  })
}

function watchGeneralApplicationCategoryAll() {
  return watchSaga({
    action: actionTypes[GENERAL_APPLICATION_CATEGORY_ALL],
    api: API_APPLICATION_CATEGORY.generalApplicationCategoryAll,
  })
}

function watchGeneralApplicationRequest() {
  return watchSaga({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST],
    api: API_APPLICATION_REQUEST.generalApplicationRequest,
  })
}

function* watchGeneralApplicationRequestFulfilled() {
  yield takeEvery(actionTypes[TYPES.GENERAL_APPLICATION_REQUEST].fulfilled, function* ({ payload, args }) {
    const onFulfilled = propOr(() => {}, 'onFulfilled', args)
    yield onFulfilled(payload)
  })
}

function watchGeneralApplicationRequestAll() {
  return watchSaga({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_ALL],
    api: API_APPLICATION_REQUEST.generalApplicationRequestAll,
  })
}

function watchGeneralApplicationRequestEdit() {
  return watchSaga({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_EDIT],
    api: API_APPLICATION_REQUEST.generalApplicationRequestEdit,
  })
}

function watchGeneralApplicationRequestUpdate() {
  return watchSaga({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_UPDATE],
    api: API_APPLICATION_REQUEST.generalApplicationRequestUpdate,
  })
}

function watchGeneralApplicationRequestUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_UPDATE],
    shouldDoFulfilledFunction: true,
    message: {
      description: 'Your changes have been saved!',
    },
  })
}

function watchGeneralApplicationRequestUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_UPDATE],
    message: {
      description: 'Could not save your changes',
    },
  })
}

export function* generalAdmissionSaga() {
  yield all([
    fork(watchGeneralApplication),
    fork(watchGeneralApplicationCreate),
    fork(watchGeneralApplicationDelete),
    fork(watchGeneralApplicationDeleteFulfilled),
    fork(watchGeneralApplicationList),
    fork(watchGeneralApplicationListRejected),
    fork(watchGeneralApplicationAll),
    fork(watchGeneralApplicationFieldBatch),
    fork(watchGeneralApplicationFieldBatchCreate),
    fork(watchGeneralApplicationFieldBatchCreateFulfilled),
    fork(watchGeneralApplicationCategory),
    fork(watchGeneralApplicationCategoryCreate),
    fork(watchGeneralApplicationCategoryCreateFulfilled),
    fork(watchGeneralApplicationCategoryDelete),
    fork(watchGeneralApplicationCategoryFulfilled),
    fork(watchGeneralApplicationCategoryList),
    fork(watchGeneralApplicationCategoryAll),
    fork(watchGeneralApplicationRequest),
    fork(watchGeneralApplicationRequestFulfilled),
    fork(watchGeneralApplicationRequestAll),
    fork(watchGeneralApplicationRequestEdit),
    fork(watchGeneralApplicationRequestUpdate),
    fork(watchGeneralApplicationRequestUpdateFulfilled),
    fork(watchGeneralApplicationRequestUpdateRejected),
    fork(watchGeneralApplicationTypes),
    fork(watchGeneralApplicationTypesRejected),
    fork(watchGeneralApplicationStatus),
    fork(watchGeneralApplicationStatusFulfilled),
    fork(watchGeneralApplicationStatusRejected),
  ])
}

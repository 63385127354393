import actionTypes, {
  GENERAL_APPLICATION,
  GENERAL_APPLICATION_CREATE,
  GENERAL_APPLICATION_DELETE,
  GENERAL_APPLICATION_LIST,
  GENERAL_APPLICATION_ALL,
  GENERAL_APPLICATION_FIELD_BATCH,
  GENERAL_APPLICATION_FIELD_BATCH_CREATE,
  GENERAL_APPLICATION_CATEGORY,
  GENERAL_APPLICATION_CATEGORY_CREATE,
  GENERAL_APPLICATION_CATEGORY_DELETE,
  GENERAL_APPLICATION_CATEGORY_LIST,
  GENERAL_APPLICATION_CATEGORY_ALL,
  GENERAL_APPLICATION_TYPES,
  GENERAL_APPLICATION_STATUS,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'

export const generalApplication = payload => ({
  type: actionTypes[GENERAL_APPLICATION].pending,
  payload,
})

export const generalApplicationClear = () => ({
  type: actionTypes[GENERAL_APPLICATION].clear,
})

export const generalApplicationCreate = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CREATE].pending,
  payload,
})

export const generalApplicationDelete = payload => ({
  type: actionTypes[GENERAL_APPLICATION_DELETE].pending,
  payload,
})

export const generalApplicationList = payload => ({
  type: actionTypes[GENERAL_APPLICATION_LIST].pending,
  payload,
})

export const generalApplicationListClear = payload => ({
  type: actionTypes[GENERAL_APPLICATION_LIST].clear,
  payload,
})

export const generalApplicationAll = payload => ({
  type: actionTypes[GENERAL_APPLICATION_ALL].pending,
  payload,
})

export const generalApplicationAllClear = () => ({
  type: actionTypes[GENERAL_APPLICATION_ALL].clear,
})

export const generalApplicationTypes = payload => ({
  type: actionTypes[GENERAL_APPLICATION_TYPES].pending,
  payload,
})

export const generalApplicationTypesClear = () => ({
  type: actionTypes[GENERAL_APPLICATION_TYPES].clear,
})

export const generalApplicationChangeStatus = payload => ({
  type: actionTypes[GENERAL_APPLICATION_STATUS].pending,
  payload,
})

export const generalApplicationFieldBatch = payload => ({
  type: actionTypes[GENERAL_APPLICATION_FIELD_BATCH].pending,
  payload,
})

export const generalApplicationFieldBatchClear = () => ({
  type: actionTypes[GENERAL_APPLICATION_FIELD_BATCH].clear,
})

export const generalApplicationFieldBatchCreate = payload => ({
  type: actionTypes[GENERAL_APPLICATION_FIELD_BATCH_CREATE].pending,
  payload,
})

export const generalApplicationCategory = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY].pending,
  payload,
})

export const generalApplicationCategoryCreate = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY_CREATE].pending,
  payload,
})

export const generalApplicationCategoryDelete = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY_DELETE].pending,
  payload,
})

export const generalApplicationCategoryList = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY_LIST].pending,
  payload,
})

export const generalApplicationCategoryListClear = () => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY_LIST].clear,
})

export const generalApplicationCategoryAll = payload => ({
  type: actionTypes[GENERAL_APPLICATION_CATEGORY_ALL].pending,
  payload,
})

export const generalApplicationRequest = payload => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST].pending,
  payload,
})

export const generalApplicationRequestAll = payload => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_ALL].pending,
  payload,
})

export const generalApplicationRequestEdit = payload => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_EDIT].pending,
  payload,
})

export const generalApplicationRequestEditClear = () => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_EDIT].clear,
})

export const generalApplicationRequestUpdate = payload => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_UPDATE].pending,
  payload,
})

export const generalApplicationRequestUpdateClear = () => ({
  type: actionTypes[TYPES.GENERAL_APPLICATION_REQUEST_UPDATE].clear,
})

import { dissoc, map, pathOr, pipe, prop } from 'ramda/es'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { jsonToCSV } from 'react-papaparse'
import actionTypes, {
  MODULES,
  MODULES_CREATE,
  MODULES_DELETE,
  MODULES_LIST,
  MODULES_ALL,
} from '../constants/actionTypes'
import * as API from '../api'
import * as actions from '../../../../redux/actions'
import { watchSaga } from '../../../../helpers/customSaga'
import createCSV from '../../../../helpers/createCSV'

function watchModule() {
  return watchSaga({
    action: actionTypes[MODULES],
    api: API.modules,
  })
}

function watchModuleCreate() {
  return watchSaga({
    action: actionTypes[MODULES_CREATE],
    api: API.modulesCreate,
  })
}

function* watchModuleCreateFulfilled() {
  yield takeEvery(actionTypes[MODULES_CREATE].fulfilled, function* () {
    yield put(actions.modulesList())
  })
}

function watchModuleDelete() {
  return watchSaga({
    action: actionTypes[MODULES_DELETE],
    api: API.modulesDelete,
  })
}

function* watchModuleDeleteFulfilled() {
  yield takeEvery(actionTypes[MODULES_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.modulesList())
  })
}

function* watchModuleDeleteRejected() {
  yield takeEvery(actionTypes[MODULES_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchModuleList() {
  return watchSaga({
    action: actionTypes[MODULES_LIST],
    api: API.modulesList,
  })
}

function* watchModuleAll() {
  yield takeEvery(actionTypes[MODULES_ALL].pending, function* ({ payload }) {
    try {
      const { data } = yield call(API.modulesAll, dissoc('csv', payload))

      yield put({
        type: actionTypes[MODULES_ALL].fulfilled,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionTypes[MODULES_ALL].rejected,
        payload: error,
      })
    }
  })
}

function* watchModuleAllFulfilled() {
  yield takeEvery(actionTypes[MODULES_ALL].fulfilled, function ({ payload, args }) {
    const csv = prop('csv', args)

    if (csv) {
      const newCSV = pipe(
        map(item => ({
          Title: prop('title', item),
        })),
        item => jsonToCSV(item, { header: true }),
      )(payload || [])

      createCSV(newCSV, 'Module.csv')
    }
  })
}

export function* moduleSaga() {
  yield all([
    fork(watchModule),
    fork(watchModuleCreate),
    fork(watchModuleCreateFulfilled),
    fork(watchModuleDelete),
    fork(watchModuleDeleteFulfilled),
    fork(watchModuleDeleteRejected),
    fork(watchModuleList),
    fork(watchModuleAll),
    fork(watchModuleAllFulfilled),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  EMAIL_TEMPLATE,
  EMAIL_TEMPLATE_ALL,
  EMAIL_TEMPLATE_CREATE,
  EMAIL_TEMPLATE_LIST,
  GLOBAL_STATE,
  SEND_MAIL,
} from '../constants/state'

export const emailTemplateSelector = createSelector(path([GLOBAL_STATE, EMAIL_TEMPLATE]), data => data)

export const emailTemplateListSelector = createSelector(path([GLOBAL_STATE, EMAIL_TEMPLATE_LIST]), data => data)

export const emailTemplateAllSelector = createSelector(path([GLOBAL_STATE, EMAIL_TEMPLATE_ALL]), data => data)

export const emailTemplateCreateSelector = createSelector(path([GLOBAL_STATE, EMAIL_TEMPLATE_CREATE]), data => data)

export const sendMailSelector = createSelector(path([GLOBAL_STATE, SEND_MAIL]), data => data)

export const emailTemplateSelectors = {
  sendMailSelector,
  emailTemplateSelector,
  emailTemplateListSelector,
  emailTemplateAllSelector,
  emailTemplateCreateSelector,
}

import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const groupAll = data =>
  getInstance().get(API.GROUP_ALL, {
    params: data,
  })

export const groupGrouping = data => getInstance().post(API.GROUP_GROUPING, data)

export const groupShuffle = data => getInstance().post(API.GROUP_SHUFFLE, data)

export const groupStudentTransfer = data => getInstance().post(API.GROUP_STUDENT_TRANSFER, data)

export const groupEdit = data => getInstance().post(API.GROUP, data)

export const groupAssign = data => getInstance().post(API.GROUP_ASSIGN, data)

export const groupAssignedList = data => getInstance().get(API.GROUP_ASSIGN, { params: data })

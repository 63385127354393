import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import * as TYPES from '../constants/actionTypes'
import * as API from '../constants/api'

export const colorPalette = createAsyncThunk(
  TYPES.COLOR_PALETTE,
  async (params: PayloadCreatorParams<`${typeof API.COLOR_PALETTE}`, 'get'> | undefined, { rejectWithValue }) => {
    try {
      const response = await api.colorPalette(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const colorPaletteClear = createAction(`${TYPES.COLOR_PALETTE}/clear`)

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GENERATE_TRANSCRIPT,
  GLOBAL_STATE,
  STUDENT,
  STUDENT_ACHIEVEMENT_RECORDS,
  STUDENT_ALL,
  STUDENT_CONTACT_INFO_UPDATE,
  STUDENT_GUARD_LIST,
  STUDENT_LIST,
  STUDENT_PERSONAL_INFO_UPDATE,
  STUDENT_USERNAME,
} from '../constants/state'

export const studentAchievementRecordsSelector = createSelector(
  path([GLOBAL_STATE, STUDENT_ACHIEVEMENT_RECORDS]),
  data => data
)

export const studentAllSelector = createSelector(path([GLOBAL_STATE, STUDENT_ALL]), data => data)

export const studentSelector = createSelector(path([GLOBAL_STATE, STUDENT]), data => data)

export const studentListSelector = createSelector(path([GLOBAL_STATE, STUDENT_LIST]), data => data)

export const studentGuardListSelector = createSelector(path([GLOBAL_STATE, STUDENT_GUARD_LIST]), data => data)

export const studentUsernameSelector = createSelector(path([GLOBAL_STATE, STUDENT_USERNAME]), data => data)

export const generateTranscriptSelector = createSelector(path([GLOBAL_STATE, GENERATE_TRANSCRIPT]), data => data)

export const studentPersonalInfoUpdate = createSelector(
  path([GLOBAL_STATE, STUDENT_PERSONAL_INFO_UPDATE]),
  data => data
)

export const studentContactInfoUpdate = createSelector(path([GLOBAL_STATE, STUDENT_CONTACT_INFO_UPDATE]), data => data)

export const studentSelectors = {
  studentAchievementRecordsSelector,
  studentAllSelector,
  studentSelector,
  studentListSelector,
  studentGuardListSelector,
  studentUsernameSelector,
  generateTranscriptSelector,
  studentPersonalInfoUpdate,
  studentContactInfoUpdate,
}

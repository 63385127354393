export const HR = '/admission'
export const HR_MY_APPLICATIONS = '/guest-application/job'
export const HR_APPLICATIONS = '/applications'
export const HR_MY_APPLICATIONS_CREATE = `${HR_MY_APPLICATIONS}/create`
export const HR_APPLICATIONS_EDIT = `${HR_APPLICATIONS}/edit`

export const HR_ADMIN = `${HR}/admin`
export const HR_ADMIN_APPLICATION_FORM = `${HR_ADMIN}/application-form`
export const HR_ADMIN_APPLICATION_CREATE = `${HR_ADMIN_APPLICATION_FORM}/create`
export const HR_ADMIN_APPLICATION_CATEGORY = `${HR_ADMIN}/category`

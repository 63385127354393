export const GLOBAL_STATE = 'Shop'

export const PRODUCT_ITEM = 'PRODUCT_ITEM'
export const PRODUCT_ITEM_CLIENT = 'PRODUCT_ITEM_CLIENT'
export const PRODUCT_ITEM_LIST = 'PRODUCT_ITEM_LIST'
export const PRODUCT_ITEM_CREATE = 'PRODUCT_ITEM_CREATE'
export const PRODUCT_ITEM_DELETE = 'PRODUCT_ITEM_DELETE'
export const PRODUCT_ITEM_ALL = 'PRODUCT_ITEM_ALL'
export const PRODUCT_ITEM_ALL_SHORT = 'PRODUCT_ITEM_ALL_SHORT'

export const PRODUCT_CATEGORY_LIST = 'PRODUCT_CATEGORY'
export const PRODUCT_CATEGORY_CREATE = 'PRODUCT_CATEGORY_CREATE'
export const PRODUCT_CATEGORY_DELETE = 'PRODUCT_CATEGORY_DELETE'
export const PRODUCT_CATEGORY_ALL = 'PRODUCT_CATEGORY_ALL'

export const PRODUCT_COLOR_LIST = 'PRODUCT_COLOR_LIST'
export const PRODUCT_COLOR_DELETE = 'PRODUCT_COLOR_DELETE'
export const PRODUCT_COLOR_CREATE = 'PRODUCT_COLOR_CREATE'
export const PRODUCT_COLOR_ALL = 'PRODUCT_COLOR_ALL'

export const PRODUCT_SIZE_LIST = 'PRODUCT_SIZE_LIST'
export const PRODUCT_SIZE_CREATE = 'PRODUCT_SIZE_CREATE'
export const PRODUCT_SIZE_DELETE = 'PRODUCT_SIZE_DELETE'
export const PRODUCT_SIZE_ALL = 'PRODUCT_SIZE_ALL'

export const PRODUCT_MATERIAL_LIST = 'PRODUCT_MATERIAL_LIST'
export const PRODUCT_MATERIAL_CREATE = 'PRODUCT_MATERIAL_CREATE'
export const PRODUCT_MATERIAL_DELETE = 'PRODUCT_MATERIAL_DELETE'
export const PRODUCT_MATERIAL_ALL = 'PRODUCT_MATERIAL_ALL'

export const PRODUCT_ORDER = 'PRODUCT_ORDER'

export const PRODUCT_TRADE_LIST = 'PRODUCT_TRADE_LIST'
export const PRODUCT_TRADE_CREATE = 'PRODUCT_TRADE_CREATE'
export const PRODUCT_TRADE_DELETE = 'PRODUCT_TRADE_DELETE'
export const PRODUCT_TRADE_ALL = 'PRODUCT_TRADE_ALL'

export const PRODUCT_TRADE_BUYER_ALL = 'PRODUCT_TRADE_BUYER_ALL'

export const PRODUCT_TRADE_ACCEPT = 'PRODUCT_TRADE_ACCEPT'
export const PRODUCT_TRADE_MARK = 'PRODUCT_TRADE_MARK'
export const PRODUCT_TRADE_REQUEST = 'PRODUCT_TRADE_REQUEST'

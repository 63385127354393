import { createAction } from '@helpers/createAction'

export const LESSON = 'LESSON'
export const LESSON_CREATE = 'LESSON_CREATE'
export const LESSON_DELETE = 'LESSON_DELETE'
export const LESSON_LIST = 'LESSON_LIST'
export const LESSON_ALL = 'LESSON_ALL'
export const LESSON_SORTING = 'LESSON_SORTING'
export const LESSON_HOMEWORK_UPLOAD = 'LESSON_HOMEWORK_UPLOAD'
export const LESSON_ASSESSMENT = 'LESSON_ASSESSMENT'
export const LESSON_ASSESSMENT_ALL = 'LESSON_ASSESSMENT_ALL'
export const LESSON_MARK_ASSESSMENT = 'LESSON_MARK_ASSESSMENT'
export const LESSON_ASSESSMENT_LIST = 'LESSON_ASSESSMENT_LIST'

export default {
  [LESSON]: createAction(LESSON),
  [LESSON_CREATE]: createAction(LESSON_CREATE),
  [LESSON_DELETE]: createAction(LESSON_DELETE),
  [LESSON_LIST]: createAction(LESSON_LIST),
  [LESSON_ALL]: createAction(LESSON_ALL),
  [LESSON_SORTING]: createAction(LESSON_SORTING),
  [LESSON_HOMEWORK_UPLOAD]: createAction(LESSON_HOMEWORK_UPLOAD),
  [LESSON_ASSESSMENT]: createAction(LESSON_ASSESSMENT),
  [LESSON_ASSESSMENT_ALL]: createAction(LESSON_ASSESSMENT_ALL),
  [LESSON_MARK_ASSESSMENT]: createAction(LESSON_MARK_ASSESSMENT),
  [LESSON_ASSESSMENT_LIST]: createAction(LESSON_ASSESSMENT_LIST),
}

import { stats as statsApi } from '@module/Stats/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'
import { DefaultState, PayloadCreatorParams } from '@store/types'
import axios from 'axios'
import { omit } from 'ramda'
import { toast } from 'react-toastify'

import { GLOBAL_STATE, STATS } from '../constants/actionNames'

export const STATS_MAIN = GLOBAL_STATE

export const stats = createAsyncThunk(
  STATS,
  async (params: PayloadCreatorParams<'/stats/web', 'get'>, { rejectWithValue }) => {
    try {
      const response = await statsApi(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error(e.response?.data.message as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

const initialState: () => {
  [STATS]: DefaultState<'/stats/web', 'get'>
} = () => ({
  [STATS]: { loading: false, error: null, data: null },
})

const statsSlice = createSlice({
  name: STATS_MAIN,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(stats.pending, state => {
        state[STATS].loading = true
      })
      .addCase(stats.rejected, (state, action) => {
        state[STATS].loading = false
        state[STATS].error = action.payload as AxiosError
      })
      .addCase(stats.fulfilled, (state, action) => {
        state[STATS].loading = false
        state[STATS].error = null
        state[STATS].data = action.payload
      })
  },
})

export const { reducer: statsReducer } = statsSlice

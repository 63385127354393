import { SuccessToast } from '@components/Toasts'
import { getPageSaga, watchSaga, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../../Departments/api'
import actionTypes, {
  DEPARTMENT,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LABELS,
  DEPARTMENT_LIST,
} from '../constants/actionTypes'

function watchDepartment() {
  return watchSaga({
    action: actionTypes[DEPARTMENT],
    api: API.department,
  })
}

function watchDepartmentCreate() {
  return watchSaga({
    action: actionTypes[DEPARTMENT_CREATE],
    api: API.departmentCreate,
  })
}

function watchDepartmentLabels() {
  return watchSaga({
    action: actionTypes[DEPARTMENT_LABELS],
    api: API.departmentLabels,
  })
}

function watchDepartmentLabelsRejected() {
  return watchSagaRejected({
    action: actionTypes[DEPARTMENT_LABELS],
    message: true,
  })
}

function* watchDepartmentCreateFulfilled() {
  yield takeEvery(actionTypes[DEPARTMENT_CREATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled(action)
    toast.success(<SuccessToast text='success' />)
    yield getPageSaga({
      action: actions.departmentList,
    })
  })
}

function watchDepartmentDelete() {
  return watchSaga({
    action: actionTypes[DEPARTMENT_DELETE],
    api: API.departmentDelete,
  })
}

function* watchDepartmentDeleteFulfilled() {
  yield takeEvery(actionTypes[DEPARTMENT_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield getPageSaga({
      action: actions.departmentList,
    })
  })
}

function* watchDepartmentDeleteRejected() {
  yield takeEvery(actionTypes[DEPARTMENT_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchDepartmentList() {
  return watchSaga({
    action: actionTypes[DEPARTMENT_LIST],
    api: API.departmentList,
  })
}

function watchDepartmentAll() {
  return watchSaga({
    action: actionTypes[DEPARTMENT_ALL],
    api: API.departmentAll,
  })
}

export function* departmentsSaga() {
  yield all([
    fork(watchDepartment),
    fork(watchDepartmentCreate),
    fork(watchDepartmentCreateFulfilled),
    fork(watchDepartmentDelete),
    fork(watchDepartmentDeleteFulfilled),
    fork(watchDepartmentDeleteRejected),
    fork(watchDepartmentList),
    fork(watchDepartmentAll),
    fork(watchDepartmentLabels),
    fork(watchDepartmentLabelsRejected),
  ])
}

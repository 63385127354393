import { definitions } from '@src/types/schema'

import { STORAGE_USER_INFO } from '../constants/storage'

export const getUserInfo = (): null | definitions['UserInfo'] => {
  try {
    const userInfo = localStorage.getItem(STORAGE_USER_INFO)
    if (userInfo) {
      return JSON.parse(userInfo)
    }
  } catch (e) {
    console.log('You have to login')
  }
  return null
}

export const setUserInfo = (userInfo: definitions['UserInfo']) => {
  localStorage.setItem(STORAGE_USER_INFO, JSON.stringify(userInfo))
}

export const removeUserInfo = () => {
  localStorage.removeItem(STORAGE_USER_INFO)
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, MODULES_OUTCOME, MODULES_OUTCOME_CREATE, MODULES_OUTCOME_LIST } from '../constants/state'

export const modulesOutcomeSelector = createSelector(path([GLOBAL_STATE, MODULES_OUTCOME]), data => data)

export const modulesOutcomeCreateSelector = createSelector(path([GLOBAL_STATE, MODULES_OUTCOME_CREATE]), data => data)

export const modulesOutcomeListSelector = createSelector(path([GLOBAL_STATE, MODULES_OUTCOME_LIST]), data => data)

export const modulesOutcomeSelectors = {
  modulesOutcomeSelector,
  modulesOutcomeCreateSelector,
  modulesOutcomeListSelector,
}

import { getInstance } from '@helpers/httpClient'
import { prop } from 'ramda'

import * as API from '../constants/api'

export const moduleFileAll = data =>
  getInstance().get(API.MODULE_FILE_ALL, {
    params: data,
  })

export const moduleFileList = data =>
  getInstance().get(API.MODULE_FILE, {
    params: data,
  })

export const moduleFileById = data =>
  getInstance().get(`${API.MODULE_FILE}${prop('id', data)}`, {
    params: data,
  })

export const moduleFileDelete = data => getInstance().delete(`${API.MODULE_FILE}${prop('id', data)}`)

export const moduleFileCreate = data => getInstance().post(API.MODULE_FILE, data)

export const moduleFileActivate = data => getInstance().put(`${API.MODULE_FILE}${prop('id', data)}`)

import actionTypes, {
  COUNTRY_DELETE,
  COUNTRY,
  COUNTRY_ALL,
  COUNTRY_CREATE,
  COUNTRY_LIST,
} from '../constants/actionTypes'

export const country = payload => ({
  type: actionTypes[COUNTRY].pending,
  payload,
})

export const countryClear = () => ({
  type: actionTypes[COUNTRY].clear,
})

export const countryCreate = payload => ({
  type: actionTypes[COUNTRY_CREATE].pending,
  payload,
})

export const countryDelete = payload => ({
  type: actionTypes[COUNTRY_DELETE].pending,
  payload,
})

export const countryList = payload => ({
  type: actionTypes[COUNTRY_LIST].pending,
  payload,
})

export const countryListClear = () => ({
  type: actionTypes[COUNTRY_LIST].clear,
})

export const countryAll = payload => ({
  type: actionTypes[COUNTRY_ALL].pending,
  payload,
})

export const countryAllClear = () => ({
  type: actionTypes[COUNTRY_ALL].clear,
})

export const GLOBAL_STATE = 'Forms'

export const FORM = 'form'
export const FORM_LIST = 'formList'
export const FORM_ALL = 'formAll'
export const FORM_FIELD_BATCH = 'formBatch'
export const FORM_ACTION_TYPE = 'actionType'
export const FORM_TYPE = 'formType'
export const FORM_TYPE_LIST = 'typeList'
export const FORM_TYPE_ALL = 'typeAll'
export const FORM_REQUEST = 'request'
export const FORM_REQUEST_ITEM = 'requestItem'
export const FORM_REQUEST_STATUS = 'requestStatus'
export const FORM_REQUEST_CREATE = 'requestCreate'
export const FORM_REQUEST_LIST = 'requestList'
export const FORM_REQUEST_ALL = 'requestAll'
export const FORM_REQUEST_FIELDS = 'requestFields'
export const FORM_REQUEST_CERTIFICATE = 'requestCertificate'

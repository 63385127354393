import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.MODULE_FILE]: createState(actionTypes.MODULE_FILE),
  [STATE.MODULE_FILE_CREATE]: createState(actionTypes.MODULE_FILE_CREATE),
  [STATE.MODULE_FILE_DELETE]: createState(actionTypes.MODULE_FILE_DELETE),
  [STATE.MODULE_FILE_LIST]: createState(actionTypes.MODULE_FILE_LIST),
  [STATE.MODULE_FILE_ALL]: createState(actionTypes.MODULE_FILE_ALL),
})

export const MODULE_FILE = STATE.GLOBAL_STATE

export default reducer

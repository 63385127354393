import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const authorizedInstance = getInstance()

export const stcStep: ApiFunc<`/${typeof API.STC_STEP}{id}`, 'get'> = data =>
  authorizedInstance.get(`${API.STC_STEP}${'id' in data ? data.id : ''}`)

export const stcStepAll: ApiFunc<`/${typeof API.STC_STEP_ALL}`, 'get'> = data =>
  authorizedInstance.get(API.STC_STEP_ALL, {
    params: data,
  })

export const stcStepList: ApiFunc<`/${typeof API.STC_STEP}`, 'get'> = data =>
  authorizedInstance.get(API.STC_STEP, {
    params: data,
  })

export const stcStepCreate: ApiFunc<`/${typeof API.STC_STEP}`, 'post'> = data =>
  authorizedInstance.post(API.STC_STEP, data)

export const stcStepComplete: ApiFunc<`/${typeof API.STC_STEP_COMPLETE}`, 'post'> = data =>
  authorizedInstance.post(API.STC_STEP_COMPLETE, data)

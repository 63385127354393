import { ASSESSMENT } from '../../Assessment/constants/api'

export const OFFENCE = `offence/`
export const OFFENCE_ALL = `${OFFENCE}all/`
export const OFFENCE_CHANGE_STATUS = `${OFFENCE}change/status`
export const OFFENCE_TYPE = `${OFFENCE}type/`
export const OFFENCE_TYPE_ALL = `${OFFENCE_TYPE}all/`
export const OFFENCE_INVIGILATION = `${OFFENCE}invigilation/`
export const OFFENCE_INVIGILATION_ALL = `${OFFENCE_INVIGILATION}all`
export const OFFENCE_INVIGILATION_MINUTES = `${OFFENCE}invigilation_minutes/`
export const OFFENCE_INVIGILATION_MINUTES_ALL = `${OFFENCE_INVIGILATION_MINUTES}all`
export const OFFENCE_INVIGILATION_CONSULTATIONS = `${OFFENCE}consultation_reports/`
export const OFFENCE_INVIGILATION_CONSULTATIONS_ALL = `${OFFENCE_INVIGILATION_CONSULTATIONS}all`
export const OFFENCE_REPORT = `${OFFENCE}report/`
export const OFFENCE_REPORT_ALL = `${OFFENCE_REPORT}all`
export const ASSESSMENT_OFFENCE_PUBLISH = `${OFFENCE}publish`
export const ASSESSMENT_OFFENCE_UNPUBLISH = `${OFFENCE}unpublish`
export const OFFENCE_CHANGE_SUBJECT_BOARD_STATUS = `${ASSESSMENT}change/subjectboard/status`
export const REPORT_MISCONDUCT = `/report/`

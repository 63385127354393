import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ASSESSMENT_AMENDMENT]: createState(actionTypes.ASSESSMENT_AMENDMENT),
  [STATE.ASSESSMENT_AMENDMENT_LIST]: createState(actionTypes.ASSESSMENT_AMENDMENT_LIST),
  [STATE.ASSESSMENT_AMENDMENT_ALL]: createState(actionTypes.ASSESSMENT_AMENDMENT_ALL),
  [STATE.ASSESSMENT_MARK_STATUS]: createState(actionTypes.ASSESSMENT_MARK_STATUS),
  [STATE.ASSESSMENT_AMENDMENT_CREATE]: createState(actionTypes.ASSESSMENT_AMENDMENT_CREATE),
})

export const ASSESSMENT_MARK = STATE.GLOBAL_STATE

export default reducer

import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import jobPositionApis from '../api'
import {
  JOB_POSITION,
  JOB_POSITION_ACTIVATE,
  JOB_POSITION_ALL,
  JOB_POSITION_CREATE,
  JOB_POSITION_DELETE,
  JOB_POSITION_LIST,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchJobPosition() {
  return watchSaga({
    action: actionTypes[JOB_POSITION],
    api: jobPositionApis[JOB_POSITION],
  })
}

function watchJobPositionRejected() {
  return watchSagaRejected({
    action: actionTypes[JOB_POSITION],
    message: true,
  })
}

function watchJobPositionList() {
  return watchSaga({
    action: actionTypes[JOB_POSITION_LIST],
    api: jobPositionApis[JOB_POSITION_LIST],
  })
}

function watchJobPositionListRejected() {
  return watchSagaRejected({
    action: actionTypes[JOB_POSITION_LIST],
    message: true,
  })
}

function watchJobPositionAll() {
  return watchSaga({
    action: actionTypes[JOB_POSITION_ALL],
    api: jobPositionApis[JOB_POSITION_ALL],
  })
}

function watchJobPositionAllRejected() {
  return watchSagaRejected({
    action: actionTypes[JOB_POSITION_ALL],
    message: true,
  })
}

function watchJobPositionCreate() {
  return watchSaga({
    action: actionTypes[JOB_POSITION_CREATE],
    api: jobPositionApis[JOB_POSITION_CREATE],
  })
}

function* watchJobPositionCreateFulfilled() {
  yield takeEvery(actionTypes[JOB_POSITION_CREATE].fulfilled, function* (action) {
    toast.success(<SuccessToast text='Successfully created' />)
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled()
  })
}

function watchJobPositionCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[JOB_POSITION_CREATE],
    message: true,
  })
}

function watchJobPositionActivate() {
  return watchSaga({
    action: actionTypes[JOB_POSITION_ACTIVATE],
    api: jobPositionApis[JOB_POSITION_ACTIVATE],
  })
}

function* watchJobPositionActivateFulfilled() {
  yield takeEvery(actionTypes[JOB_POSITION_ACTIVATE].fulfilled, function* (action) {
    toast.success(<SuccessToast text='Successfully activated' />)
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    onFulfilled()
  })
}

function watchJobPositionDelete() {
  return watchSaga({
    action: actionTypes[JOB_POSITION_DELETE],
    api: jobPositionApis[JOB_POSITION_DELETE],
  })
}

function* watchJobPositionDeleteFulfilled() {
  yield takeEvery(actionTypes[JOB_POSITION_DELETE].fulfilled, function* (action) {
    const callback = pathOr(() => {}, ['args', 'callback'], action)
    yield callback(action)
  })
}

function* watchJobPositionDeleteRejected() {
  yield takeEvery(actionTypes[JOB_POSITION_DELETE].rejected, function* (action) {
    const callback = pathOr(() => {}, ['args', 'callback'], action)
    yield callback(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
  })
}

export function* jobPositionSaga() {
  yield all([
    fork(watchJobPosition),
    fork(watchJobPositionRejected),
    fork(watchJobPositionList),
    fork(watchJobPositionListRejected),
    fork(watchJobPositionAll),
    fork(watchJobPositionAllRejected),
    fork(watchJobPositionCreate),
    fork(watchJobPositionCreateFulfilled),
    fork(watchJobPositionActivate),
    fork(watchJobPositionActivateFulfilled),
    fork(watchJobPositionCreateRejected),
    fork(watchJobPositionDelete),
    fork(watchJobPositionDeleteFulfilled),
    fork(watchJobPositionDeleteRejected),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import { CITY, CITY_ALL, CITY_CREATE, CITY_LIST, GLOBAL_STATE } from '../constants/state'

export const citySelector = createSelector(path([GLOBAL_STATE, CITY]), data => data)

export const cityListSelector = createSelector(path([GLOBAL_STATE, CITY_LIST]), data => data)

export const cityAllSelector = createSelector(path([GLOBAL_STATE, CITY_ALL]), data => data)

export const cityCreateSelector = createSelector(path([GLOBAL_STATE, CITY_CREATE]), data => data)

export const citySelectors = {
  citySelector,
  cityListSelector,
  cityAllSelector,
  cityCreateSelector,
}

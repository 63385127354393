import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const hrApplicationCategory = data =>
  getInstance().get(`${API.HR_APPLICATION_CATEGORY}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const hrApplicationCategoryCreate = data => getInstance().post(API.HR_APPLICATION_CATEGORY, data)

export const hrApplicationCategoryDelete = data =>
  getInstance().delete(`${API.HR_APPLICATION_CATEGORY}${prop('id', data)}`)

export const hrApplicationCategoryList = data =>
  getInstance().get(API.HR_APPLICATION_CATEGORY, {
    params: data,
  })

export const hrApplicationCategoryAll = data =>
  getInstance().get(API.HR_APPLICATION_CATEGORY_ALL, {
    params: data,
  })

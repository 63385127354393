import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const course = data =>
  getInstance().get(`${API.COURSE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const courseCreate = data => getInstance().post(API.COURSE, data)

export const courseDelete = data => getInstance().delete(`${API.COURSE}${prop('id', data)}`)

export const courseList = data =>
  getInstance().get(API.COURSE, {
    params: data,
  })

export const courseAll = data =>
  getInstance().get(API.COURSE_ALL, {
    params: data,
  })

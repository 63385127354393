import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_DELETE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
} from '../constants/actionTypes'

function watchCalendarEvent() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT],
    api: API.calendarEvent,
  })
}

function watchCalendarEventCreate() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT_CREATE],
    api: API.calendarEventCreate,
  })
}

function watchCalendarEventCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[CALENDAR_EVENT_CREATE],
    filter: {
      filterAction: actions.calendarEventAll,
    },
    shouldCloseModal: true,
    message: {
      title: 'Success',
      description: 'Event has been created',
    },
  })
}

function watchCalendarEventCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[CALENDAR_EVENT_CREATE],
    message: true,
  })
}

function watchCalendarEventDelete() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT_DELETE],
    api: API.calendarEventDelete,
  })
}

function* watchCalendarEventDeleteFulfilled() {
  yield takeEvery(actionTypes[CALENDAR_EVENT_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.calendarEventAll())
  })
}

function* watchCalendarEventDeleteRejected() {
  yield takeEvery(actionTypes[CALENDAR_EVENT_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCalendarEventList() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT_LIST],
    api: API.calendarEventList,
  })
}

function watchCalendarEventAll() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT_ALL],
    api: API.calendarEventAll,
  })
}

function watchCalendarEventListStudent() {
  return watchSaga({
    action: actionTypes[CALENDAR_EVENT_LIST_STUDENT],
    api: API.calendarEventListStudent,
  })
}

export function* calendarEventSaga() {
  yield all([
    fork(watchCalendarEvent),
    fork(watchCalendarEventCreate),
    fork(watchCalendarEventCreateFulfilled),
    fork(watchCalendarEventDelete),
    fork(watchCalendarEventDeleteFulfilled),
    fork(watchCalendarEventDeleteRejected),
    fork(watchCalendarEventList),
    fork(watchCalendarEventAll),
    fork(watchCalendarEventListStudent),
    fork(watchCalendarEventCreateRejected),
  ])
}

export const GLOBAL_STATE = 'ShortCourseRegistration'

export const SHORT_COURSE_REGISTRATION = 'application'
export const SHORT_COURSE_REGISTRATIONS = 'applications'
export const SHORT_COURSE_REGISTRATION_LIST = 'applicationList'
export const SHORT_COURSE_REGISTRATION_ALL = 'applicationAll'
export const SHORT_COURSE_REGISTRATION_STATUS = 'applicationStatus'
export const SHORT_COURSE_REGISTRATION_TYPES = 'applicationTypes'

export const SHORT_COURSE_REGISTRATION_FIELD_BATCH = 'applicationFieldBatch'

// category
export const SHORT_COURSE_REGISTRATION_CATEGORY = 'category'
export const SHORT_COURSE_REGISTRATION_CATEGORY_LIST = 'categoryList'
export const SHORT_COURSE_REGISTRATION_CATEGORY_ALL = 'categoryAll'

// request
export const SHORT_COURSE_REGISTRATION_REQUEST_ALL = 'requestAll'
export const SHORT_COURSE_REGISTRATION_REQUEST_EDIT = 'requestEdit'
export const SHORT_COURSE_REGISTRATION_REQUEST_UPDATE = 'requestUpdate'

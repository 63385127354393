import { WatchSaga, watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, call, fork, takeEvery } from 'redux-saga/effects'

import feedbackApi from '../api'
import {
  FEEDBACK_ALL,
  FEEDBACK_CREATE,
  FEEDBACK_EDIT,
  FEEDBACK_FORM,
  FEEDBACK_FORM_ALL,
  FEEDBACK_FORM_CREATE,
  FEEDBACK_FORM_DELETE,
  FEEDBACK_FORM_LIST,
  FEEDBACK_LIST,
  FEEDBACK_TYPES,
  FEEDBACK_UPDATE,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchFeedbackAll() {
  return watchSaga({
    action: actionTypes[FEEDBACK_ALL],
    api: feedbackApi[FEEDBACK_ALL],
  })
}

function watchFeedbackAllRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_ALL],
    message: true,
  })
}

function watchFeedbackList() {
  return watchSaga({
    action: actionTypes[FEEDBACK_LIST],
    api: feedbackApi[FEEDBACK_LIST],
  })
}

function watchFeedbackListRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_LIST],
    message: true,
  })
}

function watchFeedbackCreate() {
  return watchSaga({
    action: actionTypes[FEEDBACK_CREATE],
    api: feedbackApi[FEEDBACK_CREATE],
  })
}

function* watchFeedbackCreateFulfilled() {
  yield takeEvery(actionTypes[FEEDBACK_CREATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield call(onFulfilled, action)
  })
}

function watchFeedbackCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_CREATE],
    message: true,
  })
}

function watchFeedbackTypes() {
  return watchSaga({
    action: actionTypes[FEEDBACK_TYPES],
    api: feedbackApi[FEEDBACK_TYPES],
  })
}

function watchFeedbackTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_TYPES],
    message: true,
  })
}

function watchFeedback() {
  return watchSaga({
    action: actionTypes[FEEDBACK_EDIT],
    api: feedbackApi[FEEDBACK_EDIT],
  })
}

function* watchFeedbackFulfilled() {
  yield takeEvery(actionTypes[FEEDBACK_EDIT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield call(onFulfilled, action)
  })
}

function watchFeedbackRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_EDIT],
    message: true,
  })
}

function watchFeedbackUpdate() {
  return watchSaga({
    action: actionTypes[FEEDBACK_UPDATE],
    api: feedbackApi[FEEDBACK_UPDATE],
  })
}

function* watchFeedbackUpdateFulfilled() {
  yield takeEvery(actionTypes[FEEDBACK_UPDATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield call(onFulfilled, action)
  })
}

function watchFeedbackUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[FEEDBACK_UPDATE],
    message: true,
  })
}

const feedbackForm = new WatchSaga({ actionType: actionTypes[FEEDBACK_FORM], api: feedbackApi[FEEDBACK_FORM] })

const feedbackFormCreate = new WatchSaga({
  actionType: actionTypes[FEEDBACK_FORM_CREATE],
  api: feedbackApi[FEEDBACK_FORM_CREATE],
})

const feedbackFormDelete = new WatchSaga({
  actionType: actionTypes[FEEDBACK_FORM_DELETE],
  api: feedbackApi[FEEDBACK_FORM_DELETE],
})
const feedbackFormAll = new WatchSaga({
  actionType: actionTypes[FEEDBACK_FORM_ALL],
  api: feedbackApi[FEEDBACK_FORM_ALL],
})
const feedbackFormList = new WatchSaga({
  actionType: actionTypes[FEEDBACK_FORM_LIST],
  api: feedbackApi[FEEDBACK_FORM_LIST],
})

export function* feedbackSagas() {
  yield all([
    fork(watchFeedbackAll),
    fork(watchFeedbackAllRejected),
    fork(watchFeedbackList),
    fork(watchFeedbackListRejected),
    fork(watchFeedbackCreate),
    fork(watchFeedbackCreateFulfilled),
    fork(watchFeedbackCreateRejected),
    fork(watchFeedbackTypes),
    fork(watchFeedbackTypesRejected),
    fork(watchFeedback),
    fork(watchFeedbackFulfilled),
    fork(watchFeedbackRejected),
    fork(watchFeedbackUpdate),
    fork(watchFeedbackUpdateFulfilled),
    fork(watchFeedbackUpdateRejected),

    fork(feedbackFormList.watch),
    fork(feedbackFormList.watchFulfilled),
    fork(feedbackFormList.watchRejected),

    fork(feedbackFormCreate.watch),
    fork(feedbackFormCreate.watchFulfilled),
    fork(feedbackFormCreate.watchRejected),
  ])
}

import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  STC,
  STC_ALL,
  STC_CATEGORY,
  STC_CATEGORY_ALL,
  STC_CATEGORY_CREATE,
  STC_CATEGORY_LIST,
  STC_CREATE,
  STC_LIST,
  STC_STEP,
  STC_STEP_ALL,
  STC_STEP_COMPLETE,
  STC_STEP_CREATE,
  STC_STEP_LIST,
} from '../constants/actionTypes'
import {
  stcAction,
  stcAllAction,
  stcCategoryAction,
  stcCategoryAllAction,
  stcCategoryCreateAction,
  stcCategoryListAction,
  stcCreateAction,
  stcListAction,
  stcStepAction,
  stcStepAllAction,
  stcStepCompleteAction,
  stcStepCreateAction,
  stcStepListAction,
} from './actions'
import { initialState } from './initialState'

export const SHORT_TERM_COURSE = GLOBAL_STATE

const stcSlice = createSlice({
  name: SHORT_TERM_COURSE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(stcAction.pending, state => {
        state[STC].loading = true
      })
      .addCase(stcAction.rejected, (state, action) => {
        state[STC].loading = false
        state[STC].error = action.payload as AxiosError
      })
      .addCase(stcAction.fulfilled, (state, action) => {
        state[STC].loading = false
        state[STC].error = null
        state[STC].data = action.payload
      })

    builder
      .addCase(stcAllAction.pending, state => {
        state[STC_ALL].loading = true
      })
      .addCase(stcAllAction.rejected, (state, action) => {
        state[STC_ALL].loading = false
        state[STC_ALL].error = action.payload as AxiosError
      })
      .addCase(stcAllAction.fulfilled, (state, action) => {
        state[STC_ALL].loading = false
        state[STC_ALL].error = null
        state[STC_ALL].data = action.payload
      })

    builder
      .addCase(stcListAction.pending, state => {
        state[STC_LIST].loading = true
      })
      .addCase(stcListAction.rejected, (state, action) => {
        state[STC_LIST].loading = false
        state[STC_LIST].error = action.payload as AxiosError
      })
      .addCase(stcListAction.fulfilled, (state, action) => {
        state[STC_LIST].loading = false
        state[STC_LIST].error = null
        state[STC_LIST].data = action.payload
      })

    builder
      .addCase(stcCategoryAction.pending, state => {
        state[STC_CATEGORY].loading = true
      })
      .addCase(stcCategoryAction.rejected, (state, action) => {
        state[STC_CATEGORY].loading = false
        state[STC_CATEGORY].error = action.payload as AxiosError
      })
      .addCase(stcCategoryAction.fulfilled, (state, action) => {
        state[STC_CATEGORY].loading = false
        state[STC_CATEGORY].error = null
        state[STC_CATEGORY].data = action.payload
      })

    builder
      .addCase(stcCategoryAllAction.pending, state => {
        state[STC_CATEGORY_ALL].loading = true
      })
      .addCase(stcCategoryAllAction.rejected, (state, action) => {
        state[STC_CATEGORY_ALL].loading = false
        state[STC_CATEGORY_ALL].error = action.payload as AxiosError
      })
      .addCase(stcCategoryAllAction.fulfilled, (state, action) => {
        state[STC_CATEGORY_ALL].loading = false
        state[STC_CATEGORY_ALL].error = null
        state[STC_CATEGORY_ALL].data = action.payload
      })

    builder
      .addCase(stcCategoryCreateAction.pending, state => {
        state[STC_CATEGORY_CREATE].loading = true
      })
      .addCase(stcCategoryCreateAction.rejected, (state, action) => {
        state[STC_CATEGORY_CREATE].loading = false
        state[STC_CATEGORY_CREATE].error = action.payload as AxiosError
      })
      .addCase(stcCategoryCreateAction.fulfilled, (state, action) => {
        state[STC_CATEGORY_CREATE].loading = false
        state[STC_CATEGORY_CREATE].error = null
        state[STC_CATEGORY_CREATE].data = action.payload
      })

    builder
      .addCase(stcCategoryListAction.pending, state => {
        state[STC_CATEGORY_LIST].loading = true
      })
      .addCase(stcCategoryListAction.rejected, (state, action) => {
        state[STC_CATEGORY_LIST].loading = false
        state[STC_CATEGORY_LIST].error = action.payload as AxiosError
      })
      .addCase(stcCategoryListAction.fulfilled, (state, action) => {
        state[STC_CATEGORY_LIST].loading = false
        state[STC_CATEGORY_LIST].error = null
        state[STC_CATEGORY_LIST].data = action.payload
      })

    builder
      .addCase(stcCreateAction.pending, state => {
        state[STC_CREATE].loading = true
      })
      .addCase(stcCreateAction.rejected, (state, action) => {
        state[STC_CREATE].loading = false
        state[STC_CREATE].error = action.payload as AxiosError
      })
      .addCase(stcCreateAction.fulfilled, (state, action) => {
        state[STC_CREATE].loading = false
        state[STC_CREATE].error = null
        state[STC_CREATE].data = action.payload
      })

    builder
      .addCase(stcStepAction.pending, state => {
        state[STC_STEP].loading = true
      })
      .addCase(stcStepAction.rejected, (state, action) => {
        state[STC_STEP].loading = false
        state[STC_STEP].error = action.payload as AxiosError
      })
      .addCase(stcStepAction.fulfilled, (state, action) => {
        state[STC_STEP].loading = false
        state[STC_STEP].error = null
        state[STC_STEP].data = action.payload
      })

    builder
      .addCase(stcStepAllAction.pending, state => {
        state[STC_STEP_ALL].loading = true
      })
      .addCase(stcStepAllAction.rejected, (state, action) => {
        state[STC_STEP_ALL].loading = false
        state[STC_STEP_ALL].error = action.payload as AxiosError
      })
      .addCase(stcStepAllAction.fulfilled, (state, action) => {
        state[STC_STEP_ALL].loading = false
        state[STC_STEP_ALL].error = null
        state[STC_STEP_ALL].data = action.payload
      })

    builder
      .addCase(stcStepListAction.pending, state => {
        state[STC_STEP_LIST].loading = true
      })
      .addCase(stcStepListAction.rejected, (state, action) => {
        state[STC_STEP_LIST].loading = false
        state[STC_STEP_LIST].error = action.payload as AxiosError
      })
      .addCase(stcStepListAction.fulfilled, (state, action) => {
        state[STC_STEP_LIST].loading = false
        state[STC_STEP_LIST].error = null
        state[STC_STEP_LIST].data = action.payload
      })

    builder
      .addCase(stcStepCompleteAction.pending, state => {
        state[STC_STEP_COMPLETE].loading = true
      })
      .addCase(stcStepCompleteAction.rejected, (state, action) => {
        state[STC_STEP_COMPLETE].loading = false
        state[STC_STEP_COMPLETE].error = action.payload as AxiosError
      })
      .addCase(stcStepCompleteAction.fulfilled, (state, action) => {
        state[STC_STEP_COMPLETE].loading = false
        state[STC_STEP_COMPLETE].error = null
        state[STC_STEP_COMPLETE].data = action.payload
      })

    builder
      .addCase(stcStepCreateAction.pending, state => {
        state[STC_STEP_CREATE].loading = true
      })
      .addCase(stcStepCreateAction.rejected, (state, action) => {
        state[STC_STEP_CREATE].loading = false
        state[STC_STEP_CREATE].error = action.payload as AxiosError
      })
      .addCase(stcStepCreateAction.fulfilled, (state, action) => {
        state[STC_STEP_CREATE].loading = false
        state[STC_STEP_CREATE].error = null
        state[STC_STEP_CREATE].data = action.payload
      })
  },
})

export const { reducer: stcReducer } = stcSlice

import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../../Departments/constants/api'

export const department = data =>
  getInstance().get(`${API.DEPARTMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const departmentCreate = data => getInstance().post(API.DEPARTMENT, data)

export const departmentDelete = data => getInstance().delete(`${API.DEPARTMENT}${prop('id', data)}`)

export const departmentList = data =>
  getInstance().get(API.DEPARTMENT, {
    params: data,
  })

export const departmentAll = data =>
  getInstance().get(API.DEPARTMENT_ALL, {
    params: data,
  })

export const departmentLabels = data =>
  getInstance().get(API.DEPARTMENT_LABELS, {
    params: data,
  })

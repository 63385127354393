import { createAction } from '@helpers/createAction'

export const ASSESSMENT_OFFENCE = 'ASSESSMENT_OFFENCE'
export const ASSESSMENT_OFFENCE_CREATE = 'ASSESSMENT_OFFENCE_CREATE'
export const ASSESSMENT_OFFENCE_ALL = 'ASSESSMENT_OFFENCE_ALL'
export const ASSESSMENT_OFFENCE_DELETE = 'ASSESSMENT_OFFENCE_DELETE'
export const ASSESSMENT_OFFENCE_INVIGILATION = 'ASSESSMENT_OFFENCE_INVIGILATION'
export const ASSESSMENT_OFFENCE_INVIGILATION_CREATE = 'ASSESSMENT_OFFENCE_INVIGILATION_CREATE'
export const ASSESSMENT_OFFENCE_INVIGILATION_ALL = 'ASSESSMENT_OFFENCE_INVIGILATION_ALL'
export const ASSESSMENT_OFFENCE_INVIGILATION_DELETE = 'ASSESSMENT_OFFENCE_INVIGILATION_DELETE'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS = 'ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE =
  'ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL = 'ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL'
export const ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE =
  'ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES = 'ASSESSMENT_OFFENCE_INVIGILATION_MINUTES'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE = 'ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL = 'ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL'
export const ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE = 'ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE'

export const ASSESSMENT_OFFENCE_TYPE = 'ASSESSMENT_OFFENCE_TYPE'
export const ASSESSMENT_OFFENCE_TYPE_CREATE = 'ASSESSMENT_OFFENCE_TYPE_CREATE'
export const ASSESSMENT_OFFENCE_TYPE_ALL = 'ASSESSMENT_OFFENCE_TYPE_ALL'
export const ASSESSMENT_OFFENCE_TYPE_DELETE = 'ASSESSMENT_OFFENCE_TYPE_DELETE'
export const ASSESSMENT_OFFENCE_REPORT_LIST = 'ASSESSMENT_OFFENCE_REPORT_LIST'
export const ASSESSMENT_OFFENCE_REPORT_ALL = 'ASSESSMENT_OFFENCE_REPORT_ALL'
export const ASSESSMENT_OFFENCE_REPORT_CREATE = 'ASSESSMENT_OFFENCE_REPORT_CREATE'
export const ASSESSMENT_OFFENCE_CHANGE_STATUS = 'ASSESSMENT_OFFENCE_CHANGE_STATUS'
export const ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS = 'ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS'
export const ASSESSMENT_OFFENCE_PUBLISH = 'ASSESSMENT_OFFENCE_PUBLISH'
export const ASSESSMENT_OFFENCE_UNPUBLISH = 'ASSESSMENT_OFFENCE_UNPUBLISH'
export const REPORT_MISCONDUCT = 'REPORT_MISCONDUCT'

export default {
  [ASSESSMENT_OFFENCE]: createAction(ASSESSMENT_OFFENCE),
  [ASSESSMENT_OFFENCE_CREATE]: createAction(ASSESSMENT_OFFENCE_CREATE),
  [ASSESSMENT_OFFENCE_ALL]: createAction(ASSESSMENT_OFFENCE_ALL),
  [ASSESSMENT_OFFENCE_DELETE]: createAction(ASSESSMENT_OFFENCE_DELETE),
  [ASSESSMENT_OFFENCE_INVIGILATION]: createAction(ASSESSMENT_OFFENCE_INVIGILATION),
  [ASSESSMENT_OFFENCE_INVIGILATION_CREATE]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_CREATE),
  [ASSESSMENT_OFFENCE_INVIGILATION_ALL]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_ALL),
  [ASSESSMENT_OFFENCE_INVIGILATION_DELETE]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_DELETE),
  [ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS),
  [ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE]: createAction(
    ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE
  ),
  [ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL),
  [ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE]: createAction(
    ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE
  ),
  [ASSESSMENT_OFFENCE_INVIGILATION_MINUTES]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_MINUTES),
  [ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE),
  [ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL),
  [ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE]: createAction(ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE),
  [ASSESSMENT_OFFENCE_TYPE]: createAction(ASSESSMENT_OFFENCE_TYPE),
  [ASSESSMENT_OFFENCE_TYPE_CREATE]: createAction(ASSESSMENT_OFFENCE_TYPE_CREATE),
  [ASSESSMENT_OFFENCE_TYPE_ALL]: createAction(ASSESSMENT_OFFENCE_TYPE_ALL),
  [ASSESSMENT_OFFENCE_TYPE_DELETE]: createAction(ASSESSMENT_OFFENCE_TYPE_DELETE),
  [ASSESSMENT_OFFENCE_REPORT_LIST]: createAction(ASSESSMENT_OFFENCE_REPORT_LIST),
  [ASSESSMENT_OFFENCE_REPORT_ALL]: createAction(ASSESSMENT_OFFENCE_REPORT_ALL),
  [ASSESSMENT_OFFENCE_REPORT_CREATE]: createAction(ASSESSMENT_OFFENCE_REPORT_CREATE),
  [ASSESSMENT_OFFENCE_CHANGE_STATUS]: createAction(ASSESSMENT_OFFENCE_CHANGE_STATUS),
  [ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS]: createAction(ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS),
  [ASSESSMENT_OFFENCE_PUBLISH]: createAction(ASSESSMENT_OFFENCE_PUBLISH),
  [ASSESSMENT_OFFENCE_UNPUBLISH]: createAction(ASSESSMENT_OFFENCE_UNPUBLISH),
  [REPORT_MISCONDUCT]: createAction(REPORT_MISCONDUCT),
}

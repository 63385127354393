import { path, prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  GROUP_ALL,
  GROUP_ASSIGNED_LIST,
  GROUP_ASSIGN_TEACHER,
  GROUP_GROUPING,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/state'

const groupGlobalSelector = prop(GLOBAL_STATE)

export const groupAllSelector = createSelector(groupGlobalSelector, prop(GROUP_ALL))

/**
 * Returns students found in group based on "group={id}" query
 */
export const groupedStudentsSelector = createSelector(
  state => state,
  state => {
    const query = path(['router', 'location', 'query'], state)
    return query.group
  },
  groupAllSelector,
  (_, groupId, groupAll) => {
    if (!groupId || !prop('data', groupAll) || !groupAll.data.groups) return

    return groupAll.data.groups.find(item => item.group.id === Number(groupId))?.students
  }
)

export const groupGroupingSelector = createSelector(groupGlobalSelector, prop(GROUP_GROUPING))

export const groupShuffleSelector = createSelector(groupGlobalSelector, prop(GROUP_SHUFFLE))

export const groupStudentTransferSelector = createSelector(groupGlobalSelector, prop(GROUP_STUDENT_TRANSFER))

export const groupAssignSelector = createSelector(groupGlobalSelector, prop(GROUP_ASSIGN_TEACHER))

export const groupAssignedListSelector = createSelector(groupGlobalSelector, prop(GROUP_ASSIGNED_LIST))

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ASSESSMENT_AMENDMENT,
  ASSESSMENT_AMENDMENT_ALL,
  ASSESSMENT_AMENDMENT_CREATE,
  ASSESSMENT_AMENDMENT_LIST,
  ASSESSMENT_MARK_STATUS,
  GLOBAL_STATE,
} from '../constants/state'

export const assessmentMarkStatusSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_MARK_STATUS]), data => data)

export const assessmentAmendmentSelector = createSelector(path([GLOBAL_STATE, ASSESSMENT_AMENDMENT]), data => data)

export const assessmentAmendmentAllSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_AMENDMENT_ALL]),
  data => data
)

export const assessmentAmendmentListSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_AMENDMENT_LIST]),
  data => data
)

export const assessmentAmendmentCreateSelector = createSelector(
  path([GLOBAL_STATE, ASSESSMENT_AMENDMENT_CREATE]),
  data => data
)

export const assessmentMarkSelectors = {
  assessmentMarkStatusSelector,
  assessmentAmendmentSelector,
  assessmentAmendmentAllSelector,
  assessmentAmendmentListSelector,
  assessmentAmendmentCreateSelector,
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.DASHBOARD_STUDENT]: createState(actionTypes.DASHBOARD_STUDENT),
})

export const STUDENT_DASHBOARD = STATE.GLOBAL_STATE

export default reducer

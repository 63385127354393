import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const state = data =>
  getInstance('edulab').get(`${API.STATE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const stateCreate = data => getInstance('edulab').post(API.STATE, data)

export const stateDelete = data => getInstance('edulab').delete(`${API.STATE}${prop('id', data)}`)

export const stateList = data =>
  getInstance('edulab').get(API.STATE, {
    params: data,
  })

export const stateAll = data =>
  getInstance('edulab').get(API.STATE_ALL, {
    params: data,
  })

export const GENERAL_EVENT = 'GENERAL_EVENT'
export const TEACHING_WEEK = 'TEACHING_WEEK'
export const ASSESSMENT_WEEK = 'ASSESSMENT_WEEK'
export const ASSESSMENT_DAY = 'ASSESSMENT_DAY'
export const HOLIDAY = 'HOLIDAY'
export const DEADLINE = 'DEADLINE'
export const EXTRACURRICULAR_ACTIVITY = 'EXTRACURRICULAR_ACTIVITY'

export const calendarEventTypeOptions = [
  {
    value: GENERAL_EVENT,
    label: 'General Event',
  },
  {
    value: TEACHING_WEEK,
    label: 'Teaching Week',
  },
  {
    value: ASSESSMENT_WEEK,
    label: 'Assessment Week',
  },
  {
    value: ASSESSMENT_DAY,
    label: 'Assessment Day',
  },
  {
    value: HOLIDAY,
    label: 'Holiday',
  },
  {
    value: DEADLINE,
    label: 'Deadline',
  },
]

export const calendarExtracurricularActivitiesTypes = [
  {
    value: EXTRACURRICULAR_ACTIVITY,
    label: 'Extracurricular activity',
  },
]

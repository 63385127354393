import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
  GLOBAL_STATE,
} from '../constants/state'

export const enrollmentSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT]), data => data)

export const enrollmentCreateSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT_CREATE]), data => data)

export const enrollmentListSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT_LIST]), data => data)

export const enrollmentAllSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT_ALL]), data => data)

export const enrollmentStatusSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT_STATUS]), data => data)

export const enrollmentCoursesSelector = createSelector(path([GLOBAL_STATE, ENROLLMENT_COURSES]), data => data)

export const enrollmentSelectors = {
  enrollmentSelector,
  enrollmentCreateSelector,
  enrollmentListSelector,
  enrollmentAllSelector,
  enrollmentStatusSelector,
  enrollmentCoursesSelector,
}

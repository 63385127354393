import { toCamelCase } from '@helpers/toCamelCase'

export const VACANCY_POSITION_LIST = 'VACANCY_POSITION_LIST'
export const VACANCY_POSITION_ALL = 'VACANCY_POSITION_ALL'
export const VACANCY_POSITION_CREATE = 'VACANCY_POSITION_CREATE'
export const VACANCY_POSITION_DELETE = 'VACANCY_POSITION_DELETE'
export const VACANCY_POSITION = 'VACANCY_POSITION'
export const VACANCY_POSITION_ACTIVATE = 'VACANCY_POSITION_ACTIVATE'
export const VACANCY_POSITION_OPEN = 'VACANCY_POSITION_OPEN'
export const VACANCY_POSITION_CLOSE = 'VACANCY_POSITION_CLOSE'

export const GLOBAL_STATE = 'VacancyPosition'

export const actionNames = {
  [toCamelCase(VACANCY_POSITION_LIST)]: VACANCY_POSITION_LIST,
  [toCamelCase(VACANCY_POSITION_ALL)]: VACANCY_POSITION_ALL,
  [toCamelCase(VACANCY_POSITION_CREATE)]: VACANCY_POSITION_CREATE,
  [toCamelCase(VACANCY_POSITION_DELETE)]: VACANCY_POSITION_DELETE,
  [toCamelCase(VACANCY_POSITION)]: VACANCY_POSITION,
  [toCamelCase(VACANCY_POSITION_ACTIVATE)]: VACANCY_POSITION_ACTIVATE,
  [toCamelCase(VACANCY_POSITION_OPEN)]: VACANCY_POSITION_OPEN,
  [toCamelCase(VACANCY_POSITION_CLOSE)]: VACANCY_POSITION_CLOSE,
}

import actionTypes, {
  ASSESSMENT_OFFENCE_TYPE_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL,
  ASSESSMENT_OFFENCE,
  ASSESSMENT_OFFENCE_ALL,
  ASSESSMENT_OFFENCE_CHANGE_STATUS,
  ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS,
  ASSESSMENT_OFFENCE_CREATE,
  ASSESSMENT_OFFENCE_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION,
  ASSESSMENT_OFFENCE_INVIGILATION_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_DELETE,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE,
  ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE,
  ASSESSMENT_OFFENCE_REPORT_ALL,
  ASSESSMENT_OFFENCE_REPORT_CREATE,
  ASSESSMENT_OFFENCE_REPORT_LIST,
  ASSESSMENT_OFFENCE_TYPE,
  ASSESSMENT_OFFENCE_TYPE_CREATE,
  ASSESSMENT_OFFENCE_TYPE_DELETE,
  REPORT_MISCONDUCT,
  ASSESSMENT_OFFENCE_UNPUBLISH,
  ASSESSMENT_OFFENCE_PUBLISH,
} from '../constants/actionTypes'

export const assessmentOffence = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE].pending,
  payload,
})

export const assessmentOffenceCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_CREATE].pending,
  payload,
})

export const assessmentOffenceAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_ALL].pending,
  payload,
})

export const assessmentOffenceAllClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_ALL].clear,
})

export const assessmentOffenceClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE].clear,
})

export const assessmentOffenceDelete = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_DELETE].pending,
  payload,
})

export const assessmentOffenceInvigilation = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION].pending,
  payload,
})

export const assessmentOffenceInvigilationCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CREATE].pending,
  payload,
})

export const assessmentOffenceInvigilationAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_ALL].pending,
  payload,
})

export const assessmentOffenceInvigilationAllClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_ALL].clear,
})

export const assessmentOffenceInvigilationClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_ALL].clear,
})

export const assessmentOffenceInvigilationDelete = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_DELETE].pending,
  payload,
})

export const assessmentOffenceInvigilationConsultations = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS].pending,
  payload,
})

export const assessmentOffenceInvigilationConsultationsCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_CREATE].pending,
  payload,
})

export const assessmentOffenceInvigilationConsultationsAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL].pending,
  payload,
})

export const assessmentOffenceInvigilationConsultationsAllClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL].clear,
})

export const assessmentOffenceInvigilationConsultationsClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_ALL].clear,
})

export const assessmentOffenceInvigilationConsultationsDelete = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_CONSULTATIONS_DELETE].pending,
  payload,
})

export const assessmentOffenceInvigilationMinutes = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES].pending,
  payload,
})

export const assessmentOffenceInvigilationMinutesCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_CREATE].pending,
  payload,
})

export const assessmentOffenceInvigilationMinutesAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL].pending,
  payload,
})

export const assessmentOffenceInvigilationMinutesAllClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_ALL].clear,
})

export const assessmentOffenceInvigilationMinutesDelete = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_INVIGILATION_MINUTES_DELETE].pending,
  payload,
})

export const assessmentOffenceType = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_TYPE].pending,
  payload,
})

export const assessmentOffenceChangeStatus = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_CHANGE_STATUS].pending,
  payload,
})

export const assessmentOffenceTypeCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_TYPE_CREATE].pending,
  payload,
})

export const assessmentOffenceTypeAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_TYPE_ALL].pending,
  payload,
})

export const assessmentOffenceTypeClear = () => ({
  type: actionTypes[ASSESSMENT_OFFENCE_TYPE_ALL].clear,
})

export const assessmentOffenceTypeDelete = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_TYPE_DELETE].pending,
  payload,
})
export const assessmentOffencePublish = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_PUBLISH].pending,
  payload,
})
export const assessmentOffenceUnpublish = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_UNPUBLISH].pending,
  payload,
})

export const assessmentOffenceReportList = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_REPORT_LIST].pending,
  payload,
})

export const assessmentOffenceReportCreate = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_REPORT_CREATE].pending,
  payload,
})

export const assessmentOffenceReportAll = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_REPORT_ALL].pending,
  payload,
})
export const assessmentOffenceSubjectBoardStatusChange = payload => ({
  type: actionTypes[ASSESSMENT_OFFENCE_CHANGE_SUBJECT_BOARD_STATUS].pending,
  payload,
})

export const reportMisconduct = payload => ({
  type: actionTypes[REPORT_MISCONDUCT].pending,
  payload,
})

import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const authorizedInstance = getInstance()

export const stats: ApiFunc<'/stats/web', 'get'> = data =>
  authorizedInstance.get(API.STATS, {
    params: data,
  })

import { createAction } from '@helpers/createAction'

export const MODULES_OUTCOME = 'MODULES_OUTCOME'
export const MODULES_OUTCOME_CREATE = 'MODULES_OUTCOME_CREATE'
export const MODULES_OUTCOME_LIST = 'MODULES_OUTCOME_LIST'
export const CANCEL_MODULES_OUTCOME = 'CANCEL_MODULES_OUTCOME'

export default {
  [MODULES_OUTCOME]: createAction(MODULES_OUTCOME),
  [MODULES_OUTCOME_CREATE]: createAction(MODULES_OUTCOME_CREATE),
  [MODULES_OUTCOME_LIST]: createAction(MODULES_OUTCOME_LIST),
  [CANCEL_MODULES_OUTCOME]: createAction(CANCEL_MODULES_OUTCOME),
}

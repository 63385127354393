import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  ACCOUNT,
  ACCOUNT_ALL,
  ACCOUNT_CREATE,
  ACCOUNT_LIST,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_TYPES,
  GLOBAL_STATE,
} from '../constants/state'

export const accountSelector = createSelector(path([GLOBAL_STATE, ACCOUNT]), data => data)

export const accountCreateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_CREATE]), data => data)

export const accountListSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_LIST]), data => data)

export const accountAllSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_ALL]), data => data)

export const accountTypesSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_TYPES]), data => data)

export const accountProfileUpdateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_PROFILE_UPDATE]), data => data)

export const accountSelectors = {
  accountSelector,
  accountListSelector,
  accountAllSelector,
  accountProfileUpdateSelector,
  accountCreateSelector,
  accountTypesSelector,
}

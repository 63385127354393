import actionTypes, {
  CALENDAR_EVENT,
  CALENDAR_EVENT_ALL,
  CALENDAR_EVENT_CREATE,
  CALENDAR_EVENT_DELETE,
  CALENDAR_EVENT_LIST,
  CALENDAR_EVENT_LIST_STUDENT,
} from '../constants/actionTypes'

export const calendarEvent = payload => ({
  type: actionTypes[CALENDAR_EVENT].pending,
  payload,
})

export const calendarEventClear = payload => ({
  type: actionTypes[CALENDAR_EVENT].clear,
  payload,
})

export const calendarEventCreate = payload => ({
  type: actionTypes[CALENDAR_EVENT_CREATE].pending,
  payload,
})

export const calendarEventDelete = payload => ({
  type: actionTypes[CALENDAR_EVENT_DELETE].pending,
  payload,
})

export const calendarEventList = payload => ({
  type: actionTypes[CALENDAR_EVENT_LIST].pending,
  payload,
})

export const calendarEventListClear = () => ({
  type: actionTypes[CALENDAR_EVENT_LIST].clear,
})

export const calendarEventAll = payload => ({
  type: actionTypes[CALENDAR_EVENT_ALL].pending,
  payload,
})

export const calendarEventAllClear = payload => ({
  type: actionTypes[CALENDAR_EVENT_ALL].clear,
  payload,
})

export const calendarEventListStudent = payload => ({
  type: actionTypes[CALENDAR_EVENT_LIST_STUDENT].pending,
  payload,
})

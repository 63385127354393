import { dispatchActions } from '@helpers/dispatchActions'
import { DispatchFunc } from '@src/hooks/reduxHooks'
import { MouseEvent, useState } from 'react'

export function useUniversalModal(
  onOpen = (item?: Record<string, number | string | boolean>) => {},
  onClose = () => {},
  dispatchArgs: { actions: []; dispatch?: ReturnType<DispatchFunc>; fetchParams: Record<string, any> } = {
    actions: [],
    fetchParams: {},
  }
): [
  { state: boolean; item?: Record<string, number | string | boolean> },
  (...args: [MouseEvent<HTMLButtonElement>] | any[]) => void,
  () => void,
  (item: Record<string, number | string | boolean>) => void
] {
  const [state, setState] = useState<{ state: boolean; item?: Record<string, number | string | boolean> }>({
    state: false,
    item: undefined,
  })
  function setNewItem(item: Record<string, number | string | boolean>) {
    return setState(state => ({
      ...state,
      item,
    }))
  }

  function onStateOpen(...args: [MouseEvent<HTMLButtonElement>] | any[]) {
    let item
    if (args.length > 1) {
      item = args[1]
    } else if (args[0] && !args[0].nativeEvent) {
      item = args[0]
    }

    onOpen(item)

    setState({
      state: true,
      item,
    })

    dispatchActions(dispatchArgs)
  }

  function onStateClose() {
    onClose()

    return setState({
      item: undefined,
      state: false,
    })
  }

  return [state, onStateOpen, onStateClose, setNewItem]
}

export const GLOBAL_STATE = 'Admission'

export const APPLICATION = 'application'
export const APPLICATIONS = 'applications'
export const APPLICATION_LIST = 'applicationList'
export const APPLICATION_ALL = 'applicationAll'
export const APPLICATION_FIELDS = 'applicationFields'
export const APPLICATION_GENERATE = 'applicationGenerate'
export const APPLICATION_STATUS = 'applicationStatus'
export const APPLICATION_TYPES = 'applicationTypes'

export const APPLICATION_FIELD_BATCH = 'applicationFieldBatch'

// category
export const APPLICATION_CATEGORY = 'category'
export const APPLICATION_CATEGORY_LIST = 'categoryList'
export const APPLICATION_CATEGORY_ALL = 'categoryAll'

// request
export const APPLICATION_REQUEST_ALL = 'requestAll'
export const APPLICATION_REQUEST_EDIT = 'requestEdit'
export const APPLICATION_REQUEST_UPDATE = 'requestUpdate'

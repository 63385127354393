import { createAction } from '@helpers/createAction'

export const CALENDAR_EVENT = 'CALENDAR_EVENT'
export const CALENDAR_EVENT_CREATE = 'CALENDAR_EVENT_CREATE'
export const CALENDAR_EVENT_DELETE = 'CALENDAR_EVENT_DELETE'
export const CALENDAR_EVENT_LIST = 'CALENDAR_EVENT_LIST'
export const CALENDAR_EVENT_LIST_STUDENT = 'CALENDAR_EVENT_LIST_STUDENT'
export const CALENDAR_EVENT_ALL = 'CALENDAR_EVENT_LIST_ALL'

export default {
  [CALENDAR_EVENT]: createAction(CALENDAR_EVENT),
  [CALENDAR_EVENT_CREATE]: createAction(CALENDAR_EVENT_CREATE),
  [CALENDAR_EVENT_DELETE]: createAction(CALENDAR_EVENT_DELETE),
  [CALENDAR_EVENT_LIST]: createAction(CALENDAR_EVENT_LIST),
  [CALENDAR_EVENT_ALL]: createAction(CALENDAR_EVENT_ALL),
  [CALENDAR_EVENT_LIST_STUDENT]: createAction(CALENDAR_EVENT_LIST_STUDENT),
}

import { createAction } from '@helpers/createAction'

export const APPLICATION = 'APPLICATION'
export const APPLICATION_CREATE = 'APPLICATION_CREATE'
export const APPLICATION_DELETE = 'APPLICATION_DELETE'
export const APPLICATION_LIST = 'APPLICATION_LIST'
export const APPLICATION_ALL = 'APPLICATION_ALL'
export const APPLICATION_TYPES = 'APPLICATION_TYPES'
export const APPLICATION_STATUS = 'APPLICATION_STATUS'
export const APPLICATION_GENERATE = 'APPLICATION_GENERATE'
export const APPLICATION_FIELDS = 'APPLICATION_FIELDS'

export const APPLICATION_FIELD_BATCH = 'APPLICATION_FIELD_BATCH'
export const APPLICATION_FIELD_BATCH_CREATE = 'APPLICATION_FIELD_BATCH_CREATE'

// category
export const APPLICATION_CATEGORY = 'APPLICATION_CATEGORY'
export const APPLICATION_CATEGORY_CREATE = 'APPLICATION_CATEGORY_CREATE'
export const APPLICATION_CATEGORY_DELETE = 'APPLICATION_CATEGORY_DELETE'
export const APPLICATION_CATEGORY_LIST = 'APPLICATION_CATEGORY_LIST'
export const APPLICATION_CATEGORY_ALL = 'APPLICATION_CATEGORY_ALL'

// request
export const APPLICATION_REQUEST = 'APPLICATION_REQUEST'
export const APPLICATION_REQUEST_ALL = 'APPLICATION_REQUEST_ALL'
export const APPLICATION_REQUEST_EDIT = 'APPLICATION_REQUEST_EDIT'
export const APPLICATION_REQUEST_UPDATE = 'APPLICATION_REQUEST_UPDATE'

export default {
  [APPLICATION]: createAction(APPLICATION),
  [APPLICATION_CREATE]: createAction(APPLICATION_CREATE),
  [APPLICATION_DELETE]: createAction(APPLICATION_DELETE),
  [APPLICATION_STATUS]: createAction(APPLICATION_STATUS),
  [APPLICATION_LIST]: createAction(APPLICATION_LIST),
  [APPLICATION_ALL]: createAction(APPLICATION_ALL),
  [APPLICATION_TYPES]: createAction(APPLICATION_TYPES),
  [APPLICATION_FIELD_BATCH]: createAction(APPLICATION_FIELD_BATCH),
  [APPLICATION_FIELD_BATCH_CREATE]: createAction(APPLICATION_FIELD_BATCH_CREATE),
  [APPLICATION_CATEGORY]: createAction(APPLICATION_CATEGORY),
  [APPLICATION_CATEGORY_CREATE]: createAction(APPLICATION_CATEGORY_CREATE),
  [APPLICATION_CATEGORY_DELETE]: createAction(APPLICATION_CATEGORY_DELETE),
  [APPLICATION_CATEGORY_LIST]: createAction(APPLICATION_CATEGORY_LIST),
  [APPLICATION_CATEGORY_ALL]: createAction(APPLICATION_CATEGORY_ALL),
  [APPLICATION_REQUEST]: createAction(APPLICATION_REQUEST),
  [APPLICATION_REQUEST_ALL]: createAction(APPLICATION_REQUEST_ALL),
  [APPLICATION_REQUEST_EDIT]: createAction(APPLICATION_REQUEST_EDIT),
  [APPLICATION_REQUEST_UPDATE]: createAction(APPLICATION_REQUEST_UPDATE),
  [APPLICATION_FIELDS]: createAction(APPLICATION_FIELDS),
  [APPLICATION_GENERATE]: createAction(APPLICATION_GENERATE),
}

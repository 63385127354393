import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const generalApplication = data =>
  getInstance().get(`${API.GENERAL_APPLICATION_FORM}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const generalApplicationCreate = data => getInstance().post(API.GENERAL_APPLICATION_FORM, data)

export const generalApplicationDelete = data =>
  getInstance().delete(`${API.GENERAL_APPLICATION_FORM}${prop('id', data)}`)

export const generalApplicationList = data =>
  getInstance().get(API.GENERAL_APPLICATION, {
    params: data,
  })

export const generalApplicationAll = data =>
  getInstance().get(API.GENERAL_APPLICATION_ALL, {
    params: data,
  })

export const generalApplicationTypes = data =>
  getInstance().get(API.GENERAL_APPLICATION_TYPES, {
    params: data,
  })

export const generalApplicationStatus = data => getInstance().put(API.GENERAL_APPLICATION_STATUS, data)

export const generalApplicationFieldBatchCreate = data => getInstance().post(API.GENERAL_APPLICATION_FIELD_BATCH, data)

export const generalApplicationFieldBatch = data =>
  getInstance().get(`${API.GENERAL_APPLICATION_FIELD_BATCH}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

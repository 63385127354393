import { ApiFunc, getInstance } from '@src/helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

// Product Items
export const productItem: ApiFunc<`${typeof API.PRODUCT_ITEM}{id}`, 'get'> = data =>
  instance.get(`${API.PRODUCT_ITEM}${'id' in data ? data.id : data.name}`)

export const productItemClient: ApiFunc<`${typeof API.PRODUCT_ITEM_CLIENT}{id}`, 'get'> = data =>
  instance.get(`${API.PRODUCT_ITEM_CLIENT}${'id' in data ? data.id : data.name}`)

export const productItemList: ApiFunc<`${typeof API.PRODUCT_ITEM}`, 'get'> = data =>
  instance.get(API.PRODUCT_ITEM, { params: data })

export const productItemCreate: ApiFunc<`${typeof API.PRODUCT_ITEM}`, 'post'> = data =>
  instance.post(API.PRODUCT_ITEM, data)

export const productItemDelete: ApiFunc<`${typeof API.PRODUCT_ITEM}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_ITEM}${'id' in data ? data.id : data.name}`)

export const productItemAll: ApiFunc<`${typeof API.PRODUCT_ITEM_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_ITEM_ALL, { params: data })

export const productItemAllShort: ApiFunc<`${typeof API.PRODUCT_ITEM_ALL_SHORT}`, 'get'> = data =>
  instance.get(API.PRODUCT_ITEM_ALL_SHORT, { params: data })

// Product Categories
export const productCategoryList: ApiFunc<`${typeof API.PRODUCT_CATEGORY}`, 'get'> = data =>
  instance.get(API.PRODUCT_CATEGORY, { params: data })

export const productCategoryCreate: ApiFunc<`${typeof API.PRODUCT_CATEGORY}`, 'post'> = data =>
  instance.post(API.PRODUCT_CATEGORY, data)

export const productCategoryDelete: ApiFunc<`${typeof API.PRODUCT_CATEGORY}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_CATEGORY}${'id' in data ? data.id : data.name}`)

export const productCategoryAll: ApiFunc<`${typeof API.PRODUCT_CATEGORY_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_CATEGORY_ALL, { params: data })

// Product Colors
export const productColorList: ApiFunc<`${typeof API.PRODUCT_COLOR}`, 'get'> = data =>
  instance.get(API.PRODUCT_COLOR, { params: data })

// export const productColorCreate: ApiFunc<`${typeof API.PRODUCT_COLOR}`, 'post'> = data =>
//   instance.post(API.PRODUCT_COLOR, data)

export const productColorDelete: ApiFunc<`${typeof API.PRODUCT_COLOR}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_COLOR}${'id' in data ? data.id : data.name}`)

export const productColorAll: ApiFunc<`${typeof API.PRODUCT_COLOR_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_COLOR_ALL, { params: data })

// Product Sizes
export const productSizeList: ApiFunc<`${typeof API.PRODUCT_SIZE}`, 'get'> = data =>
  instance.get(API.PRODUCT_SIZE, { params: data })

// export const productSizeCreate: ApiFunc<`${typeof API.PRODUCT_SIZE}`, 'post'> = data =>
//   instance.post(API.PRODUCT_SIZE, data)

export const productSizeDelete: ApiFunc<`${typeof API.PRODUCT_SIZE}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_SIZE}${'id' in data ? data.id : data.name}`)

export const productSizeAll: ApiFunc<`${typeof API.PRODUCT_SIZE_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_SIZE_ALL, { params: data })

// Product Materials
export const productMaterialList: ApiFunc<`${typeof API.PRODUCT_MATERIAL}`, 'get'> = data =>
  instance.get(API.PRODUCT_MATERIAL, { params: data })

export const productMaterialCreate: ApiFunc<`${typeof API.PRODUCT_MATERIAL}`, 'post'> = data =>
  instance.post(API.PRODUCT_MATERIAL, data)

export const productMaterialDelete: ApiFunc<`${typeof API.PRODUCT_MATERIAL}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_MATERIAL}${'id' in data ? data.id : data.name}`)

export const productMaterialAll: ApiFunc<`${typeof API.PRODUCT_MATERIAL_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_MATERIAL_ALL, { params: data })

// Product Trade
export const productOrder: ApiFunc<`${typeof API.PRODUCT_TRADE}`, 'post'> = data =>
  instance.post(API.PRODUCT_TRADE, data)

export const productTradeList: ApiFunc<`${typeof API.PRODUCT_TRADE}`, 'get'> = data =>
  instance.get(API.PRODUCT_TRADE, { params: data })

export const productTradeCreate: ApiFunc<`${typeof API.PRODUCT_TRADE}`, 'post'> = data =>
  instance.post(API.PRODUCT_TRADE, data)

export const productTradeDelete: ApiFunc<`${typeof API.PRODUCT_TRADE}{id}`, 'delete'> = data =>
  instance.delete(`${API.PRODUCT_TRADE}${'id' in data ? data.id : data.name}`)

export const productTradeAll: ApiFunc<`${typeof API.PRODUCT_TRADE_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_TRADE_ALL, { params: data })

export const productTradeBuyerAll: ApiFunc<`${typeof API.PRODUCT_TRADE_BUYER_ALL}`, 'get'> = data =>
  instance.get(API.PRODUCT_TRADE_BUYER_ALL, { params: data })

// Product Trade Action
export const productTradeAccept: ApiFunc<`${typeof API.PRODUCT_TRADE_ACCEPT}`, 'put'> = data =>
  instance.put(API.PRODUCT_TRADE_ACCEPT, data)

export const productTradeMark: ApiFunc<`${typeof API.PRODUCT_TRADE_MARK}`, 'put'> = data =>
  instance.put(API.PRODUCT_TRADE_MARK, data)

export const productTradeRequest: ApiFunc<`${typeof API.PRODUCT_TRADE_REQUEST}`, 'put'> = data =>
  instance.put(API.PRODUCT_TRADE_REQUEST, data)

import { getDataFromState } from '@helpers/get'
import { createSelector } from 'reselect'

import * as STATE from '../constants/state'

export const shortCourseRegistrationSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationListSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_LIST, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationTypesSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_TYPES, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationStatusSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_STATUS, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationAllSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const shortCourseRegistrationFieldBatchSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_FIELD_BATCH, STATE.GLOBAL_STATE),
  data => data
)

// category
export const shortCourseRegistrationCategorySelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_CATEGORY, STATE.GLOBAL_STATE),
  data => data
)
export const shortCourseRegistrationCategoryListSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_CATEGORY_LIST, STATE.GLOBAL_STATE),
  data => data
)
export const shortCourseRegistrationCategoryAllSelector = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_CATEGORY_ALL, STATE.GLOBAL_STATE),
  data => data
)

// request
export const shortCourseRegistrationRequestAll = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_REQUEST_ALL, STATE.GLOBAL_STATE),
  data => data
)
export const shortCourseRegistrationRequestEdit = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_REQUEST_EDIT, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationRequestUpdate = createSelector(
  getDataFromState(STATE.SHORT_COURSE_REGISTRATION_REQUEST_UPDATE, STATE.GLOBAL_STATE),
  data => data
)

export const shortCourseRegistrationSelectors = {
  shortCourseRegistrationSelector,
  shortCourseRegistrationListSelector,
  shortCourseRegistrationAllSelector,
  shortCourseRegistrationFieldBatchSelector,
  shortCourseRegistrationCategorySelector,
  shortCourseRegistrationCategoryListSelector,
  shortCourseRegistrationCategoryAllSelector,
  shortCourseRegistrationRequestAll,
  shortCourseRegistrationRequestEdit,
  shortCourseRegistrationRequestUpdate,
  shortCourseRegistrationTypesSelector,
  shortCourseRegistrationStatusSelector,
}

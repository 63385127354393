import { createAction } from '../../../../helpers/createAction'

export const CCLS_MODULE = 'CCLS_MODULE'
export const CCLS_MODULE_CREATE = 'CCLS_MODULE_CREATE'
export const CCLS_MODULE_TEACHER_UPDATE = 'CCLS_MODULE_TEACHER_UPDATE'
export const CCLS_MODULE_DELETE = 'CCLSMODULE_DELETE'
export const CCLS_MODULE_LIST = 'CCLS_MODULE_LIST'
export const CCLS_MODULE_ALL = 'CCLS_MODULE_ALL'
export const CCLS_MODULES_FILTER = 'CCLS_MODULES_FILTER'
export const CCLS_MODULE_UPLOAD_MATERIALS = 'CCLS_MODULE_UPLOAD_MATERIALS'

export default {
  [CCLS_MODULE]: createAction(CCLS_MODULE),
  [CCLS_MODULE_CREATE]: createAction(CCLS_MODULE_CREATE),
  [CCLS_MODULE_TEACHER_UPDATE]: createAction(CCLS_MODULE_TEACHER_UPDATE),
  [CCLS_MODULE_DELETE]: createAction(CCLS_MODULE_DELETE),
  [CCLS_MODULE_LIST]: createAction(CCLS_MODULE_LIST),
  [CCLS_MODULE_ALL]: createAction(CCLS_MODULE_ALL),
  [CCLS_MODULES_FILTER]: createAction(CCLS_MODULES_FILTER),
  [CCLS_MODULE_UPLOAD_MATERIALS]: createAction(CCLS_MODULE_UPLOAD_MATERIALS),
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.EDUCATION_PLACE_LIST]: createState(actionTypes.EDUCATION_PLACE_LIST),
  [STATE.EDUCATION_PLACE_CREATE]: createState(actionTypes.EDUCATION_PLACE_CREATE),
  [STATE.EDUCATION_PLACE_ALL]: createState(actionTypes.EDUCATION_PLACE_ALL),
  [STATE.EDUCATION_PLACE]: createState(actionTypes.EDUCATION_PLACE),
})

export const EDUCATION_PLACE = STATE.GLOBAL_STATE

export default reducer

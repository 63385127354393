import createCSV from '@helpers/createCSV'
import { watchSaga } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { dissoc, join, map, path, pathOr, pipe, prop } from 'ramda'
import { jsonToCSV } from 'react-papaparse'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_LIST,
} from '../constants/actionTypes'

function watchCourseCampaign() {
  return watchSaga({
    action: actionTypes[COURSE_CAMPAIGN],
    api: API.courseCampaign,
  })
}

function watchCourseCampaignCreate() {
  return watchSaga({
    action: actionTypes[COURSE_CAMPAIGN_CREATE],
    api: API.courseCampaignCreate,
  })
}

function* watchCourseCampaignCreateFulfilled() {
  yield takeEvery(actionTypes[COURSE_CAMPAIGN_CREATE].fulfilled, function* () {
    yield put(actions.courseCampaignList())
  })
}

function watchCourseCampaignDelete() {
  return watchSaga({
    action: actionTypes[COURSE_CAMPAIGN_DELETE],
    api: API.courseCampaignDelete,
  })
}

function* watchCourseCampaignDeleteFulfilled() {
  yield takeEvery(actionTypes[COURSE_CAMPAIGN_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.courseCampaignList())
  })
}

function* watchCourseCampaignDeleteRejected() {
  yield takeEvery(actionTypes[COURSE_CAMPAIGN_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCourseCampaignList() {
  return watchSaga({
    action: actionTypes[COURSE_CAMPAIGN_LIST],
    api: API.courseCampaignList,
  })
}

function watchCourseCampaignAll() {
  return watchSaga({
    action: actionTypes[COURSE_CAMPAIGN_ALL],
    api: API.courseCampaignAll,
  })
}

function* watchCourseCampaignAllFulfilled() {
  yield takeEvery(actionTypes[COURSE_CAMPAIGN_ALL].fulfilled, function* ({ payload, args }) {
    const csv = prop('csv', args)

    if (csv) {
      const newCSV = pipe(
        map(item => ({
          Title: prop('title', item),
          'Academic year': path(['academic_year', 'name'], item),
          'Course levels': join(',', prop('course_levels_numbers', item) || []),
        })),
        map(dissoc('course_levels_numbers')),
        map(dissoc('course_specifications')),
        map(dissoc('course_validation_evidences')),
        item => jsonToCSV(item, { header: true })
      )(payload || [])

      createCSV(newCSV, 'Activate Course.csv')
    }
    const onFulfilled = prop('onFulfilled', args)
    onFulfilled && onFulfilled(payload)
  })
}

export function* courseSaga() {
  yield all([
    fork(watchCourseCampaign),
    fork(watchCourseCampaignCreate),
    fork(watchCourseCampaignCreateFulfilled),
    fork(watchCourseCampaignDelete),
    fork(watchCourseCampaignDeleteFulfilled),
    fork(watchCourseCampaignDeleteRejected),
    fork(watchCourseCampaignList),
    fork(watchCourseCampaignAll),
    fork(watchCourseCampaignAllFulfilled),
  ])
}

import { path } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  LESSON,
  LESSON_ALL,
  LESSON_ASSESSMENT,
  LESSON_ASSESSMENT_ALL,
  LESSON_CREATE,
  LESSON_HOMEWORK_UPLOAD,
  LESSON_LIST,
  LESSON_MARK_ASSESSMENT,
} from '../constants/state'

export const lessonSelector = createSelector(path([GLOBAL_STATE, LESSON]), data => data)

export const lessonListSelector = createSelector(path([GLOBAL_STATE, LESSON_LIST]), data => data)

export const lessonAllSelector = createSelector(path([GLOBAL_STATE, LESSON_ALL]), data => data)

export const lessonCreateSelector = createSelector(path([GLOBAL_STATE, LESSON_CREATE]), data => data)

export const lessonHomeworkUploadSelector = createSelector(path([GLOBAL_STATE, LESSON_HOMEWORK_UPLOAD]), data => data)

export const lessonAssessmentSelector = createSelector(path([GLOBAL_STATE, LESSON_ASSESSMENT]), data => data)

export const lessonAssessmentAllSelector = createSelector(path([GLOBAL_STATE, LESSON_ASSESSMENT_ALL]), data => data)

export const lessonMarkAssessmentSelector = createSelector(path([GLOBAL_STATE, LESSON_MARK_ASSESSMENT]), data => data)

export const lessonSelectors = {
  lessonSelector,
  lessonListSelector,
  lessonAllSelector,
  lessonCreateSelector,
  lessonHomeworkUploadSelector,
  lessonAssessmentSelector,
  lessonAssessmentAllSelector,
}

import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ACHIEVEMENT_RECORDS]: createState(actionTypes.ACHIEVEMENT_RECORDS),
  [STATE.ACHIEVEMENT_RECORDS_ALL]: createState(actionTypes.ACHIEVEMENT_RECORDS_ALL),
  [STATE.ACHIEVEMENT_RECORDS_LIST]: createState(actionTypes.ACHIEVEMENT_RECORDS_LIST),
  [STATE.ACHIEVEMENT_RECORDS_TYPES]: createState(actionTypes.ACHIEVEMENT_RECORDS_TYPES),
  [STATE.ACHIEVEMENT_RECORDS_STUDENT]: createState(actionTypes.ACHIEVEMENT_RECORDS_STUDENT),
  [STATE.ACHIEVEMENT_RECORDS_CHANGE_STATUS]: createState(actionTypes.ACHIEVEMENT_RECORDS_CHANGE_STATUS),
  [STATE.ACHIEVEMENT_RECORDS]: createState(actionTypes.ACHIEVEMENT_RECORDS_CREATE),
  [STATE.ACHIEVEMENT_RECORDS_CREATE]: createState(actionTypes.ACHIEVEMENT_RECORDS_CREATE),
})

export const ACHIEVEMENT_RECORDS = STATE.GLOBAL_STATE

export default reducer

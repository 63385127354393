import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const staff = data =>
  getInstance().get(`${API.STAFF}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const staffCreate = data => getInstance().post(API.STAFF, data)

export const staffDelete = data => getInstance().delete(`${API.STAFF}${prop('id', data)}`)

export const staffList = data =>
  getInstance().get(API.STAFF, {
    params: data,
  })

export const staffAll = data =>
  getInstance().get(API.STAFF_ALL, {
    params: data,
  })

export const staffUsername = data =>
  getInstance().get(`${API.STAFF_USERNAME}${prop('username', data)}`, {
    params: dissoc('username', data),
  })

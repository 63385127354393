export const GLOBAL_STATE = 'Student'

export const STUDENT = 'student'
export const STUDENT_LIST = 'studentList'
export const STUDENT_GUARD_LIST = 'studentGuardList'
export const STUDENT_USERNAME = 'studentUsername'
export const STUDENT_ALL = 'studentAll'
export const GENERATE_TRANSCRIPT = 'generateTranscript'
export const STUDENT_ACHIEVEMENT_RECORDS = 'studentAchievementRecords'
export const STUDENT_PERSONAL_INFO_UPDATE = 'studentPersonalInfoUpdate'
export const STUDENT_CONTACT_INFO_UPDATE = 'studentContactInfoUpdate'

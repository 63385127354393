import actionTypes, { STATE_DELETE, STATE, STATE_ALL, STATE_CREATE, STATE_LIST } from '../constants/actionTypes'

export const state = payload => ({
  type: actionTypes[STATE].pending,
  payload,
})

export const stateClear = () => ({
  type: actionTypes[STATE].clear,
})

export const stateCreate = payload => ({
  type: actionTypes[STATE_CREATE].pending,
  payload,
})

export const stateDelete = payload => ({
  type: actionTypes[STATE_DELETE].pending,
  payload,
})

export const stateList = payload => ({
  type: actionTypes[STATE_LIST].pending,
  payload,
})

export const stateListClear = () => ({
  type: actionTypes[STATE_LIST].clear,
})

export const stateAll = payload => ({
  type: actionTypes[STATE_ALL].pending,
  payload,
})

export const stateAllClear = () => ({
  type: actionTypes[STATE_ALL].clear,
})

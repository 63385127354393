import { createState } from '@helpers/createState'
import { combineReducers } from 'redux'

import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ENROLLMENT]: createState(actionTypes.ENROLLMENT),
  [STATE.ENROLLMENT_CREATE]: createState(actionTypes.ENROLLMENT_CREATE),
  [STATE.ENROLLMENT_LIST]: createState(actionTypes.ENROLLMENT_LIST),
  [STATE.ENROLLMENT_ALL]: createState(actionTypes.ENROLLMENT_ALL),
  [STATE.ENROLLMENT_STATUS]: createState(actionTypes.ENROLLMENT_STATUS),
  [STATE.ENROLLMENT_COURSES]: createState(actionTypes.ENROLLMENT_COURSES),
})

export const ENROLLMENT = STATE.GLOBAL_STATE

export default reducer

import { watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { CITY, CITY_ALL, CITY_CREATE, CITY_DELETE, CITY_LIST } from '../constants/actionTypes'
import * as actions from './actions'

function watchCity() {
  return watchSaga({
    action: actionTypes[CITY],
    api: API.city,
  })
}

function watchCityCreate() {
  return watchSaga({
    action: actionTypes[CITY_CREATE],
    api: API.cityCreate,
  })
}

function watchCityCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[CITY_CREATE],
    message: {
      title: 'Success',
      description: 'City has been created',
    },
    filter: {
      takeActionFromPayload: true,
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchCityDelete() {
  return watchSaga({
    action: actionTypes[CITY_DELETE],
    api: API.cityDelete,
  })
}

function* watchCityDeleteFulfilled() {
  yield takeEvery(actionTypes[CITY_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.cityList())
  })
}

function* watchCityDeleteRejected() {
  yield takeEvery(actionTypes[CITY_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCityList() {
  return watchSaga({
    action: actionTypes[CITY_LIST],
    api: API.cityList,
  })
}

function watchCityAll() {
  return watchSaga({
    action: actionTypes[CITY_ALL],
    api: API.cityAll,
  })
}

export function* citySaga() {
  yield all([
    fork(watchCity),
    fork(watchCityCreate),
    fork(watchCityCreateFulfilled),
    fork(watchCityDelete),
    fork(watchCityDeleteFulfilled),
    fork(watchCityDeleteRejected),
    fork(watchCityList),
    fork(watchCityAll),
  ])
}

import { DefaultState } from '@src/redux/types'

import {
  MEAL,
  MEAL_ACTIVATION,
  MEAL_ACTIVATION_ALL,
  MEAL_ACTIVATION_DELETE,
  MEAL_ACTIVATION_UPDATE_STATUS,
  MEAL_ALL,
  MEAL_CREATE,
  MEAL_DELETE,
  MEAL_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [MEAL]: DefaultState<`${typeof API.MEAL}{id}`, 'get'>
  [MEAL_ALL]: DefaultState<`${typeof API.MEAL_ALL}`, 'get'>
  [MEAL_LIST]: DefaultState<`${typeof API.MEAL}`, 'get'>
  [MEAL_CREATE]: DefaultState<`${typeof API.MEAL}`, 'post'>
  [MEAL_DELETE]: DefaultState<`${typeof API.MEAL}{ids}`, 'delete'>
  [MEAL_ACTIVATION]: DefaultState<`${typeof API.MEAL_ACTIVATION}{id}`, 'get'>
  [MEAL_ACTIVATION_ALL]: DefaultState<`${typeof API.MEAL_ACTIVATION_ALL}`, 'get'>
  // [MEAL_ACTIVATION_CREATE]: DefaultState<`${typeof API.MEAL_ACTIVATION}`, 'post'>
  [MEAL_ACTIVATION_DELETE]: DefaultState<`${typeof API.MEAL_ACTIVATION}{id}`, 'delete'>
  [MEAL_ACTIVATION_UPDATE_STATUS]: DefaultState<`${typeof API.MEAL_ACTIVATION_STATUS}`, 'put'>
} = () => ({
  [MEAL]: defaultState(),
  [MEAL_ALL]: defaultState(),
  [MEAL_LIST]: defaultState(),
  [MEAL_CREATE]: defaultState(),
  [MEAL_DELETE]: defaultState(),
  [MEAL_ACTIVATION]: defaultState(),
  [MEAL_ACTIVATION_ALL]: defaultState(),
  // [MEAL_ACTIVATION_CREATE]: defaultState(),
  [MEAL_ACTIVATION_DELETE]: defaultState(),
  [MEAL_ACTIVATION_UPDATE_STATUS]: defaultState(),
})

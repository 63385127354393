import { ErrorToast, SuccessToast } from '@components/Toasts'
import { watchSaga } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, call, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  REPORT,
  REPORT_ALL,
  REPORT_CREATE,
  REPORT_FIELDS,
  REPORT_FIELDS_FILTER,
  REPORT_LIST,
} from '../constants/actionTypes'

function watchReport() {
  return watchSaga({
    action: actionTypes[REPORT],
    api: API.report,
  })
}

function* watchReportFulfilled() {
  yield takeEvery(actionTypes[REPORT].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield call(onFulfilled, action.payload)
  })
}

function* watchReportRejected() {
  yield takeEvery(actionTypes[REPORT].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action.payload)
    toast.error(<ErrorToast text={pathOr('', ['message', 'payload'], action)} />)
  })
}

function watchReportCreate() {
  return watchSaga({
    action: actionTypes[REPORT_CREATE],
    api: API.reportCreate,
  })
}

function* watchReportCreateFulfilled() {
  yield takeEvery(actionTypes[REPORT_CREATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action.payload)
    toast.success(<SuccessToast />)
  })
}

function* watchReportCreateRejected() {
  yield takeEvery(actionTypes[REPORT_CREATE].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action.payload)
    toast.error(<ErrorToast text={pathOr('', ['message', 'payload'], action)} />)
  })
}

function watchReportList() {
  return watchSaga({
    action: actionTypes[REPORT_LIST],
    api: API.reportList,
  })
}

function watchReportAll() {
  return watchSaga({
    action: actionTypes[REPORT_ALL],
    api: API.reportAll,
  })
}

function watchReportFields() {
  return watchSaga({
    action: actionTypes[REPORT_FIELDS],
    api: API.reportFields,
  })
}

function* watchReportFieldsFulfilled() {
  yield takeEvery(actionTypes[REPORT_FIELDS].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action.payload)
  })
}

function* watchReportFieldsRejected() {
  yield takeEvery(actionTypes[REPORT_FIELDS].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action.payload)
    toast.error(<ErrorToast text={pathOr('', ['message', 'payload'], action)} />)
  })
}

function watchReportFieldFilter() {
  return watchSaga({
    action: actionTypes[REPORT_FIELDS_FILTER],
    api: API.reportFieldsFilter,
  })
}

function* watchReportFieldFilterFulfilled() {
  yield takeEvery(actionTypes[REPORT_FIELDS_FILTER].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchReportFieldFilterRejected() {
  yield takeEvery(actionTypes[REPORT_FIELDS_FILTER].rejected, function* (action) {
    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
    yield onRejected(action.payload)
    toast.error(<ErrorToast text={pathOr('', ['message', 'payload'], action)} />)
  })
}

export function* reportSaga() {
  yield all([
    fork(watchReport),
    fork(watchReportFulfilled),
    fork(watchReportRejected),
    fork(watchReportCreate),
    fork(watchReportCreateFulfilled),
    fork(watchReportCreateRejected),
    fork(watchReportList),
    fork(watchReportAll),
    fork(watchReportFields),
    fork(watchReportFieldsFulfilled),
    fork(watchReportFieldsRejected),
    fork(watchReportFieldFilter),
    fork(watchReportFieldFilterFulfilled),
    fork(watchReportFieldFilterRejected),
  ])
}

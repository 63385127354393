import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const hrApplication = data =>
  getInstance().get(`${API.HR_APPLICATION_FORM}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const hrApplicationCreate = data => getInstance().post(API.HR_APPLICATION_FORM, data)

export const hrApplicationDelete = data => getInstance().delete(`${API.HR_APPLICATION_FORM}${prop('id', data)}`)

export const hrApplicationList = data =>
  getInstance().get(API.HR_APPLICATION, {
    params: data,
  })

export const hrApplicationAll = data =>
  getInstance().get(API.HR_APPLICATION_ALL, {
    params: data,
  })

export const hrApplicationTypes = data =>
  getInstance().get(API.HR_APPLICATION_TYPES, {
    params: data,
  })

export const hrApplicationStatus = data => getInstance().put(API.HR_APPLICATION_STATUS, data)

export const hrApplicationFieldBatchCreate = data => getInstance().post(API.HR_APPLICATION_FIELD_BATCH, data)

export const hrApplicationFieldBatch = data =>
  getInstance().get(`${API.HR_APPLICATION_FIELD_BATCH}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

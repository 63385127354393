import queryString from 'query-string'
import { equals, isEmpty, omit, path, pickBy, prop, propOr, type } from 'ramda'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as selectors from '../redux/selector'

function useTableQueryAction({ totalElements }) {
  const history = useHistory()
  const { pathname, query } = useSelector(selectors.routerSelector)

  const SPSSQuery = {
    search: prop('search', query),
    page: propOr(0, 'page', query),
    sort: prop('sort', query),
    size: propOr(10, 'size', query),
  }

  function defineQuery({ queryValue, queryName, decode = false }) {
    if (decode) {
      return equals(type(queryValue), 'Null')
        ? { [queryName]: false }
        : queryValue
        ? { [queryName]: decodeURIComponent(queryValue) }
        : prop(queryName, SPSSQuery)
        ? { [queryName]: decodeURIComponent(prop(queryName, SPSSQuery)) }
        : {}
    } else {
      return queryValue || equals(+queryValue, 0)
        ? { [queryName]: queryValue }
        : prop(queryName, SPSSQuery)
        ? { [queryName]: prop(queryName, SPSSQuery) }
        : {}
    }
  }

  function tableQueryAction({ sort, search, page, size, encode = false }, restProps) {
    const sortQuery = defineQuery({ queryValue: sort, queryName: 'sort', decode: true }),
      searchQuery = defineQuery({ queryValue: search, queryName: 'search', decode: true }),
      pageQuery =
        prop('totalElements', restProps) && equals(prop('totalElements', restProps), size)
          ? { page: 0 }
          : defineQuery({ queryValue: page, queryName: 'page' }),
      sizeQuery = defineQuery({ queryValue: size, queryName: 'size' })
    const processedQuery = {
      ...omit(['search', 'sort', 'page', 'size'], query),
      ...sortQuery,
      ...searchQuery,
      ...pageQuery,
      ...sizeQuery,
    }
    const filteredQuery = pickBy(value => value, processedQuery)
    return history.push({
      pathname,
      search: queryString.stringify(
        {
          ...filteredQuery,
        },
        { encode }
      ),
      state: {
        from: pathname,
      },
    })
  }

  function onPageSizeChange(e) {
    const value = path(['target', 'value'], e)
    tableQueryAction(
      { size: +value, search: !isEmpty(prop('search', query)) ? prop('search', query) : null },
      { totalElements }
    )
  }

  function onPageChange({ selected }) {
    tableQueryAction({
      page: selected,
      size: prop('size', SPSSQuery),
      search: !isEmpty(prop('search', query)) ? prop('search', query) : null,
    })
  }

  function onTableSearch(value) {
    tableQueryAction({ search: !isEmpty(value) ? value : null, encode: true })
  }

  return {
    tableQueryAction,
    defineQuery,
    query,
    onPageSizeChange,
    onPageChange,
    onTableSearch,
  }
}

export default useTableQueryAction

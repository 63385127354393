import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const achievementRecordsList = data =>
  getInstance().get(API.ACHIEVEMENT_RECORDS, {
    params: data,
  })

export const achievementRecordsAll = data =>
  getInstance().get(API.ACHIEVEMENT_RECORDS_ALL, {
    params: data,
  })

export const achievementRecordsTypes = data =>
  getInstance().get(API.ACHIEVEMENT_RECORDS_TYPES, {
    params: data,
  })

export const getAchievementRecordById = data =>
  getInstance().get(`${API.ACHIEVEMENT_RECORDS}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const achievementRecordsChangeStatus = data => getInstance().put(API.ACHIEVEMENT_RECORDS_CHANGE_STATUS, data)

export const achievementRecordsStudent = data =>
  getInstance().get(`${API.ACHIEVEMENT_RECORDS_STUDENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const achievementRecordsCreate = data => getInstance().post(API.ACHIEVEMENT_RECORDS, data)

export const achievementRecordsDelete = data => getInstance().delete(`${API.ACHIEVEMENT_RECORDS}${prop('id', data)}`)

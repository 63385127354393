import { watchSaga, watchSagaFulfilled, watchSagaRejected } from '@helpers/customSaga'
import * as actions from '@store/actions'
import { path, pathOr, prop } from 'ramda'
import { all, fork, put, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  ENROLLMENT,
  ENROLLMENT_ALL,
  ENROLLMENT_COURSES,
  ENROLLMENT_CREATE,
  ENROLLMENT_DELETE,
  ENROLLMENT_LIST,
  ENROLLMENT_STATUS,
} from '../constants/actionTypes'

function watchEnrollment() {
  return watchSaga({
    action: actionTypes[ENROLLMENT],
    api: API.enrollment,
  })
}

function watchEnrollmentCreate() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_CREATE],
    api: API.enrollmentCreate,
  })
}

function* watchEnrollmentCreateFulfilled() {
  yield takeEvery(actionTypes[ENROLLMENT_CREATE].fulfilled, function* (payload) {
    const hasInfo = path(['payload', 'has_info'], payload)
    const openModal = pathOr(() => {}, ['args', 'openModal'], payload)
    hasInfo ? openModal(undefined, prop('payload', payload)) : yield put(actions.authUserInfo())
  })
}

function* watchEnrollmentCreateRejected() {
  yield takeEvery(actionTypes[ENROLLMENT_CREATE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchEnrollmentDelete() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_DELETE],
    api: API.enrollmentDelete,
  })
}

function watchEnrollmentList() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_LIST],
    api: API.enrollmentList,
  })
}

function watchEnrollmentAll() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_ALL],
    api: API.enrollmentAll,
  })
}

function watchEnrollmentStatus() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_STATUS],
    api: API.enrollmentStatus,
  })
}

function watchEnrollmentStatusFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ENROLLMENT_STATUS],
    filter: {
      filterAction: actions.enrollmentList,
    },
    message: {
      title: 'Success',
    },
  })
}

function watchEnrollmentStatusRejected() {
  return watchSagaRejected({
    action: actionTypes[ENROLLMENT_STATUS],
    message: true,
  })
}

function watchEnrollmentCourses() {
  return watchSaga({
    action: actionTypes[ENROLLMENT_COURSES],
    api: API.enrollmentCourses,
  })
}

function watchEnrollmentCoursesRejected() {
  return watchSagaRejected({
    action: actionTypes[ENROLLMENT_COURSES],
    message: true,
  })
}

export function* enrollmentSaga() {
  yield all([
    fork(watchEnrollment),
    fork(watchEnrollmentCreate),
    fork(watchEnrollmentCreateFulfilled),
    fork(watchEnrollmentCreateRejected),
    fork(watchEnrollmentDelete),
    fork(watchEnrollmentList),
    fork(watchEnrollmentAll),
    fork(watchEnrollmentStatus),
    fork(watchEnrollmentStatusFulfilled),
    fork(watchEnrollmentStatusRejected),
    fork(watchEnrollmentCourses),
    fork(watchEnrollmentCoursesRejected),
  ])
}

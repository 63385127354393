import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, MEAL, MEAL_ACTIVATION, MEAL_ACTIVATION_ALL, MEAL_ALL, MEAL_LIST } from '../constants/actionTypes'

const mealSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const mealSelector = createSelector(mealSelectors, state => state[MEAL])

export const mealListSelector = createSelector(mealSelectors, state => state[MEAL_LIST])

export const mealAllSelector = createSelector(mealSelectors, state => state[MEAL_ALL])

export const mealActivationSelector = createSelector(mealSelectors, state => state[MEAL_ACTIVATION])

export const mealActivationAllSelector = createSelector(mealSelectors, state => state[MEAL_ACTIVATION_ALL])

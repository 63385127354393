import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const stCourseApplicationRequest = data => getInstance().post(API.ST_COURSE_APPLICATION_REQUEST, data)

export const stCourseApplicationRequestAll = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_REQUEST_ALL, {
    params: data,
  })

export const stCourseApplicationRequestEdit = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_REQUEST_EDIT, {
    params: data,
  })

export const stCourseApplicationRequestUpdate = data =>
  getInstance().put(API.ST_COURSE_APPLICATION_REQUEST_UPDATE, data)

import { createAction } from '@helpers/createAction'

export const DEPARTMENT = 'DEPARTMENT'
export const DEPARTMENT_CREATE = 'DEPARTMENT_CREATE'
export const DEPARTMENT_DELETE = 'DEPARTMENT_DELETE'
export const DEPARTMENT_LIST = 'DEPARTMENT_LIST'
export const DEPARTMENT_ALL = 'DEPARTMENT_ALL'
export const DEPARTMENT_LABELS = 'DEPARTMENT_LABELS'

export default {
  [DEPARTMENT]: createAction(DEPARTMENT),
  [DEPARTMENT_CREATE]: createAction(DEPARTMENT_CREATE),
  [DEPARTMENT_DELETE]: createAction(DEPARTMENT_DELETE),
  [DEPARTMENT_LIST]: createAction(DEPARTMENT_LIST),
  [DEPARTMENT_ALL]: createAction(DEPARTMENT_ALL),
  [DEPARTMENT_LABELS]: createAction(DEPARTMENT_LABELS),
}

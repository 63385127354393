import { SuccessToast } from '@components/Toasts'
import { watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import ELibraryApis from '../api'
import {
  E_LIBRARY,
  E_LIBRARY_ALL,
  E_LIBRARY_CREATE,
  E_LIBRARY_DELETE,
  E_LIBRARY_LIST,
  E_LIBRARY_TYPES,
} from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

function watchELibrary() {
  return watchSaga({
    action: actionTypes[E_LIBRARY],
    api: ELibraryApis[E_LIBRARY],
  })
}

function watchELibraryRejected() {
  return watchSagaRejected({
    action: actionTypes[E_LIBRARY],
    message: true,
  })
}

function watchELibraryTypes() {
  return watchSaga({
    action: actionTypes[E_LIBRARY_TYPES],
    api: ELibraryApis[E_LIBRARY_TYPES],
  })
}

function watchELibraryTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[E_LIBRARY_TYPES],
    message: true,
  })
}

function watchELibraryList() {
  return watchSaga({
    action: actionTypes[E_LIBRARY_LIST],
    api: ELibraryApis[E_LIBRARY_LIST],
  })
}

function watchELibraryListRejected() {
  return watchSagaRejected({
    action: actionTypes[E_LIBRARY_LIST],
    message: true,
  })
}

function watchELibraryAll() {
  return watchSaga({
    action: actionTypes[E_LIBRARY_ALL],
    api: ELibraryApis[E_LIBRARY_ALL],
  })
}

function watchELibraryAllRejected() {
  return watchSagaRejected({
    action: actionTypes[E_LIBRARY_ALL],
    message: true,
  })
}

function watchELibraryCreate() {
  return watchSaga({
    action: actionTypes[E_LIBRARY_CREATE],
    api: ELibraryApis[E_LIBRARY_CREATE],
  })
}

function* watchELibraryCreateFulfilled() {
  yield takeEvery(actionTypes[E_LIBRARY_CREATE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled()
    toast.success(<SuccessToast text='Successfully added' />)
  })
}

function watchELibraryCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[E_LIBRARY_CREATE],
    message: true,
  })
}

function watchELibraryDelete() {
  return watchSaga({
    action: actionTypes[E_LIBRARY_DELETE],
    api: ELibraryApis[E_LIBRARY_DELETE],
  })
}

function* watchELibraryDeleteFulfilled() {
  yield takeEvery(actionTypes[E_LIBRARY_DELETE].fulfilled, function* (action) {
    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
    yield onFulfilled(action)
  })
}

function* watchELibraryDeleteRejected() {
  yield takeEvery(actionTypes[E_LIBRARY_DELETE].rejected, function* (action) {
    const callback = pathOr(() => {}, ['args', 'callback'], action)
    yield callback(action, pathOr('Sorry cannot perform Your request', ['payload', 'message'], action))
  })
}

export function* eLibrarySaga() {
  yield all([
    fork(watchELibrary),
    fork(watchELibraryRejected),
    fork(watchELibraryTypes),
    fork(watchELibraryTypesRejected),
    fork(watchELibraryList),
    fork(watchELibraryListRejected),
    fork(watchELibraryAll),
    fork(watchELibraryAllRejected),
    fork(watchELibraryCreate),
    fork(watchELibraryCreateFulfilled),
    fork(watchELibraryCreateRejected),
    fork(watchELibraryDelete),
    fork(watchELibraryDeleteFulfilled),
    fork(watchELibraryDeleteRejected),
  ])
}

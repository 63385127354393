import { getInstance } from '@helpers/httpClient'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

export const stCourseApplication = data =>
  getInstance().get(`${API.ST_COURSE_APPLICATION_FORM}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const stCourseApplicationCreate = data => getInstance().post(API.ST_COURSE_APPLICATION_FORM, data)

export const stCourseApplicationDelete = data =>
  getInstance().delete(`${API.ST_COURSE_APPLICATION_FORM}${prop('id', data)}`)

export const stCourseApplicationList = data =>
  getInstance().get(API.ST_COURSE_APPLICATION, {
    params: data,
  })

export const stCourseApplicationAll = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_ALL, {
    params: data,
  })

export const stCourseApplicationTypes = data =>
  getInstance().get(API.ST_COURSE_APPLICATION_TYPES, {
    params: data,
  })

export const stCourseApplicationStatus = data => getInstance().put(API.ST_COURSE_APPLICATION_STATUS, data)

export const stCourseApplicationFieldBatchCreate = data =>
  getInstance().post(API.ST_COURSE_APPLICATION_FIELD_BATCH, data)

export const stCourseApplicationFieldBatch = data =>
  getInstance().get(`${API.ST_COURSE_APPLICATION_FIELD_BATCH}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

import { createAction } from '@helpers/createAction'

export const SEMESTER = 'SEMESTER'
export const SEMESTER_CREATE = 'SEMESTER_CREATE'
export const SEMESTER_DELETE = 'SEMESTER_DELETE'
export const SEMESTER_LIST = 'SEMESTER_LIST'
export const SEMESTER_ALL = 'SEMESTER_ALL'

export default {
  [SEMESTER]: createAction(SEMESTER),
  [SEMESTER_CREATE]: createAction(SEMESTER_CREATE),
  [SEMESTER_DELETE]: createAction(SEMESTER_DELETE),
  [SEMESTER_LIST]: createAction(SEMESTER_LIST),
  [SEMESTER_ALL]: createAction(SEMESTER_ALL),
}

import actionTypes, {
  HR_APPLICATION,
  HR_APPLICATION_CREATE,
  HR_APPLICATION_DELETE,
  HR_APPLICATION_LIST,
  HR_APPLICATION_ALL,
  HR_APPLICATION_FIELD_BATCH,
  HR_APPLICATION_FIELD_BATCH_CREATE,
  HR_APPLICATION_CATEGORY,
  HR_APPLICATION_CATEGORY_CREATE,
  HR_APPLICATION_CATEGORY_DELETE,
  HR_APPLICATION_CATEGORY_LIST,
  HR_APPLICATION_CATEGORY_ALL,
  HR_APPLICATION_TYPES,
  HR_APPLICATION_STATUS,
} from '../constants/actionTypes'
import * as TYPES from '../constants/actionTypes'

export const hrApplication = payload => ({
  type: actionTypes[HR_APPLICATION].pending,
  payload,
})

export const hrApplicationClear = () => ({
  type: actionTypes[HR_APPLICATION].clear,
})

export const hrApplicationCreate = payload => ({
  type: actionTypes[HR_APPLICATION_CREATE].pending,
  payload,
})

export const hrApplicationDelete = payload => ({
  type: actionTypes[HR_APPLICATION_DELETE].pending,
  payload,
})

export const hrApplicationList = payload => ({
  type: actionTypes[HR_APPLICATION_LIST].pending,
  payload,
})

export const hrApplicationListClear = payload => ({
  type: actionTypes[HR_APPLICATION_LIST].clear,
  payload,
})

export const hrApplicationAll = payload => ({
  type: actionTypes[HR_APPLICATION_ALL].pending,
  payload,
})

export const hrApplicationAllClear = () => ({
  type: actionTypes[HR_APPLICATION_ALL].clear,
})

export const hrApplicationTypes = payload => ({
  type: actionTypes[HR_APPLICATION_TYPES].pending,
  payload,
})

export const hrApplicationTypesClear = () => ({
  type: actionTypes[HR_APPLICATION_TYPES].clear,
})

export const hrApplicationChangeStatus = payload => ({
  type: actionTypes[HR_APPLICATION_STATUS].pending,
  payload,
})

export const hrApplicationFieldBatch = payload => ({
  type: actionTypes[HR_APPLICATION_FIELD_BATCH].pending,
  payload,
})

export const hrApplicationFieldBatchClear = () => ({
  type: actionTypes[HR_APPLICATION_FIELD_BATCH].clear,
})

export const hrApplicationFieldBatchCreate = payload => ({
  type: actionTypes[HR_APPLICATION_FIELD_BATCH_CREATE].pending,
  payload,
})

export const hrApplicationCategory = payload => ({
  type: actionTypes[HR_APPLICATION_CATEGORY].pending,
  payload,
})

export const hrApplicationCategoryCreate = payload => ({
  type: actionTypes[HR_APPLICATION_CATEGORY_CREATE].pending,
  payload,
})

export const hrApplicationCategoryDelete = payload => ({
  type: actionTypes[HR_APPLICATION_CATEGORY_DELETE].pending,
  payload,
})

export const hrApplicationCategoryList = payload => ({
  type: actionTypes[HR_APPLICATION_CATEGORY_LIST].pending,
  payload,
})

export const hrApplicationCategoryListClear = () => ({
  type: actionTypes[HR_APPLICATION_CATEGORY_LIST].clear,
})

export const hrApplicationCategoryAll = payload => ({
  type: actionTypes[HR_APPLICATION_CATEGORY_ALL].pending,
  payload,
})

export const hrApplicationRequest = payload => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST].pending,
  payload,
})

export const hrApplicationRequestAll = payload => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST_ALL].pending,
  payload,
})

export const hrApplicationRequestEdit = payload => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST_EDIT].pending,
  payload,
})

export const hrApplicationRequestEditClear = () => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST_EDIT].clear,
})

export const hrApplicationRequestUpdate = payload => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST_UPDATE].pending,
  payload,
})

export const hrApplicationRequestUpdateClear = () => ({
  type: actionTypes[TYPES.HR_APPLICATION_REQUEST_UPDATE].clear,
})

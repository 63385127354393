import { createAction } from '@helpers/createAction'

export const EDUCATION_PLACE = 'EDUCATION_PLACE'
export const EDUCATION_PLACE_CREATE = 'EDUCATION_PLACE_CREATE'
export const EDUCATION_PLACE_DELETE = 'EDUCATION_PLACE_DELETE'
export const EDUCATION_PLACE_LIST = 'EDUCATION_PLACE_LIST'
export const EDUCATION_PLACE_ALL = 'EDUCATION_PLACE_ALL'

export default {
  [EDUCATION_PLACE]: createAction(EDUCATION_PLACE),
  [EDUCATION_PLACE_CREATE]: createAction(EDUCATION_PLACE_CREATE),
  [EDUCATION_PLACE_DELETE]: createAction(EDUCATION_PLACE_DELETE),
  [EDUCATION_PLACE_LIST]: createAction(EDUCATION_PLACE_LIST),
  [EDUCATION_PLACE_ALL]: createAction(EDUCATION_PLACE_ALL),
}

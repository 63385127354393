import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, TYPES, TYPES_CHILD } from '../constants/actionTypes'

export const typesSelector = createSelector(
  (state: RootState) => prop(GLOBAL_STATE, state),
  data => data[TYPES]
)

export const typesChildSelector = createSelector(
  (state: RootState) => prop(GLOBAL_STATE, state),
  data => data[TYPES_CHILD]
)

import { ApiFunc, getInstance } from '@helpers/httpClient'
import { dissoc } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const mealAll: ApiFunc<`${typeof API.MEAL_ALL}`, 'get'> = data =>
  instance.get(API.MEAL_ALL, {
    params: data,
  })

export const mealList: ApiFunc<`${typeof API.MEAL}`, 'get'> = data =>
  instance.get(API.MEAL, {
    params: data,
  })

export const mealCreate: ApiFunc<`${typeof API.MEAL}`, 'post'> = data => instance.post(API.MEAL, data)

export const mealDelete: ApiFunc<`${typeof API.MEAL}{ids}`, 'delete'> = data =>
  instance.delete(`${API.MEAL}${'ids' in data ? data.ids : data.name}`)

export const meal: ApiFunc<`${typeof API.MEAL}{id}`, 'get'> = data =>
  instance.get(`${API.MEAL}${data.id}`, {
    params: dissoc('id', data),
  })

export const mealActivationAll: ApiFunc<`${typeof API.MEAL_ACTIVATION_ALL}`, 'get'> = data =>
  instance.get(API.MEAL_ACTIVATION_ALL, {
    params: data,
  })

// export const mealActivationCreate: ApiFunc<`${typeof API.MEAL_ACTIVATION}`, 'post'> = data =>
//   instance.post(API.MEAL_ACTIVATION, data)

export const mealActivationDelete: ApiFunc<`${typeof API.MEAL_ACTIVATION}{id}`, 'delete'> = data =>
  instance.delete(`${API.MEAL_ACTIVATION}${'id' in data ? data.id : data.name}`)

export const mealActivationUpdateStatus: ApiFunc<`${typeof API.MEAL_ACTIVATION_STATUS}`, 'put'> = data =>
  instance.put(API.MEAL_ACTIVATION_STATUS, data)

export const mealActivation: ApiFunc<`${typeof API.MEAL_ACTIVATION}{id}`, 'get'> = data =>
  instance.get(`${API.MEAL_ACTIVATION}${data.id}`, {
    params: dissoc('id', data),
  })

import actionTypes, {
  STUDENT,
  STUDENT_ACHIEVEMENT_RECORDS,
  STUDENT_ALL,
  STUDENT_CONTACT_INFO,
  STUDENT_DELETE,
  STUDENT_GUARD_CREATE,
  STUDENT_GUARD_LIST,
  STUDENT_LIST,
  STUDENT_PERSONAL_INFO,
  STUDENT_STATUS_UPDATE,
  STUDENT_USERNAME,
  GENERATE_TRANSCRIPT,
} from '../constants/actionTypes'

export const student = payload => ({
  type: actionTypes[STUDENT].pending,
  payload,
})

export const studentClear = () => ({
  type: actionTypes[STUDENT].clear,
})

export const studentDelete = payload => ({
  type: actionTypes[STUDENT_DELETE].pending,
  payload,
})

export const studentList = payload => ({
  type: actionTypes[STUDENT_LIST].pending,
  payload,
})

export const studentListClear = () => ({
  type: actionTypes[STUDENT_LIST].clear,
})

export const studentAchievementRecords = payload => ({
  type: actionTypes[STUDENT_ACHIEVEMENT_RECORDS].pending,
  payload,
})

export const studentAchievementRecordsClear = () => ({
  type: actionTypes[STUDENT_ACHIEVEMENT_RECORDS].clear,
})

export const studentAll = payload => ({
  type: actionTypes[STUDENT_ALL].pending,
  payload,
})

export const studentAllClear = () => ({
  type: actionTypes[STUDENT_ALL].clear,
})

export const studentGuardCreate = payload => ({
  type: actionTypes[STUDENT_GUARD_CREATE].pending,
  payload,
})

export const studentGuardList = payload => ({
  type: actionTypes[STUDENT_GUARD_LIST].pending,
  payload,
})

export const studentGuardListClear = () => ({
  type: actionTypes[STUDENT_GUARD_LIST].clear,
})

export const studentUsername = payload => ({
  type: actionTypes[STUDENT_USERNAME].pending,
  payload,
})

export const studentUsernameClear = () => ({
  type: actionTypes[STUDENT_USERNAME].clear,
})

export const studentPersonalInfo = payload => ({
  type: actionTypes[STUDENT_PERSONAL_INFO].pending,
  payload,
})

export const studentContactInfo = payload => ({
  type: actionTypes[STUDENT_CONTACT_INFO].pending,
  payload,
})

export const studentStatusUpdate = payload => ({
  type: actionTypes[STUDENT_STATUS_UPDATE].pending,
  payload,
})

export const generateTranscript = payload => ({
  type: actionTypes[GENERATE_TRANSCRIPT].pending,
  payload,
})
